import { AuthService } from '../auth/api-services';
import type { PIMSRequest, PIMSMatch, SimpleMatchRequest } from './models';

const ANALYTIX_URL = import.meta.env.VITE_ANALYTIX_API_URL;
const AUTH_URL = import.meta.env.VITE_AUTH_API_URL;
const KOMMIN_URL = import.meta.env.VITE_KOMMIN_API_URL;
const API_KEY = import.meta.env.VITE_API_KEY;

export class PIMSService {
    private static getHeaders(): Record<string, string> {
        const baseHeaders = {
            'Content-Type': 'application/json',
            'X-API-Key': API_KEY || ''
        };

        const sessionData = AuthService.getSessionData();
        if (sessionData) {
            return {
                ...baseHeaders,
                'X-Session-ID': sessionData.session_id,
                'X-Public-Key': sessionData.public_key,
                'X-User-ID': sessionData.userId?.toString() || ''
            };
        }

        return baseHeaders;
    }

    static async postUsersByGeohash(geohash: string, excludedUserIds: number[], filterByLocation: boolean): Promise<number[] | null> {
        try {
            const response = await fetch(`${AUTH_URL}/get-users-by-geohash`, {
                method: 'POST',
                headers: this.getHeaders(),
                body: JSON.stringify({
                    geohash,
                    excluded_ids: excludedUserIds,
                    location_pref: filterByLocation
                })
            });

            if (!response.ok) return null;
            const data = await response.json();
            return data.data;
        } catch (error) {
            return null;
        }
    }

    static async getRetrieveExcludeUsers(userId: number) {
        try {
            const response = await fetch(`${ANALYTIX_URL}/retrieve-excluded-user-ids?userId=${userId}`, {
                headers: this.getHeaders()
            });

            if (!response.ok) return null;
            return await response.json();
        } catch (error) {
            return null;
        }
    }

    static async getTopITagsForUserId(userId: number) {
        try {
            const response = await fetch(`${KOMMIN_URL}/get-top-itags-for-user-id?user_id=${userId}`, {
                headers: this.getHeaders()
            });

            if (!response.ok) return null;
            const data = await response.json();
            return data.topTags?.map((tag: { Tag: string }) => tag.Tag) || null;
        } catch (error) {
            return null;
        }
    }

    static async simpleMatching(request: SimpleMatchRequest): Promise<PIMSMatch[] | null> {
        try {
            const response = await fetch(`${ANALYTIX_URL}/simple-matching`, {
                method: 'POST',
                headers: this.getHeaders(),
                body: JSON.stringify(request)
            });

            if (!response.ok) return null;
            const data = await response.json();
            return data.matches;
        } catch (error) {
            return null;
        }
    }

    static async personalityInterestMatchSystem(request: PIMSRequest): Promise<PIMSMatch[] | null> {
        try {
            const response = await fetch(`${ANALYTIX_URL}/pims`, {
                method: 'POST',
                headers: this.getHeaders(),
                body: JSON.stringify(request)
            });

            if (!response.ok) return null;
            const data = await response.json();
            return data.matches;
        } catch (error) {
            return null;
        }
    }

    static async getAnalytixByAnalytixId(analytixId: number) {
        try {
            const response = await fetch(`${ANALYTIX_URL}/get-analytix-by-analytix-id?analytix_id=${analytixId}`, {
                headers: this.getHeaders()
            });

            if (!response.ok) return null;
            return await response.json();
        } catch (error) {
            return null;
        }
    }
}