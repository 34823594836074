import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { navigate } from '../../app-router';
import { Hash } from '../../utilities/hash';
import { ModalForm } from '../reusable/modal-form';

export class NearbyContent {
    private element: HTMLDivElement;
    private map: L.Map | null = null;
    private markers: L.Marker[] = [];
    private userLocationMarker: L.Marker | null = null;
    private modalForm: ModalForm;

    constructor() {
        this.modalForm = new ModalForm();
        this.modalForm.mount(document.body);
        this.element = document.createElement('div');
        this.element.className = 'flex flex-col h-full';

        // City Bar
        const cityBar = document.createElement('div');
        cityBar.className = `
            w-full 
            flex 
            items-center 
            gap-2 
            overflow-x-auto 
            pb-4 
            scrollbar-thin 
            scrollbar-track-white/5 
            scrollbar-thumb-white/10 
            hover:scrollbar-thumb-white/20
        `;

        // Define cities with their coordinates
        const cities = [
            { 
                name: 'My Location', 
                icon: 'fa-location-dot'
            },
            { name: 'New York, NY', coords: [40.7128, -74.0060] },
            { name: 'Los Angeles, CA', coords: [34.0522, -118.2437] },
            { name: 'Austin, TX', coords: [30.2672, -97.7431] },
            { name: 'Chicago, IL', coords: [41.8781, -87.6298] },
            { name: 'London, UK', coords: [51.5074, -0.1278] },
            { name: 'Tokyo, JP', coords: [35.6762, 139.6503] },
            { name: 'Paris, FR', coords: [48.8566, 2.3522] },
            { name: 'Berlin, DE', coords: [52.5200, 13.4050] }
        ];

        cities.forEach(city => {
            const cityButton = document.createElement('button');
            cityButton.className = `
                flex-shrink-0
                px-4 
                py-2 
                bg-white/5 
                hover:bg-white/10 
                border 
                border-white/10 
                rounded-lg 
                text-white/70 
                hover:text-white/90 
                text-sm 
                transition-all
                duration-200
                hover:border-purple-500/30
                flex
                items-center
                gap-2
            `;
            
            if (city.name === 'My Location') {
                // Test geolocation first
                if ('geolocation' in navigator) {
                    navigator.geolocation.getCurrentPosition(
                        // Success - show button normally
                        () => {
                            if (city.icon) {
                                cityButton.innerHTML = `<i class="fas ${city.icon}"></i>`;
                            }
                            cityButton.innerHTML += city.name;
                        },
                        // Error - hide button
                        () => {
                            cityButton.remove();
                        }
                    );
                } else {
                    // No geolocation support - don't add button
                    return;
                }
            } else {
                // Regular city button
                if (city.icon) {
                    cityButton.innerHTML = `<i class="fas ${city.icon}"></i>`;
                }
                cityButton.innerHTML += city.name;
            }

            cityButton.addEventListener('click', () => {
                if (city.name === 'My Location') {
                    if ('geolocation' in navigator) {
                        navigator.geolocation.getCurrentPosition(
                            (position) => {
                                this.setUserLocation(position);
                            },
                            (error) => {
                                console.error('Location error:', error);
                            },
                            {
                                enableHighAccuracy: true,
                                timeout: 5000,
                                maximumAge: 0
                            }
                        );
                    }
                } else {
                    this.map?.setView(city.coords as L.LatLngExpression, 12);
                }
            });
            cityBar.appendChild(cityButton);
        });

        // View Options Bar
        const viewOptionsBar = document.createElement('div');
        viewOptionsBar.className = `
            w-full 
            flex 
            items-center 
            gap-4 
            mb-4
        `;

        // Virtual Events Button
        const virtualEventsButton = document.createElement('button');
        virtualEventsButton.className = `
            flex-1
            px-4 
            py-3
            bg-gradient-to-r 
            from-purple-500/20 
            to-indigo-500/20
            hover:from-purple-500/30 
            hover:to-indigo-500/30
            border 
            border-purple-500/20
            rounded-xl
            text-white/90
            transition-all
            duration-200
            flex
            items-center
            justify-center
            gap-2
            font-medium
        `;
        virtualEventsButton.innerHTML = `
            <i class="fas fa-vr-cardboard"></i>
            <span>Virtual Events</span>
        `;

        // Events List Button
        const eventsListButton = document.createElement('button');
        eventsListButton.className = `
            flex-1
            px-4 
            py-3
            bg-gradient-to-r 
            from-rose-500/20 
            to-orange-500/20
            hover:from-rose-500/30 
            hover:to-orange-500/30
            border 
            border-rose-500/20
            rounded-xl
            text-white/90
            transition-all
            duration-200
            flex
            items-center
            justify-center
            gap-2
            font-medium
        `;
        eventsListButton.innerHTML = `
            <i class="fas fa-list"></i>
            <span>Events List</span>
        `;

        viewOptionsBar.append(virtualEventsButton, eventsListButton);

        // Search wrapper with conditional invite button
        const searchWrapper = document.createElement('div');
        searchWrapper.className = 'flex items-center gap-3 mb-4';

        // Search Input
        const searchInput = document.createElement('input');
        searchInput.type = 'text';
        searchInput.placeholder = 'Start typing an address...';
        searchInput.className = `
            flex-1
            bg-white/5
            border
            border-white/10
            rounded-xl
            px-4
            py-3.5
            text-white/90
            placeholder:text-white/30
            focus:outline-none
            focus:border-purple-500/50
            text-base
        `;

        // Only append search input by default
        searchWrapper.appendChild(searchInput);

        // Add invite button only if logged in
        const userId = localStorage.getItem('userId');
        if (userId) {
            const inviteButton = document.createElement('button');
            inviteButton.className = `
                relative 
                w-[52px]
                h-[52px]
                bg-gradient-to-r 
                from-sky-400/20 
                to-blue-500/20
                hover:from-sky-400/30 
                hover:to-blue-500/30
                rounded-xl
                transition-all
                duration-200
                border
                border-sky-500/20
                hover:border-sky-500/30
                transform
                hover:scale-105
                hover:shadow-lg
                hover:shadow-sky-500/10
                flex
                items-center
                justify-center
            `;
            inviteButton.innerHTML = `
                <i class="fas fa-envelope text-sky-300 text-lg"></i>
                <div class="absolute -top-2.5 -right-2.5 w-7 h-7 bg-indigo-500 rounded-full text-white text-sm font-medium flex items-center justify-center shadow-lg border border-white/10">
                    3
                </div>
            `;

            searchWrapper.appendChild(inviteButton);
            
            inviteButton.addEventListener('click', () => {
                this.showInvitationsModal();
            });
        }

        // Filter Button
        const filterButton = document.createElement('button');
        filterButton.className = `
            px-4
            py-2
            bg-purple-500/20
            hover:bg-purple-500/30
            border
            border-purple-500/30
            rounded-lg
            text-white/90
            transition-colors
            flex
            items-center
            gap-2
        `;
        filterButton.innerHTML = `
            <i class="fas fa-filter"></i>
            <span>Filter</span>
        `;

        // Add after the filter button
        const timeFilterDropdown = document.createElement('div');
        timeFilterDropdown.className = 'absolute top-4 right-4 z-20';

        const timeFilterButton = document.createElement('button');
        timeFilterButton.className = `
            px-4 
            py-2.5
            bg-gradient-to-r 
            from-gray-800/80 
            to-gray-700/80
            hover:from-gray-700/90 
            hover:to-gray-600/90
            rounded-lg
            text-white/70
            hover:text-white/90
            shadow-lg
            transition-all
            duration-200
            flex
            items-center
            gap-2
            text-sm
            z-20
            border
            border-white/10
        `;
        timeFilterButton.innerHTML = `
            <i class="fas fa-clock"></i>
            <span id="selected-time-range">Next 30 Days</span>
            <i class="fas fa-chevron-down ml-1 text-xs opacity-50"></i>
        `;

        const dropdownContent = document.createElement('div');
        dropdownContent.className = `
            absolute 
            right-0 
            mt-2 
            w-48 
            bg-gray-900 
            border 
            border-white/10 
            rounded-lg 
            shadow-xl 
            py-2 
            z-30
            hidden
        `;

        const timeRanges = [
            { label: 'Today', value: 'today' },
            { label: 'Next 7 Days', value: '7days' },
            { label: 'Next 30 Days', value: '30days' },
            { label: 'Next 60 Days', value: '60days' },
            { label: 'Next 90 Days', value: '90days' },
            { label: 'Next 365 Days', value: '365days' }
        ];

        timeRanges.forEach(range => {
            const option = document.createElement('button');
            option.className = `
                w-full 
                px-4 
                py-2 
                text-left 
                text-white/70 
                hover:text-white/90 
                text-sm
                hover:bg-white/5
                transition-colors
                flex
                items-center
                gap-2
            `;
            option.innerHTML = `
                <i class="fas fa-check opacity-0 w-4"></i>
                <span>${range.label}</span>
            `;
            
            if (range.value === '30days') {
                option.querySelector('i')!.classList.remove('opacity-0');
            }

            option.addEventListener('click', () => {
                // Update selected text
                document.getElementById('selected-time-range')!.textContent = range.label;
                
                // Update checkmarks
                dropdownContent.querySelectorAll('i').forEach(icon => icon.classList.add('opacity-0'));
                option.querySelector('i')!.classList.remove('opacity-0');
                
                // Hide dropdown
                dropdownContent.classList.add('hidden');
                
                // Handle filter change
                this.filterEventsByTimeRange(range.value);
            });
            
            dropdownContent.appendChild(option);
        });

        // Toggle dropdown
        timeFilterButton.addEventListener('click', () => {
            dropdownContent.classList.toggle('hidden');
        });

        // Close dropdown when clicking outside
        document.addEventListener('click', (e) => {
            if (!timeFilterDropdown.contains(e.target as Node)) {
                dropdownContent.classList.add('hidden');
            }
        });

        timeFilterDropdown.append(timeFilterButton, dropdownContent);

        // Create a wrapper div for the map and buttons
        const mapWrapper = document.createElement('div');
        mapWrapper.className = 'relative';

        // Map Container
        const mapContainer = document.createElement('div');
        mapContainer.id = 'nearby-map';
        mapContainer.className = `
            w-full 
            h-[calc(100vh-12rem)] 
            rounded-2xl 
            overflow-hidden 
            border 
            border-white/10 
            shadow-lg
            z-10
        `;

        // Create Event Button (left side)
        const createEventButton = document.createElement('button');
        createEventButton.className = `
            absolute 
            top-4 
            left-4 
            px-4 
            py-2.5
            bg-gradient-to-r 
            from-emerald-500 
            to-teal-500
            hover:from-emerald-600 
            hover:to-teal-600
            rounded-lg
            text-white
            shadow-lg
            transition-all
            duration-200
            flex
            items-center
            gap-2
            font-medium
            text-sm
            z-20
            border
            border-white/10
        `;
        createEventButton.innerHTML = `
            <i class="fas fa-plus"></i>
            <span>Create Event</span>
        `;

        // Time Filter (right side)
        timeFilterDropdown.className = 'absolute top-4 right-4 z-20';

        // Append elements to map wrapper
        mapWrapper.appendChild(mapContainer);
        mapWrapper.appendChild(createEventButton);
        mapWrapper.appendChild(timeFilterDropdown);

        // Add click handler
        createEventButton.addEventListener('click', () => {
            navigate('/nearby/new');
        });

        // Append everything in correct order
        this.element.append(searchWrapper, cityBar, viewOptionsBar, mapWrapper);

        // Add search functionality
        let searchTimeout: NodeJS.Timeout;
        searchInput.addEventListener('input', (e) => {
            const target = e.target as HTMLInputElement;
            clearTimeout(searchTimeout);
            searchTimeout = setTimeout(() => {
                if (target.value.trim()) {
                    this.searchAddress(target.value);
                }
            }, 500); // Debounce for 500ms
        });

        searchInput.addEventListener('keypress', (e) => {
            if (e.key === 'Enter') {
                const target = e.target as HTMLInputElement;
                if (target.value.trim()) {
                    this.searchAddress(target.value);
                }
            }
        });

        // In the virtual events button click handler
        virtualEventsButton.addEventListener('click', () => {
            navigate('/nearby/virtual-events');
        });

        // In the events list button click handler
        eventsListButton.addEventListener('click', () => {
            navigate('/nearby/events-list');
        });
    }

    private setUserLocation(position: GeolocationPosition) {
        const { latitude, longitude } = position.coords;
        this.map?.setView([latitude, longitude], 13);
        
        if (this.userLocationMarker) {
            this.userLocationMarker.remove();
        }
        
        this.userLocationMarker = L.marker([latitude, longitude], {
            icon: L.divIcon({
                className: 'user-location-marker',
                html: '<div class="w-4 h-4 bg-purple-500/70 rounded-full ring-4 ring-purple-500/20"></div>'
            })
        }).addTo(this.map!);
    }

    private async initializeMap() {
        if (!this.map) {
            // Wait for next tick to ensure container is mounted
            await new Promise(resolve => setTimeout(resolve, 0));
            
            // Initialize map with New York as default view
            this.map = L.map('nearby-map', {
                zoomControl: false,
                attributionControl: false
            }).setView([40.7128, -74.0060], 10);
            
            // Add controls and tile layer
            L.control.zoom({ position: 'bottomright' }).addTo(this.map);
            L.control.attribution({ position: 'bottomleft' })
                .addAttribution('OpenStreetMap, ©CartoDB')
                .addTo(this.map);

            L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
                maxZoom: 19,
                minZoom: 3
            }).addTo(this.map);

            // Add example pins first
            this.addExamplePins();

            // Then get user location and center map
            if ('geolocation' in navigator) {
                navigator.geolocation.getCurrentPosition((position) => {
                    this.setUserLocation(position);
                });
            }
            
            // Force resize
            setTimeout(() => this.map?.invalidateSize(), 100);
        }
    }

    public mount(parent: HTMLElement): void {
        parent.appendChild(this.element);
        this.initializeMap();
    }

    public unmount(): void {
        if (this.map) {
            this.map.remove();
            this.map = null;
        }
        this.modalForm.unmount();
        this.element.remove();
    }

    public getElement(): HTMLElement {
        return this.element;
    }

    // Method to add event markers (to be implemented)
    private addEventMarker(lat: number, lng: number, event: any) {
        const marker = L.marker([lat, lng], {
            icon: L.divIcon({
                className: 'event-marker',
                html: `
                    <div class="
                        w-8 h-8 
                        bg-gradient-to-br from-purple-500/70 to-indigo-600/70 
                        hover:from-purple-500/90 hover:to-indigo-600/90
                        rounded-lg 
                        flex items-center justify-center 
                        shadow-lg
                        transform hover:scale-110 
                        transition-all duration-200
                        group
                        cursor-pointer
                    ">
                        <i class="fas fa-calendar-alt text-white/90 group-hover:text-white text-xs"></i>
                        <div class="
                            absolute
                            bottom-full
                            left-1/2
                            -translate-x-1/2
                            mb-2
                            w-64
                            bg-black/90
                            backdrop-blur-lg
                            rounded-xl
                            p-4
                            opacity-0
                            group-hover:opacity-100
                            transition-opacity
                            duration-200
                            pointer-events-none
                            border
                            border-white/10
                            shadow-xl
                            space-y-2
                        ">
                            <h3 class="font-bold text-white/90 text-sm">${event.title}</h3>
                            <p class="text-white/70 text-xs line-clamp-2">${event.description}</p>
                            <div class="flex items-center gap-2 text-xs text-white/60">
                                <svg class="w-3 h-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                                          d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"/>
                                </svg>
                                May 15, 2024
                                <span class="w-px h-3 bg-white/20"></span>
                                <svg class="w-3 h-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                                          d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                </svg>
                                6:30 PM
                            </div>
                            <div class="absolute -bottom-1 left-1/2 -translate-x-1/2 w-2 h-2 bg-black/90 rotate-45"></div>
                        </div>
                    </div>
                `
            })
        }).addTo(this.map!);

        // Remove the popup and add click handler for navigation
        marker.on('click', () => {
            const hashedId = Hash.encodeId(event.id);
            navigate(`/nearby/event/${hashedId}`);
        });

        this.markers.push(marker);
    }

    private async searchAddress(query: string) {
        try {
            const response = await fetch(
                `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(query)}`
            );
            const results = await response.json();
            
            if (results && results.length > 0) {
                const location = results[0];
                this.map?.setView(
                    [parseFloat(location.lat), parseFloat(location.lon)], 
                    16
                );

                // Add a marker for the searched location
                const marker = L.marker([parseFloat(location.lat), parseFloat(location.lon)], {
                    icon: L.divIcon({
                        className: 'search-marker',
                        html: `
                            <div class="
                                w-6 h-6 
                                bg-orange-500 
                                rounded-full 
                                ring-4 
                                ring-orange-500/30
                                flex 
                                items-center 
                                justify-center
                            ">
                                <i class="fas fa-map-pin text-white text-xs"></i>
                            </div>
                        `
                    })
                }).addTo(this.map!);

                marker.bindPopup(location.display_name).openPopup();
            } else {
                console.log('No results found');
            }
        } catch (error) {
            console.error('Error searching address:', error);
        }
    }

    private addExamplePins() {
        // Example locations in Schenectady
        const locations = [
            {
                title: "Union College",
                description: "Historic liberal arts college founded in 1795",
                coords: [42.8173, -73.9279]
            },
            {
                title: "Proctors Theatre",
                description: "Historic vaudeville theater hosting performances since 1926",
                coords: [42.8142, -73.9395]
            },
            {
                title: "Central Park",
                description: "Large public park with rose garden and tennis courts",
                coords: [42.8152, -73.9177]
            },
            {
                title: "Rivers Casino",
                description: "Modern casino and resort on the Mohawk River",
                coords: [42.8136, -73.9432]
            },
            {
                title: "miSci Museum",
                description: "Science museum with interactive exhibits",
                coords: [42.8091, -73.9432]
            }
        ];

        locations.forEach(location => {
            this.addEventMarker(
                location.coords[0], 
                location.coords[1], 
                {
                    title: location.title,
                    description: location.description
                }
            );
        });
    }

    private showInvitationsModal() {
        const content = document.createElement('div');
        content.className = 'space-y-4';

        // Header
        const header = document.createElement('div');
        header.className = 'flex items-center justify-between mb-6';
        header.innerHTML = `
            <h2 class="text-xl font-bold text-white/90">Event Invitations</h2>
            <span class="text-sm text-white/50">3 pending</span>
        `;
        content.appendChild(header);

        // Example invitations
        const invitations = [
            {
                from: "Union College Events",
                title: "Spring Tech Symposium 2024",
                description: "Join us for a day of tech talks, workshops, and networking opportunities with industry leaders.",
                date: "March 15, 2024",
                time: "9:00 AM - 5:00 PM",
                id: 1
            },
            {
                from: "Proctors Theatre",
                title: "Broadway Musical Night",
                description: "Experience an evening of Broadway classics performed by our talented ensemble.",
                date: "March 20, 2024",
                time: "7:30 PM - 10:00 PM",
                id: 2
            },
            {
                from: "Schenectady Cultural Center",
                title: "Local Artists Exhibition",
                description: "Discover the works of emerging local artists in this curated exhibition.",
                date: "March 25, 2024",
                time: "6:00 PM - 9:00 PM",
                id: 3
            }
        ];

        invitations.forEach(inv => {
            const invitation = document.createElement('div');
            invitation.className = `
                bg-gradient-to-br from-purple-900 to-indigo-900
                hover:from-purple-800 hover:to-indigo-800
                border 
                border-purple-500/30 
                rounded-xl 
                p-5
                transition-all
                duration-200
                group
                relative
                overflow-hidden
            `;

            invitation.innerHTML = `
                <div class="relative space-y-3">
                    <div class="flex items-center justify-between">
                        <span class="text-sm text-white/70">${inv.from}</span>
                        <div class="flex items-center gap-2 text-sm text-white/70">
                            <i class="fas fa-calendar text-xs"></i>
                            ${inv.date}
                        </div>
                    </div>
                    
                    <div>
                        <h3 class="text-lg font-medium text-white/90 group-hover:text-white">${inv.title}</h3>
                        <p class="mt-1 text-sm text-white/70 line-clamp-2">${inv.description}</p>
                    </div>

                    <div class="flex flex-col gap-3">
                        <button class="w-full py-2 rounded-lg bg-gradient-to-r from-purple-500 to-indigo-500 
                                      hover:from-purple-600 hover:to-indigo-600 text-white font-medium text-sm transition-colors">
                            RSVP Now
                        </button>
                        <div class="flex items-center gap-2">
                            <button class="flex-1 py-1.5 rounded-lg bg-red-500/30 hover:bg-red-500/40 text-red-300 text-sm transition-colors">
                                Decline
                            </button>
                            <button class="flex-1 py-1.5 rounded-lg bg-white/15 hover:bg-white/25 text-white/80 hover:text-white/90 text-sm transition-colors">
                                Details
                            </button>
                            <button class="p-1.5 rounded-lg bg-white/15 hover:bg-white/25 text-white/80 hover:text-white/90 transition-colors">
                                <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                                          d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            `;

            // Add click handlers
            const rsvpButton = invitation.querySelector('button:first-child');
            const [declineButton, detailsButton, moreButton] = invitation.querySelectorAll('.flex.items-center.gap-2 button');

            rsvpButton?.addEventListener('click', (e) => {
                e.stopPropagation();
                // Handle RSVP logic
                console.log('RSVP for:', inv.title);
            });

            declineButton?.addEventListener('click', (e) => {
                e.stopPropagation();
                // Handle decline logic
                console.log('Decline:', inv.title);
            });

            detailsButton?.addEventListener('click', (e) => {
                e.stopPropagation();
                this.modalForm.close();
                navigate(`/nearby/event/${Hash.encodeId(inv.id)}`);
            });

            moreButton?.addEventListener('click', (e) => {
                e.stopPropagation();
                // Handle more options logic
                console.log('More options:', inv.title);
            });

            content.appendChild(invitation);
        });

        this.modalForm.append(content);
        this.modalForm.open();
    }

    private filterEventsByTimeRange(range: string) {
        console.log('Filtering events by range:', range);
        // Implement your filtering logic here
        // This will depend on how you're storing/fetching events
    }
}