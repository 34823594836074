import { ModalForm } from '../../pieces/reusable/modal-form';
import { NavColumn } from '../../pieces/general/nav-column';
import { ContentColumn } from '../../pieces/general/content-column';
import { DiscoveryColumn } from '../../pieces/general/discovery-column';
import { YourBig5 } from '../../pieces/general/your-big-5';
import { MobileNavButtons } from '../../pieces/reusable/mobile-nav-buttons';
import { ContentTitle } from '../../pieces/reusable/content-title';

export class NearbyEventScreen {
    private container: HTMLElement | null = null;
    private modalForm: ModalForm;
    private yourBig5: YourBig5;
    private mobileNavButtons: MobileNavButtons;
    private eventId: number;

    constructor(eventId: number) {
        this.eventId = eventId;
        this.modalForm = new ModalForm();
        this.yourBig5 = new YourBig5(() => {});
        this.mobileNavButtons = new MobileNavButtons(this.modalForm, this.yourBig5);
    }

    public mount(container: HTMLElement) {
        this.container = container;
        this.container.innerHTML = '';
        this.container.className = 'min-h-screen';

        // Create layout
        const columnLayout = document.createElement('div');
        columnLayout.className = 'flex min-h-screen h-screen overflow-hidden';

        // Content wrapper
        const contentWrapper = document.createElement('div');
        contentWrapper.className = 'flex lg:flex-row flex-col flex-1 overflow-hidden';

        // Mount columns
        const navColumn = new NavColumn(this.modalForm);
        const contentColumn = new ContentColumn();
        const discoveryColumn = new DiscoveryColumn(this.modalForm, this.yourBig5);

        navColumn.mount(columnLayout);
        contentColumn.mount(contentWrapper);
        discoveryColumn.mount(contentWrapper);

        // Load event content
        this.loadEventContent(contentColumn.getContainer());

        columnLayout.appendChild(contentWrapper);
        
        this.modalForm.mount(container);
        container.appendChild(columnLayout);
        this.mobileNavButtons.mount(container);
    }

    private loadEventContent(container: HTMLElement) {
        const event = this.getExampleEvent();
        
        const mainContent = document.createElement('div');
        mainContent.className = 'max-w-3xl mx-auto px-4 py-6 space-y-6 pb-24';

        // Content Title
        const contentTitle = new ContentTitle('Event Details', 'nearby');
        contentTitle.mount(mainContent);

        // Back button
        const backButton = document.createElement('button');
        backButton.className = 'mb-4 text-purple-400 hover:text-purple-300 transition-colors flex items-center gap-2';
        backButton.innerHTML = `
            <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path>
            </svg>
            Back
        `;
        backButton.addEventListener('click', () => history.back());

        // Event content
        const eventContent = document.createElement('div');
        eventContent.className = `
            bg-gradient-to-br from-purple-500/5 to-indigo-500/5
            border border-white/10
            rounded-2xl 
            p-8 
            space-y-8
            relative
            overflow-hidden
        `;

        eventContent.innerHTML = `
            <div class="absolute top-0 right-0 w-96 h-96 bg-purple-500/5 rounded-full blur-3xl -mr-48 -mt-48 pointer-events-none"></div>
            <div class="absolute bottom-0 left-0 w-96 h-96 bg-indigo-500/5 rounded-full blur-3xl -ml-48 -mb-48 pointer-events-none"></div>

            <!-- Header -->
            <div class="space-y-3">
                <h1 class="text-2xl font-bold text-white/90">${event.title}</h1>
                <p class="text-base text-white/60 leading-relaxed">${event.description}</p>
            </div>

            <!-- Key Info Grid -->
            <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                <!-- Date & Time -->
                <div class="p-6 bg-white/5 rounded-xl border border-white/10 space-y-4">
                    <h3 class="text-lg font-medium text-white/80">Date & Time</h3>
                    <div class="flex items-center gap-4">
                        <div class="p-3 bg-purple-500/10 rounded-lg">
                            <svg class="w-6 h-6 text-purple-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                                      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"/>
                            </svg>
                        </div>
                        <div class="space-y-1">
                            <div class="text-white/90 font-medium">${event.date}</div>
                            <div class="text-white/60">${event.time}</div>
                        </div>
                    </div>
                </div>

                <!-- Location -->
                <div class="p-6 bg-white/5 rounded-xl border border-white/10 space-y-4">
                    <h3 class="text-lg font-medium text-white/80">Location</h3>
                    <div class="flex items-center gap-4">
                        <div class="p-3 bg-purple-500/10 rounded-lg">
                            <svg class="w-6 h-6 text-purple-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                                      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
                            </svg>
                        </div>
                        <div class="space-y-1">
                            <div class="text-white/90 font-medium">${event.address}</div>
                            ${event.distance ? `
                                <div class="text-white/60">${(event.distance * 0.621371).toFixed(1)} miles away</div>
                            ` : ''}
                        </div>
                    </div>
                </div>
            </div>

            <!-- Additional Info -->
            <div class="p-6 bg-white/5 rounded-xl border border-white/10 space-y-6">
                <h3 class="text-lg font-medium text-white/80">Event Details</h3>
                
                <div class="space-y-4">
                    <div class="flex items-center gap-3 text-white/70">
                        <svg class="w-5 h-5 text-purple-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                                  d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0z"/>
                        </svg>
                        <span>${event.attendees} people attending</span>
                    </div>

                    <div class="flex items-center gap-3 text-white/70">
                        <svg class="w-5 h-5 text-purple-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                        </svg>
                        <span>${event.additionalInfo}</span>
                    </div>

                    <div class="pt-4 space-y-3">
                        <div class="flex items-center justify-between text-sm">
                            <span class="text-white/70">Avg Rating</span>
                            <span class="text-purple-300 font-medium" id="rating-value">92%</span>
                        </div>
                        <div class="flex gap-4 items-center">
                            <input 
                                type="range" 
                                min="0" 
                                max="100" 
                                value="50" 
                                class="flex-1 h-2 bg-white/5 rounded-full appearance-none cursor-pointer
                                       [&::-webkit-slider-thumb]:appearance-none
                                       [&::-webkit-slider-thumb]:w-4
                                       [&::-webkit-slider-thumb]:h-4
                                       [&::-webkit-slider-thumb]:rounded-full
                                       [&::-webkit-slider-thumb]:bg-gradient-to-r
                                       [&::-webkit-slider-thumb]:from-purple-500
                                       [&::-webkit-slider-thumb]:to-indigo-500
                                       [&::-webkit-slider-thumb]:border-2
                                       [&::-webkit-slider-thumb]:border-white/20"
                                id="rating-slider"
                            >
                            <button class="px-4 py-2 bg-purple-500/20 hover:bg-purple-500/30 text-purple-300 
                                          rounded-lg transition-colors text-sm font-medium">
                                Rate
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Action Buttons -->
            <div class="flex items-center gap-4 pt-4">
                <button class="flex-1 py-4 px-6 bg-gradient-to-r from-purple-500 to-indigo-500 
                              hover:from-purple-600 hover:to-indigo-600 text-white font-medium rounded-xl
                              transition-all duration-300 transform hover:scale-[1.02] hover:shadow-lg
                              flex items-center justify-center gap-2 text-lg">
                    <span>RSVP Now</span>
                    <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                              d="M13 7l5 5m0 0l-5 5m5-5H6"/>
                    </svg>
                </button>
                <button class="p-4 bg-white/5 hover:bg-white/10 text-white/70 hover:text-white/90 
                              rounded-xl transition-colors">
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                              d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"/>
                    </svg>
                </button>
            </div>
        `;

        mainContent.appendChild(backButton);
        mainContent.appendChild(eventContent);
        container.appendChild(mainContent);
    }

    private getExampleEvent() {
        return {
            title: "Local Tech Meetup",
            description: "Join fellow developers and tech enthusiasts for an evening of networking and knowledge sharing. We'll have guest speakers from leading tech companies sharing their experiences and insights.",
            address: "123 Innovation Hub, Tech District",
            date: "May 15, 2024",
            time: "6:30 PM EST",
            additionalInfo: "Light refreshments provided. Bring your laptop! We'll have dedicated time for networking and collaborative coding sessions.",
            attendees: 45,
            avgRating: 92,
            distance: 10,
            location: {
                lat: 40.7128,
                lng: -74.0060
            }
        };
    }

    public unmount(): void {
        if (this.container) {
            this.container.innerHTML = '';
        }
        this.container = null;
        this.modalForm.unmount();
        this.yourBig5?.unmount();
        this.mobileNavButtons.unmount();
    }
}