import { ModalForm } from '../reusable/modal-form';
import { AuthContent } from '../auth/auth-content';
import { PlatoChatService } from '../../actions/platochat/api-services';
import { AuthService } from '../../actions/auth/api-services';
import { Hash } from '../../utilities/hash';
import { navigate } from '../../app-router';
import { NanoResponse } from '../../actions/platochat/models';
import { EmojiPicker } from '../platochat/selection/emoji-picker';
import { ColorPicker } from '../platochat/selection/color-picker';
import { CustomNanoContent } from '../platochat/custom-nano-content';
import { TitleEditor } from '../platochat/selection/title-editor';

export class NavColumn {
    private container: HTMLElement;
    private currentApplet: string;
    private onAppletChange?: (applet: string) => void;
    private isNanoView: boolean = false;
    private readonly defaultNanoStyling = [
        { color: '#184469', emoji: '🫨' },
        { color: '#6D1818', emoji: '🐊' },
        { color: '#1D5F21', emoji: '🌶️' },
        { color: '#B3881C', emoji: '🎱' },
        { color: '#5F1674', emoji: '👀' },
        { color: '#003366', emoji: '🌨️' },
        { color: '#993300', emoji: '🍀' },
        { color: '#006600', emoji: '🔥' },
        { color: '#330066', emoji: '🌟' },
        { color: '#663300', emoji: '🔮' }
    ];
    private hasMore: boolean = true;
    private offset: number = 0;
    private userNames: Record<number, string> = {};
    private userId: number = parseInt(localStorage.getItem('userId') || '0', 10);
    private modal: ModalForm;

    constructor(modal: ModalForm) {
        this.container = document.createElement('div');
        this.container.className = `
            w-[100px] lg:w-72 
            flex flex-col 
            h-screen mt-2 mx-auto lg:mx-2
            border-r-2
            animated-border
            overflow-y-auto
            overflow-x-hidden
            no-scrollbar
            transition-all duration-300
        `;
        
        // Add class for when in nano view
        if (this.isNanoView) {
            this.container.classList.remove('w-[100px]');
            this.container.classList.add('w-[160px]');
        }
        
        // Add the animated gradient border and scrollbar hiding styles
        const style = document.createElement('style');
        style.textContent = `
            .animated-border {
                border-image: linear-gradient(to bottom, rgb(168 85 247 / 0.2), rgb(249 115 22 / 0.2), rgb(168 85 247 / 0.2)) 1;
                animation: gradient-move 3s infinite alternate;
            }

            @keyframes gradient-move {
                0% {
                    border-image-source: linear-gradient(to bottom, rgb(168 85 247 / 0.2), rgb(249 115 22 / 0.2), rgb(168 85 247 / 0.2));
                }
                100% {
                    border-image-source: linear-gradient(to top, rgb(168 85 247 / 0.2), rgb(249 115 22 / 0.2), rgb(168 85 247 / 0.2));
                }
            }

            .no-scrollbar::-webkit-scrollbar {
                display: none;
            }
            .no-scrollbar {
                -ms-overflow-style: none;
                scrollbar-width: none;
            }
        `;
        document.head.appendChild(style);
        
        const savedApplet = localStorage.getItem('chosenApplet') || 'home';
        const validApplets = ['home', 'nanos', 'kommin', 'nearby', 'profile', 'ai', 'icebreakers', 'settings', 'upgrade'];
        this.currentApplet = validApplets.includes(savedApplet) ? savedApplet : 'home';
        
        this.initializeLayout();

        // If we're in nanos applet, show nano view by default
        if (localStorage.getItem('chosenApplet') === 'nanos') {
            this.switchView(true);
        }
        this.modal = modal;
        this.userId = Number(localStorage.getItem('userId'));

        // Initial badge update
        this.updateNotificationBadges();
        
        // Update badges periodically
        setInterval(() => this.updateNotificationBadges(), 30000); // Every 30 seconds
    }

    private initializeLayout(): void {
        // Logo section - centered in narrow view
        const logoSection = document.createElement('div');
        logoSection.className = 'p-4 flex justify-center items-center';
        logoSection.innerHTML = `
            <img src="/icon.png" class="h-12 w-12 rounded-xl shadow-lg hover:shadow-xl hover:scale-105 transition-all cursor-pointer lg:hidden" alt="Icon">
            <img src="/logo.png" class="h-6 w-auto hidden lg:block cursor-pointer transition-opacity hover:opacity-80" alt="Logo">
        `;
        
        // Make logo clickable to go home
        logoSection.querySelector('img')?.addEventListener('click', () => {
            window.location.href = '/';
        });
        
        this.container.appendChild(logoSection);

        // Check if user is logged in
        const userId = localStorage.getItem('userId');
        if (userId) {
            // Add view toggle only if logged in
            const viewToggle = document.createElement('div');
            viewToggle.className = 'px-2 lg:px-4 flex justify-center items-center lg:flex-row mb-2';
            viewToggle.innerHTML = `
                <button class="view-toggle-btn applet-btn w-10 h-10 rounded-full text-sm font-medium transition-all flex items-center justify-center
                    bg-gradient-to-br from-purple-500/20 to-pink-500/20 hover:from-purple-500/30 hover:to-pink-500/30 text-purple-400 border border-purple-500/20
                    lg:w-full lg:h-10 lg:rounded-t-lg lg:rounded-l-2xl lg:rounded-r-none lg:rounded-tr-none lg:rounded-br-none">
                    <i class="fas fa-th-large text-lg"></i>
                    <span class="hidden lg:inline ml-2">Applets</span>
                </button>
                <button class="view-toggle-btn nano-btn w-10 h-10 rounded-full text-sm font-medium transition-all flex items-center justify-center
                    text-white/30 hover:text-blue-400 border border-white/5
                    lg:w-full lg:h-10 lg:rounded-b-lg lg:rounded-r-2xl lg:rounded-l-none lg:border-l-0 lg:rounded-tl-none lg:rounded-bl-none">
                    <i class="fas fa-comments text-lg"></i>
                    <span class="hidden lg:inline ml-2">Nanos</span>
                </button>
            `;
            this.container.appendChild(viewToggle);
        }

        // Add Join/Login button for non-logged in users right after logo
        if (!userId) {
            const joinButton = document.createElement('div');
            joinButton.className = 'px-4 pt-4';
            joinButton.innerHTML = `
                <button class="
                    w-full
                    h-12
                    bg-gradient-to-r from-blue-500 to-blue-600
                    hover:from-blue-600 hover:to-blue-700
                    text-white
                    rounded-xl
                    text-sm
                    font-medium
                    transition-all
                    duration-200
                    flex
                    items-center
                    justify-center
                    gap-2
                    shadow-lg
                    shadow-blue-500/20
                ">
                    <i class="fas fa-user-plus text-white text-base"></i>
                    <span class="hidden lg:inline">Join PLATO5</span>
                </button>
            `;

            // Add click handler to open auth modal
            joinButton.querySelector('button')?.addEventListener('click', () => {
                const modalForm = new ModalForm();
                const authContent = new AuthContent();
                modalForm.append(authContent.getElement());
                modalForm.mount(document.body);
                modalForm.open();
            });

            this.container.appendChild(joinButton);
        }

        // Create containers for both views
        const navSection = document.createElement('div');
        navSection.className = `
            flex flex-col flex-1 
            min-h-0 
            overflow-y-auto 
            scrollbar-none
            [-ms-overflow-style:none]
            [scrollbar-width:none]
            [&::-webkit-scrollbar]:hidden
        `;

        // Create view containers
        const appletView = document.createElement('div');
        appletView.className = 'applet-view';
        
        const nanoView = document.createElement('div');
        nanoView.className = 'nano-view hidden';

        // Add single click handler for all nano navigation
        nanoView.addEventListener('click', (e) => {
            const target = e.target as HTMLElement;
            const nanoElement = target.closest('[data-nano-id]');
            
            if (!nanoElement) return;

            // If we clicked a button or the customize card, don't navigate
            if (target.closest('button') || nanoElement.classList.contains('customize-card')) {
                return;
            }

            const nanoId = nanoElement.getAttribute('data-nano-id');
            if (nanoId) {
                const hashedId = Hash.encodeId(parseInt(nanoId));
                navigate(`/platochat/nanos/${hashedId}`);
            }
        });

        // Show skeleton loaders initially
        this.renderSkeletonLoaders(nanoView);

        if (userId) {
            this.loadNanos(nanoView, parseInt(userId));
        }

        // Keep existing buttons container
        const buttonsContainer = document.createElement('div');
        buttonsContainer.className = 'py-4 px-3 lg:px-4 flex flex-col gap-2';
        
        // Update nav items to be centered in narrow view with perfect squares
        buttonsContainer.innerHTML = `
            <a href="/" data-applet="home" class="group flex items-center justify-center lg:justify-start gap-3 p-3">
                <div class="w-12 h-12 flex items-center justify-center rounded-xl bg-pink-500/10 group-hover:bg-pink-500/20 transition-all border border-pink-500/20">
                    <i class="fa-solid fa-people-group text-lg text-pink-400"></i>
                </div>
                <span class="text-sm text-pink-400 hidden lg:inline">Matching</span>
                <div class="hidden lg:block ml-auto opacity-0 group-hover:opacity-100 transition-opacity">
                    <button onclick="window.open('/', '_blank'); event.preventDefault();" class="w-5 h-5 flex items-center justify-center rounded-full hover:bg-pink-500/10 transition-all ring-1 ring-pink-400/20">
                        <span class="text-[8px] font-medium text-pink-400/50 hover:text-pink-400">TAB</span>
                    </button>
                </div>
            </a>

            <a href="/kommin" data-applet="kommin" class="group flex items-center justify-center lg:justify-start gap-3 p-3">
                <div class="w-12 h-12 flex items-center justify-center rounded-xl bg-orange-500/10 group-hover:bg-orange-500/20 transition-all border border-orange-500/20">
                    <span class="text-xl font-bold text-orange-400">K</span>
                </div>
                <span class="text-sm text-orange-400 hidden lg:inline">Kommin</span>
                <div class="hidden lg:block ml-auto opacity-0 group-hover:opacity-100 transition-opacity">
                    <button onclick="window.open('/kommin', '_blank'); event.preventDefault();" class="w-5 h-5 flex items-center justify-center rounded-full hover:bg-orange-500/10 transition-all ring-1 ring-orange-400/20">
                        <span class="text-[8px] font-medium text-orange-400/50 hover:text-orange-400">TAB</span>
                    </button>
                </div>
            </a>

            <a href="/nearby" data-applet="nearby" class="group flex items-center justify-center lg:justify-start gap-3 p-3">
                <div class="relative w-12 h-12 flex items-center justify-center rounded-xl bg-purple-500/10 group-hover:bg-purple-500/20 transition-all border border-purple-500/20">
                    <svg class="w-6 h-6 text-purple-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
                    </svg>
                </div>
                <span class="text-sm text-purple-400 hidden lg:inline">Nearby</span>
                <div class="hidden lg:block ml-auto opacity-0 group-hover:opacity-100 transition-opacity">
                    <button onclick="window.open('/nearby', '_blank'); event.preventDefault();" class="w-5 h-5 flex items-center justify-center rounded-full hover:bg-purple-500/10 transition-all ring-1 ring-purple-400/20">
                        <span class="text-[8px] font-medium text-purple-400/50 hover:text-purple-400">TAB</span>
                    </button>
                </div>
            </a>

            <a href="/your-profile" data-applet="profile" class="group flex items-center justify-center lg:justify-start gap-3 p-3">
                <div class="w-12 h-12 flex items-center justify-center rounded-xl bg-yellow-500/10 group-hover:bg-yellow-500/20 transition-all border border-yellow-500/20">
                    <svg class="w-6 h-6 text-yellow-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
                    </svg>
                </div>
                <span class="text-sm text-yellow-400 hidden lg:inline">Profile</span>
                <div class="hidden lg:block ml-auto opacity-0 group-hover:opacity-100 transition-opacity">
                    <button onclick="window.open('/your-profile', '_blank'); event.preventDefault();" class="w-5 h-5 flex items-center justify-center rounded-full hover:bg-yellow-500/10 transition-all ring-1 ring-yellow-400/20">
                        <span class="text-[8px] font-medium text-yellow-400/50 hover:text-yellow-400">TAB</span>
                    </button>
                </div>
            </a>

            <a href="/ai" data-applet="ai" class="group flex items-center justify-center lg:justify-start gap-3 p-3">
                <div class="w-12 h-12 flex items-center justify-center rounded-xl bg-emerald-500/10 group-hover:bg-emerald-500/20 transition-all border border-emerald-500/20">
                    <span class="text-lg font-mono font-bold text-emerald-400">Ai</span>
                </div>
                <span class="text-sm text-emerald-400 hidden lg:inline">Ai Assistants</span>
                <div class="hidden lg:block ml-auto opacity-0 group-hover:opacity-100 transition-opacity">
                    <button onclick="window.open('/ai', '_blank'); event.preventDefault();" class="w-5 h-5 flex items-center justify-center rounded-full hover:bg-emerald-500/10 transition-all ring-1 ring-emerald-400/20">
                        <span class="text-[8px] font-medium text-emerald-400/50 hover:text-emerald-400">TAB</span>
                    </button>
                </div>
            </a>

            <a href="/platochat/icebreakers" data-applet="icebreakers" class="group flex items-center justify-center lg:justify-start gap-3 p-3">
                <div class="w-12 h-12 flex items-center justify-center rounded-xl bg-sky-500/10 group-hover:bg-sky-500/20 transition-all border border-sky-500/20">
                    <i class="fa-solid fa-snowflake text-lg text-sky-400"></i>
                </div>
                <span class="text-sm text-sky-400 hidden lg:inline">Icebreakers</span>
                <div class="hidden lg:block ml-auto opacity-0 group-hover:opacity-100 transition-opacity">
                    <button onclick="window.open('/platochat/icebreakers', '_blank'); event.preventDefault();" class="w-5 h-5 flex items-center justify-center rounded-full hover:bg-sky-500/10 transition-all ring-1 ring-sky-400/20">
                        <span class="text-[8px] font-medium text-sky-400/50 hover:text-sky-400">TAB</span>
                    </button>
                </div>
            </a>

            ${userId ? `
                <a href="/upgrade" data-applet="upgrade" class="group flex items-center justify-center lg:justify-start gap-3 p-3">
                    <div class="w-12 h-12 flex items-center justify-center rounded-xl bg-gradient-to-br from-amber-500/20 to-orange-500/20 group-hover:from-amber-500/30 group-hover:to-orange-500/30 transition-all border border-amber-500/20">
                        <svg class="w-6 h-6 text-amber-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z"></path>
                        </svg>
                    </div>
                    <span class="text-sm text-amber-400 hidden lg:inline">Upgrade</span>
                    <div class="hidden lg:block ml-auto opacity-0 group-hover:opacity-100 transition-opacity">
                        <button onclick="window.open('/upgrade', '_blank'); event.preventDefault();" class="w-5 h-5 flex items-center justify-center rounded-full hover:bg-amber-500/10 transition-all ring-1 ring-amber-400/20">
                            <span class="text-[8px] font-medium text-amber-400/50 hover:text-amber-400">TAB</span>
                        </button>
                    </div>
                </a>
            ` : ''}

            <a href="/settings" data-applet="settings" class="group flex items-center justify-center lg:justify-start gap-3 p-3">
                <div class="w-12 h-12 flex items-center justify-center rounded-xl bg-gray-500/10 group-hover:bg-gray-500/20 transition-all border border-gray-500/20">
                    <svg class="w-6 h-6 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"></path>
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                    </svg>
                </div>
                <span class="text-sm text-gray-400 hidden lg:inline">Settings</span>
                <div class="hidden lg:block ml-auto opacity-0 group-hover:opacity-100 transition-opacity">
                    <button onclick="window.open('/settings', '_blank'); event.preventDefault();" class="w-5 h-5 flex items-center justify-center rounded-full hover:bg-gray-500/10 transition-all ring-1 ring-gray-400/20">
                        <span class="text-[8px] font-medium text-gray-400/50 hover:text-gray-400">TAB</span>
                    </button>
                </div>
            </a>
        `;

        buttonsContainer.querySelectorAll('a').forEach(link => {
            const applet = link.getAttribute('data-applet');
            if (applet === this.currentApplet) {
                link.classList.add('bg-white/5', 'rounded-xl', 'ring-1', 'ring-white/10', 'shadow-lg', 'shadow-black/5');
            }
        });

        appletView.appendChild(buttonsContainer);
        navSection.appendChild(appletView);
        navSection.appendChild(nanoView);
        this.container.appendChild(navSection);

        // Ensure event listeners are set up correctly
        if (userId) {
            const appletBtn = this.container.querySelector('.applet-btn');
            const nanoBtn = this.container.querySelector('.nano-btn');
            
            appletBtn?.addEventListener('click', () => this.switchView(false));
            nanoBtn?.addEventListener('click', () => this.switchView(true));
        }
    }

    private switchView(toNanoView: boolean): void {
        this.isNanoView = toNanoView;
        const appletView = this.container.querySelector('.applet-view');
        const nanoView = this.container.querySelector('.nano-view');
        const appletBtn = this.container.querySelector('.applet-btn');
        const nanoBtn = this.container.querySelector('.nano-btn');

        if (toNanoView) {
            appletView?.classList.add('hidden');
            nanoView?.classList.remove('hidden');
            this.container.classList.remove('w-[100px]');
            this.container.classList.add('w-[160px]');
            
            // Deselect applet button
            appletBtn?.classList.remove('bg-gradient-to-br', 'from-purple-500/20', 'to-pink-500/20', 'text-purple-400', 'border-purple-500/20');
            appletBtn?.classList.add('text-white/30', 'border-white/5');
            
            // Select nano button
            nanoBtn?.classList.remove('text-white/30', 'border-white/5');
            nanoBtn?.classList.add('bg-gradient-to-br', 'from-blue-500/20', 'to-cyan-500/20', 'text-blue-400', 'border-blue-500/20');
        } else {
            appletView?.classList.remove('hidden');
            nanoView?.classList.add('hidden');
            this.container.classList.remove('w-[160px]');
            this.container.classList.add('w-[100px]');
            
            // Select applet button
            appletBtn?.classList.add('bg-gradient-to-br', 'from-purple-500/20', 'to-pink-500/20', 'text-purple-400', 'border-purple-500/20');
            appletBtn?.classList.remove('text-white/30', 'border-white/5');
            
            // Deselect nano button
            nanoBtn?.classList.remove('bg-gradient-to-br', 'from-blue-500/20', 'to-cyan-500/20', 'text-blue-400', 'border-blue-500/20');
            nanoBtn?.classList.add('text-white/30', 'border-white/5');
        }
    }

    public onSelect(callback: (applet: string) => void) {
        this.onAppletChange = callback;
    }

    public mount(parent: HTMLElement): void {
        parent.appendChild(this.container);
    }

    public unmount(): void {
        this.container.remove();
    }

    public getElement(): HTMLElement {
        return this.container;
    }

    private async loadNanos(container: HTMLElement, userId: number) {
        try {
            const fetchedNanos = await PlatoChatService.getNanosByLastChat(this.offset, userId);
            if (fetchedNanos) {
                if (this.offset === 0) {
                    // Save existing badges before clearing container
                    const existingBadges = new Map();
                    container.querySelectorAll('[data-nano-id]').forEach(element => {
                        const nanoId = element.getAttribute('data-nano-id');
                        const badge = element.querySelector('.nano-badge');
                        if (nanoId && badge) {
                            existingBadges.set(nanoId, badge.cloneNode(true));
                        }
                    });

                    // Render container
                    container.innerHTML = `
                        <div class="py-2 px-3">
                            <div class="flex items-center justify-between mb-3">
                                <h3 class="text-sm font-semibold bg-gradient-to-r from-blue-400 to-violet-400 
                                    bg-clip-text text-transparent">PlatoChat Nanos</h3>
                                <button id="create-nano-btn" class="w-7 h-7 border border-white/10 bg-[#0F0F0F] rounded-full
                                    shadow-md hover:shadow-lg transition-all duration-200
                                    flex items-center justify-center hover:scale-105">
                                    <span class="text-lg leading-none translate-y-[-1px] bg-gradient-to-r from-blue-400 to-violet-400 
                                        bg-clip-text text-transparent">+</span>
                                </button>
                            </div>
                            <div class="nanos-container space-y-0 sm:space-y-3"></div>
                            <div class="load-more-container text-center mt-4"></div>
                        </div>
                    `;

                    // Restore badges after re-render
                    existingBadges.forEach((badge, nanoId) => {
                        const nanoElement = container.querySelector(`[data-nano-id="${nanoId}"]`);
                        if (nanoElement) {
                            (nanoElement as HTMLElement).style.position = 'relative';
                            nanoElement.appendChild(badge);
                        }
                    });

                    const createNanoBtn = container.querySelector('#create-nano-btn');
                    if (createNanoBtn) {
                        createNanoBtn.addEventListener('click', () => {
                            const modal = new ModalForm();
                            const customNanoContent = new CustomNanoContent((success) => {
                                modal.close();
                            });
                            modal.mount(container);
                            modal.clear();
                            modal.append(customNanoContent.getElement());
                            modal.open();
                        });
                    }
                }

                const nanosContainer = container.querySelector('.nanos-container')!;
                // Make sure the title is included when mapping the nanos
                const nanosWithChats = await Promise.all(fetchedNanos.map(async nano => {
                    const chats = await PlatoChatService.getChats(nano.ID, 0);
                    const lastChat = chats && chats.length > 0 ? chats[0] : null;
                    return {
                        ...nano,
                        lastMessage: lastChat?.content || 'Start a new conversation...',
                        title: nano.title // Ensure title is explicitly included
                    };
                }));
                
                const userIds = new Set(nanosWithChats.flatMap(nano => 
                    [nano.user1_id, nano.user2_id, nano.user3_id, nano.user4_id].filter(id => id > 0)
                ));
                
                const userPromises = Array.from(userIds).map(async id => {
                    const user = await AuthService.getUserById(id);
                    if (user) {
                        this.userNames[id] = user.name;
                    }
                });
                await Promise.all(userPromises);
                
                this.offset += fetchedNanos.length;
                this.hasMore = fetchedNanos.length === 6;
                this.renderNanos(container, nanosWithChats, this.userNames, userId);
                await this.updateNotificationBadges(); // Update badges after rendering nanos
            }
        } catch (error) {
            console.error('Failed to load nanos:', error);
        }
    }

    private renderNanos(container: HTMLElement, nanos: NanoResponse[], userNames: Record<number, string>, userId: number) {
        const nanosContainer = container.querySelector('.nanos-container')!;
        const currentNanoId = window.location.pathname.split('/').pop();
        const modal = new ModalForm();
        modal.mount(container);

        const newNanos = nanos.map((nano, index) => {
            const defaultStyle = this.defaultNanoStyling[index % this.defaultNanoStyling.length];
            const backgroundColor = nano.color ? 
                `#${nano.color.slice(2)}` : 
                defaultStyle.color;
            const emoji = nano.emoji || defaultStyle.emoji;
            const lastMessage = (nano as any).lastMessage || 'Start a new conversation...';
            const isSelected = Hash.encodeId(nano.ID) === currentNanoId;
            
            // Make sure to use nano.title directly, without the nullish coalescing operator
            const nanoTitle = nano.title || 'Untitled';
            
            return `
                <div class="space-y-0 group/nano transform transition-all duration-300 hover:-translate-y-0.5">
                    <div class="group bg-[#0F0F0F] backdrop-blur-xl border border-white/5 border-b-0 rounded-t-xl
                        p-4 hover:bg-[#0F0F0F] transition-all cursor-pointer relative shadow-[0_8px_30px_rgb(0,0,0,0.12)]
                        ${isSelected ? 'bg-[#0F0F0F]' : ''}" 
                        data-nano-id="${nano.ID}">
                        <div class="nano-content flex flex-col gap-3">
                            <div class="w-12 h-12 sm:w-14 sm:h-14 rounded-full flex items-center justify-center
                                transition-all duration-300 group-hover:scale-105 border border-white/10
                                shadow-[inset_0_0_20px_rgba(0,0,0,0.2)]"
                                style="background-color: ${backgroundColor}30">
                                <span class="text-xl sm:text-2xl filter drop-shadow-md">${emoji}</span>
                            </div>
                            <div class="space-y-1">
                                <h4 class="text-base font-medium text-white/90">
                                    ${nanoTitle}
                                </h4>
                                <p class="text-sm text-white/70 truncate">
                                    ${lastMessage}
                                </p>
                            </div>
                            <div class="border-t border-white/5 pt-3">
                                <p class="text-xs text-white/50">
                                    ${this.formatParticipants(nano, userNames, userId)}
                                </p>
                            </div>
                        </div>
                        ${isSelected ? `
                            <div class="absolute right-0 top-0 bottom-0 w-1 
                                bg-gradient-to-b from-blue-500 to-violet-500 rounded-r-xl
                                shadow-[0_0_15px_rgba(96,165,250,0.3)]"></div>
                        ` : ''}
                    </div>
                    <div class="bg-[#0F0F0F]/90 backdrop-blur-xl border border-white/5 rounded-b-xl
                        flex mb-3 customize-card overflow-hidden shadow-[0_8px_30px_rgb(0,0,0,0.12)]" 
                        data-nano-id="${nano.ID}">
                        <div class="flex-1 p-2.5 text-white/40 hover:text-white/60 hover:bg-black/20 
                            transition-all border-r border-white/5 cursor-pointer flex items-center justify-center" 
                            data-action="title"
                            data-nano-id="${nano.ID}">
                            <i class="fas fa-pencil-alt text-sm filter drop-shadow-sm"></i>
                        </div>
                        <div class="flex-1 p-2.5 text-white/40 hover:text-white/60 hover:bg-black/20 
                            transition-all border-r border-white/5 cursor-pointer flex items-center justify-center" 
                            data-action="emoji"
                            data-nano-id="${nano.ID}">
                            <i class="fas fa-smile text-sm filter drop-shadow-sm"></i>
                        </div>
                        <div class="flex-1 p-2.5 text-white/40 hover:text-white/60 hover:bg-black/20 
                            transition-all cursor-pointer flex items-center justify-center" 
                            data-action="color"
                            data-nano-id="${nano.ID}">
                            <i class="fas fa-palette text-sm filter drop-shadow-sm"></i>
                        </div>
                    </div>
                </div>
            `;
        }).join('');

        nanosContainer.insertAdjacentHTML('beforeend', newNanos);

        // Add event listeners for emoji and color buttons
        nanosContainer.querySelectorAll('[data-action]').forEach(element => {
            element.addEventListener('click', async (e) => {
                e.preventDefault();
                e.stopPropagation();
                const action = element.getAttribute('data-action');
                const nanoId = element.getAttribute('data-nano-id');
                const nano = nanos.find(n => n.ID === parseInt(nanoId!));

                if (!nano) return;

                if (action === 'title') {
                    const titleEditor = new TitleEditor(async (newTitle) => {
                        if (newTitle) {
                            await PlatoChatService.updateNanoColorAndEmoji(
                                nano.ID,
                                nano.color ?? '#0088ff',
                                nano.emoji ?? '🥹',
                                newTitle
                            );
                            modal.close();
                            window.location.reload();
                        }
                    });
                    modal.clear();
                    modal.append(titleEditor.getElement());
                    modal.open();
                } else if (action === 'emoji') {
                    const emojiPicker = new EmojiPicker(async (newEmoji) => {
                        await PlatoChatService.updateNanoColorAndEmoji(
                            nano.ID,
                            nano.color ?? '#0088ff',
                            newEmoji,
                            nano.title ?? 'Untitled'
                        );
                        modal.close();
                        window.location.reload();
                    });
                    modal.clear();
                    modal.append(emojiPicker.getElement());
                    modal.open();
                } else if (action === 'color') {
                    const colorPicker = new ColorPicker(async (newColor) => {
                        await PlatoChatService.updateNanoColorAndEmoji(
                            nano.ID,
                            newColor,
                            nano.emoji ?? '🥹',
                            nano.title ?? 'Untitled'
                        );
                        modal.close();
                        window.location.reload();
                    });
                    modal.clear();
                    modal.append(colorPicker.getElement());
                    modal.open();
                }
            });
        });

        const loadMoreContainer = container.querySelector('.load-more-container') || (() => {
            const div = document.createElement('div');
            div.className = 'load-more-container text-center mt-4';
            container.querySelector('.py-4')?.appendChild(div);
            return div;
        })();

        loadMoreContainer.innerHTML = this.hasMore ? `
            <button id="load-more-btn" 
                class="px-4 py-2 bg-white/5 hover:bg-white/10 
                rounded-lg text-white/70 text-sm
                transition-all duration-300">
                Load More
            </button>
        ` : '';

        this.attachLoadMoreHandler(container);
    }

    private formatParticipants(nano: NanoResponse, userNames: Record<number, string>, userId: number): string {
        const participants = [nano.user1_id, nano.user2_id, nano.user3_id, nano.user4_id]
            .filter(id => id !== userId && id > 0)
            .map(id => userNames[id] || 'Unknown')
            .join(', ');
        return participants || 'No other participants';
    }

    private renderSkeletonLoaders(container: HTMLElement) {
        container.innerHTML = `
            <div class="py-4 px-3 lg:px-4">
                <h3 class="text-sm font-medium text-white/70 mb-4">PlatoChat Nanos</h3>
                <div class="space-y-4">
                    ${Array.from({ length: 6 }).map(() => `
                        <div class="animate-pulse flex items-center space-x-4">
                            <div class="rounded-full bg-white/10 h-12 w-12 flex-shrink-0"></div>
                            <div class="flex-1 space-y-4 py-1 hidden sm:block">
                                <div class="h-4 bg-white/10 rounded w-3/4"></div>
                                <div class="h-4 bg-white/10 rounded w-1/2"></div>
                            </div>
                        </div>
                    `).join('')}
                </div>
            </div>
        `;
    }

    private attachLoadMoreHandler(container: HTMLElement) {
        const loadMoreBtn = container.querySelector('#load-more-btn');
        if (loadMoreBtn) {
            loadMoreBtn.addEventListener('click', async () => {
                const button = loadMoreBtn as HTMLButtonElement;
                button.disabled = true;
                button.textContent = 'Loading...';
                
                try {
                    await this.loadNanos(container, this.userId);
                } catch (error) {
                    console.error('Failed to load more nanos:', error);
                    button.textContent = 'Load More';
                    button.disabled = false;
                }
            });
        }
    }

    private async updateNotificationBadges() {
        const userId = Number(localStorage.getItem('userId'));
        if (!userId) return;

        // Update main nano view badge
        const totalUnread = await PlatoChatService.getUnreadChatCountAllNanos(userId);
        const nanoBtn = this.container.querySelector('.nano-btn');
        if (nanoBtn) {
            const existingBadge = nanoBtn.querySelector('.notification-badge');
            if (existingBadge) {
                existingBadge.remove();
            }
            
            if (totalUnread > 0) {
                const badge = document.createElement('div');
                badge.className = `
                    notification-badge
                    absolute -top-2 -right-2
                    min-w-[22px] h-[22px]
                    bg-gradient-to-br from-blue-500 to-violet-500
                    backdrop-blur-md
                    text-white text-[11px]
                    rounded-full
                    flex items-center justify-center
                    px-1.5
                    font-semibold
                    border border-white/30
                    shadow-lg shadow-blue-500/30
                `;
                badge.textContent = totalUnread > 5 ? '5+' : totalUnread.toString();
                (nanoBtn as HTMLElement).style.position = 'relative';
                nanoBtn.appendChild(badge);
            }
        }

        // Update individual nano badges (only on main nano cards)
        const nanosContainer = this.container.querySelector('.nanos-container');
        if (nanosContainer) {
            const nanoElements = nanosContainer.querySelectorAll('.nano-content');
            for (const nanoElement of nanoElements) {
                const parent = nanoElement.closest('[data-nano-id]');
                if (!parent) continue;
                
                const nanoId = parent.getAttribute('data-nano-id');
                if (nanoId) {
                    const unreadCount = await PlatoChatService.getUnreadChatCount(parseInt(nanoId), userId);
                    const existingBadge = nanoElement.querySelector('.nano-badge');
                    if (existingBadge) {
                        existingBadge.remove();
                    }

                    if (unreadCount > 0) {
                        const badge = document.createElement('div');
                        badge.className = `
                            nano-badge
                            absolute top-3 right-3
                            min-w-[20px] h-[20px]
                            bg-gradient-to-br from-blue-500 to-violet-500
                            backdrop-blur-md
                            text-white text-[11px]
                            rounded-full
                            flex items-center justify-center
                            px-1.5
                            font-semibold
                            border border-white/30
                            shadow-lg shadow-blue-500/30
                            transform transition-all duration-300
                            group-hover/nano:scale-110
                        `;
                        badge.textContent = unreadCount > 5 ? '5+' : unreadCount.toString();
                        (nanoElement as HTMLElement).style.position = 'relative';
                        nanoElement.appendChild(badge);
                    }
                }
            }
        }
    }
}