import { ModalForm } from '../../pieces/reusable/modal-form';
import { NavColumn } from '../../pieces/general/nav-column';
import { ContentColumn } from '../../pieces/general/content-column';
import { DiscoveryColumn } from '../../pieces/general/discovery-column';
import { YourBig5 } from '../../pieces/general/your-big-5';
import { ContentTitle } from '../../pieces/reusable/content-title';
import { MobileNavButtons } from '../../pieces/reusable/mobile-nav-buttons';
import { KomminService } from '../../actions/kommin/api-services';
import { UINotifications } from '../../utilities/errors';
import { navigate } from '../../app-router';
import { ComingSoonContent } from '../../pieces/reusable/coming-soon-content';

interface Group {
    ID: number;
    Title: string;
    Description: string;
    Public: boolean;
    CreatorName: string;
    CreatorImage?: string;
    ApprovedUsers: Array<{
        ID: number;
        Name: string;
        ImageUrl?: string;
    }>;
    CreatedAt: string;
}

export class KomminGroupsScreen {
    private container: HTMLElement | null = null;
    private modalForm: ModalForm;
    private yourBig5: YourBig5;
    private mobileNavButtons: MobileNavButtons;
    private readonly GROUPS_ENABLED = false; // Set to true when ready to launch

    constructor() {
        this.modalForm = new ModalForm();
        this.yourBig5 = new YourBig5(() => {});
        this.mobileNavButtons = new MobileNavButtons(this.modalForm, this.yourBig5);
    }

    public async mount(container: HTMLElement) {
        // Set chosen applet
        localStorage.setItem('chosenApplet', 'kommin');

        this.container = container;
        this.container.innerHTML = '';
        this.container.className = 'min-h-screen';

        // Create layout
        const columnLayout = document.createElement('div');
        columnLayout.className = 'flex min-h-screen h-screen overflow-hidden';

        // Content wrapper
        const contentWrapper = document.createElement('div');
        contentWrapper.className = 'flex lg:flex-row flex-col flex-1 overflow-hidden';

        // Mount columns
        const navColumn = new NavColumn(this.modalForm);
        const contentColumn = new ContentColumn();
        const discoveryColumn = new DiscoveryColumn(this.modalForm, this.yourBig5);

        // Only mount nav column on desktop
        const navElement = navColumn.getElement();
        navElement.className = 'hidden sm:block ' + navElement.className;
        navColumn.mount(columnLayout);

        contentColumn.mount(contentWrapper);
        
        // Hide discovery column on mobile
        const discoveryElement = discoveryColumn.getElement();
        discoveryElement.className = 'hidden sm:block discovery-column ' + discoveryElement.className;
        discoveryColumn.mount(contentWrapper);

        // Load Groups content
        await this.loadKomminGroupsContent(contentColumn.getContainer());

        columnLayout.appendChild(contentWrapper);
        
        // Mount modal form and layout
        this.modalForm.mount(container);
        container.appendChild(columnLayout);

        // Add mobile nav buttons
        this.mobileNavButtons.mount(container);
    }

    private async loadKomminGroupsContent(container: HTMLElement) {
        container.innerHTML = '';
        
        // Create main container with padding
        const mainContent = document.createElement('div');
        mainContent.className = 'max-w-3xl mx-auto px-4 py-6 space-y-6 pb-24';
        
        // Add content title
        const contentTitle = new ContentTitle('Groups', 'kommin');
        contentTitle.mount(mainContent);

        // Create navigation row
        const navRow = document.createElement('div');
        navRow.className = 'flex items-center justify-between mb-6';

        // Create back button
        const backButton = document.createElement('button');
        backButton.className = `
            flex items-center gap-2
            text-white/50 hover:text-white/70
            transition-colors
            text-sm
        `;
        backButton.innerHTML = `
            <i class="fas fa-arrow-left"></i>
            <span>Back</span>
        `;
        backButton.addEventListener('click', () => navigate('/kommin'));

        // Add new group button if logged in
        const isLoggedIn = localStorage.getItem('userId') !== null;
        if (isLoggedIn) {
            const newGroupButton = document.createElement('button');
            newGroupButton.className = `
                flex items-center justify-center gap-1.5
                bg-blue-500/30 hover:bg-blue-500/40
                text-blue-400
                px-4 py-2
                rounded-xl
                transition-all duration-300
                border border-blue-500/30
                text-sm
                h-[40px]
            `;
            newGroupButton.innerHTML = `
                <i class="fas fa-plus"></i>
                <span>New Group</span>
            `;
            newGroupButton.addEventListener('click', () => {
                navigate('/kommin/group/new');
            });

            navRow.appendChild(backButton);
            navRow.appendChild(newGroupButton);
        } else {
            navRow.appendChild(backButton);
        }

        mainContent.appendChild(navRow);

        // Create groups grid
        const groupsGrid = document.createElement('div');
        groupsGrid.className = 'grid grid-cols-1 gap-6';

        UINotifications.showGlobalLoadingIndication();

        if (!this.GROUPS_ENABLED) {
            const comingSoon = new ComingSoonContent(
                'Groups Coming Soon',
                'Create and join private communities. Group functionality is currently being developed and will be available shortly.',
                '👥'
            );
            comingSoon.mount(container);
            return;
        }

        try {
            const groups = await this.getMockGroups();
            
            if (groups.length === 0) {
                const notice = document.createElement('div');
                notice.className = 'text-center p-8 bg-white/5 backdrop-blur-sm rounded-lg border border-white/10';
                notice.innerHTML = `
                    <h3 class="text-lg font-medium text-white/90 mb-2">No groups found</h3>
                    <p class="text-sm text-white/70">Be the first to create a group!</p>
                `;
                groupsGrid.appendChild(notice);
            } else {
                for (const group of groups) {
                    const card = await this.createGroupCard(group);
                    groupsGrid.appendChild(card);
                }
            }
        } catch (error) {
            console.error('Error loading groups:', error);
            const errorNotice = document.createElement('div');
            errorNotice.className = 'text-center p-8 bg-red-500/10 backdrop-blur-sm rounded-lg border border-red-500/20';
            errorNotice.innerHTML = `
                <h3 class="text-lg font-medium text-red-400 mb-2">Error loading groups</h3>
                <p class="text-sm text-red-300">Please try again later</p>
            `;
            groupsGrid.appendChild(errorNotice);
        } finally {
            UINotifications.hideGlobalLoadingIndication();
        }

        mainContent.appendChild(groupsGrid);
        container.appendChild(mainContent);
    }

    private async createGroupCard(group: Group): Promise<HTMLElement> {
        const card = document.createElement('div');
        card.className = `
            relative overflow-hidden
            bg-gradient-to-br from-black/20 to-black/30
            hover:from-black/25 hover:to-black/35
            backdrop-blur-lg
            border-b border-white/5
            p-5
            transition-all duration-300 cursor-pointer
            rounded-xl
            group
            hover:shadow-lg hover:shadow-orange-500/5
            hover:scale-[1.01]
            hover:border-orange-500/10
        `;

        card.innerHTML = `
            <div class="flex flex-col gap-4">
                <div class="flex items-center gap-2">
                    <div class="w-11 h-11 rounded-full 
                        bg-gradient-to-br from-blue-500/20 to-blue-600/20 
                        border-2 border-blue-500/40
                        flex items-center justify-center
                        shadow-lg shadow-blue-500/20
                        overflow-hidden
                        ring-2 ring-blue-500/20 ring-offset-2 ring-offset-black/50
                        transition-all duration-300">
                        <svg class="w-6 h-6 text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                                  d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"></path>
                        </svg>
                    </div>
                    <div>
                        <h3 class="font-[Space_Grotesk] font-bold text-xl text-white/95 
                                 group-hover:text-orange-400/90 transition-colors">
                            ${group.Title}
                        </h3>
                        <p class="text-white/50 text-sm font-[Quicksand]">Created by ${group.CreatorName}</p>
                    </div>
                </div>
                
                <div class="prose prose-invert">
                    <p class="text-white/80 font-[Crimson_Text] text-lg leading-relaxed mb-4">
                        ${group.Description}
                    </p>
                </div>

                <div class="flex flex-wrap gap-2">
                    ${group.ApprovedUsers.slice(0, 12).map(user => `
                        <div class="
                            flex items-center gap-2
                            px-3 py-1.5
                            bg-blue-500/10 hover:bg-blue-500/20
                            border border-blue-500/20
                            rounded-lg
                            transition-all duration-300
                            cursor-pointer
                        ">
                            <div class="w-6 h-6 rounded-full 
                                bg-gradient-to-br from-blue-500/20 to-blue-600/20 
                                border border-blue-500/40
                                flex items-center justify-center
                                overflow-hidden">
                                ${user.ImageUrl ? `
                                    <img src="${user.ImageUrl}" alt="${user.Name}" class="w-full h-full object-cover">
                                ` : `
                                    <span class="text-blue-400 font-[Quicksand] text-xs">${user.Name.charAt(0).toUpperCase()}</span>
                                `}
                            </div>
                            <span class="text-blue-400 text-sm font-[Quicksand]">${user.Name}</span>
                        </div>
                    `).join('')}
                </div>

                <div class="flex items-center justify-end text-white/40 text-sm">
                    <div class="flex items-center gap-2">
                        <button class="
                            flex items-center gap-2
                            px-4 py-2
                            bg-orange-500/20 hover:bg-orange-500/30
                            text-orange-400
                            rounded-xl
                            transition-all duration-300
                            border border-orange-500/30
                            text-sm font-[Quicksand]
                        ">
                            <i class="far fa-comment"></i>
                            <span>View Komms</span>
                        </button>
                        ${group.Public ? `
                            <button class="
                                px-4 py-2
                                bg-orange-500/20 hover:bg-orange-500/30
                                text-orange-400
                                rounded-xl
                                transition-all duration-300
                                border border-orange-500/30
                                text-sm font-[Quicksand]
                            ">
                                Join Group
                            </button>
                        ` : `
                            <div class="
                                px-4 py-2
                                bg-white/5
                                text-white/30
                                rounded-xl
                                text-sm font-[Quicksand]
                            ">
                                Private Group
                            </div>
                        `}
                    </div>
                </div>
            </div>
        `;

        return card;
    }

    private async getMockGroups(): Promise<Group[]> {
        return [
            {
                ID: 1,
                Title: "Design Enthusiasts",
                Description: "A group for sharing and discussing design principles, trends, and creative work.",
                Public: true,
                CreatorName: "Sarah Chen",
                CreatorImage: undefined,
                ApprovedUsers: [
                    { ID: 1, Name: "Sarah Chen" },
                    { ID: 2, Name: "Alex Rivera" },
                    { ID: 3, Name: "Marcus Kim" },
                    { ID: 4, Name: "Emma Wilson" },
                    { ID: 5, Name: "David Park" },
                    { ID: 6, Name: "Sophie Martin" }
                ],
                CreatedAt: "2 weeks ago"
            },
            {
                ID: 2,
                Title: "Tech Innovators",
                Description: "Discussing the latest in technology, from AI to quantum computing. Share your projects and insights.",
                Public: true,
                CreatorName: "Alex Rivera",
                CreatorImage: undefined,
                ApprovedUsers: [
                    { ID: 2, Name: "Alex Rivera" },
                    { ID: 7, Name: "John Smith" },
                    { ID: 8, Name: "Lisa Chen" },
                    { ID: 9, Name: "Mike Johnson" },
                    { ID: 10, Name: "Anna Lee" }
                ],
                CreatedAt: "1 month ago"
            },
            {
                ID: 3,
                Title: "Creative Writers Circle",
                Description: "A private group for serious writers to share their work, get feedback, and discuss writing techniques.",
                Public: false,
                CreatorName: "Emma Wilson",
                CreatorImage: undefined,
                ApprovedUsers: [
                    { ID: 4, Name: "Emma Wilson" },
                    { ID: 11, Name: "James Wright" },
                    { ID: 12, Name: "Maria Garcia" },
                    { ID: 13, Name: "Tom Baker" }
                ],
                CreatedAt: "3 days ago"
            }
        ];
    }

    public unmount(): void {
        if (this.container) {
            this.container.innerHTML = '';
        }
        
        this.container = null;
        this.modalForm.unmount();
        this.yourBig5?.unmount();
        this.mobileNavButtons.unmount();
    }
}
