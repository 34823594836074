export const Hash = {
    // Convert numeric ID to base36 string
    encodeId: (id: number): string => {
        return id.toString(36);
    },

    // Convert base36 string back to numeric ID
    decodeId: (hash: string): number => {
        return parseInt(hash, 36);
    },

    // Create URL-friendly slug from name
    createSlug: (name: string): string => {
        return name.toLowerCase()
            .replace(/[^a-z0-9]+/g, '-')
            .replace(/(^-|-$)/g, '');
    }
};