// import { UINotifications } from "./utilities/errors";
// import { FeaturesScreen } from "./screens/features-screen";
import { ArticleScreen } from "./screens/article-screen";
import { UnknownRouteScreen } from "./screens/unknown-route";
import { articles } from "./pieces/tutorial/5mark-articles";
import { FeatureScreen } from "./screens/features-screen";
import { KomminScreen } from "./screens/kommin-screen";
import { Hash } from "./utilities/hash";
import { NanoScreen } from "./screens/nano-screen";
import { updatePageMetadata, updateDiscussionMetadata } from './utilities/metadata';
import { ModalForm } from "./pieces/reusable/modal-form";
import { ColumnViewScreen } from "./screens/column-view-screen";
import { KomminAppletScreen } from './screens/applet-screens/kommin';
import { NearbyAppletScreen } from './screens/applet-screens/nearby';
import { YourProfileAppletScreen } from './screens/applet-screens/your-profile';
import { AIAppletScreen } from './screens/applet-screens/ai';
import { NanosAppletScreen } from './screens/applet-screens/nanos';
import { IcebreakersAppletScreen } from './screens/applet-screens/icebreakers';
import { SettingsAppletScreen } from './screens/applet-screens/settings';
import { AffiliateScreen } from "./screens/affiliate-screen";
import { EngagementDriverScreen } from "./screens/engdriv-screen";
import { ColumnUpgradeScreen } from './screens/applet-screens/column-upgrade';
import { OtherProfileScreen } from './screens/applet-screens/other-profile-screen';
import { KomminNewKommScreen } from './screens/applet-screens/kommin-new-komm';
import { AuthService } from './actions/auth/api-services';
import { KomminMediaUploadScreen } from './screens/applet-screens/kommin-media-upload';
import { VirtualEventsScreen } from './screens/applet-screens/nearby-virtual-event';
import { NearbyEventsList } from './screens/applet-screens/nearby-events-list';
import { NearbyEventScreen } from './screens/applet-screens/nearby-event';
import { NearbyCreateEvent } from './screens/applet-screens/nearby-create-event';
import { KomminCornersScreen } from './screens/applet-screens/kommin-corners';
import { KomminGroupsScreen } from './screens/applet-screens/kommin-groups';
import { KomminNewGroupScreen } from './screens/applet-screens/kommin-new-group';
import { KomminNewCornerScreen } from './screens/applet-screens/kommin-new-corner';
// Add this at the top of the file with other declarations
let activeModal: ModalForm | null = null;

// Add this function to set the active modal
export function setActiveModal(modal: ModalForm) {
  activeModal = modal;
}

export function initializeRouter() {
  window.addEventListener('popstate', handleRoute)
  window.addEventListener('load', handleRoute)
}

export function navigate(route: string): void {
  // Close any open modal first
  if (activeModal) {
    activeModal.close();
    activeModal = null;
  }

  // Unmount the current screen if it exists
  if (currentScreen && typeof currentScreen.unmount === 'function') {
    currentScreen.unmount();
  }

  // Clear any existing content and remove any floating buttons
  const root = document.getElementById('root');
  const app = document.querySelector('#app');
  const mobileButtons = document.querySelector('.fixed.bottom-4.right-4');
  
  // Remove mobile buttons if they exist
  if (mobileButtons) {
    mobileButtons.remove();
  }
  
  // Clear both root and app containers
  if (root) {
    root.innerHTML = '';
  }
  if (app) {
    app.innerHTML = '';
  }

  // Reset scroll position
  window.scrollTo(0, 0);

  history.pushState({}, '', route);
  handleRoute();
}

// Variable to keep track of the current screen
let currentScreen: any = null;

// Renamed utility function
export function bounceToTop(): void {
  requestAnimationFrame(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  });
}

function handleRoute() {
  const modal = new ModalForm();
  const path = window.location.pathname

  // Add this line to update metadata for all routes
  updatePageMetadata(path);

  // Add regex pattern to match nano routes
  const nanoMatch = path.match(/^\/platochat\/nanos\/([a-zA-Z0-9]+)$/);
  
  // Add regex pattern to match discussion routes
  const discussionMatch = path.match(/^\/kommin\/discussion\/([a-zA-Z0-9]+)$/);
  
  const profileMatch = path.match(/^\/people\/([^\/]+)\/([a-zA-Z0-9]+)$/);
  if (profileMatch) {
    const [, nameSlug, hashedId] = profileMatch;
    showOtherProfile(Hash.decodeId(hashedId), nameSlug);
    return;
  }
  
  if (nanoMatch) {
    showNano(nanoMatch[1], modal);
    return;
  }
  
  if (discussionMatch) {
    showDiscussion(discussionMatch[1]);
    return;
  }

  // Add with other regex patterns at the top of handleRoute()
  const mediaUploadMatch = path.match(/^\/kommin\/discussion\/([a-zA-Z0-9]+)\/upload-media$/);

  if (mediaUploadMatch) {
    showMediaUpload(mediaUploadMatch[1]);
    return;
  }

  // Add this new regex pattern with the existing ones at the top
  const eventMatch = path.match(/^\/nearby\/event\/([a-zA-Z0-9]+)$/);
  if (eventMatch) {
    showEvent(eventMatch[1]);
    return;
  }

  switch (path) {
    case '/':
      showColumnView()
      break
    case '/features':
      showFeatures()
      break
    case '/about/ai-powered-conversations-unlocking-infinite-topics-on-plato5':
      showArticle(0)
      break
    case '/about/how-social-engines-can-solve-the-loneliness-epidemic':
      showArticle(1)
      break
    case '/about/gamified-conversations-and-nano-blogging':
      showArticle(2)
      break
    case '/about/explore-the-plato5-suite-kommin-platochat-and-more':
      showArticle(3)
      break
    case '/about/safety-suggestions-for-online-meetups':
      showArticle(4)
      break
    case '/unknown-route':
      showUnknownRoute()
      break
    case '/kommin':
      showKommin()
      break
    case '/nearby':
      showNearby()
      break
    //case '/nearby/virtual-events':
    //  showVirtualEvents()
    //  break
    //case '/nearby/events-list':
    //  showEventsList()
    //  break
    case '/your-profile':
      showYourProfile()
      break
    case '/ai':
      showAI()
      break
    case '/platochat/nanos':
      showNanos()
      break
    case '/platochat/icebreakers':
      showIcebreakers();
      break;
    case '/settings':
      showSettings();
      break;
    case '/programs/affiliates':
      showAffiliates();
      break;
      // Add to switch statement in handleRoute()
    case '/programs/engagement-drivers':
      showEngdriv();
      break;
    case '/upgrade':
      showUpgrade();
      break;
    case '/kommin/new':
      showNewKomm();
      break;
    //case '/nearby/new':
    //  showCreateEvent();
    //  break
    case '/kommin/corners':
      showCorners();
      break;
    case '/kommin/groups':
      showGroups();
      break;
    //case '/kommin/group/new':
    //  showNewGroup();
    //  break
    //case '/kommin/corner/new':
    //  showNewCorner();
    //  break
    default:
      // Redirect to 404 page instead of showing it directly
      history.replaceState({}, '', '/unknown-route');
      showUnknownRoute()
  }
}

function showColumnView() {
  const screen = new ColumnViewScreen()
  screen.mount(document.querySelector('#app')!)
  currentScreen = screen
}

function showArticle(index: number) {
  const screen = new ArticleScreen(articles[index])
  screen.mount(document.querySelector('#app')!)
}

function showUnknownRoute() {
  const screen = new UnknownRouteScreen()
  screen.mount(document.querySelector('#app')!)
}

function showFeatures() {
  const screen = new FeatureScreen()
  screen.mount(document.querySelector('#app')!)
}

function handleBackNavigation() {
    window.addEventListener('popstate', () => {
        const root = document.getElementById('root');
        const app = document.querySelector('#app');
        
        // Clear both root and app containers
        if (root) {
            root.innerHTML = '';
        }
        if (app) {
            app.innerHTML = '';
        }

        // Unmount the current screen if it exists
        if (currentScreen && typeof currentScreen.unmount === 'function') {
            currentScreen.unmount();
        }

        // Reset scroll position
        window.scrollTo(0, 0);

        // Handle the route based on the current path
        handleRoute();
    });
}

// Add new function to show upgrade screen
function showUpgrade() {
  const userId = localStorage.getItem('userId');
  if (!userId) {
    // Redirect to home if not logged in
    history.replaceState({}, '', '/');
    showColumnView();
    return;
  }

  const screen = new ColumnUpgradeScreen();
  screen.mount(document.querySelector('#app')!);
  currentScreen = screen;
}

// Add new function to show discussion
async function showDiscussion(hashedId: string) {
  try {
    const discussionId = Hash.decodeId(hashedId);
    
    // Get discussion data BEFORE creating screen
    const screen = new KomminScreen(discussionId);
    const discussionData = await screen.getDiscussionData();
    
    // Update metadata BEFORE mounting screen
    await updateDiscussionMetadata(hashedId, {
      title: discussionData.title,
      description: discussionData.description,
      author: discussionData.author,
      created: discussionData.created
    });
    
    // Mount screen after metadata is set
    screen.mount(document.querySelector('#app')!);
    currentScreen = screen;
    
  } catch (error) {
    console.error('Failed to handle discussion:', error);
    history.replaceState({}, '', '/unknown-route');
    showUnknownRoute();
  }
}

// Add new function to show nano
function showNano(hashedId: string, modal: ModalForm) {
  const nanoId = Hash.decodeId(hashedId);
  const screen = new NanoScreen(nanoId, modal);
  
  // Add robots meta tag to prevent indexing of nano pages
  const robotsMeta = document.createElement('meta');
  robotsMeta.setAttribute('name', 'robots');
  robotsMeta.setAttribute('content', 'noindex, nofollow');
  document.head.appendChild(robotsMeta);
  
  screen.mount(document.querySelector('#app')!);
  currentScreen = screen;
}

// Add new function to show Kommin
function showKommin() {
    const screen = new KomminAppletScreen();
    screen.mount(document.querySelector('#app')!);
    currentScreen = screen;
}

// Add new function to show Nearby
function showNearby() {
    const screen = new NearbyAppletScreen();
    screen.mount(document.querySelector('#app')!);
    currentScreen = screen;
}

// Add new function to show profile
function showYourProfile() {
    const screen = new YourProfileAppletScreen();
    screen.mount(document.querySelector('#app')!);
    currentScreen = screen;
}

// Add new function to show AI
function showAI() {
    const screen = new AIAppletScreen();
    screen.mount(document.querySelector('#app')!);
    currentScreen = screen;
}

// Add new function to show Nanos
function showNanos() {
    const screen = new NanosAppletScreen();
    screen.mount(document.querySelector('#app')!);
    currentScreen = screen;
}

// Add new function to show Icebreakers
function showIcebreakers() {
    const screen = new IcebreakersAppletScreen();
    screen.mount(document.querySelector('#app')!);
    currentScreen = screen;
}

// Add new function to show Settings
function showSettings() {
    const screen = new SettingsAppletScreen();
    screen.mount(document.querySelector('#app')!);
    currentScreen = screen;
}

// Add new function to show Affiliates
function showAffiliates() {
    const screen = new AffiliateScreen();
    screen.mount(document.querySelector('#app')!);
    currentScreen = screen;
} 

// Add new function to show Engdriv
function showEngdriv() {
    const screen = new EngagementDriverScreen();
    screen.mount(document.querySelector('#app')!);
    currentScreen = screen;
}

// Add new function
async function showOtherProfile(userId: number, nameSlug: string) {
    try {
        // Get user data BEFORE creating screen
        const userData = await AuthService.getUserById(userId);
        if (!userData) {
            history.replaceState({}, '', '/unknown-route');
            showUnknownRoute();
            return;
        }
        
        const screen = new OtherProfileScreen(userId, nameSlug);
        screen.mount(document.querySelector('#app')!);
        currentScreen = screen;
        
    } catch (error) {
        console.error('Failed to load profile:', error);
        history.replaceState({}, '', '/unknown-route');
        showUnknownRoute();
    }
}

// Add the new show function
function showNewKomm() {
    const screen = new KomminNewKommScreen();
    screen.mount(document.querySelector('#app')!);
    currentScreen = screen;
}

// Add new function to show media upload
function showMediaUpload(hashedId: string) {
    const discussionId = Hash.decodeId(hashedId);
    const screen = new KomminMediaUploadScreen(discussionId);
    screen.mount(document.querySelector('#app')!);
    currentScreen = screen;
}

// Add new function to show virtual events
function showVirtualEvents() {
    const screen = new VirtualEventsScreen();
    screen.mount(document.querySelector('#app')!);
    currentScreen = screen;
}

// Add with other show functions
function showEventsList() {
    const screen = new NearbyEventsList();
    screen.mount(document.querySelector('#app')!);
    currentScreen = screen;
}

// Add this new function with the other show functions
function showEvent(hashedId: string) {
    const eventId = Hash.decodeId(hashedId);
    const screen = new NearbyEventScreen(eventId);
    screen.mount(document.querySelector('#app')!);
    currentScreen = screen;
}

// Add new function with other show functions
function showCreateEvent() {
    const screen = new NearbyCreateEvent();
    screen.mount(document.querySelector('#app')!);
    currentScreen = screen;
}

// Add new function to show Corners
function showCorners() {
    const screen = new KomminCornersScreen();
    screen.mount(document.querySelector('#app')!);
    currentScreen = screen;
}

// Add new function to show Groups
function showGroups() {
    const screen = new KomminGroupsScreen();
    screen.mount(document.querySelector('#app')!);
    currentScreen = screen;
}

// Add new show functions at the bottom with other show functions
function showNewGroup() {
    const screen = new KomminNewGroupScreen();
    screen.mount(document.querySelector('#app')!);
    currentScreen = screen;
}

function showNewCorner() {
    const screen = new KomminNewCornerScreen();
    screen.mount(document.querySelector('#app')!);
    currentScreen = screen;
}

