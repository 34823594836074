import { TextInput } from '../reusable/text-input';
import { Button } from '../reusable/button';
import { AuthService } from '../../actions/auth/api-services';
import { UINotifications } from '../../utilities/errors';
import { SettingsContent } from './settings-content';

export class AuthContent {
  private element: HTMLDivElement;
  private tabsContainer: HTMLDivElement;
  private contentContainer: HTMLDivElement;
  private activeTab: 'login' | 'signup' | 'verify' = 'login';
  private signupData?: {
    name: string;
    email: string;
    password: string;
    accepted_tos: boolean;
    newsletter: boolean;
    stay_logged_in: boolean;
    regCode: string;
  };
  private readonly appSections = [
    'Authentication', 'Analytix', 'PlatoChat', 'Kommin', 'Design', 
    'Notifications', 'Emailing Services', 'Location Services', 
    'Profile Management', 'Search Functionality', 'Settings',
    'User Interactions', 'Content Display', 'Media Handling',
    'Performance', 'Security', 'Data Synchronization',
    'Offline Mode', 'Accessibility Features', 'Language/Localization',
    'Third-party Integrations', 'Payments/Transactions', 
    'Onboarding Process', 'Tutorial/Help', 'Other', 'Unsure'
  ];

  private readonly issueTypes = [
    'Not Functioning', 'Not Navigating', 'Permanently Loading', 'Frozen App',
    'Retrieving Incorrect Data', 'Overflow on the X or Y Axis', 
    'Crash on Specific Action', 'Unexpected Logout', 'Data Loss',
    'Slow Performance', 'High Battery Drain', 'Excessive Data Usage',
    'Inconsistent Behavior', 'UI Elements Misaligned', 'Unresponsive Buttons',
    'Incorrect Error Messages', 'Feature Not Working as Expected',
    'Compatibility Issues', 'Login/Authentication Failure',
    'Notification Issues', 'Search Not Yielding Results',
    'Sync Problems', 'Accessibility Feature Not Working',
    'Language/Translation Errors', 'Security Concern',
    'Payment Processing Issue', 'Other'
  ];

  public getElement(): HTMLElement {
    return this.element;
  }

  constructor() {
    this.element = document.createElement('div');
    this.element.className = 'space-y-6';

    // Tabs
    this.tabsContainer = document.createElement('div');
    this.tabsContainer.className = 'flex space-x-4 mb-6';
    
    const loginTab = this.createTab('Login');
    const signupTab = this.createTab('Signup');
    this.tabsContainer.append(loginTab, signupTab);

    // Content Container
    this.contentContainer = document.createElement('div');
    this.contentContainer.className = 'space-y-4';
    
    this.element.append(this.tabsContainer, this.contentContainer);
    
    // Check if user is logged in
    const userId = localStorage.getItem('userId');
    if (userId) {
        this.showLoggedInState();
    } else {
        this.showLogin();
    }
  }

  private createTab(text: string): HTMLButtonElement {
    const tab = document.createElement('button');
    tab.textContent = text;
    tab.className = `
      px-4 
      py-2 
      rounded-lg 
      transition-all
      duration-300
      hover:bg-white/10
    `;

    tab.addEventListener('click', () => {
      if (text === 'Login') {
        this.showLogin();
      } else {
        this.showSignup();
      }
    });

    return tab;
  }

  private updateTabs() {
    const tabs = this.tabsContainer.children;
    for (let i = 0; i < tabs.length; i++) {
      const tab = tabs[i] as HTMLButtonElement;
      if (
        (this.activeTab === 'login' && i === 0) || 
        (this.activeTab === 'signup' && i === 1)
      ) {
        tab.classList.add('bg-white/10', 'text-white');
      } else {
        tab.classList.remove('bg-white/10', 'text-white');
        tab.classList.add('text-white/70');
      }
    }
  }


private showLogin() {
    this.activeTab = 'login';
    this.updateTabs();
    this.contentContainer.innerHTML = '';
    this.tabsContainer.style.display = 'flex';

    const form = document.createElement('form');
    form.className = 'space-y-4';
    this.contentContainer.appendChild(form);

    const emailInput = new TextInput('email@example.com', 'email');
    const passwordInput = new TextInput('password', 'password');
    const stayLoggedInCheckbox = this.createCheckbox('Keep me logged in for up to 60 days of inactivity');
    const proceedButton = new Button('Proceed');
    const forgotPasswordButton = new Button('Forgot Password?');

    passwordInput['element'].type = 'password';
    
    forgotPasswordButton.getElement().className = `
        text-white/50 
        text-sm 
        hover:text-white 
        transition-colors
        duration-300
    `;

    emailInput.mount(form);
    passwordInput.mount(form);
    form.appendChild(stayLoggedInCheckbox);
    proceedButton.mount(form);
    forgotPasswordButton.mount(form);

    const handleSubmit = async (e?: Event) => {
        if (e) e.preventDefault();
        const email = emailInput.getValue();
        const password = passwordInput.getValue();
        const stay_logged_in = (stayLoggedInCheckbox.querySelector('input') as HTMLInputElement).checked;
        
        const response = await AuthService.login({
            email,
            password,
            device_type: "PLATO5 WebApp | https://plato5.us",
            stay_logged_in
        });
        if (response) {
            localStorage.setItem('userId', response.user.ID.toString());
            window.location.reload();
        }
    };

    form.addEventListener('submit', handleSubmit);
    proceedButton.getElement().addEventListener('click', handleSubmit);

    forgotPasswordButton.getElement().addEventListener('click', async (e) => {
        e.preventDefault();
        const email = emailInput.getValue();
        if (!email.includes('@')) {
            UINotifications.shipErrorToUI('Please enter a valid email address');
            return;
        }

        const response = await AuthService.forgotPassword(email);
        if (response) {
            this.showResetCode(email, response.randomString, response.userId);
        }
    });
}

private showSignup() {
    this.activeTab = 'signup';
    this.updateTabs();
    this.contentContainer.innerHTML = '';
    this.tabsContainer.style.display = 'flex';

    const form = document.createElement('form');
    form.className = 'space-y-4';
    this.contentContainer.appendChild(form);

    const nameInput = new TextInput('full name', 'username');
    const emailInput = new TextInput('email@example.com', 'email');
    const passwordInput = new TextInput('password', 'password');
    const tosCheckbox = this.createCheckbox(`I accept the <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=258cd87c-9f29-4484-a8a3-ba477ba41496" target="_blank" rel="noopener">Terms of Service</a> & <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=2791893c-8d65-4498-95ff-ec98723912e7" target="_blank" rel="noopener">Privacy Policy</a>`);
    const newsletterCheckbox = this.createCheckbox('I want to receive newsletters and updates');
    const stayLoggedInCheckbox = this.createCheckbox('Keep me logged in for up to 60 days of inactivity');
    const proceedButton = new Button('Get Started');

    passwordInput['element'].type = 'password';
    
    nameInput.mount(form);
    emailInput.mount(form);
    passwordInput.mount(form);
    form.appendChild(tosCheckbox);
    form.appendChild(newsletterCheckbox);
    form.appendChild(stayLoggedInCheckbox);
    proceedButton.mount(form);

    const handleSubmit = async (e?: Event) => {
        if (e) e.preventDefault();
        const name = nameInput.getValue();
        const email = emailInput.getValue();
        const password = passwordInput.getValue();
        const accepted_tos = (tosCheckbox.querySelector('input') as HTMLInputElement).checked;
        const newsletter = (newsletterCheckbox.querySelector('input') as HTMLInputElement).checked;
        const stay_logged_in = (stayLoggedInCheckbox.querySelector('input') as HTMLInputElement).checked;

        if (!accepted_tos) {
            UINotifications.shipErrorToUI('Please accept the Terms of Service');
            return;
        }

        if (!email.includes('@')) {
            UINotifications.shipErrorToUI('Please enter a valid email address');
            return;
        }

        if (password.length < 8) {
            UINotifications.shipErrorToUI('Password must be at least 8 characters long');
            return;
        }

        if (name.length < 2) {
            UINotifications.shipErrorToUI('Please enter your full name');
            return;
        }

        const emailExists = await AuthService.checkEmailExists(email);
        if (emailExists) {
            UINotifications.shipErrorToUI('This email is already registered. Please login instead.');
            this.showLogin();
            return;
        }

        this.showVerification(
            name, 
            email, 
            password, 
            accepted_tos, 
            newsletter,
            stay_logged_in
        );
    };

    form.addEventListener('submit', handleSubmit);
    proceedButton.getElement().addEventListener('click', handleSubmit);
}

private showVerification(
    name: string, 
    email: string, 
    password: string, 
    accepted_tos: boolean, 
    newsletter: boolean,
    stay_logged_in: boolean
) {
    console.log('Starting verification process for:', email);
    
    this.activeTab = 'verify';
    this.contentContainer.innerHTML = '';
    this.tabsContainer.style.display = 'none';

    const backButton = document.createElement('button');
    backButton.className = `
        flex items-center gap-2 text-white/70 hover:text-white 
        transition-colors duration-300 mb-6
    `;
    backButton.innerHTML = `
        <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"/>
        </svg>
        <span>Back to Signup</span>
    `;
    backButton.onclick = this.backToSignup;

    const verificationContent = document.createElement('div');
    verificationContent.className = 'space-y-6';
    verificationContent.innerHTML = `
        <div class="text-center">
            <h2 class="text-2xl font-bold text-white mb-2">Verify Your Email</h2>
            <p class="text-white/70 text-sm">
                A verification code has been sent to ${email}.<br>
                Please check your inbox (and spam folder).
            </p>
            <p class="text-white/50 text-xs mt-2">
                Having trouble? Send us an email at <a href="mailto:support@plato5.us" class="text-white/70 hover:text-white transition-colors duration-300">support@plato5.us</a> and we'll get you an account.
            </p>
        </div>
        <input type="text" 
            class="w-full bg-white/5 border border-white/10 rounded-xl px-4 py-3
            text-white text-center text-2xl tracking-[0.5em] uppercase
            focus:outline-none focus:border-white/30 transition-colors"
            maxlength="5"
            placeholder="XXXXX"
        />
    `;

    const verifyButton = new Button('Verify Email');
    verifyButton.getElement().className = `
        w-full px-6 py-3.5 text-base font-medium
        bg-gradient-to-r from-blue-500 to-indigo-500
        hover:from-blue-600 hover:to-indigo-600
        text-white rounded-xl transition-all duration-300
        transform hover:scale-[1.02] active:scale-[0.98]
    `;

    this.contentContainer.appendChild(backButton);
    this.contentContainer.appendChild(verificationContent);
    verifyButton.mount(this.contentContainer);

    const input = verificationContent.querySelector('input')!;
    input.addEventListener('input', (e: Event) => {
        const target = e.target as HTMLInputElement;
        target.value = target.value.toUpperCase().replace(/[^A-Z0-9]/g, '');
    });

    if (this.signupData?.email === email) {
        console.log('Verification already in progress for:', email);
        return;
    }

    console.log('Sending registration code to:', email);
    AuthService.sendRegCode(email, name).then(regCode => {
        if (regCode) {
            this.signupData = {
                name,
                email,
                password,
                accepted_tos,
                newsletter,
                stay_logged_in,
                regCode
            };
        } else {
            UINotifications.shipErrorToUI('Failed to send verification code');
            this.showSignup();
        }
    });

    verifyButton.getElement().addEventListener('click', async () => {
        if (!this.signupData) return;
        
        const userInput = input.value.trim();
        if (userInput === this.signupData.regCode) {
            const response = await AuthService.signup({
                name: this.signupData.name,
                email: this.signupData.email,
                password: this.signupData.password,
                accepted_tos: this.signupData.accepted_tos,
                newsletter: this.signupData.newsletter,
                device_type: "PLATO5 WebApp | https://plato5.us",
                stay_logged_in: this.signupData.stay_logged_in
            });

            if (response) {
                localStorage.setItem('userId', response.id.toString());
                UINotifications.shipSuccessToUI('Welcome to PLATO5!');
                window.location.reload();
            }
        } else {
            UINotifications.shipErrorToUI('Invalid verification code. Send a new one.');
            this.showSignup();
        }
    });
}

private createCheckbox(label: string): HTMLDivElement {
    const container = document.createElement('div');
    container.className = 'flex items-center space-x-2';

    const checkbox = document.createElement('input');
    checkbox.type = 'checkbox';
    checkbox.className = 'form-checkbox rounded bg-transparent border-white/30';

    const labelElement = document.createElement('label');
    labelElement.innerHTML = label;
    labelElement.className = 'text-sm text-white/70';

    container.append(checkbox, labelElement);
    return container;
}

private showLoggedInState() {
    this.contentContainer.innerHTML = '';
    this.tabsContainer.style.display = 'none';
    
    // Add report button
    const reportButton = new Button('EasyReport an Issue');
    reportButton.getElement().className = `
        w-full px-3 py-1 text-sm font-medium bg-gradient-to-br 
        from-orange-500/20 to-red-500/20 
        hover:from-orange-500/30 hover:to-red-500/30 
        text-white rounded-lg transition-all duration-300 
        shadow-md hover:shadow-orange-500/25 transform 
        backdrop-blur-sm border border-white/10 
        flex items-center justify-center gap-2
    `;
    
    reportButton.getElement().innerHTML = `
        <i class="fas fa-bug"></i>
        EasyReport Issue
    `;

    reportButton.onClick(() => {
        this.showEasyReportDialog();
    });

    this.contentContainer.appendChild(reportButton.getElement());
    
    const settingsContent = new SettingsContent();
    settingsContent.mount(this.contentContainer);
}

  public mount(parent: HTMLElement): void {
    parent.appendChild(this.element);
  }

  private backToSignup = () => {
    this.tabsContainer.style.display = 'flex';
    this.showSignup();
  };

  private showResetCode(email: string, code: string, userId: number) {
    this.contentContainer.innerHTML = '';
    this.tabsContainer.style.display = 'none';

    const content = document.createElement('div');
    content.className = 'text-center space-y-4';
    content.innerHTML = `
        <h2 class="text-2xl font-bold text-white">Reset Password</h2>
        <p class="text-white/70 text-sm">Enter the code sent to ${email}</p>
        <input type="text" 
            class="w-full bg-white/5 border border-white/10 rounded-xl px-4 py-3
            text-white text-center text-2xl tracking-[0.5em] uppercase
            focus:outline-none focus:border-white/30 transition-colors"
            maxlength="5"
            placeholder="XXXXX"
        />
    `;

    const input = content.querySelector('input')!;
    input.addEventListener('input', (e: Event) => {
        const target = e.target as HTMLInputElement;
        target.value = target.value.toUpperCase().replace(/[^A-Z0-9]/g, '');
    });

    const verifyButton = new Button('Verify Code');
    verifyButton.onClick(async () => {
        const userInput = input.value.trim();
        if (userInput === code) {
            this.showNewPassword(email, userId);
        } else {
            UINotifications.shipErrorToUI('Invalid code');
        }
    });

    this.contentContainer.append(content);
    verifyButton.mount(this.contentContainer);
  }

  public showEasyReportDialog(): void {
    this.contentContainer.innerHTML = '';
    const content = document.createElement('div');
    content.className = 'space-y-4 w-full max-w-md mx-auto';
    content.innerHTML = `
        <div class="text-center mb-4">
            <h2 class="text-xl font-semibold text-white">EasyReport an Issue</h2>
            <p class="text-sm text-white/70 mt-1">
                Help us improve PLATO5 by reporting any issues you encounter during beta testing.
            </p>
        </div>
        
        <div class="space-y-3">
            <div class="space-y-1">
                <label class="text-white/90 text-xs">Affected Applet</label>
                <select class="w-full bg-white/5 border border-white/10 rounded-lg p-2 text-sm text-white">
                    ${this.appSections.map(section => 
                        `<option value="${section}">${section}</option>`
                    ).join('')}
                </select>
            </div>

            <div class="space-y-1">
                <label class="text-white/90 text-xs">Issue Type</label>
                <select class="w-full bg-white/5 border border-white/10 rounded-lg p-2 text-sm text-white">
                    ${this.issueTypes.map(type => 
                        `<option value="${type}">${type}</option>`
                    ).join('')}
                </select>
            </div>

            <div class="space-y-1">
                <label class="text-white/90 text-xs">Description</label>
                <textarea 
                    class="w-full bg-white/5 border border-white/10 rounded-lg p-2 text-sm text-white min-h-[100px] resize-none"
                    placeholder="Please describe the issue..."
                ></textarea>
            </div>
        </div>

        <div class="flex justify-center gap-3 mt-6">
            <button class="cancel-btn px-4 py-2 text-sm text-white/70 hover:text-white transition-colors duration-300">
                Cancel
            </button>
            <button class="submit-btn px-4 py-2 text-sm bg-purple-500/20 hover:bg-purple-500/30 rounded-lg border border-white/10 text-white transition-all duration-300">
                Submit Report
            </button>
        </div>
    `;

    const backButton = content.querySelector('button');
    const cancelButton = content.querySelector('.cancel-btn');
    const submitButton = content.querySelector('.submit-btn');
    const [appletSelect, issueSelect, descriptionTextarea] = content.querySelectorAll('select, textarea');

    backButton?.addEventListener('click', () => {
        this.showLoggedInState();
    });

    cancelButton?.addEventListener('click', () => {
        // Close the dialog
    });
    
    submitButton?.addEventListener('click', async () => {
        const userId = localStorage.getItem('userId');
        if (!userId) return;

        const success = await AuthService.submitEasyReport(
            parseInt(userId),
            (appletSelect as HTMLSelectElement).value,
            (issueSelect as HTMLSelectElement).value,
            (descriptionTextarea as HTMLTextAreaElement).value
        );

        if (success) {
            // Close the dialog
        }
    });

    this.contentContainer.appendChild(content);
  }

  private showNewPassword(email: string, userId: number) {
    this.contentContainer.innerHTML = '';

    const content = document.createElement('div');
    content.className = 'text-center space-y-4';
    content.innerHTML = `
        <h2 class="text-2xl font-bold text-white">Set New Password</h2>
        <p class="text-white/70 text-sm">Enter your new password</p>
    `;

    const passwordInput = new TextInput('new password', 'password');
    passwordInput['element'].type = 'password';
    
    const resetButton = new Button('Reset Password');
    resetButton.onClick(async () => {
        const newPassword = passwordInput.getValue();
        if (newPassword.length < 8) {
            UINotifications.shipErrorToUI('Password must be at least 8 characters');
            return;
        }

        const success = await AuthService.resetPassword(userId, newPassword, email);
        if (success) {
            UINotifications.shipSuccessToUI('Password reset successfully');
            this.showLogin();
        }
    });

    this.contentContainer.append(content);
    passwordInput.mount(this.contentContainer);
    resetButton.mount(this.contentContainer);
  }
}