import { Button } from '../reusable/button';
import { ModalForm } from '../reusable/modal-form';
import { AuthContent } from '../auth/auth-content';
import { DownloadContent } from '../reusable/download-content';

export class Blurb {
    private element: HTMLDivElement;
    private authContent: AuthContent;
    private downloadContent: DownloadContent;

    constructor(private modalForm: ModalForm) {
        this.element = document.createElement('div');
        this.element.className = `
            flex
            flex-col
            items-center
            justify-center
            gap-6
            text-center
            mt-16
            px-4
            w-full
            max-w-4xl
            mx-auto
        `;
        this.authContent = new AuthContent();
        this.downloadContent = new DownloadContent();

        this.modalForm.append(this.authContent.getElement());

        const contrastHeader = document.createElement('h1');
        contrastHeader.textContent = 'Your friendly, neighborhood connection generator';
        contrastHeader.className = `
            text-4xl md:text-4xl lg:text-5xl
            font-['Space_Grotesk']
            font-black
            mb-1
            animate-fade-in
            tracking-tight
            text-shadow-lg
            text-transparent
            bg-clip-text
            bg-gradient-to-r from-purple-400 via-pink-400 to-blue-400
            leading-tight
            pb-1
        `;

        const text = document.createElement('p');
        text.textContent = 'PLATO5 is a social engine built to generate platonic relationships based on personality traits, location, and interests';
        text.className = `
            text-transparent bg-clip-text
            bg-gradient-to-r from-purple-400 via-pink-400 to-blue-400
            text-lg md:text-xl lg:text-2xl
            font-extrabold
            font-['Inter']
            leading-relaxed
            max-w-2xl
            animate-gradient
            tracking-tight
            animate-fade-in
            animate-delay-150
        `;

        const subheading = document.createElement('p');
        subheading.className = `
            text-gray-400
            text-base md:text-lg
            font-medium
            animate-fade-in
            animate-delay-300
            mt-2
        `;

        // Desktop version (arrow left)
        const desktopText = document.createElement('span');
        desktopText.className = 'hidden sm:inline-flex items-center gap-2';
        desktopText.innerHTML = '<i class="fa-solid fa-arrow-left"></i> Explore our Applets to the Left';

        // Mobile version (hamburger menu) - inline
        const mobileText = document.createElement('span');
        mobileText.className = 'sm:hidden inline-flex flex-col items-center gap-2';
        mobileText.innerHTML = `
            <div class="flex items-center gap-2">
                Use the Navigation Button 
                <div class="w-6 h-6 bg-black/80 rounded-full flex items-center justify-center">
                    <i class="fa-solid fa-bars text-sm text-white"></i>
                </div> 
                Below <i class="fa-solid fa-arrow-down"></i>
            </div>
            <span>to Explore PLATO5 Applets</span>
        `;

        subheading.appendChild(desktopText);
        subheading.appendChild(mobileText);

        const actionButton = new Button('Login or Signup');
        actionButton.getElement().onclick = () => {
            this.modalForm.append(this.authContent.getElement());
            this.modalForm.open();
        };
        actionButton.getElement().className = `
            px-6 py-2
            text-base
            bg-purple-900
            hover:bg-purple-800
            rounded-full
            transition-all duration-300
            font-medium
            text-white/90
            shadow-lg
            hover:shadow-purple-500/20
            hover:scale-105
            animate-fade-in
        `;

        const downloadButton = new Button('Download the App');
        downloadButton.getElement().onclick = () => {
            this.modalForm.append(this.downloadContent.getElement());
            this.modalForm.open();
        };
        downloadButton.getElement().className = `
            px-6 py-2
            text-base
            bg-black
            hover:bg-gray-900
            rounded-full
            transition-all duration-300
            font-medium
            text-white
            shadow-lg
            hover:shadow-gray-500/20
            hover:scale-105
            animate-fade-in
            ml-4
        `;

        const buttonContainer = document.createElement('div');
        buttonContainer.className = 'flex items-center gap-4 mt-8 animate-fade-in animate-delay-450';
        buttonContainer.appendChild(actionButton.getElement());
        buttonContainer.appendChild(downloadButton.getElement());

        // Add the contrast header before the main text
        this.element.appendChild(contrastHeader);
        this.element.appendChild(text);
        this.element.appendChild(subheading);
        this.element.appendChild(buttonContainer);
    }

    mount(parent: HTMLElement): void {
        parent.appendChild(this.element);
    }

    public getElement(): HTMLElement {
        return this.element;
    }
}