import { AuthService } from '../auth/api-services';
import { ChatHistory, MonthlyUsage, AIResponse } from './models';

const PLATOCHAT_BASE_URL = import.meta.env.VITE_PLATOCHAT_API_URL;
const API_KEY = import.meta.env.VITE_API_KEY;

export class AIService {
    private static getHeaders(): Record<string, string> {
        const baseHeaders = {
            'Content-Type': 'application/json',
            'X-API-Key': API_KEY || ''
        };

        const sessionData = AuthService.getSessionData();
        if (sessionData) {
            return {
                ...baseHeaders,
                'X-Session-ID': sessionData.session_id,
                'X-Public-Key': sessionData.public_key,
                'X-User-ID': sessionData.userId?.toString() || ''
            };
        }

        return baseHeaders;
    }

    static async getAIResponse(userId: number, assistantId: number, message: string): Promise<AIResponse | null> {
        try {
            const response = await fetch(`${PLATOCHAT_BASE_URL}/ai-response`, {
                method: 'POST',
                headers: this.getHeaders(),
                body: JSON.stringify({
                    user_id: userId,
                    assistant_id: assistantId,
                    message: message
                })
            });
    
            if (!response.ok) return null;
            const data = await response.json();
            console.log('Raw API response:', data);
            // Changed this part to use chat_id instead of id
            return {
                id: data.chat_id, // Changed from data.id to data.chat_id
                content: data.response
            };
    
        } catch (error) {
            return null;
        }
    }

    static async getInjectedAIResponse(
        userId: number,
        assistantId: number,
        message: string,
        suggestorData: string,
        suggestorName: string,
        nanoUserData: string,
        lastChats: string
    ): Promise<string | null> {
        try {
            const response = await fetch(`${PLATOCHAT_BASE_URL}/injected-ai-response`, {
                method: 'POST',
                headers: this.getHeaders(),
                body: JSON.stringify({
                    user_id: userId,
                    assistant_id: assistantId,
                    message,
                    suggestor_data: suggestorData,
                    suggestor_name: suggestorName,
                    nano_user_data: nanoUserData,
                    last_chats: lastChats
                })
            });

            if (!response.ok) return null;
            const data = await response.json();
            return data.response;
        } catch (error) {
            console.error('Error getting injected AI response:', error);
            return null;
        }
    }

    static async getChatHistory(userId: number, assistantId: number, limit?: number): Promise<ChatHistory[] | null> {
        try {
            const response = await fetch(`${PLATOCHAT_BASE_URL}/get-ai-chat-history?user_id=${userId}&assistant_id=${assistantId}&limit=${limit || 10}`, {
                method: 'GET',  // Changed to GET as per backend implementation
                headers: this.getHeaders()
        });

        if (!response.ok) return null;
        const data = await response.json();
        
        // Filter the history for specific assistant
        const filteredHistory = data.history.filter((chat: ChatHistory) => 
            chat.assistant_id === assistantId
        ).slice(0, limit || 10);
        
        return filteredHistory;
    } catch (error) {
        console.error('Error getting chat history:', error);
        return null;
    }
}

    static async getMonthlyUsage(userId: number, period?: string): Promise<MonthlyUsage | null> {
        try {
            const response = await fetch(`${PLATOCHAT_BASE_URL}/get-ai-monthly-usage?user_id=${userId}&period=${period || new Date().toISOString().slice(0, 7)}`, {
                method: 'GET',
                headers: this.getHeaders(),
            });

            if (!response.ok) return null;
            const data = await response.json();
            return {
                user_id: data.user_id,
                period: data.period,
                uses: data.uses,
                remaining: data.remaining
            };
        } catch (error) {
            console.error('Error getting monthly usage:', error);
            return null;
        }
    }

    static async determineGood(chatId: number): Promise<boolean> {
        try {
            const response = await fetch(`${PLATOCHAT_BASE_URL}/determine-good`, {
                method: 'POST',
                headers: this.getHeaders(),
                body: JSON.stringify({
                    chat_id: chatId
                })
            });

            return response.ok;
        } catch (error) {
            console.error('Error determining good:', error);
            return false;
        }
    }

    static async determineBad(chatId: number): Promise<boolean> {
        try {
            const response = await fetch(`${PLATOCHAT_BASE_URL}/determine-bad`, {
                method: 'POST',
                headers: this.getHeaders(),
                body: JSON.stringify({
                    chat_id: chatId
                })
            });

            return response.ok;
        } catch (error) {
            console.error('Error determining bad:', error);
            return false;
        }
    }
}
