import { ModalForm } from '../../pieces/reusable/modal-form';
import { NavColumn } from '../../pieces/general/nav-column';
import { ContentColumn } from '../../pieces/general/content-column';
import { DiscoveryColumn } from '../../pieces/general/discovery-column';
import { YourBig5 } from '../../pieces/general/your-big-5';
import { ContentTitle } from '../../pieces/reusable/content-title';
import { MobileNavButtons } from '../../pieces/reusable/mobile-nav-buttons';
import { KomminService } from '../../actions/kommin/api-services';
import { UINotifications } from '../../utilities/errors';
import { navigate } from '../../app-router';
import { ComingSoonContent } from '../../pieces/reusable/coming-soon-content';

interface Corner {
    id: string;
    title: string;
    description: string;
    moderators: Array<{
        id: string;
        name: string;
        imageUrl?: string;
    }>;
    discussions: Array<{
        id: string;
        title: string;
        author: string;
        timestamp: string;
        excerpt: string;
    }>;
}

export class KomminCornersScreen {
    private container: HTMLElement | null = null;
    private modalForm: ModalForm;
    private yourBig5: YourBig5;
    private mobileNavButtons: MobileNavButtons;
    private readonly CORNERS_ENABLED = false; // Set to true when ready to launch

    constructor() {
        this.modalForm = new ModalForm();
        this.yourBig5 = new YourBig5(() => {});
        this.mobileNavButtons = new MobileNavButtons(this.modalForm, this.yourBig5);
    }

    private async createCornerCard(corner: Corner): Promise<HTMLElement> {
        const card = document.createElement('div');
        card.className = `
            relative overflow-hidden
            bg-gradient-to-br from-black/20 to-black/30
            hover:from-black/25 hover:to-black/35
            backdrop-blur-lg
            border-b border-white/5
            p-5
            transition-all duration-300 cursor-pointer
            rounded-xl
            group
            hover:shadow-lg hover:shadow-orange-500/5
            hover:scale-[1.01]
            hover:border-orange-500/10
        `;

        const moderatorsList = corner.moderators.slice(0, 3).map(mod => mod.name).join(', ');
        const remainingMods = corner.moderators.length > 3 ? '...' : '';

        card.innerHTML = `
            <div class="flex flex-col gap-4">
                <div class="flex items-center gap-2">
                    <div class="w-11 h-11 rounded-full 
                        bg-gradient-to-br from-purple-500/20 to-purple-600/20 
                        border-2 border-purple-500/40
                        flex items-center justify-center
                        shadow-lg shadow-purple-500/20
                        overflow-hidden
                        ring-2 ring-purple-500/20 ring-offset-2 ring-offset-black/50
                        group-hover:ring-purple-500/40 group-hover:shadow-purple-500/30
                        transition-all duration-300">
                        <svg class="w-6 h-6 text-purple-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                  d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
                        </svg>
                    </div>
                    <div>
                        <h3 class="font-[Space_Grotesk] font-bold text-xl text-white/95 
                                 group-hover:text-orange-400/90 transition-colors">
                            ${corner.title}
                        </h3>
                    </div>
                </div>
                
                <div class="prose prose-invert">
                    <p class="text-white/80 font-[Crimson_Text] text-lg leading-relaxed mb-4">
                        ${corner.description}
                    </p>
                </div>

                <div class="grid grid-cols-2 gap-4">
                    ${corner.discussions.slice(0, 4).map(disc => `
                        <div class="relative z-10 p-4 rounded-lg 
                             bg-black/20
                             border border-white/5
                             transition-all duration-300
                             hover:scale-[1.02] hover:shadow-lg hover:shadow-orange-500/5
                             hover:bg-black/30 hover:border-orange-500/20
                             cursor-pointer
                             space-y-2
                             group/disc">
                            <h4 class="text-white/60 font-[Space_Grotesk] font-medium text-base 
                                     group-hover/disc:text-orange-400/90 transition-colors">
                                ${disc.title}
                            </h4>
                            <p class="text-white/40 font-[Crimson_Text] text-sm leading-relaxed
                                    group-hover/disc:text-white/60 transition-colors">
                                ${disc.excerpt}
                            </p>
                            <div class="flex items-center gap-2 text-white/30 text-xs font-[Quicksand]
                                      group-hover/disc:text-white/40 transition-colors">
                                <span class="font-medium">${disc.author}</span>
                                <span>·</span>
                                <span>${disc.timestamp}</span>
                            </div>
                        </div>
                    `).join('')}
                </div>

                <div class="flex items-center gap-2 text-white/40">
                    <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                              d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"/>
                    </svg>
                    <span class="text-xs font-[Quicksand] font-medium">Moderated by: ${moderatorsList}${remainingMods}</span>
                </div>
            </div>
        `;

        return card;
    }

    public async mount(container: HTMLElement) {
        // Set chosen applet
        localStorage.setItem('chosenApplet', 'kommin');

        this.container = container;
        this.container.innerHTML = '';
        this.container.className = 'min-h-screen';

        // Create layout
        const columnLayout = document.createElement('div');
        columnLayout.className = 'flex min-h-screen h-screen overflow-hidden';

        // Content wrapper
        const contentWrapper = document.createElement('div');
        contentWrapper.className = 'flex lg:flex-row flex-col flex-1 overflow-hidden';

        // Mount columns
        const navColumn = new NavColumn(this.modalForm);
        const contentColumn = new ContentColumn();
        const discoveryColumn = new DiscoveryColumn(this.modalForm, this.yourBig5);

        // Only mount nav column on desktop
        const navElement = navColumn.getElement();
        navElement.className = 'hidden sm:block ' + navElement.className;
        navColumn.mount(columnLayout);

        contentColumn.mount(contentWrapper);
        
        // Hide discovery column on mobile
        const discoveryElement = discoveryColumn.getElement();
        discoveryElement.className = 'hidden sm:block discovery-column ' + discoveryElement.className;
        discoveryColumn.mount(contentWrapper);

        // Load Corners content
        await this.loadKomminCornersContent(contentColumn.getContainer());

        columnLayout.appendChild(contentWrapper);
        
        // Mount modal form and layout
        this.modalForm.mount(container);
        container.appendChild(columnLayout);

        // Add mobile nav buttons
        this.mobileNavButtons.mount(container);
    }

    private async loadKomminCornersContent(container: HTMLElement) {
        container.innerHTML = '';
        
        // Create main container with padding
        const mainContent = document.createElement('div');
        mainContent.className = 'max-w-3xl mx-auto px-4 py-6 space-y-6 pb-24';
        
        // Add content title
        const contentTitle = new ContentTitle('Corners', 'kommin');
        contentTitle.mount(mainContent);

        // Create navigation row
        const navRow = document.createElement('div');
        navRow.className = 'flex items-center justify-between mb-6';

        // Create back button
        const backButton = document.createElement('button');
        backButton.className = `
            flex items-center gap-2
            text-white/50 hover:text-white/70
            transition-colors
            text-sm
        `;
        backButton.innerHTML = `
            <i class="fas fa-arrow-left"></i>
            <span>Back</span>
        `;
        backButton.addEventListener('click', () => navigate('/kommin'));

        // Add new corner button if logged in
        const isLoggedIn = localStorage.getItem('userId') !== null;
        if (isLoggedIn) {
            const newCornerButton = document.createElement('button');
            newCornerButton.className = `
                flex items-center justify-center gap-1.5
                bg-purple-500/30 hover:bg-purple-500/40
                text-purple-400
                px-4 py-2
                rounded-xl
                transition-all duration-300
                border border-purple-500/30
                text-sm
                h-[40px]
            `;
            newCornerButton.innerHTML = `
                <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                        d="M12 4v16m8-8H4"></path>
                </svg>
                <span>New Corner</span>
            `;
            newCornerButton.addEventListener('click', () => {
                navigate('/kommin/corner/new');
            });

            navRow.appendChild(backButton);
            navRow.appendChild(newCornerButton);
        } else {
            navRow.appendChild(backButton);
        }

        mainContent.appendChild(navRow);

        // Create corners grid
        const cornersGrid = document.createElement('div');
        cornersGrid.className = 'grid grid-cols-1 gap-6';

        UINotifications.showGlobalLoadingIndication();

        if (!this.CORNERS_ENABLED) {
            const comingSoon = new ComingSoonContent(
                'Corners Coming Soon',
                'Create and join topic-focused communities. Corner functionality is currently being developed and will be available shortly.',
                '🎯'
            );
            comingSoon.mount(container);
            return;
        }

        try {
            // TODO: Replace with actual API call when available
            const corners: Corner[] = await this.getMockCorners();
            
            if (corners.length === 0) {
                const notice = document.createElement('div');
                notice.className = 'text-center p-8 bg-white/5 backdrop-blur-sm rounded-lg border border-white/10';
                notice.innerHTML = `
                    <h3 class="text-lg font-medium text-white/90 mb-2">No corners found</h3>
                    <p class="text-sm text-white/70">Be the first to create a corner!</p>
                `;
                cornersGrid.appendChild(notice);
            } else {
                for (const corner of corners) {
                    const card = await this.createCornerCard(corner);
                    cornersGrid.appendChild(card);
                }
            }
        } catch (error) {
            console.error('Error loading corners:', error);
            const errorNotice = document.createElement('div');
            errorNotice.className = 'text-center p-8 bg-red-500/10 backdrop-blur-sm rounded-lg border border-red-500/20';
            errorNotice.innerHTML = `
                <h3 class="text-lg font-medium text-red-400 mb-2">Error loading corners</h3>
                <p class="text-sm text-red-300">Please try again later</p>
            `;
            cornersGrid.appendChild(errorNotice);
        } finally {
            UINotifications.hideGlobalLoadingIndication();
        }

        mainContent.appendChild(cornersGrid);
        container.appendChild(mainContent);
    }

    // Temporary mock data function - replace with actual API call
    private async getMockCorners(): Promise<Corner[]> {
        return [
            {
                id: '1',
                title: 'Art & Creativity',
                description: 'A space for artists, creators, and enthusiasts to share their work and discuss creative processes.',
                moderators: [
                    { id: '1', name: 'ArtisticSoul' },
                    { id: '2', name: 'CreativeSpirit' },
                    { id: '3', name: 'DesignMaster' },
                    { id: '4', name: 'ColorTheory' }
                ],
                discussions: [
                    {
                        id: '1',
                        title: 'Digital Art Tips & Tricks',
                        author: 'ArtisticSoul',
                        timestamp: '2h ago',
                        excerpt: 'Sharing some of my favorite digital art techniques...'
                    },
                    {
                        id: '2',
                        title: 'Weekly Sketch Challenge',
                        author: 'CreativeSpirit',
                        timestamp: '5h ago',
                        excerpt: 'This week\'s theme: Urban Landscapes'
                    },
                    {
                        id: '3',
                        title: 'Color Theory Basics',
                        author: 'ColorTheory',
                        timestamp: '1d ago',
                        excerpt: 'Understanding the color wheel and its applications...'
                    },
                    {
                        id: '4',
                        title: 'Traditional vs Digital',
                        author: 'DesignMaster',
                        timestamp: '2d ago',
                        excerpt: 'Exploring the pros and cons of both mediums...'
                    }
                ]
            },
            {
                id: '2',
                title: 'Tech & Programming',
                description: 'Discuss the latest in technology, share coding projects, and help others learn programming.',
                moderators: [
                    { id: '5', name: 'CodeMaster' },
                    { id: '6', name: 'TechGuru' },
                    { id: '7', name: 'DevOps' },
                    { id: '8', name: 'WebDev' },
                    { id: '9', name: 'AIEnthusiast' }
                ],
                discussions: [
                    {
                        id: '5',
                        title: 'Getting Started with React',
                        author: 'CodeMaster',
                        timestamp: '1h ago',
                        excerpt: 'A beginner-friendly guide to React fundamentals...'
                    },
                    {
                        id: '6',
                        title: 'Python vs JavaScript',
                        author: 'TechGuru',
                        timestamp: '3h ago',
                        excerpt: 'Comparing two popular programming languages...'
                    },
                    {
                        id: '7',
                        title: 'AI Development Tips',
                        author: 'AIEnthusiast',
                        timestamp: '6h ago',
                        excerpt: 'Best practices for developing AI applications...'
                    },
                    {
                        id: '8',
                        title: 'Cloud Computing Basics',
                        author: 'DevOps',
                        timestamp: '1d ago',
                        excerpt: 'Introduction to cloud platforms and services...'
                    }
                ]
            },
            {
                id: '3',
                title: 'Gaming & Entertainment',
                description: 'Connect with fellow gamers, discuss latest releases, share gaming experiences and strategies.',
                moderators: [
                    { id: '10', name: 'GameMaster' },
                    { id: '11', name: 'ESportsPro' },
                    { id: '12', name: 'StreamerElite' },
                    { id: '13', name: 'RPGFan' }
                ],
                discussions: [
                    {
                        id: '9',
                        title: 'Latest Game Releases',
                        author: 'GameMaster',
                        timestamp: '30m ago',
                        excerpt: 'Reviewing this month\'s biggest game launches...'
                    },
                    {
                        id: '10',
                        title: 'ESports Tournament Guide',
                        author: 'ESportsPro',
                        timestamp: '2h ago',
                        excerpt: 'How to organize and participate in tournaments...'
                    },
                    {
                        id: '11',
                        title: 'Streaming Setup Tips',
                        author: 'StreamerElite',
                        timestamp: '4h ago',
                        excerpt: 'Essential equipment and software for streaming...'
                    },
                    {
                        id: '12',
                        title: 'RPG Strategy Guide',
                        author: 'RPGFan',
                        timestamp: '1d ago',
                        excerpt: 'Advanced tactics for role-playing games...'
                    }
                ]
            }
        ];
    }

    public unmount(): void {
        if (this.container) {
            this.container.innerHTML = '';
        }
        
        this.container = null;
        this.modalForm.unmount();
        this.yourBig5?.unmount();
        this.mobileNavButtons.unmount();
    }
}
