import { KomminService } from '../../actions/kommin/api-services';
import { DiscussionResponse } from '../../actions/kommin/models';
import { UINotifications } from '../../utilities/errors';
import { Button } from '../reusable/button';
import { ModalForm } from '../reusable/modal-form';
import { Hash } from '../../utilities/hash';
import { navigate } from '../../app-router';
import { UpdateSubscriptionContent } from '../reusable/update-subscription-content';
import { AuthService } from '../../actions/auth/api-services';

interface ExtendedDiscussionResponse extends DiscussionResponse {
    Corner?: string | null;
    Group?: string | null;
}

export class DiscussionsContent {
    private discussions: DiscussionResponse[] = [];
    private currentOffset: number = 0;
    private grid!: HTMLElement;
    private modalForm: ModalForm;
    private container: HTMLElement | null = null;

    constructor(modalForm: ModalForm) {
        this.modalForm = modalForm;
    }

    async mount(parent: HTMLElement) {
        this.currentOffset = 0;

        const content = document.createElement('div');
        content.className = 'discussions-content space-y-8 max-w-2xl mx-auto';
        parent.appendChild(content);

        const buttonContainer = document.createElement('div');
        buttonContainer.className = 'flex items-center justify-center gap-4 mt-4';

        const isLoggedIn = this.checkUserLoggedIn();

        // Only show New Komm button if logged in
        if (isLoggedIn) {
            const startDiscussionButton = new Button('New Komm');
            startDiscussionButton.getElement().className = `
                flex items-center justify-center gap-1.5
                bg-orange-500/30 hover:bg-orange-500/40
                text-orange-400
                px-4 py-2
                rounded-xl
                transition-all duration-300
                border border-orange-500/30
                text-sm
                h-[40px]
            `;
            startDiscussionButton.getElement().innerHTML = `
                <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                          d="M12 4v16m8-8H4"></path>
                </svg>
                <span class="hidden sm:inline">New Komm</span>
                <span class="sm:hidden inline">New</span>
            `;
            startDiscussionButton.onClick(() => {
                navigate('/kommin/new');
            });
            buttonContainer.appendChild(startDiscussionButton.getElement());
        }

        // Always show Corners and Groups buttons
        const cornersButton = new Button('Corners');
        cornersButton.getElement().className = `
            flex items-center justify-center gap-1.5
            bg-purple-500/30 hover:bg-purple-500/40
            text-purple-400
            px-4 py-2
            rounded-xl
            transition-all duration-300
            border border-purple-500/30
            text-sm
            h-[40px]
        `;
        cornersButton.getElement().innerHTML = `
            <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
            </svg>
            Corners
        `;
        cornersButton.onClick(() => {
            navigate('/kommin/corners');
        });

        const groupsButton = new Button('Groups');
        groupsButton.getElement().className = `
            flex items-center justify-center gap-1.5
            bg-blue-500/30 hover:bg-blue-500/40
            text-blue-400
            px-4 py-2
            rounded-xl
            transition-all duration-300
            border border-blue-500/30
            text-sm
            h-[40px]
        `;
        groupsButton.getElement().innerHTML = `
            <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                      d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"></path>
            </svg>
            Groups
        `;
        groupsButton.onClick(() => {
            navigate('/kommin/groups');
        });

        buttonContainer.appendChild(cornersButton.getElement());
        buttonContainer.appendChild(groupsButton.getElement());
        content.appendChild(buttonContainer);

        this.grid = document.createElement('div');
        this.grid.className = 'flex flex-col gap-4 divide-y divide-white/5';
        content.appendChild(this.grid);

        UINotifications.showGlobalLoadingIndication();

        try {
            const initialDiscussions = await KomminService.getDiscussionsByRecency(0);
            UINotifications.hideGlobalLoadingIndication();

            if (!initialDiscussions || initialDiscussions.length === 0) {
                const notice = document.createElement('div');
                notice.className = 'col-span-full text-center p-8 bg-white/5 backdrop-blur-sm rounded-lg border border-white/10';
                notice.innerHTML = `
                    <h3 class="text-lg font-medium text-white/90 mb-2">No discussions found.</h3>
                    <p class="text-sm text-white/70 mb-3">Try selecting a different iTag or check back later.</p>
                `;
                this.grid.appendChild(notice);
                return;
            }

            await this.loadMoreDiscussions();
        } catch (error) {
            console.error('Error fetching discussions:', error);
            UINotifications.hideGlobalLoadingIndication();
        }

        const loadMoreButton = new Button('Load More');
        loadMoreButton.getElement().style.width = '100%';
        loadMoreButton.onClick(async () => {
            loadMoreButton.getElement().disabled = true;
            await this.loadMoreDiscussions();
            loadMoreButton.getElement().disabled = false;
        });
        loadMoreButton.mount(content);
    }

    public async loadMostRecentDiscussions() {
        this.currentOffset = 0;
        this.grid.innerHTML = ''; // Clear existing discussions
        UINotifications.showGlobalLoadingIndication();
        try {
            const discussions = await KomminService.getDiscussionsByRecency(0);
            UINotifications.hideGlobalLoadingIndication();
            this.renderDiscussions(discussions);
        } catch (error) {
            console.error('Error loading most recent discussions:', error);
            UINotifications.hideGlobalLoadingIndication();
        }
    }

    public async loadDiscussionsByITag(itag: string) {
        this.grid.innerHTML = ''; // Clear existing discussions
        UINotifications.showGlobalLoadingIndication();
        try {
            const discussions = await KomminService.getDiscussionsByITag(itag);
            UINotifications.hideGlobalLoadingIndication();
            this.renderDiscussions(discussions);
        } catch (error) {
            console.error('Error loading discussions by iTag:', error);
            UINotifications.hideGlobalLoadingIndication();
        }
    }

    private async renderDiscussions(discussions: DiscussionResponse[] | null) {
        if (discussions && discussions.length > 0) {
            for (const discussion of discussions) {
                const tile = await this.createDiscussionTile(discussion);
                this.grid.appendChild(tile);
            }
        } else {
            const notice = document.createElement('div');
            notice.className = 'col-span-full text-center p-8 bg-white/5 backdrop-blur-sm rounded-lg border border-white/10';
            notice.innerHTML = `
                <h3 class="text-lg font-medium text-white/90 mb-2">No discussions found.</h3>
                <p class="text-sm text-white/70 mb-3">Try selecting a different iTag or check back later.</p>
            `;
            this.grid.appendChild(notice);
        }
    }

    private async loadMoreDiscussions() {
        UINotifications.showGlobalLoadingIndication();

        const newDiscussions = await KomminService.getDiscussionsByRecency(this.currentOffset);

        UINotifications.hideGlobalLoadingIndication();

        if (newDiscussions && newDiscussions.length > 0) {
            for (const discussion of newDiscussions) {
                const tile = await this.createDiscussionTile(discussion);
                this.grid.appendChild(tile);
            }
            this.currentOffset += newDiscussions.length;
            this.discussions = [...this.discussions, ...newDiscussions];
        }
    }

    private async fetchDiscussions(): Promise<DiscussionResponse[]> {
        const discussions = await KomminService.getDiscussionsByRecency(0);
        return discussions || [];
    }

    private async createDiscussionTile(discussion: ExtendedDiscussionResponse): Promise<HTMLElement> {
        const tile = document.createElement('div');
        tile.className = `
            relative overflow-hidden
            bg-gradient-to-br from-black/20 to-black/30
            hover:from-black/25 hover:to-black/35
            backdrop-blur-lg
            border-b border-white/5
            p-5
            transition-all duration-300 cursor-pointer
            rounded-xl
            group
            hover:shadow-lg hover:shadow-orange-500/5
            hover:scale-[1.01]
            hover:border-orange-500/10
        `;

        // Create profile URL using Hash utility
        const authorSlug = Hash.createSlug(discussion.Author);
        const profileUrl = `/people/${authorSlug}/${Hash.encodeId(discussion.UserID)}`;

        // Create avatar container with link
        const avatarLink = document.createElement('a');
        avatarLink.href = profileUrl;
        avatarLink.className = 'block cursor-pointer relative z-10';
        avatarLink.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            navigate(profileUrl);
        });

        const avatarContainer = document.createElement('div');
        avatarContainer.className = `
            w-11 h-11 rounded-full 
            bg-gradient-to-br from-orange-500/20 to-orange-600/20 
            border-2 border-purple-500/40
            flex items-center justify-center
            shadow-lg shadow-purple-500/20
            overflow-hidden
            ring-2 ring-purple-500/20 ring-offset-2 ring-offset-black/50
            group-hover:ring-purple-500/40 group-hover:shadow-purple-500/30
            transition-all duration-300
        `;
        avatarContainer.innerHTML = `<span class="text-orange-400 font-medium text-sm">${discussion.Author.charAt(0).toUpperCase()}</span>`;

        // Lazy load the image
        const observer = new IntersectionObserver(async (entries) => {
            if (entries[0].isIntersecting) {
                observer.disconnect();
                const firstImage = await AuthService.getFirstImage(discussion.UserID);
                if (firstImage && firstImage.ImageURL) {
                    avatarContainer.innerHTML = `
                        <img 
                            src="${firstImage.ImageURL.replace('/upload/', '/upload/c_fill,g_face,q_auto/e_improve/')}" 
                            class="w-full h-full object-cover transition-transform duration-300 group-hover:scale-110" 
                            alt="${discussion.Author}" 
                        />`;
                }
            }
        });

        observer.observe(avatarContainer);

        const rating = Math.round(Number(discussion.AverageRating) * 100) / 100;
        const ratingDisplay = !isNaN(rating) 
            ? (rating > 50 ? `+${rating}` : `-${rating}`)
            : 'N/A';
        const ratingColor = !isNaN(rating)
            ? (rating > 50 ? 'text-green-400' : 'text-red-400')
            : 'text-white/50';

        tile.innerHTML = `
            <div class="flex flex-col gap-4">
                <div class="flex items-center gap-2">
                    <div class="flex-shrink-0" id="avatar-container"></div>
                    <div class="flex items-center gap-2">
                        <div class="relative z-20">
                            <a href="${profileUrl}" 
                               class="font-[Quicksand] font-bold text-white/90 hover:text-orange-400 text-[15px] tracking-normal transition-colors inline-block"
                               onclick="event.preventDefault(); event.stopPropagation();">
                                ${discussion.Author}
                            </a>
                        </div>
                        <span class="text-white/30 text-sm">·</span>
                        <span class="text-white/30 text-sm">${discussion.Timestamp}</span>
                    </div>
                </div>
                
                <div class="prose prose-invert">
                    ${discussion.Title ? `
                        <h2 class="!font-[Space_Grotesk] !font-bold !text-xl !text-white/95 !mb-3 
                                 !border-b !border-white/10 !pb-2
                                 group-hover:!text-orange-400/90 !transition-colors">
                            ${discussion.Title}
                        </h2>
                    ` : ''}
                    <div id="media-container-${discussion.ID}"></div>
                    <p class="text-white/80 mb-4 font-[Crimson_Text] text-lg leading-relaxed">
                        ${this.buildExcerpt(discussion.Body, 280)}
                    </p>
                </div>
                <div class="flex flex-wrap items-center gap-x-6 gap-y-2 text-white/40">
                    <div class="flex items-center gap-2 group-hover:text-orange-400 transition-colors">
                        <i class="fa-regular fa-comment text-sm"></i>
                        <span class="text-xs font-medium">View Replies</span>
                    </div>
                    <div class="flex items-center gap-2">
                        <span class="${ratingColor} text-xs font-medium">${ratingDisplay}</span>
                        <span class="text-xs">rating</span>
                    </div>
                    <div class="flex flex-wrap items-center gap-4">
                        ${discussion.Corner ? `
                            <div class="flex items-center gap-1 text-purple-400/70">
                                <svg class="w-3.5 h-3.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                          d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
                                </svg>
                                <span class="text-xs font-medium">News</span>
                            </div>
                        ` : ''}
                        ${discussion.Group ? `
                            <div class="flex items-center gap-1 text-blue-400/70">
                                <svg class="w-3.5 h-3.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                                          d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"></path>
                                </svg>
                                <span class="text-xs font-medium">PLATO5 Engagement Drivers</span>
                            </div>
                        ` : ''}
                    </div>
                </div>
            </div>
        `;

        // Append the avatar container
        tile.querySelector('#avatar-container')!.appendChild(avatarContainer);

        // Lazy load the discussion images
        const mediaContainer = tile.querySelector(`#media-container-${discussion.ID}`) as HTMLElement;
        const mediaObserver = new IntersectionObserver(async (entries) => {
            if (entries[0].isIntersecting) {
                mediaObserver.disconnect();
                const pictures = await AuthService.getDiscussionPictures(discussion.ID);
                if (pictures && pictures.length > 0) {
                    mediaContainer.className = `
                        relative mb-4 w-full aspect-[16/9] rounded-lg overflow-hidden
                        bg-gradient-to-br from-black/40 to-black/20
                        backdrop-blur-xl
                        border border-white/5
                    `;
                    
                    // Transform the Cloudinary URL for optimal display
                    const optimizedUrl = pictures[0].ImageURL.replace('/upload/', '/upload/c_fill,g_auto,w_1280,h_720,q_auto,f_auto/');
                    
                    mediaContainer.innerHTML = `
                        <img 
                            src="${optimizedUrl}" 
                            alt="Discussion media"
                            class="w-full h-full object-cover object-center transform hover:scale-105 transition-transform duration-500"
                            loading="lazy"
                        />
                    `;
                }
            }
        });

        mediaObserver.observe(mediaContainer);

        // Add a click handler to the tile that checks the click target
        tile.addEventListener('click', (e) => {
            // Only navigate to discussion if we didn't click a link
            if (!(e.target as HTMLElement).closest('a')) {
                const hashedId = Hash.encodeId(discussion.ID);
                navigate(`/kommin/discussion/${hashedId}`);
            }
        });

        // Add click handlers
        const profileLink = tile.querySelector('a');
        if (profileLink) {
            profileLink.addEventListener('click', (e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate(profileUrl);
            });
        }

        const memos = await AuthService.getDiscussionMemos(discussion.ID);
        if (memos && memos.length > 0) {
            const memo = memos[0];
            const memoContainer = document.createElement('div');
            memoContainer.className = `
                relative mt-6 p-5 rounded-xl
                bg-gradient-to-br from-black/40 to-black/60
                border border-orange-500/10
                backdrop-blur-xl
                hover:border-orange-500/20
                transition-all duration-300
                group
                cursor-pointer
            `;
            memoContainer.innerHTML = `
                <div class="flex flex-col gap-3">
                    ${memo.Caption ? `
                        <p class="text-sm text-white/60">"${memo.Caption}"</p>
                    ` : ''}
                    <audio src="${memo.AudioURL}" 
                           class="w-full h-[36px] [&::-webkit-media-controls-panel]:bg-orange-950/40 
                           [&::-webkit-media-controls-current-time-display]:text-orange-400
                           [&::-webkit-media-controls-time-remaining-display]:text-orange-400
                           [&::-webkit-media-controls-timeline]:accent-orange-400
                           [&::-webkit-media-controls-play-button]:text-orange-400" 
                           controls
                           preload="none"></audio>
                </div>
            `;

            // Add click handler for the container
            memoContainer.addEventListener('click', (e) => {
                e.stopPropagation(); // Stop event from bubbling up to discussion tile
                // Don't trigger if clicking the audio controls directly
                if (!(e.target as HTMLElement).closest('audio')) {
                    const audioElement = memoContainer.querySelector('audio');
                    if (audioElement) {
                        if (audioElement.paused) {
                            audioElement.play();
                        } else {
                            audioElement.pause();
                        }
                    }
                }
            });

            // Insert after post body
            const postBody = tile.querySelector('.prose p');
            if (postBody) {
                postBody.parentNode?.insertBefore(memoContainer, postBody.nextSibling);
            }
        }

        return tile;
    }

    private checkUserLoggedIn(): boolean {
        const userId = localStorage.getItem('userId');
        return userId !== null;
    }

    private buildExcerpt(text: string, maxLength: number = 200): string {
        if (text.length <= maxLength) return text;
        
        // Find the last space before maxLength to avoid cutting words
        const lastSpace = text.substring(0, maxLength).lastIndexOf(' ');
        return text.substring(0, lastSpace) + '...';
    }

    public unmount(): void {
        if (this.container) {
            this.container.innerHTML = '';
            this.container = null;
        }
    }
} 