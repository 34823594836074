import { UserData } from '../../actions/auth/models';
import { ModalForm } from '../reusable/modal-form';
import { AnalytixService } from '../../actions/analytix/api-services';
import { AuthService } from '../../actions/auth/api-services';

export class OtherProfileContent {
    private element: HTMLElement;
    private modalForm: ModalForm;
    private analytixData: Record<string, number> = {};

    private readonly traitColors: { [K in 'Openness' | 'Conscientiousness' | 'Extraversion' | 'Agreeableness' | 'Neuroticism']: string[] } = {
        'Openness': ['from-purple-500', 'to-pink-500'],
        'Conscientiousness': ['from-orange-500', 'to-yellow-300'],
        'Extraversion': ['from-blue-500', 'to-cyan-300'],
        'Agreeableness': ['from-green-500', 'to-emerald-300'],
        'Neuroticism': ['from-red-500', 'to-rose-300']
    };

    private readonly traitDescriptions = {
        'Openness': `Openness describes receptiveness to new ideas and experiences. High scorers are typically artistic, curious, and adventurous (think innovative entrepreneurs or creative artists), while low scorers prefer routine and familiar settings (like someone who eats the same breakfast daily and vacations at the same spot yearly).`,
        'Conscientiousness': `Conscientiousness relates to organization and responsibility. High scorers are methodical planners who maintain detailed calendars and clean spaces (like a detail-oriented accountant), while low scorers tend to be spontaneous and flexible but sometimes scattered (like a free-spirited artist who works in creative bursts).`,
        'Extraversion': `Extraversion measures energy derived from social interaction. High scorers thrive in group settings and seek excitement (picture an energetic party host), while low scorers prefer solitude and quiet reflection (like a focused researcher).`,
        'Agreeableness': `Agreeableness reflects concern for others' wellbeing. High scorers are empathetic and cooperative (think dedicated nurses or mediators), while low scorers tend to be competitive and direct (like tough negotiators or critics).`,
        'Neuroticism': `Neuroticism indicates emotional sensitivity and stress response. High scorers experience intense emotions and worry frequently (like someone who thoroughly plans for every contingency), while low scorers remain calm under pressure and recover quickly from setbacks (like a steady emergency responder).`
    };

    constructor(modalForm: ModalForm) {
        this.element = document.createElement('div');
        this.element.className = 'p-4 space-y-4';
        this.modalForm = modalForm;
    }

    private async loadAnalytixData(userId: number): Promise<void> {
        try {
            let analytixData = await AnalytixService.getAnalytixByUserId(userId);
            
            if (!analytixData || 
                typeof analytixData.tOpenness !== 'number' ||
                typeof analytixData.tConscientiousness !== 'number' ||
                typeof analytixData.tExtraversion !== 'number' ||
                typeof analytixData.tAgreeableness !== 'number' ||
                typeof analytixData.tNeuroticism !== 'number') {
                return;
            }

            this.analytixData = {
                't_openness': analytixData.tOpenness / 100,
                't_conscientiousness': analytixData.tConscientiousness / 100,
                't_extraversion': analytixData.tExtraversion / 100,
                't_agreeableness': analytixData.tAgreeableness / 100,
                't_neuroticism': analytixData.tNeuroticism / 100
            };

            this.renderGraph();
        } catch (error) {
            console.error('Failed to load analytix data:', error);
        }
    }

    private renderGraph(): void {
        const graphContainer = this.element.querySelector('#personality-graph');
        if (!graphContainer) return;
        
        graphContainer.innerHTML = '';
        
        const title = document.createElement('h2');
        title.className = 'text-xl font-bold text-white/90 mb-6 text-center pb-6 pt-4';
        title.textContent = 'Personality Traits';
        graphContainer.appendChild(title);
        
        const barContainer = document.createElement('div');
        barContainer.className = 'relative h-60 md:h-80 min-h-[200px] flex items-end gap-[2px] mb-8';

        // Create bars for each trait
        const traits = {
            'Openness': this.analytixData.t_openness,
            'Conscientiousness': this.analytixData.t_conscientiousness,
            'Extraversion': this.analytixData.t_extraversion,
            'Agreeableness': this.analytixData.t_agreeableness,
            'Neuroticism': this.analytixData.t_neuroticism
        };

        Object.entries(traits).forEach(([trait, score]) => {
            const barWrapper = document.createElement('div');
            barWrapper.className = 'relative flex flex-col items-center gap-2 h-full flex-1';

            const bar = document.createElement('div');
            const height = Math.max(5, score * 100);
            bar.className = `absolute bottom-0 w-full bg-gradient-to-t ${this.traitColors[trait as keyof typeof this.traitColors].join(' ')} transition-all duration-500 cursor-pointer hover:opacity-90`;
            bar.style.height = `${height}%`;

            // Add click handler for trait description
            bar.addEventListener('click', () => {
                this.modalForm.clear();
                const content = document.createElement('div');
                content.className = 'p-6 max-w-lg mx-auto';
                content.innerHTML = `
                    <h3 class="text-xl font-bold text-white/90 mb-4">${trait}</h3>
                    <p class="text-white/70 leading-relaxed">
                        ${this.traitDescriptions[trait as keyof typeof this.traitDescriptions]}
                    </p>
                    <p class="text-white/90 mt-4 font-medium">
                        Score: ${(score * 100).toFixed(2)}%
                    </p>
                `;
                this.modalForm.append(content);
                this.modalForm.open();
            });

            const label = document.createElement('div');
            label.className = 'absolute bottom-0 transform translate-y-full mt-2 text-white/70 text-xs md:text-sm text-center';
            label.innerHTML = `
                <span class="hidden md:inline">${trait}</span>
                <span class="inline md:hidden">${trait[0]}</span>
            `;

            const scoreLabel = document.createElement('div');
            scoreLabel.className = 'absolute top-0 transform -translate-y-full mb-2 text-white/90 text-xs md:text-sm font-medium py-1';
            scoreLabel.textContent = `${(score * 100).toFixed(2)}%`;

            barWrapper.appendChild(bar);
            barWrapper.appendChild(scoreLabel);
            barWrapper.appendChild(label);
            barContainer.appendChild(barWrapper);
        });

        graphContainer.appendChild(barContainer);
    }

    public async updateContent(userData: UserData) {
        // Clear the element first
        this.element.innerHTML = '';

        // Name section
        const nameSection = document.createElement('div');
        nameSection.className = 'flex flex-col gap-3 mb-6 pt-2';
        nameSection.innerHTML = `<h1 class="text-xl font-bold text-white">${userData.name}</h1>`;
        this.element.appendChild(nameSection);

        // Image gallery section
        const images = await AuthService.getGalleryImages(userData.id);
        if (images && images.length > 0) {
            const galleryContainer = document.createElement('div');
            galleryContainer.className = `
                relative mb-8 w-full pb-[100%] group
                bg-white/5
                border border-white/10 rounded-lg
                overflow-hidden
                transition-all duration-300 
                hover:border-white/20
                hover:bg-black/30
                backdrop-blur-sm
                shadow-xl shadow-black/20
                ring-1 ring-white/10
                hover:ring-purple-500/20
                hover:shadow-purple-900/20
            `;

            const slider = document.createElement('div');
            slider.className = 'absolute inset-0 flex overflow-x-auto snap-x snap-mandatory scrollbar-thin scrollbar-track-white/10 scrollbar-thumb-white/20 hover:scrollbar-thumb-white/40';
            slider.style.cssText = `
                scroll-behavior: smooth;
            `;

            // Add image slides
            images.forEach(image => {
                const imgContainer = document.createElement('div');
                imgContainer.className = 'w-full h-full flex-shrink-0 snap-center';
                
                const img = document.createElement('img');
                img.className = 'w-full h-full object-cover';
                img.src = image.ImageURL.replace('/upload/', '/upload/c_fill,g_face,q_auto/');
                
                imgContainer.appendChild(img);
                slider.appendChild(imgContainer);
            });

            // Add scroll indicator
            const scrollIndicator = document.createElement('div');
            scrollIndicator.className = `
                absolute top-4 right-4
                px-4 py-2
                bg-black/60 backdrop-blur-sm
                rounded-lg
                text-white/90 text-sm
                flex items-center gap-2
                opacity-0 group-hover:opacity-100
                transition-opacity duration-200
                bg-gradient-to-r from-pink-500 to-purple-500
            `;
            scrollIndicator.innerHTML = `
                Scroll to see more 
                <i class="fas fa-chevron-right"></i>
            `;

            // Hide indicator when scrolling to end
            slider.addEventListener('scroll', () => {
                const isAtEnd = slider.scrollLeft >= slider.scrollWidth - slider.offsetWidth - 10;
                scrollIndicator.style.display = isAtEnd ? 'none' : 'flex';
            });

            galleryContainer.appendChild(slider);
            galleryContainer.appendChild(scrollIndicator);
            this.element.appendChild(galleryContainer);
        }

        // Add personality graph - Using DOM manipulation instead of innerHTML
        const graphContainer = document.createElement('div');
        graphContainer.className = 'mb-8';
        graphContainer.id = 'personality-graph';
        this.element.appendChild(graphContainer);
        await this.loadAnalytixData(userData.id);

        // Add the rest of the profile content using DOM manipulation
        if (userData.intro) {
            const introCard = document.createElement('div');
            introCard.className = 'bg-white/5 rounded-lg p-3 backdrop-blur-sm border border-white/5';
            introCard.innerHTML = `
                <div class="flex items-center gap-3">
                    <div class="w-8 h-8 rounded-lg flex items-center justify-center">
                        <svg class="w-4 h-4 text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
                        </svg>
                    </div>
                    <div>
                        <div class="text-sm text-white/50">Intro</div>
                        <div class="text-white">${userData.intro}</div>
                    </div>
                </div>
            `;
            this.element.appendChild(introCard);
        }

        if (userData.location) {
            const locationCard = document.createElement('div');
            locationCard.className = 'bg-white/5 rounded-lg p-3 backdrop-blur-sm border border-white/5';
            locationCard.innerHTML = `
                <div class="flex items-center gap-3">
                    <div class="w-8 h-8 rounded-lg flex items-center justify-center">
                        <svg class="w-4 h-4 text-yellow-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
                        </svg>
                    </div>
                    <div>
                        <div class="text-sm text-white/50">Location</div>
                        <div class="text-white">${userData.location}</div>
                    </div>
                </div>
            `;
            this.element.appendChild(locationCard);
        }

        // Add remaining cards using the same pattern...
    }

    public mount(parent: HTMLElement) {
        parent.appendChild(this.element);
    }

    public unmount() {
        this.element.remove();
    }

    private createNavButton(direction: 'prev' | 'next'): HTMLButtonElement {
        const button = document.createElement('button');
        button.className = `
            absolute ${direction === 'prev' ? 'left-3' : 'right-3'} top-1/2 -translate-y-1/2
            w-8 h-8
            flex items-center justify-center
            bg-black/60 backdrop-blur-sm
            border border-white/10
            rounded-full
            text-white/90
            hover:bg-black/80
            transition-all duration-200
            opacity-0 group-hover:opacity-100
            z-10
        `;
        button.innerHTML = `<i class="fas fa-chevron-${direction === 'prev' ? 'left' : 'right'}"></i>`;
        return button;
    }
}