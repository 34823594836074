export class ComingSoonContent {
    private element: HTMLElement;

    public getElement(): HTMLElement {
        return this.element;
    }

    constructor(title: string, description: string, icon: string = '🏗️', eta?: string) {
        this.element = document.createElement('div');
        this.initialize(title, description, icon, eta);
    }

    private initialize(title: string, description: string, icon: string, eta?: string) {
        this.element.className = 'h-full w-full relative';
        
        const mainContent = document.createElement('div');
        mainContent.className = `
            h-full w-full
            flex flex-col items-center
            pt-24
        `;
        
        mainContent.innerHTML = `
            <style>
                @keyframes gentleBounce {
                    0%, 100% { transform: translateY(0); }
                    50% { transform: translateY(-20px); }
                }
                .gentle-bounce {
                    animation: gentleBounce 2s ease-in-out infinite;
                }
            </style>
            <div class="text-7xl gentle-bounce">${icon}</div>
            <div class="space-y-3 mt-8">
                <div class="text-4xl font-bold bg-gradient-to-r from-purple-400 to-pink-400 text-transparent bg-clip-text text-center">
                    ${title}
                </div>
                ${eta ? `
                    <div class="flex items-center justify-center gap-2 text-white/50 text-base">
                        <i class="fas fa-clock"></i>
                        <span>Coming ${eta}</span>
                    </div>
                ` : ''}
            </div>
            <div class="text-white/80 text-lg max-w-xl text-center leading-relaxed mt-6">
                ${description}
            </div>
        `;
        
        this.element.appendChild(mainContent);
    }

    public mount(container: HTMLElement) {
        container.appendChild(this.element);
    }

    public unmount() {
        this.element.remove();
    }
}