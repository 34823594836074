import { UserData } from '../../actions/auth/models';
import { Button } from '../reusable/button';
import { AuthService } from '../../actions/auth/api-services';
import { UINotifications } from '../../utilities/errors';
import { countryList } from '../../utilities/country-list';

export class ProfileBuilder {
    private element: HTMLElement;
    private onComplete?: () => void;

    constructor(userData: UserData | null, onComplete?: () => void) {
        this.element = document.createElement('div');
        this.onComplete = onComplete;
        this.render(userData);
    }

    private render(userData: UserData | null): void {
        this.element.className = 'p-6 space-y-6 max-w-lg mx-auto';
        this.element.innerHTML = `
            <h3 class="text-xl font-bold text-white/90 mb-6">Build Your Profile</h3>
            <div class="space-y-4">
                <div>
                    <label class="block text-sm font-medium text-white/70 mb-2">Date of Birth <span class="text-pink-500">*</span></label>
                    <input type="date" id="dob" value="${userData?.dob || ''}" 
                        class="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg text-white/90 focus:outline-none focus:border-blue-500/50" 
                        required>
                    <p class="mt-1 text-xs text-white/50">You must be 18 or older to use this matching service</p>
                </div>
                <div>
                    <label class="block text-sm font-medium text-white/70 mb-2">Intro <span class="text-pink-500">*</span></label>
                    <textarea id="intro" class="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg text-white/90 focus:outline-none focus:border-blue-500/50" 
                        rows="3" placeholder="Tell us a bit about yourself..." required>${userData?.intro || ''}</textarea>
                </div>
                <div>
                    <label class="block text-sm font-medium text-white/70 mb-2">Country <span class="text-pink-500">*</span></label>
                    <select id="country" class="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg text-white/90 focus:outline-none focus:border-blue-500/50" required>
                        <option value="">Select your country</option>
                        ${countryList.map(country => `
                            <option value="${country}" ${country === userData?.country ? 'selected' : ''}>
                                ${country}
                            </option>
                        `).join('')}
                    </select>
                </div>
                <div>
                    <label class="block text-sm font-medium text-white/70 mb-2">Location</label>
                    <input type="text" id="location" value="${userData?.location || ''}" 
                        placeholder="e.g., New York City" 
                        class="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg text-white/90 focus:outline-none focus:border-blue-500/50">
                </div>
                <div>
                    <label class="block text-sm font-medium text-white/70 mb-2">Instagram</label>
                    <input type="text" id="instagramLink" value="${userData?.instagramLink || ''}" 
                        placeholder="@username" 
                        class="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg text-white/90 focus:outline-none focus:border-blue-500/50">
                </div>
            </div>
        `;

        const continueButton = new Button('Continue');
        continueButton.getElement().className = 'mt-6 w-full px-6 py-3 bg-gradient-to-r from-purple-500 to-pink-500 rounded-lg text-white font-medium hover:from-purple-600 hover:to-pink-600 transition-all duration-300';
        
        continueButton.getElement().addEventListener('click', async () => {
            const userId = parseInt(localStorage.getItem('userId') || '0');
            if (!userId) {
                UINotifications.shipErrorToUI('Please log in to continue');
                return;
            }

            const dob = (this.element.querySelector('#dob') as HTMLInputElement).value;
            const intro = (this.element.querySelector('#intro') as HTMLTextAreaElement).value;
            const country = (this.element.querySelector('#country') as HTMLSelectElement).value;
            const location = (this.element.querySelector('#location') as HTMLInputElement).value;
            const instagramLink = (this.element.querySelector('#instagramLink') as HTMLInputElement).value;

            // Validate required fields
            if (!dob || !intro || !country) {
                UINotifications.shipErrorToUI('Please fill out all required fields');
                return;
            }

            // Validate age (18+)
            const birthDate = new Date(dob);
            const today = new Date();
            let age = today.getFullYear() - birthDate.getFullYear();
            const monthDiff = today.getMonth() - birthDate.getMonth();
            if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            
            if (age < 18) {
                UINotifications.shipErrorToUI('You must be 18 or older to use this platform');
                return;
            }

            const updateData = { dob, intro, country, location, instagramLink };

            const success = await AuthService.updateUser(userId, updateData);
            if (success) {
                UINotifications.shipSuccessToUI('Profile updated successfully!');
                this.onComplete?.();
            }
        });

        this.element.appendChild(continueButton.getElement());
    }

    public getElement(): HTMLElement {
        return this.element;
    }
}
