export class TitleEditor {
    private element: HTMLElement;
    private onComplete: (newTitle: string) => void;

    constructor(onComplete: (newTitle: string) => void) {
        this.element = document.createElement('div');
        this.onComplete = onComplete;
        this.render();
    }

    private render(): void {
        this.element.className = 'p-6 space-y-4';
        this.element.innerHTML = `
            <div class="space-y-4">
                <h2 class="text-lg font-semibold text-white">Edit Nano Title</h2>
                <input type="text" 
                    id="nano-title-input"
                    class="w-full bg-[#0F0F0F] text-white rounded-xl px-4 py-3 
                    border border-white/10 
                    focus:border-white/20 focus:outline-none
                    placeholder-white/40"
                    placeholder="New Title...">
                <div class="flex justify-end gap-2 mt-4">
                    <button id="cancel-btn"
                        class="px-4 py-2 rounded-lg border border-white/10 
                        text-white/70 hover:text-white/90 text-sm
                        transition-all duration-200">
                        Cancel
                    </button>
                    <button id="save-btn"
                        class="px-4 py-2 rounded-lg bg-blue-500/20 
                        hover:bg-blue-500/30 text-white/90 text-sm
                        transition-all duration-200">
                        Save Changes
                    </button>
                </div>
            </div>
        `;

        this.attachEventListeners();
    }

    private attachEventListeners(): void {
        const input = this.element.querySelector('#nano-title-input') as HTMLInputElement;
        const saveBtn = this.element.querySelector('#save-btn');
        const cancelBtn = this.element.querySelector('#cancel-btn');

        if (saveBtn) {
            saveBtn.addEventListener('click', () => {
                const newTitle = input.value.trim();
                if (newTitle) {
                    this.onComplete(newTitle);
                }
            });
        }

        if (cancelBtn) {
            cancelBtn.addEventListener('click', () => {
                this.onComplete('');
                window.location.reload();  
            });
        }

        // Auto-focus the input
        setTimeout(() => input?.focus(), 100);
    }

    public getElement(): HTMLElement {
        return this.element;
    }
}