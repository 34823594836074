import { PlatoChatService } from '../../actions/platochat/api-services';
import { AuthService } from '../../actions/auth/api-services';
import { UINotifications } from '../../utilities/errors';
import { Hash } from '../../utilities/hash';
import { navigate } from '../../app-router';

export class CustomNanoContent {
    private element: HTMLElement;
    private onComplete: (success: boolean) => void;
    private userId: number;
    private currentView: 'selection' | 'fullscreen' = 'selection';
    private fullscreenImage: { url: string; userName: string } | null = null;

    constructor(onComplete: (success: boolean) => void) {
        this.element = document.createElement('div');
        this.onComplete = onComplete;
        this.userId = Number(localStorage.getItem('userId'));
        this.render();
    }

    private showFullscreenImage(imageUrl: string, userName: string) {
        this.fullscreenImage = { url: imageUrl, userName };
        this.currentView = 'fullscreen';
        this.render();
    }

    private render(): void {
        if (this.currentView === 'fullscreen' && this.fullscreenImage) {
            const modalElement = this.element.closest('.modal-container, [class*="max-h-"]');
            if (modalElement) {
                (modalElement as HTMLElement).style.maxHeight = 'none';
                (modalElement as HTMLElement).style.height = '100vh';
                (modalElement as HTMLElement).style.maxWidth = 'none';
                (modalElement as HTMLElement).style.width = '100vw';
                (modalElement as HTMLElement).style.overflow = 'hidden';
                (modalElement as HTMLElement).style.padding = '0';
            }

            this.element.className = 'fixed inset-0 z-[9999] bg-black';
            this.element.innerHTML = `
                <div class="h-full w-full flex flex-col">
                    <div class="p-4 flex items-center gap-4">
                        <button class="p-2 rounded-lg bg-black/50 hover:bg-black/70 
                            text-white/90 transition-all duration-200 backdrop-blur-sm border border-white/10">
                            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"/>
                            </svg>
                        </button>
                        <div class="py-2 px-4 rounded-lg bg-black/50 backdrop-blur-sm text-white/90">
                            ${this.fullscreenImage.userName}
                        </div>
                    </div>
                    <div class="flex-1 flex items-center justify-center">
                        <img src="${this.fullscreenImage.url}" 
                            alt="${this.fullscreenImage.userName}'s profile picture"
                            class="max-h-[85vh] w-auto object-contain">
                    </div>
                </div>
            `;

            const backButton = this.element.querySelector('button');
            if (backButton) {
                backButton.addEventListener('click', () => {
                    if (modalElement) {
                        (modalElement as HTMLElement).style.maxHeight = '';
                        (modalElement as HTMLElement).style.height = '';
                        (modalElement as HTMLElement).style.maxWidth = '';
                        (modalElement as HTMLElement).style.width = '';
                        (modalElement as HTMLElement).style.overflow = '';
                        (modalElement as HTMLElement).style.padding = '';
                    }
                    this.currentView = 'selection';
                    this.fullscreenImage = null;
                    this.render();
                });
            }
        } else {
            this.element.className = 'space-y-4';
            this.element.innerHTML = `
                <h2 class="text-lg font-semibold text-white mb-4">Create a Custom Nano</h2>
                <p class="text-white/70 text-sm mb-4">Select 3 users to create a nano with. You will automatically be added as the fourth participant. All users participating in chats must be 18 or older.</p>
                
                <div class="space-y-2 max-h-[50vh] overflow-y-auto pr-2 scrollbar-thin scrollbar-track-white/10 scrollbar-thumb-white/20">
                    <div id="users-list" class="space-y-2">
                        
                    </div>
                </div>
                
                <div class="flex justify-between items-center mt-4 pt-4 border-t border-white/10">
                    <span class="text-white/70 text-sm">Selected: <span id="selected-count">0</span>/3</span>
                    <button id="create-btn" disabled
                        class="px-4 py-2 bg-blue-500/20 hover:bg-blue-500/30 rounded-lg
                        text-white/90 text-sm transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed">
                        Create Nano
                    </button>
                </div>
            `;
            this.initializeUserList();
        }
    }

    private async initializeUserList() {
        const usersList = this.element.querySelector('#users-list');
        const selectedUsers = new Set<number>();
        let page = 0;

        const loadUsers = async () => {
            const users = await AuthService.getUsers(page * 10);
            page++;
            
            const usersWithImages = await Promise.all(users.map(async user => {
                const firstImage = await AuthService.getFirstImage(user.id);
                return {
                    ...user,
                    profilePic: firstImage?.ImageURL || null
                };
            }));
            
            const usersHtml = usersWithImages
                .filter(user => user.id !== Number(localStorage.getItem('userId')))
                .map(user => {
                    const cloudinaryTransforms = '/upload/c_fill,g_face,w_160,h_160,q_auto,f_auto/';
                    return `
                        <div class="flex p-4 mb-3 rounded-xl bg-gradient-to-br from-white/10 to-white/5 
                             hover:from-white/15 hover:to-white/10 cursor-pointer
                             backdrop-blur-lg border border-white/10
                             hover:border-violet-500/30 shadow-lg shadow-black/10
                             transition-all duration-300"
                            data-user-id="${user.id}">
                        
                        <div class="flex-shrink-0 mr-5">
                            ${user.profilePic ? `
                                <img src="${user.profilePic.replace('/upload/', cloudinaryTransforms)}" 
                                    alt="${user.name}" 
                                    class="w-20 h-20 rounded-xl object-cover border border-white/20 shadow-md cursor-pointer hover:border-violet-500/30 transition-colors"
                                    data-full-image="${user.profilePic}"
                                    data-user-name="${user.name}"
                                    onerror="this.onerror=null; this.src=''; this.parentElement.innerHTML = '<div class=\'w-20 h-20 rounded-xl bg-gradient-to-br from-violet-500/20 via-fuchsia-500/20 to-pink-500/20 flex items-center justify-center border border-white/20\'><span class=\'text-2xl font-medium text-white/90\'>${user.name.charAt(0)}</span></div>';">
                            ` : `
                                <div class="w-20 h-20 rounded-xl bg-gradient-to-br from-violet-500/20 via-fuchsia-500/20 to-pink-500/20 
                                     flex items-center justify-center border border-white/20 shadow-md">
                                    <span class="text-2xl font-medium text-white/90">${user.name.charAt(0)}</span>
                                </div>
                            `}
                        </div>
                        
                        <div class="flex-grow">
                            <div class="flex items-center justify-between mb-3">
                                <span class="text-lg text-white font-medium">${user.name}</span>
                                <div class="w-6 h-6 rounded-lg border border-white/20 flex items-center justify-center select-checkbox 
                                    transition-all duration-200 hover:border-violet-500/50 shadow-sm">
                                </div>
                            </div>
                            
                            <div class="space-y-2 text-sm">
                                ${user.location ? `
                                    <div class="flex items-center gap-2 text-white/70">
                                        <svg class="w-4 h-4 shrink-0 text-violet-400/90" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                                                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
                                        </svg>
                                        <span class="line-clamp-1">${user.location}</span>
                                    </div>
                                ` : ''}
                                
                                ${user.intro ? `
                                    <div class="flex items-start gap-2 text-white/70">
                                        <svg class="w-4 h-4 shrink-0 mt-0.5 text-violet-400/90" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                                                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"/>
                                        </svg>
                                        <span class="line-clamp-2">${user.intro}</span>
                                    </div>
                                ` : ''}
                            </div>
                        </div>
                    </div>
                `;
            }).join('');

            if (usersList) {
                const oldButton = this.element.querySelector('#load-more');
                oldButton?.remove();
                
                usersList.insertAdjacentHTML('beforeend', usersHtml);

                if (users.length === 10) {
                    usersList.insertAdjacentHTML('beforeend', `
                        <button id="load-more" class="w-full mt-4 px-4 py-2 bg-white/5 hover:bg-white/10 rounded-lg text-white/90 text-sm">
                            Load More
                        </button>
                    `);
                    const loadMore = this.element.querySelector('#load-more');
                    loadMore?.addEventListener('click', loadUsers);
                }
            }
        };

        loadUsers(); // Initial load

        // Rest of your existing code (updateUI, click handlers, etc.)
        const updateUI = () => {
            const countEl = this.element.querySelector('#selected-count');
            const createBtn = this.element.querySelector('#create-btn');
            if (countEl) countEl.textContent = selectedUsers.size.toString();
            if (createBtn) (createBtn as HTMLButtonElement).disabled = selectedUsers.size !== 3;
        };

        if (usersList) {
            usersList.addEventListener('click', (e) => {
                const userEl = (e.target as HTMLElement).closest('[data-user-id]');
                if (!userEl) return;

                const userId = parseInt(userEl.getAttribute('data-user-id') || '0');
                if (selectedUsers.has(userId)) {
                    selectedUsers.delete(userId);
                    const checkbox = userEl.querySelector('.select-checkbox');
                    if (checkbox) {
                        checkbox.classList.remove('bg-blue-500', 'border-blue-500');
                        checkbox.innerHTML = '';
                    }
                } else if (selectedUsers.size < 3) {
                    selectedUsers.add(userId);
                    const checkbox = userEl.querySelector('.select-checkbox');
                    if (checkbox) {
                        checkbox.classList.add('bg-blue-500', 'border-blue-500');
                        checkbox.innerHTML = '<svg class="w-3 h-3 text-white" fill="currentColor" viewBox="0 0 20 20"><path d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"></path></svg>';
                    }
                }
                updateUI();
            });
        }

        const createBtn = this.element.querySelector('#create-btn');
        if (createBtn) {
            createBtn.addEventListener('click', async () => {
                if (selectedUsers.size === 3) {
                    const userIds = Array.from(selectedUsers);
                    const nanoId = await PlatoChatService.createNano(
                        userIds[0],
                        userIds[1],
                        userIds[2],
                        this.userId
                    );
                    
                    if (nanoId) {
                        const hashedNanoId = Hash.encodeId(nanoId);
                        navigate(`/platochat/nanos/${hashedNanoId}`);
                        this.onComplete(true);
                    } else {
                        UINotifications.shipErrorToUI('Failed to create custom nano');
                        this.onComplete(false);
                    }
                }
            });
        }

        // Add click handler for images
        if (usersList) {
            usersList.addEventListener('click', (e) => {
                const target = e.target as HTMLElement;
                if (target.tagName === 'IMG' && target.dataset.fullImage) {
                    e.preventDefault();
                    e.stopPropagation();
                    this.showFullscreenImage(target.dataset.fullImage, target.dataset.userName || '');
                }
            });
        }
    }

    public getElement(): HTMLElement {
        return this.element;
    }
}