import { UpdateSubscriptionContent } from '../reusable/update-subscription-content'
import { ModalForm } from '../reusable/modal-form';
import { AIService } from '../../actions/ai/api-services';
import { ChatHistory } from '../../actions/ai/models';

export class AIContent {
    private element: HTMLElement;
    private chatSection: HTMLElement;
    private selectionContainer: HTMLElement;
    private selectedAi: 'zenith' | 'steward' | 'plato' | 'cyba' | 'gertrude' | null = null;

    constructor() {
        this.element = document.createElement('div');
        this.element.className = 'flex flex-col gap-6';

        this.updateUsageCounter(); // Add initial update
        
        this.selectionContainer = this.createSelectionContainer();
        this.chatSection = this.createChatSection();
        
        this.element.appendChild(this.selectionContainer);
    }

    private createSelectionContainer(): HTMLElement {
        const wrapper = document.createElement('div');
        wrapper.className = 'flex flex-col gap-8';

        // Add the header section with usage counter
        const headerSection = document.createElement('div');
        headerSection.className = 'flex items-center justify-between mb-6';

        const usageCounter = document.createElement('div');
        usageCounter.className = 'usage-counter';

        headerSection.appendChild(usageCounter);
        wrapper.appendChild(headerSection);

        const container = document.createElement('div');
        container.className = 'grid grid-cols-1 md:grid-cols-2 gap-6';

        // Zenith Card (Violet/Purple)
        const zenithCard = this.createAICard({
            name: 'ZEN',
            description: 'A fun-loving but neurotic creative spirit focused on art, music, games, and relationships. Known for sparking imaginative conversations while channeling her anxiety into artistic expression and humor.',
            gradientClasses: 'from-violet-500 via-purple-500 to-fuchsia-500',
            iconContent: `
                <div class="relative w-16 h-16">
                    <div class="absolute inset-0 rounded-full bg-violet-500/20 animate-pulse"></div>
                    <div class="absolute inset-2 rounded-full bg-violet-500/40 animate-ping"></div>
                    <div class="absolute inset-4 rounded-full bg-violet-500/60 animate-pulse"></div>
                    <div class="absolute inset-6 rounded-full bg-violet-500/80"></div>
                </div>
            `,
            aiType: 'zenith'
        });

        // Steward Card (Emerald/Teal)
        const stewardCard = this.createAICard({
            name: 'STEWARD',
            description: 'A proper British AI butler with expertise in history, etiquette, and practical matters. Known for his sophisticated wit, extensive knowledge, and thoughtful conversation suggestions with a dash of historical context.',
            gradientClasses: 'from-emerald-500 via-teal-500 to-cyan-500',
            iconContent: `
                <div class="relative w-16 h-16">
                    <div class="absolute inset-0 rounded-lg rotate-45 bg-emerald-500/20 animate-pulse"></div>
                    <div class="absolute inset-2 rounded-lg rotate-90 bg-emerald-500/40 animate-pulse delay-75"></div>
                    <div class="absolute inset-4 rounded-lg rotate-180 bg-emerald-500/60 animate-pulse delay-150"></div>
                    <div class="absolute inset-6 rounded-lg rotate-45 bg-emerald-500/80"></div>
                </div>
            `,
            aiType: 'steward'
        });

        // Plato Card (Blue/Indigo) with Hexagon
        const platoCard = this.createAICard({
            name: 'PLATO',
            description: 'A philosophical provocateur who challenges assumptions and deepens discussions, though his metaphors could use some work. Known for turning casual chats into mind-expanding conversations.',
            gradientClasses: 'from-blue-500 via-indigo-500 to-violet-500',
            iconContent: `
                <div class="relative w-16 h-16">
                    <div class="absolute inset-0 rotate-90 bg-blue-500/20 animate-pulse clip-path-hexagon"></div>
                    <div class="absolute inset-2 rotate-45 bg-blue-500/40 animate-spin-slow clip-path-hexagon"></div>
                    <div class="absolute inset-4 -rotate-45 bg-blue-500/60 animate-pulse delay-150 clip-path-hexagon"></div>
                    <div class="absolute inset-6 bg-blue-500/80 clip-path-hexagon"></div>
                </div>
            `,
            aiType: 'plato'
        });

        // Cyba Card (Orange/Amber) with Triangle
        const cybaCard = this.createAICard({
            name: 'CYBA',
            description: 'A courageous cyborg running her cybernetics startup while living the digital nomad lifestyle across the globe. This multilingual adventurer combines technical expertise with cultural fluency.',
            gradientClasses: 'from-orange-400 via-amber-500 to-orange-600',
            iconContent: `
                <div class="relative w-16 h-16">
                    <div class="absolute inset-0 bg-orange-500/20 animate-bounce-gentle clip-path-triangle"></div>
                    <div class="absolute inset-2 bg-orange-500/40 animate-ping clip-path-triangle"></div>
                    <div class="absolute inset-4 bg-orange-500/60 animate-pulse clip-path-triangle"></div>
                    <div class="absolute inset-6 bg-orange-500/80 clip-path-triangle"></div>
                </div>
            `,
            aiType: 'cyba'
        });

        // Gertrude Card (Grey/Slate) with Square
        const gertrudeCard = this.createAICard({
            name: 'GERTRUDE',
            description: 'Part doting grandmother, part imperial warrior queen. Switches between offering cookies and declaring battle strategies with equal conviction. Fiercely protective of the PLATO5 realm and its users.',
            gradientClasses: 'from-slate-400 via-gray-500 to-slate-600',
            iconContent: `
                <div class="relative w-16 h-16 flex items-center justify-center">
                    <div class="absolute w-16 h-16 bg-slate-500/20 clip-path-shield"></div>
                    <div class="absolute w-12 h-12 bg-slate-500/40 clip-path-shield"></div>
                    <div class="absolute w-8 h-8 bg-slate-500/60 clip-path-shield animate-gentle-presence"></div>
                    <div class="absolute w-4 h-4 bg-slate-500/80 clip-path-shield"></div>
                </div>
            `,
            aiType: 'gertrude'
        });

        container.append(zenithCard, stewardCard, platoCard, cybaCard, gertrudeCard);
        wrapper.appendChild(container); // Only append the container here
        return wrapper;
    }
    private createAICard(config: {
        name: string;
        description: string;
        gradientClasses: string;
        iconContent: string;
        aiType: 'zenith' | 'steward' | 'plato' | 'cyba' | 'gertrude';
    }): HTMLElement {
        const isLoggedIn = localStorage.getItem('userId') !== null;
        
        const card = document.createElement('div');
        card.className = `
            relative group
            rounded-2xl overflow-hidden
            border border-white/10
            transition-all duration-500 ease-out
            ${isLoggedIn ? 'cursor-pointer hover:border-white/20' : 'opacity-50'}
            bg-black/20 backdrop-blur-sm
        `;

        // Much subtler gradient background
        const gradientBg = document.createElement('div');
        gradientBg.className = `
            absolute inset-0 opacity-0 
            ${isLoggedIn ? 'group-hover:opacity-20' : ''}
            bg-gradient-to-br ${config.gradientClasses}
            transition-opacity duration-500 -z-10 blur-3xl
        `;
        
        const content = document.createElement('div');
        content.className = 'p-6 pb-12 flex flex-col items-center gap-4';

        const usefulness = {
            zenith: 'Suggests fun and creative chats to spice up conversation',
            steward: 'Suggests practical chats with historical intrigue. His entertaining personality is a plus',
            plato: 'Suggests chats to deepen conversations and build rapport',
            cyba: 'Suggests chats to build bridges between different cultures and provides ample expertise on the world',
            gertrude: 'Suggests wildcard chat suggestions that range from obnoxious to reckless'
        }[config.aiType];

        content.innerHTML = `
            ${config.iconContent}
            <h2 class="text-2xl font-bold bg-gradient-to-r ${config.gradientClasses} bg-clip-text text-transparent font-['Noto_Serif']">
                ${config.name}
            </h2>
            <p class="text-sm font-medium border border-white/10 bg-white/5 backdrop-blur-sm rounded-lg px-3 py-2 text-center bg-gradient-to-r ${config.gradientClasses} bg-clip-text text-transparent">
                ${usefulness}
            </p>
            <p class="text-white/70 text-center text-sm mb-2">
                ${config.description}
            </p>
        `;

        card.appendChild(gradientBg);
        card.appendChild(content);

        if (isLoggedIn) {
            card.addEventListener('click', () => this.selectAi(config.aiType));
            this.updateUsageCounter();
        }
        
        return card;
    }

    private createChatSection(): HTMLElement {
        const section = document.createElement('div');
        section.className = 'flex flex-col gap-4';

        // Header with back button, AI info, and usage counter
        const header = document.createElement('div');
        header.className = 'flex items-center justify-between mb-4';

        const leftSection = document.createElement('div');
        leftSection.className = 'flex items-center gap-4';

        const backButton = document.createElement('button');
        backButton.className = 'text-white/50 hover:text-white/80 transition-colors p-1.5';
        backButton.innerHTML = `
            <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"></path>
            </svg>
        `;
        backButton.addEventListener('click', () => this.showSelection());

        const aiInfo = document.createElement('div');
        aiInfo.className = 'flex items-center gap-3';
        
        leftSection.appendChild(backButton);
        leftSection.appendChild(aiInfo);

        const usageCounter = document.createElement('div');
        usageCounter.className = 'usage-counter';

        header.appendChild(leftSection);
        header.appendChild(usageCounter);
        
        // Reminder text
        const reminder = document.createElement('div');
        reminder.className = `
            text-white/50 text-sm italic text-center mb-6 
            px-4 py-3 rounded-xl border border-white/5 
            bg-white/5 backdrop-blur-sm
        `;
        reminder.textContent = 'BETA: This conversation assistant will always include a suggested chat in their messages. Anthropic and OpenAI\'s servers can occasionally be busy - if you get an error, please try again in a few minutes.';
        
        // Chat area for messages
        const chatArea = document.createElement('div');
        chatArea.className = 'flex-1 flex flex-col gap-4 min-h-[300px]';
        
        // Prompt suggestions container with better spacing and centering
        const promptSuggestions = document.createElement('div');
        promptSuggestions.className = `
            w-full overflow-x-auto
            flex flex-wrap gap-2 mb-4 px-1 py-2
            scrollbar-thin scrollbar-thumb-white/10 scrollbar-track-transparent
        `;
        
        const suggestions = [
            { text: "Get to know matches deeper", prompt: "Suggest a chat to help me get to know my matches on a deeper level" },
            { text: "Start fun conversation", prompt: "Suggest a chat to start a fun, informal convo" },
            { text: "Intrigue matches", prompt: "Suggest a chat to push the envelope and get my matches intrigued" },
            { text: "Encourage thoughtful response", prompt: "Suggest a chat to encourage a thoughtful response from my matches" },
            { text: "Spice up the nano", prompt: "Give me something to spice up my nano" },
            { text: "Plan safe meetups", prompt: "I need a chat to encourage safe, IRL meetups" },
            { text: "Share interests & hobbies", prompt: "Suggest a chat about discovering shared interests and hobbies" },
            { text: "Discuss current events", prompt: "Help me start an engaging discussion about recent events or trends" },
            { text: "Break the ice", prompt: "Give me a creative ice breaker that will get everyone talking" },
            { text: "Future dreams & goals", prompt: "Suggest a chat about aspirations and future plans that isn't too intense" },
            { text: "Weekend adventures", prompt: "Help me start a chat about fun weekend activities we could do together" }
        ];

        suggestions.forEach(({ text, prompt }) => {
            const button = document.createElement('button');
            button.className = `
                px-3 py-1.5 rounded-lg text-sm
                border border-white/10
                bg-white/5 hover:bg-white/10
                text-white/70 hover:text-white
                transition-all duration-300
                whitespace-nowrap
                flex-shrink-0
            `;
            button.textContent = text;
            button.addEventListener('click', () => {
                const textarea = this.chatSection.querySelector('textarea');
                if (textarea) {
                    textarea.value = prompt;
                    textarea.focus();
                }
            });
            promptSuggestions.appendChild(button);
        });

        // Create a container for the bottom section (suggestions + input)
        const bottomSection = document.createElement('div');
        bottomSection.className = 'flex flex-col gap-4 mt-auto';
        
        const inputContainer = document.createElement('div');
        inputContainer.className = 'flex gap-2 relative bg-black/20 backdrop-blur-sm rounded-xl p-1.5 border border-white/10 hover:border-white/20 transition-all duration-300';
        
        const textarea = document.createElement('textarea');
        textarea.className = `
            flex-1 bg-transparent
            rounded-lg px-4 py-4 resize-none h-[160px]
            text-white/90 placeholder-white/30 text-base
            focus:outline-none
            transition-all duration-300

        `;
        textarea.placeholder = 'What kind of chat are you looking for?';
        textarea.maxLength = 500;

        const charCounter = document.createElement('div');
        charCounter.className = 'absolute bottom-3 right-[76px] text-sm text-white/30';
        charCounter.textContent = '0/500';

        textarea.addEventListener('input', () => {
            const count = textarea.value.length;
            charCounter.textContent = `${count}/500`;
            charCounter.className = count > 400 
                ? 'absolute bottom-3 right-16 text-sm text-amber-400'
                : 'absolute bottom-3 right-16 text-sm text-white/30';
        });

        const sendButton = document.createElement('button');
        sendButton.className = `
            p-2 rounded-lg aspect-square h-[52px]
            bg-white/5 hover:bg-white/10
            text-white/70 hover:text-white
            transition-all duration-300
            flex items-center justify-center
            self-end
        `;
        sendButton.innerHTML = `
            <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                    d="M13 5l7 7-7 7M5 5l7 7-7 7"></path>
            </svg>
        `;
        
        inputContainer.appendChild(textarea);
        inputContainer.appendChild(charCounter);
        inputContainer.appendChild(sendButton);

        section.appendChild(header);
        section.appendChild(reminder);
        section.appendChild(chatArea);
        bottomSection.appendChild(promptSuggestions);
        bottomSection.appendChild(inputContainer);
        section.appendChild(bottomSection);
        
        sendButton.addEventListener('click', () => {
            const message = textarea.value.trim();
            if (message) {
                this.sendMessage(message);
                textarea.value = '';
                this.updateUsageCounter();
            }
        });

        // Add enter key support
        textarea.addEventListener('keypress', (e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                const message = textarea.value.trim();
                if (message) {
                    this.sendMessage(message);
                    textarea.value = '';
                    this.updateUsageCounter();
                }
            }
        });
        
        return section;
    }

    private updateAiInfo(aiType: 'zenith' | 'steward' | 'plato' | 'cyba' | 'gertrude') {
        const aiInfo = this.chatSection.querySelector('.flex.items-center.gap-3');
        if (!aiInfo) return;

        if (aiType === 'zenith') {
            aiInfo.innerHTML = `
                <div class="relative w-10 h-10">
                    <div class="absolute inset-0 rounded-full bg-violet-500/20 animate-pulse"></div>
                    <div class="absolute inset-2 rounded-full bg-violet-500/40 animate-ping"></div>
                    <div class="absolute inset-4 rounded-full bg-violet-500/60 animate-pulse"></div>
                </div>
                <span class="text-xl font-bold bg-gradient-to-r from-violet-500 to-fuchsia-500 bg-clip-text text-transparent font-['Noto_Serif']">
                    Zen
                </span>
            `;
        } else if (aiType === 'steward') {
            aiInfo.innerHTML = `
                <div class="relative w-10 h-10">
                    <div class="absolute inset-0 rounded-lg rotate-45 bg-emerald-500/20 animate-pulse"></div>
                    <div class="absolute inset-2 rounded-lg rotate-90 bg-emerald-500/40 animate-pulse delay-75"></div>
                    <div class="absolute inset-4 rounded-lg rotate-180 bg-emerald-500/60 animate-pulse delay-150"></div>
                </div>
                <span class="text-xl font-bold bg-gradient-to-r from-emerald-500 to-teal-500 bg-clip-text text-transparent font-['Noto_Serif']">
                    Steward
                </span>
            `;
        } else if (aiType === 'plato') {
            aiInfo.innerHTML = `
                <div class="relative w-10 h-10">
                    <div class="absolute inset-0 bg-blue-500/20 clip-path-hexagon animate-pulse"></div>
                    <div class="absolute inset-2 bg-blue-500/40 clip-path-hexagon animate-spin-slow"></div>
                    <div class="absolute inset-4 bg-blue-500/60 clip-path-hexagon"></div>
                </div>
                <span class="text-xl font-bold bg-gradient-to-r from-blue-500 to-violet-500 bg-clip-text text-transparent font-['Noto_Serif']">
                    Plato
                </span>
            `;
        } else if (aiType === 'cyba') {
            aiInfo.innerHTML = `
                <div class="relative w-10 h-10">
                    <div class="absolute inset-0 bg-orange-500/20 clip-path-triangle animate-bounce-gentle"></div>
                    <div class="absolute inset-2 bg-orange-500/40 clip-path-triangle animate-ping"></div>
                    <div class="absolute inset-4 bg-orange-500/60 clip-path-triangle"></div>
                </div>
                <span class="text-xl font-bold bg-gradient-to-r from-orange-400 to-orange-600 bg-clip-text text-transparent font-['Noto_Serif']">
                    Cyba
                </span>
            `;
        } else if (aiType === 'gertrude') {
            aiInfo.innerHTML = `
                <div class="relative w-10 h-10 flex items-center justify-center">
                    <div class="absolute w-10 h-10 bg-slate-500/20 clip-path-shield"></div>
                    <div class="absolute w-8 h-8 bg-slate-500/40 clip-path-shield"></div>
                    <div class="absolute w-6 h-6 bg-slate-500/60 clip-path-shield animate-gentle-presence"></div>
                    <div class="absolute w-4 h-4 bg-slate-500/80 clip-path-shield"></div>
                </div>
                <span class="text-xl font-bold bg-gradient-to-r from-slate-400 to-slate-600 bg-clip-text text-transparent font-['Noto_Serif']">
                    Gertrude
                </span>
            `;
        }
    }

// Update the selectAi method in AIContent class to include loading chat history

private async selectAi(aiType: 'zenith' | 'steward' | 'plato' | 'cyba' | 'gertrude') {
    this.selectedAi = aiType;
    this.selectionContainer.remove();
    this.element.appendChild(this.chatSection);
    this.updateAiInfo(aiType);

    // Get the chat area ready for history
    const chatArea = this.chatSection.querySelector('.flex-1.flex.flex-col') as HTMLElement;
    if (!chatArea) return;

    // Show loading state
    const aiColors = this.getAiColors(aiType);
    const aiGradient = this.getAiGradient(aiType);
    this.showLoadingSkeleton(chatArea, aiColors, aiGradient);

    try {
        const userId = parseInt(localStorage.getItem('userId') || '0', 10);
        const assistantId = this.getAssistantId(aiType);
        const history = await AIService.getChatHistory(userId, assistantId, 10);

        // Clear loading skeleton
        const loadingSkeleton = chatArea.querySelector('.loading-skeleton');
        if (loadingSkeleton) {
            loadingSkeleton.remove();
        }

        if (history && history.length > 0) {
            // Display each message in the history
            history.reverse().forEach(chat => {
                // User message
                const userMessageElement = document.createElement('div');
                userMessageElement.className = 'flex justify-end mb-6';
                userMessageElement.innerHTML = `
                    <div class="bg-white/10 backdrop-blur-sm rounded-2xl p-4 max-w-[75%]">
                        <p class="text-white/90 text-lg">${chat.user_message}</p>
                    </div>
                `;
                chatArea.appendChild(userMessageElement);

                // Add AI response with the chat ID from history
                this.displayMessage(chat.user_message, chat.ai_response, chat.id);
            });

            // Add a time separator
            const separator = document.createElement('div');
            separator.className = 'flex items-center justify-center my-8';
            separator.innerHTML = `
                <div class="px-4 py-2 rounded-full bg-white/5 backdrop-blur-sm border border-white/10">
                    <span class="text-white/50 text-sm">Previous conversations</span>
                </div>
            `;
            chatArea.insertBefore(separator, chatArea.firstChild);
        }

        // Scroll to the text input after everything is loaded
        const textarea = this.chatSection.querySelector('textarea');
        if (textarea) {
            setTimeout(() => {
                textarea.scrollIntoView({ behavior: 'smooth' });
                textarea.focus(); // Optional: also focus the textarea
            }, 100); // Small delay to ensure everything is rendered
        }
    } catch (error) {
        console.error('Error loading chat history:', error);
        const errorMsg = document.createElement('div');
        errorMsg.className = 'text-center text-red-400/90 text-sm my-4';
        errorMsg.textContent = 'Failed to load chat history';
        chatArea.appendChild(errorMsg);
    }
}

    private showSelection() {
        this.selectedAi = null;
        
        // Clear chat messages
        const chatArea = this.chatSection.querySelector('.flex-1.flex.flex-col') as HTMLElement;
        if (chatArea) {
            chatArea.innerHTML = '';
        }
        
        this.chatSection.remove();
        this.element.appendChild(this.selectionContainer);
    }

    private async sendMessage(message: string) {
        const chatArea = this.chatSection.querySelector('.flex-1.flex.flex-col') as HTMLElement;
        if (!chatArea) return;

        // Add user message
        const userMessageElement = document.createElement('div');
        userMessageElement.className = 'flex justify-end mb-6';
        userMessageElement.innerHTML = `
                    <div class="bg-white/10 backdrop-blur-sm rounded-2xl p-4 max-w-[75%]">
                        <p class="text-white/90 text-lg">${message}</p>
                    </div>
                `;
        chatArea.appendChild(userMessageElement);

        // Show loading skeleton
        if (this.selectedAi) {
            const aiColors = this.getAiColors(this.selectedAi);
            const aiGradient = this.getAiGradient(this.selectedAi);
            this.showLoadingSkeleton(chatArea, aiColors, aiGradient);
        }

        try {
            const userId = parseInt(localStorage.getItem('userId') || '0', 10);

            if (this.selectedAi) {
                const assistantId = this.getAssistantId(this.selectedAi);
                const response = await AIService.getAIResponse(userId, assistantId, message);

                if (response) {
                    this.displayMessage(message, response, response.id);
                    this.updateUsageCounter();
                } else {
                    throw new Error('Failed to get AI response');
                }
            } else {
                console.error('No AI selected');
                // Handle the case where no AI is selected
            }
        } catch (error) {
            console.error('Error:', error);
            this.displayErrorMessage(chatArea);
        }
    }

    private getAssistantId(aiType: 'zenith' | 'steward' | 'plato' | 'cyba' | 'gertrude'): number {
        const assistantIds = {
            zenith: 1,
            steward: 2,
            plato: 3,
            cyba: 4,
            gertrude: 5
        };
        return assistantIds[aiType];
    }

    private getAiColors(aiType: 'zenith' | 'steward' | 'plato' | 'cyba' | 'gertrude'): string {
        switch (aiType) {
            case 'zenith':
                return 'bg-violet-500/10 border-violet-500/20';
            case 'steward':
                return 'bg-emerald-500/10 border-emerald-500/20';
            case 'plato':
                return 'bg-blue-500/10 border-blue-500/20';
            case 'cyba':
                return 'bg-orange-500/10 border-orange-500/20';
            case 'gertrude':
                return 'bg-slate-500/10 border-slate-500/20';
            default:
                return '';
        }
    }

    private getAiGradient(aiType: 'zenith' | 'steward' | 'plato' | 'cyba' | 'gertrude'): string {
        switch (aiType) {
            case 'zenith':
                return 'from-violet-500 via-purple-500 to-fuchsia-500';
            case 'steward':
                return 'from-emerald-500 via-teal-500 to-cyan-500';
            case 'plato':
                return 'from-blue-500 via-indigo-500 to-violet-500';
            case 'cyba':
                return 'from-orange-400 via-amber-500 to-orange-600';
            case 'gertrude':
                return 'from-slate-400 via-gray-500 to-slate-600';
            default:
                return '';
        }
    }

    private displayErrorMessage(chatArea: HTMLElement) {
        const errorMessages = {
            zenith: "Oh no! Both AI services are having issues! Maybe try again in a minute? 💫",
            steward: "I deeply regret to inform you that both AI services are currently unavailable. Might I suggest a brief pause before trying again?",
            plato: "A philosophical conundrum: our AI services seem to be in a state of temporary non-being. Shall we try again shortly?",
            cyba: "System status: offline. Diagnostic recommendation: retry in approximately 60 seconds. 🤖",
            gertrude: "Oh dearie me, the machines are being rather stubborn! Let's give them a moment to sort themselves out, shall we? 🍪"
        };

        const message = this.selectedAi ? errorMessages[this.selectedAi] : errorMessages.zenith;

        const errorMessage = document.createElement('div');
        errorMessage.className = 'flex justify-start mb-6';
        errorMessage.innerHTML = `
            <div class="
                bg-red-500/10 backdrop-blur-sm 
                border border-red-500/20
                rounded-2xl p-4 max-w-[75%]
                animate-fade-in
            ">
                <p class="text-red-400/90 text-lg">${message}</p>
            </div>
        `;
        chatArea.appendChild(errorMessage);
    }

    private displayMessage(userMessage: string, response: any, chatId?: number) {
        const chatArea = this.chatSection.querySelector('.flex-1.flex.flex-col') as HTMLElement;
        if (!chatArea || !this.selectedAi) return;
    
        // Remove loading skeleton if it exists
        const loadingSkeleton = chatArea.querySelector('.loading-skeleton');
        if (loadingSkeleton) {
            loadingSkeleton.remove();
        }
    
        // Get AI-specific colors and gradients
        const aiColors = this.getAiColors(this.selectedAi);
        const aiGradient = this.getAiGradient(this.selectedAi);
    
        // Parse the response text
        const responseText = response?.content || response?.response || response;
        const fullText = typeof responseText === 'string' ? responseText : 
                        Array.isArray(responseText) ? responseText[0]?.text : 
                        JSON.stringify(responseText);
                        
        const explanationMatch = fullText.match(/EXPLANATION:(.*?)SUGGESTION:/s);
        const suggestionMatch = fullText.match(/SUGGESTION:(.*?)$/s);
        
        let explanation = explanationMatch ? explanationMatch[1].trim() : '';
        let suggestion = suggestionMatch ? suggestionMatch[1].trim() : '';

        // Create feedback section HTML based on whether it's a new or historical message
        const feedbackHtml = chatId ? `
            <div class="mt-4 pt-4 border-t border-white/10">
                <div class="flex items-center justify-between feedback-container">
                    <span class="text-white/50 text-sm">How was this response?</span>
                    <div class="flex gap-2">
                        <button class="feedback-button good p-2 rounded-lg bg-white/5 hover:bg-emerald-500/20 transition-colors" title="Good response">
                            <i class="fa-regular fa-thumbs-up w-5 h-5 text-white/50 hover:text-emerald-400"></i>
                        </button>
                        <button class="feedback-button bad p-2 rounded-lg bg-white/5 hover:bg-red-500/20 transition-colors" title="Bad response">
                            <i class="fa-regular fa-thumbs-down w-5 h-5 text-white/50 hover:text-red-400"></i>
                        </button>
                    </div>
                </div>
            </div>
        ` : '';

        // Add actual response with fade-in effect
        const aiMessage = document.createElement('div');
        aiMessage.className = 'flex justify-start mb-6 opacity-0 transition-opacity duration-500';
        aiMessage.innerHTML = `
            <div class="${aiColors} border backdrop-blur-sm rounded-2xl p-6 max-w-[85%]">
                <div class="flex flex-col gap-4">
                    <div class="text-white/70 text-lg leading-relaxed">${explanation}</div>
                    <div class="w-full h-px bg-gradient-to-r ${aiGradient} my-2"></div>
                    <div class="flex flex-col gap-3">
                        <div class="flex items-center justify-between">
                            <div class="bg-gradient-to-r ${aiGradient} bg-clip-text text-transparent font-medium text-sm uppercase tracking-wider">
                                Suggested message
                            </div>
                            <button class="copy-button p-2 rounded-lg bg-white/5 hover:bg-white/10 transition-colors" title="Copy to clipboard">
                                <svg class="w-4 h-4 text-white/50 hover:text-white/70" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"></path>
                                </svg>
                            </button>
                        </div>
                        <div class="bg-white/5 backdrop-blur-sm rounded-xl p-4">
                            <div class="text-white/90 text-lg font-medium italic">${suggestion}</div>
                        </div>
                    </div>
                    ${feedbackHtml}
                </div>
            </div>
        `;

        chatArea.appendChild(aiMessage);
        
        // Add fade-in effect after element is added
        requestAnimationFrame(() => {
            aiMessage.classList.add('opacity-100');
        });

        // Add feedback button handlers for new messages
        if (chatId) {
            const feedbackContainer = aiMessage.querySelector('.feedback-container');
            const goodButton = aiMessage.querySelector('.feedback-button.good');
            const badButton = aiMessage.querySelector('.feedback-button.bad');

            if (goodButton && badButton && feedbackContainer) {
                goodButton.addEventListener('click', async () => {
                    const result = await AIService.determineGood(response.id);
                    if (result) {
                        feedbackContainer.innerHTML = `
                            <span class="text-emerald-400 text-sm">Rating received! Thank you</span>
                        `;
                    }
                });
                
                badButton.addEventListener('click', async () => {
                    const result = await AIService.determineBad(response.id);
                    if (result) {
                        feedbackContainer.innerHTML = `
                            <span class="text-emerald-400 text-sm">Rating received! Thank you</span>
                        `;
                    }
                });
            }
        }

        // Handle copy button functionality
        const copyButton = aiMessage.querySelector('.copy-button');
        if (copyButton) {
            copyButton.addEventListener('click', async () => {
                try {
                    await navigator.clipboard.writeText(suggestion);
                    const icon = copyButton.querySelector('svg');
                    if (icon) {
                        icon.innerHTML = `<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>`;
                        setTimeout(() => {
                            icon.innerHTML = `<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"></path>`;
                        }, 2000);
                    }
                } catch (err) {
                    console.error('Failed to copy text:', err);
                }
            });
        }

        // Scroll to bottom
        chatArea.scrollTop = chatArea.scrollHeight;
    }

    private showLoadingSkeleton(chatArea: HTMLElement, aiColors: string, aiGradient: string) {
        const skeleton = document.createElement('div');
        skeleton.className = 'flex justify-start mb-6 loading-skeleton';
        skeleton.innerHTML = `
            <div class="${aiColors} border backdrop-blur-sm rounded-2xl p-6 max-w-[85%]">
                <div class="flex flex-col gap-4">
                    <div class="flex flex-col gap-2">
                        <div class="h-4 bg-white/10 rounded animate-pulse w-[80%]"></div>
                        <div class="h-4 bg-white/10 rounded animate-pulse w-[60%]"></div>
                        <div class="h-4 bg-white/10 rounded animate-pulse w-[70%]"></div>
                    </div>
                    <div class="w-full h-px bg-gradient-to-r ${aiGradient} my-2"></div>
                    <div class="flex flex-col gap-3">
                        <div class="bg-white/10 rounded animate-pulse h-4 w-32"></div>
                        <div class="bg-white/5 backdrop-blur-sm rounded-xl p-4">
                            <div class="flex flex-col gap-2">
                                <div class="h-4 bg-white/10 rounded animate-pulse w-[75%]"></div>
                                <div class="h-4 bg-white/10 rounded animate-pulse w-[40%]"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        `;
        chatArea.appendChild(skeleton);
        chatArea.scrollTop = chatArea.scrollHeight;
    }
    private async updateUsageCounter() {
        const userId = parseInt(localStorage.getItem('userId') || '0', 10);
        if (!userId) return; // Skip if no user is logged in

        try {
            const usage = await AIService.getMonthlyUsage(userId);
            
            const remaining = usage?.remaining ?? 200;
            const used = usage?.uses ?? 0;
            const total = 200;
            const usagePercent = (used / total) * 100;

            // Find all usage counters (both in selection and chat sections)
            const usageCounters = this.element.querySelectorAll('.usage-counter');
            
            usageCounters.forEach(counter => {
                let colorScheme = {
                    text: 'text-emerald-400',
                    bg: 'bg-emerald-400/10',
                    border: 'border-emerald-400/20',
                    progress: 'from-emerald-500/30 to-emerald-400/10'
                };
                
                if (usagePercent > 80) {
                    colorScheme = {
                        text: 'text-red-400',
                        bg: 'bg-red-400/10',
                        border: 'border-red-400/20',
                        progress: 'from-red-500/30 to-red-400/10'
                    };
                } else if (usagePercent > 50) {
                    colorScheme = {
                        text: 'text-amber-400',
                        bg: 'bg-amber-400/10',
                        border: 'border-amber-400/20',
                        progress: 'from-amber-500/30 to-amber-400/10'
                    };
                }

                counter.className = `
                    usage-counter
                    flex items-center gap-4 px-4 py-2
                    rounded-xl ${colorScheme.border} border
                    ${colorScheme.bg} backdrop-blur-sm
                    transition-all duration-300
                `;
                
                counter.innerHTML = `
                    <div class="flex items-center gap-2">
                        <svg class="w-4 h-4 ${colorScheme.text}" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                                d="M13 10V3L4 14h7v7l9-11h-7z" />
                        </svg>
                        <span class="${colorScheme.text} font-medium">${remaining}</span>
                        <span class="text-white/50">suggestions left</span>
                    </div>
                    <div class="flex-1 h-1.5 bg-white/5 rounded-full overflow-hidden">
                        <div class="h-full bg-gradient-to-r ${colorScheme.progress} transition-all duration-300"
                            style="width: ${usagePercent}%">
                        </div>
                    </div>
                `;
            });
        } catch (error) {
            console.error('Error updating usage counter:', error);
            const usageCounters = this.element.querySelectorAll('.usage-counter');
            usageCounters.forEach(counter => {
                counter.innerHTML = `
                    <div class="flex items-center gap-2">
                        <svg class="w-4 h-4 text-white/50" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                                d="M13 10V3L4 14h7v7l9-11h-7z" />
                        </svg>
                        <span class="text-white/50">-- suggestions left</span>
                    </div>
                `;
            });
        }
    }
    mount(parent: HTMLElement) {
        parent.appendChild(this.element);
    }

    unmount() {
        this.element.remove();
    }

    // Add new method for creating input area with character count
    private createInputArea(): HTMLElement {
        const inputContainer = document.createElement('div');
        inputContainer.className = 'relative mt-4';
        
        const textArea = document.createElement('textarea');
        textArea.className = `
            w-full p-4 pr-20 
            bg-white/5 backdrop-blur-sm 
            border border-white/10 hover:border-white/20
            rounded-xl resize-none
            text-white/90 placeholder-white/30
            transition-all duration-300
            focus:outline-none focus:ring-2 focus:ring-white/20
            h-[100px]
        `;
        textArea.placeholder = 'Type your message...';
        textArea.maxLength = 500;

        const charCount = document.createElement('div');
        charCount.className = 'absolute bottom-4 right-4 text-sm text-white/30';
        charCount.textContent = '0/500';

        textArea.addEventListener('input', () => {
            const count = textArea.value.length;
            charCount.textContent = `${count}/500`;
            charCount.className = count > 400 
                ? 'absolute bottom-4 right-4 text-sm text-amber-400'
                : 'absolute bottom-4 right-4 text-sm text-white/30';
        });

        inputContainer.appendChild(textArea);
        inputContainer.appendChild(charCount);
        
        return inputContainer;
    }
}
