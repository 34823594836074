import { ModalForm } from '../reusable/modal-form';
import { StoryModal } from '../reusable/story-modal';
import { StoryService } from '../../actions/stories/api-services';
import { UINotifications } from '../../utilities/errors';
import { AuthService } from '../../actions/auth/api-services';
import { ComingSoonContent } from '../reusable/coming-soon-content';
import { StoryResponse } from '../../actions/stories/models';

// Add type safety for story object
interface Story {
    title: string;
    image?: string;
    isAdd?: boolean;
    hasUpdate?: boolean;
    storyId?: number;
    isUserStory?: boolean;
    profilePic?: string;
    stories?: StoryResponse[];
    UserID?: number;
}

export class StoryRow {
    private element: HTMLElement;
    private readonly STORIES_ENABLED = true;
    private storyUserID: number | undefined;

    constructor() {
        this.element = document.createElement('div');
        
        const userId = localStorage.getItem('userId');
        if (!userId) return;

        this.element.className = 'mb-6 w-full overflow-x-auto no-scrollbar';

        const container = document.createElement('div');
        container.className = 'flex gap-4 p-1';

        // Show skeleton loader first
        this.showSkeletonLoader(container);

        this.element.appendChild(container);
        this.loadUserStories(parseInt(userId), container);
    }

    private showSkeletonLoader(container: HTMLElement) {
        const skeletonStories = Array(6).fill(0).map(() => {
            const storyItem = document.createElement('div');
            storyItem.className = 'flex flex-col items-center gap-2';

            const imageContainer = document.createElement('div');
            imageContainer.className = 'w-16 h-16 rounded-full bg-white/[0.03]';

            const titleSkeleton = document.createElement('div');
            titleSkeleton.className = 'w-12 h-2 bg-white/[0.03] rounded';

            storyItem.appendChild(imageContainer);
            storyItem.appendChild(titleSkeleton);
            return storyItem;
        });

        skeletonStories.forEach(skeleton => container.appendChild(skeleton));
    }

    private async loadUserStories(userId: number, container: HTMLElement) {
        const recentStoriesResponse = await StoryService.getRecentStories(1, 10);
        
        container.innerHTML = '';
        this.renderStories(container, [{ title: 'Add Story', isAdd: true }], []);
    
        if (recentStoriesResponse?.stories) {
            // First, separate user's stories and other stories
            const userStories = recentStoriesResponse.stories.filter(s => s.UserID === userId);
            const otherStories = recentStoriesResponse.stories.filter(s => s.UserID !== userId);
    
            // Group other stories by user
            const otherUserStoryGroups = otherStories.reduce((acc, story) => {
                if (!acc[story.UserID]) {
                    acc[story.UserID] = [];
                }
                acc[story.UserID].push(story);
                return acc;
            }, {} as Record<number, StoryResponse[]>);
    
            // Process and render other users' stories
            const processedOtherStories = await Promise.all(
                Object.entries(otherUserStoryGroups).map(async ([userIdStr, stories]) => {
                    const otherUserId = parseInt(userIdStr);
                    const userData = await AuthService.getUserById(otherUserId);
                    const profilePic = await AuthService.getFirstImage(otherUserId);
                    
                    return {
                        title: userData?.name || '',
                        image: stories[0].ImageURL,
                        storyId: stories[0].ID,
                        hasUpdate: true,
                        profilePic: profilePic?.ImageURL,
                        stories: stories,
                        UserID: otherUserId
                    };
                })
            );
            this.renderStories(container, processedOtherStories, []);
    
            // Add user's stories last if they exist
            if (userStories.length > 0) {
                const userData = await AuthService.getUserById(userId);
                const profilePic = await AuthService.getFirstImage(userId);
    
                const userStory = {
                    title: 'Your Story',
                    image: userStories[0].ImageURL,
                    storyId: userStories[0].ID,
                    isUserStory: true,
                    hasUpdate: true,
                    profilePic: profilePic?.ImageURL,
                    stories: userStories,
                    UserID: userId
                };
    
                this.renderStories(container, [userStory], userStories);
            }
        }
    }
    
    private renderStories(container: HTMLElement, stories: Story[], userStories?: any[], insertBefore?: Node | null, profilePic?: string) {
        stories.forEach(story => {
            const storyItem = document.createElement('div');
            storyItem.className = 'flex flex-col items-center gap-2';

            const imageContainer = document.createElement('div');
            imageContainer.className = `
                w-16 h-16 
                rounded-full 
                flex items-center justify-center
                ${story.hasUpdate ? (story.isUserStory ? 'ring-2 ring-white/20 ring-offset-2 ring-offset-black' : 'ring-2 ring-pink-500 ring-offset-2 ring-offset-black') : ''}
                ${story.isAdd ? 'bg-white/5 hover:bg-white/10' : ''}
                cursor-pointer
                transition-all
                duration-300
            `;

                if (story.isAdd && !(userStories?.length ?? 0)) {
                imageContainer.innerHTML = `<i class="fas fa-plus text-white/70"></i>`;
                
                // Add file input for story upload
                const input = document.createElement('input');
                input.type = 'file';
                input.accept = 'image/*';
                input.style.display = 'none';
                imageContainer.appendChild(input);

                const modal = new ModalForm();
                imageContainer.addEventListener('click', () => {
                    if (!this.STORIES_ENABLED) {
                        const modal = new ModalForm();
                        const comingSoon = new ComingSoonContent(
                            'Stories Coming Soon',
                            'Share your moments with the PLATO5 community. Story functionality is currently being developed and will be available shortly.',
                            '📸'
                        );
                        modal.append(comingSoon.getElement());
                        modal.mount(document.body);
                        modal.open();
                        return;
                    }

                    const content = document.createElement('div');
                    content.className = 'text-center p-8';
                    content.innerHTML = `
                        <div class="relative">
                            <div class="mb-6">
                                <div class="w-16 h-16 mx-auto mb-4 rounded-full bg-pink-500/10 flex items-center justify-center">
                                    <i class="fas fa-camera text-2xl text-pink-400"></i>
                                </div>
                                <h3 class="text-xl font-bold text-white mb-2">Share Your Story</h3>
                                <p class="text-white/70 text-sm">
                                    Share your moments with the PLATO5 community
                                </p>
                            </div>

                            <div class="bg-white/5 rounded-lg p-4 mb-6">
                                <ul class="text-left text-white/70 text-sm space-y-3">
                                    <li class="flex items-start gap-2">
                                        <i class="fas fa-globe-americas mt-1 text-pink-400"></i>
                                        <span>Stories are public and viewable by all PLATO5 users</span>
                                    </li>
                                    <li class="flex items-start gap-2">
                                        <i class="fas fa-shield-alt mt-1 text-pink-400"></i>
                                        <span>Content is manually reviewed before appearing</span>
                                    </li>
                                    <li class="flex items-start gap-2">
                                        <i class="fas fa-clock mt-1 text-pink-400"></i>
                                        <span>Stories automatically expire after 36 hours</span>
                                    </li>
                                </ul>
                            </div>

                            <button class="w-full px-6 py-3 bg-pink-500 hover:bg-pink-600 rounded-lg text-white font-medium transition-colors duration-300">
                                Select Image
                            </button>
                        </div>
                    `;

                    const uploadButton = content.querySelector('button');
                    uploadButton?.addEventListener('click', () => {
                        modal.close();
                        input.click();
                    });

                    modal.append(content);
                    modal.mount(document.body);
                    modal.open();
                });

                input.addEventListener('change', async (e) => {
                    const file = (e.target as HTMLInputElement).files?.[0];
                    if (!file) return;

                    try {
                        const story = await StoryService.createStory(file);
                        if (story) {
                            UINotifications.shipSuccessToUI('Story uploaded successfully. It will be available after moderation.');
                            
                            // Reload user stories to show the new one
                            const userId = localStorage.getItem('userId');
                            if (userId) {
                                this.loadUserStories(parseInt(userId), container);
                            }
                            
                            // Update the existing modal content using append
                            const content = document.createElement('div');
                            content.className = 'text-center p-8';
                            content.innerHTML = `
                                <div class="mb-6">
                                    <div class="animate-bounce text-4xl mb-2">🎉</div>
                                    <h3 class="text-xl font-bold text-white mb-2">Story Uploaded!</h3>
                                </div>

                                <div class="space-y-4 text-white/70 text-sm">
                                    <p>Your story has been submitted for moderation.</p>
                                    <p>Once approved, it will be visible to the PLATO5 community for 36 hours.</p>
                                </div>
                            `;
                            modal.append(content);
                        }
                    } catch (error) {
                        UINotifications.shipErrorToUI('Failed to upload story');
                    }
                });
            } else if (story.image) {
                const img = document.createElement('img');
                img.src = story.image;
                img.className = 'w-full h-full rounded-full object-cover';
                imageContainer.appendChild(img);
                
                if (story.storyId) {
                    imageContainer.addEventListener('click', () => {
                        const storyModal = new StoryModal();
                        storyModal.show(
                            story.title,
                            story.image!,
                            story.stories?.map(s => s.ImageURL) || userStories?.map(s => s.ImageURL),
                            story.storyId,
                            story.profilePic,
                            story.UserID,
                            story.stories || userStories
                        );
                    });
                }
            } else if (story.isUserStory) {
                const img = document.createElement('img');
                img.src = story.image!;
                img.className = 'w-full h-full rounded-full object-cover';
                imageContainer.appendChild(img);
                
                imageContainer.className += ' ring-4 ring-white/20';
                
                imageContainer.addEventListener('click', () => {
                    const storyModal = new StoryModal();
                    storyModal.show(
                        'You', 
                        userStories![0].ImageURL, 
                        userStories!.map(s => s.ImageURL),
                        userStories![0].ID,
                        story.profilePic,
                        story.UserID,
                        userStories
                    );
                    this.storyUserID = story.UserID;
                });
            }

            const title = document.createElement('span');
            title.className = 'text-xs text-white/70';
            title.textContent = story.title;

            storyItem.appendChild(imageContainer);
            storyItem.appendChild(title);
            if (insertBefore) {
                insertBefore.parentNode?.insertBefore(storyItem, insertBefore.nextSibling);
            } else {
                container.appendChild(storyItem);
            }
        });
    }

    public mount(parent: HTMLElement): void {
        // Only mount if element has content (user is logged in)
        if (this.element.hasChildNodes()) {
            parent.appendChild(this.element);
        }
    }

    public unmount(): void {
        this.element.remove();
    }
}