// DiscussionResponse model
export interface DiscussionResponse {
    ID: number;
    UserID: number;
    Timestamp: string;
    Author: string;
    Title: string;
    Body: string;
    AverageRating: number;
    InteractionCount: number;
    CreatedAt: string;
    UpdatedAt: string;
    DeletedAt: string | null;
    Tags: string[] | null;
    MediaURL: string | null;
    CornerId: number | null;
    GroupId: number | null;
}

// ITagResponse model
export interface ITagResponse {
    ID: number;
    CreatedAt: string;
    UpdatedAt: string;
    DeletedAt: string | null;
    Tag: string;
    DiscussionCount: number;
}

// ITagForDiscussionResponse model
export interface ITagForDiscussionResponse {
    ID: number;
    CreatedAt: string;
    UpdatedAt: string;
    DeletedAt: string | null;
    Tag: string;
    DiscussionCount: number;
}

// CommentResponse model
export interface CommentResponse {
    ID: number;
    DiscussionID: number;
    Timestamp: string;
    Author: string;
    Text: string;
}

// Add these new interfaces
export interface CornerResponse {
    ID: number;
    CreatedAt: string;
    UpdatedAt: string;
    DeletedAt: string | null;
    Title: string;
    Description: string;
    CreatedBy: number;
    Moderators: number[];
    DiscussionIds: number[];
}

export interface GroupResponse {
    ID: number;
    CreatedAt: string;
    UpdatedAt: string;
    DeletedAt: string | null;
    Title: string;
    Description: string;
    CreatedBy: number;
    Public: boolean;
    ApprovedUsers: number[];
}

// Utility functions to convert JSON to TypeScript models
export function parseDiscussionResponse(json: any): DiscussionResponse {
    return {
        ID: json.ID as number,
        UserID: json.UserID as number,
        Timestamp: json.Timestamp as string,
        Author: json.Author as string,
        Title: json.Title as string,
        Body: json.Body as string,
        AverageRating: json.AverageRating as number,
        InteractionCount: json.InteractionCount as number,
        CreatedAt: json.CreatedAt as string,
        UpdatedAt: json.UpdatedAt as string,
        DeletedAt: json.DeletedAt as string | null,
        Tags: json.Tags as string[] | null,
        MediaURL: json.MediaURL as string | null,
        CornerId: json.CornerId as number | null,
        GroupId: json.GroupId as number | null
    };
}

export function parseITagResponse(json: any): ITagResponse {
    return {
        ID: json.ID as number,
        CreatedAt: json.CreatedAt as string,
        UpdatedAt: json.UpdatedAt as string,
        DeletedAt: json.DeletedAt as string | null,
        Tag: json.Tag as string,
        DiscussionCount: json.DiscussionCount as number,
    };
}

export function parseITagForDiscussionResponse(json: any): ITagForDiscussionResponse {
    return {
        ID: json.ID as number,
        CreatedAt: json.CreatedAt as string,
        UpdatedAt: json.UpdatedAt as string,
        DeletedAt: json.DeletedAt as string | null,
        Tag: json.Tag as string,
        DiscussionCount: json.DiscussionCount as number,
    };
}

export function parseCommentResponse(json: any): CommentResponse {
    return {
        ID: json.ID as number,
        DiscussionID: json.DiscussionID as number,
        Timestamp: json.Timestamp as string,
        Author: json.Author as string,
        Text: json.Text as string,
    };
}

export function parseCornerResponse(json: any): CornerResponse {
    return {
        ID: json.ID as number,
        CreatedAt: json.CreatedAt as string,
        UpdatedAt: json.UpdatedAt as string,
        DeletedAt: json.DeletedAt as string | null,
        Title: json.Title as string,
        Description: json.Description as string,
        CreatedBy: json.CreatedBy as number,
        Moderators: json.Moderators as number[],
        DiscussionIds: json.DiscussionIds as number[]
    };
}

export function parseGroupResponse(json: any): GroupResponse {
    return {
        ID: json.ID as number,
        CreatedAt: json.CreatedAt as string,
        UpdatedAt: json.UpdatedAt as string,
        DeletedAt: json.DeletedAt as string | null,
        Title: json.Title as string,
        Description: json.Description as string,
        CreatedBy: json.CreatedBy as number,
        Public: json.Public as boolean,
        ApprovedUsers: json.ApprovedUsers as number[]
    };
} 