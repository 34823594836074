export interface NanoResponse {
    ID: number;
    user1_id: number;
    user2_id: number;
    user3_id: number;
    user4_id: number;
    engagement_score: number;
    last_chat: string;
    emoji: string;
    color: string;
    title: string;
}

export interface CommibResponse {
    ID: number;
    author: string;
    userId: number;
    content: string;
    avgRating: number;
    views: number;
}

export class ChatResponse {
    ID!: number;
    author!: string;
    user_id!: number;
    nano_id!: number;
    content!: string;
    timestamp!: string;
    post_id?: number;
    commib_id?: number;
    reply_id?: number;
    reply_to?: string;
    insights?: string;
    analytix_id?: number;
    event_id?: number;

    static fromJson(json: any): ChatResponse {
        return Object.assign(new ChatResponse(), json);
    }
}
