import { StoryService } from '../../actions/stories/api-services';
import { AuthService } from '../../actions/auth/api-services';
import { Hash } from '../../utilities/hash';

export class StoryModal {
    private element: HTMLElement;
    private currentIndex: number = 0;
    private images: string[] | null = null;
    private storyId?: number;
    private storyUserID?: number;
    private title: string = '';
    private profilePic?: string;
    private isLiked: boolean = false;
    private stories?: Array<{ ID: number }>;

    constructor() {
        this.element = document.createElement('div');
        this.element.className = 'fixed inset-0 z-50 flex items-center justify-center bg-black/70';
    }

    private async updateContent(title: string, image: string, index?: number) {
        // If we have multiple stories and an index, update the storyId
        if (this.images && index !== undefined && this.stories && this.stories[index]) {
            this.storyId = this.stories[index].ID;
        }

        const currentUserId = parseInt(localStorage.getItem('userId') || '0');
        const isOwnStory = this.storyUserID && currentUserId === this.storyUserID;
        const userData = this.storyUserID ? await AuthService.getUserById(this.storyUserID) : null;

        // Recheck likes and increment views for the current story
        if (this.storyId) {
            this.isLiked = await StoryService.checkIfLiked(this.storyId);
            await StoryService.incrementViews(this.storyId);
        }

        // First, check if element exists
        if (!this.element) {
            this.element = document.createElement('div');
            this.element.className = 'fixed inset-0 z-50 flex items-center justify-center bg-black/70';
        }

        // Create a new container for the content
        const container = document.createElement('div');
        container.className = 'relative w-[400px] h-[600px] bg-black rounded-2xl group';
        
        // Set the HTML content
        container.innerHTML = `
            <!-- Navigation overlays -->
            <div class="absolute left-0 inset-y-0 w-1/2 cursor-pointer z-10" data-nav="prev"></div>
            <div class="absolute right-0 inset-y-0 w-1/2 cursor-pointer z-10" data-nav="next"></div>

            <!-- Image container -->
            <div class="absolute inset-0 rounded-2xl z-0">
                <img src="${image}" class="w-full h-full object-cover rounded-2xl">
            </div>

            <!-- Close button - highest z-index -->
            <button id="story-modal-close" 
                class="absolute top-4 right-4 z-[100]
                    w-8 h-8 rounded-full 
                    bg-pink-500/10 hover:bg-pink-500/20 
                    flex items-center justify-center 
                    pointer-events-auto
                    transition-all duration-300">
                <i class="fas fa-times text-pink-400 text-sm"></i>
            </button>

            <div class="absolute bottom-6 left-0 right-0 flex justify-center gap-3 z-20">
                ${!isOwnStory ? `
                    <button class="like-button 
                        w-14 h-14 rounded-full 
                        flex items-center justify-center 
                        transition-all duration-300 transform
                        hover:scale-110 active:scale-95
                        ${this.isLiked ? 
                            'bg-gradient-to-tr from-pink-500 to-rose-500 shadow-lg shadow-pink-500/25' : 
                            'bg-black/30 backdrop-blur-md border border-white/10 hover:bg-black/50'
                        }">
                        <i class="fas fa-heart text-xl
                            transition-all duration-300
                            ${this.isLiked ? 
                                'text-white scale-110' : 
                                'text-white/50 group-hover:text-white'
                            }">
                        </i>
                    </button>
                ` : `
                    <div class="flex gap-3">
                        <button class="view-likes-btn 
                            px-4 h-10 rounded-full 
                            bg-blue-500/20 backdrop-blur-md border border-blue-500/20 
                            text-blue-400 hover:text-blue-300 
                            transition-all duration-300 
                            flex items-center gap-2">
                            <i class="fas fa-heart text-sm"></i>
                            <span class="text-sm font-medium">View Likes</span>
                        </button>
                        <div class="px-4 h-10 rounded-full 
                            bg-blue-500/20 backdrop-blur-md border border-blue-500/20 
                            text-blue-400
                            flex items-center gap-2">
                            <i class="fas fa-eye text-sm"></i>
                            <span class="text-sm font-medium view-count">0</span>
                        </div>
                    </div>
                `}
            </div>

            <div class="absolute top-0 left-0 right-0 p-4 flex items-center gap-3 bg-gradient-to-b from-black/50 to-transparent z-30">
                ${this.storyUserID && userData ? `
                    <a href="/people/${Hash.createSlug(userData.name)}/${Hash.encodeId(this.storyUserID)}" 
                       class="flex items-center gap-3 hover:opacity-80 transition-opacity duration-200">
                        ${this.profilePic ? 
                            `<img src="${this.profilePic}" class="w-8 h-8 rounded-full object-cover">` : 
                            `<div class="w-8 h-8 rounded-full bg-gradient-to-tr from-pink-500/20 to-purple-500/20 flex items-center justify-center">
                                <i class="fas fa-user text-pink-400"></i>
                             </div>`
                        }
                        <span class="text-sm font-medium text-white">${title}</span>
                    </a>
                ` : `
                    <span class="text-sm font-medium text-white">${title}</span>
                `}
            </div>
        `;

        // Clear existing content and append new container
        this.element.innerHTML = '';
        this.element.appendChild(container);

        // Add to document if needed
        if (!document.body.contains(this.element)) {
            document.body.appendChild(this.element);
        }

        // Setup event listeners after DOM is updated
        this.setupEventListeners();

        // Update view count if needed
        if (isOwnStory && this.storyId) {
            const viewCount = await StoryService.getViewCount(this.storyId);
            const viewCountElement = this.element.querySelector('.view-count');
            if (viewCountElement) {
                viewCountElement.textContent = this.formatNumber(viewCount);
            }
        }
    }

    private formatNumber(num: number): string {
        if (num >= 1000000) {
            return `${(num / 1000000).toFixed(1)}M`;
        } else if (num >= 1000) {
            return `${(num / 1000).toFixed(1)}K`;
        }
        return num.toString();
    }

    public async show(title: string, image: string, newsImages?: string[], storyId?: number, profilePic?: string, storyUserID?: number, stories?: Array<{ ID: number }>) {
        this.title = title;
        this.images = newsImages || null;
        this.currentIndex = 0;
        this.storyId = storyId;
        this.profilePic = profilePic;
        this.storyUserID = storyUserID;
        this.stories = stories;

        await this.updateContent(title, image, 0);  // Pass initial index
    }

    private setupEventListeners() {
        this.element.addEventListener('click', (e) => {
            if (e.target === this.element) this.close();
        });
        
        const closeButton = this.element.querySelector('#story-modal-close');
        if (closeButton) {
            closeButton.addEventListener('click', () => this.close());
        }

        if (this.images) {
            const navElements = this.element.querySelectorAll('[data-nav]');
            navElements.forEach(nav => {
                nav.addEventListener('click', async (e) => {
                    e.stopPropagation();
                    const direction = (nav as HTMLElement).dataset.nav;
                    if (direction === 'next') {
                        if (this.currentIndex === this.images!.length - 1) {
                            this.close();
                        } else {
                            this.currentIndex++;
                            this.updateContent(this.title, this.images![this.currentIndex], this.currentIndex);
                        }
                    } else if (direction === 'prev') {
                        if (this.currentIndex === 0) {
                            this.close();
                        } else {
                            this.currentIndex--;
                            this.updateContent(this.title, this.images![this.currentIndex], this.currentIndex);
                        }
                    }
                });
            });
        }

        const likeButton = this.element.querySelector('.like-button');
        if (likeButton) {
            likeButton.addEventListener('click', (e) => {
                e.stopPropagation();
                this.toggleLike();
            });
        }

        const viewLikesBtn = this.element.querySelector('.view-likes-btn');
        if (viewLikesBtn) {
            viewLikesBtn.addEventListener('click', async () => {
                const likes = await StoryService.getStoryLikes(this.storyId!);
                this.showLikesModal(likes);
            });
        }
    }

    public close() {
        this.element.remove();
    }

    public showSuccess(imageURL: string) {
        this.element.innerHTML = `
            <div class="relative w-[400px] bg-black rounded-2xl p-8 text-center">
                <div class="mb-6">
                    <div class="animate-bounce text-4xl mb-2">🎉</div>
                    <h3 class="text-xl font-bold text-white mb-2">Story Uploaded!</h3>
                </div>

                <div class="space-y-4 text-white/70 text-sm">
                    <p>Your story has been submitted for moderation.</p>
                    <p>Once approved, it will be visible to the PLATO5 community for 36 hours.</p>
                </div>

                <button class="mt-8 px-6 py-2 bg-pink-500 hover:bg-pink-600 rounded-lg text-white text-sm transition-colors duration-300">
                    Close
                </button>
            </div>
        `;

        document.body.appendChild(this.element);

        const closeButton = this.element.querySelector('button');
        if (closeButton) {
            closeButton.addEventListener('click', () => this.close());
        }
    }

    private async toggleLike() {
        if (this.storyId) {
            const success = await StoryService.likeStory(this.storyId);
            
            if (success) {
                this.isLiked = !this.isLiked;
                const likeButton = this.element.querySelector('.like-button');
                const heartIcon = likeButton?.querySelector('i');
                
                if (likeButton && heartIcon) {
                    if (this.isLiked) {
                        // Like animation
                        likeButton.classList.remove('bg-black/30', 'backdrop-blur-md', 'border', 'border-white/10', 'hover:bg-black/50');
                        likeButton.classList.add('bg-gradient-to-tr', 'from-pink-500', 'to-rose-500', 'shadow-lg', 'shadow-pink-500/25');
                        heartIcon.classList.remove('text-white/50');
                        heartIcon.classList.add('text-white', 'scale-110');
                        
                        // Add pop animation
                        heartIcon.classList.add('animate-pop');
                        setTimeout(() => heartIcon.classList.remove('animate-pop'), 300);
                    } else {
                        // Unlike animation
                        likeButton.classList.add('bg-black/30', 'backdrop-blur-md', 'border', 'border-white/10', 'hover:bg-black/50');
                        likeButton.classList.remove('bg-gradient-to-tr', 'from-pink-500', 'to-rose-500', 'shadow-lg', 'shadow-pink-500/25');
                        heartIcon.classList.add('text-white/50');
                        heartIcon.classList.remove('text-white', 'scale-110');
                    }
                }
            }
        }
    }

    private async showLikesModal(likes: Array<{ id: number, name: string }> | null) {
        const currentContent = this.element.innerHTML;
        
        // Get profile pics for all users
        const usersWithPics = likes ? await Promise.all(likes.map(async user => {
            const profilePic = await AuthService.getFirstImage(user.id);
            return {
                ...user,
                profilePic: profilePic?.ImageURL
            };
        })) : [];
        
        this.element.innerHTML = `
            <div class="w-[300px] bg-[#1a1a1a] rounded-xl overflow-hidden">
                <div class="p-4 border-b border-white/10 flex items-center justify-between">
                    <h3 class="text-white font-medium">Liked by</h3>
                    <button class="back-btn text-white/50 hover:text-white">
                        <i class="fas fa-arrow-left"></i>
                    </button>
                </div>
                <div class="p-2 overflow-y-auto max-h-[320px]">
                    ${usersWithPics?.length ? usersWithPics.map(user => `
                        <a href="/people/${Hash.createSlug(user.name)}/${Hash.encodeId(user.id)}" 
                           class="p-2 flex items-center gap-3 hover:bg-white/5 rounded-lg cursor-pointer">
                            <div class="w-10 h-10 rounded-full bg-gradient-to-tr from-pink-500/20 to-purple-500/20 flex items-center justify-center overflow-hidden">
                                ${user.profilePic ? 
                                    `<img src="${user.profilePic}" class="w-full h-full object-cover" />` :
                                    `<i class="fas fa-user text-pink-400"></i>`
                                }
                            </div>
                            <span class="text-white hover:text-pink-400 transition-colors duration-200">${user.name}</span>
                        </a>
                    `).join('') : `
                        <div class="p-4 text-center text-white/50">
                            No likes yet
                        </div>
                    `}
                </div>
            </div>
        `;

        const backBtn = this.element.querySelector('.back-btn');
        backBtn?.addEventListener('click', () => {
            this.element.innerHTML = currentContent;
            this.setupEventListeners();
        });
    }
}