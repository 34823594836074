import { TopBar } from '../pieces/reusable/top-bar';
import { ModalForm } from '../pieces/reusable/modal-form';
import { parse5mark, build5markFromDiscussion } from '../utilities/5mark-parser';
import { KomminService } from '../actions/kommin/api-services';
import { DiscussionResponse, CommentResponse } from '../actions/kommin/models';
import { UINotifications } from '../utilities/errors';
import { AuthService } from '../actions/auth/api-services';
import { KomminFooter } from '../pieces/kommin/kommin-footer';
import { ContentTitle } from '../pieces/reusable/content-title';
import { GamificationReminder } from '../pieces/reusable/gamification-reminder';
import { DiscussionMetadata } from '../utilities/metadata'; 
import { NavColumn } from '../pieces/general/nav-column';
import { ContentColumn } from '../pieces/general/content-column';
import { DiscoveryColumn } from '../pieces/general/discovery-column';
import { YourBig5 } from '../pieces/general/your-big-5';
import { MobileNavButtons } from '../pieces/reusable/mobile-nav-buttons';
import { navigate } from '../app-router';
import { Hash } from '../utilities/hash';

export class KomminScreen {
    private container: HTMLElement | null = null;
    private modalForm: ModalForm;
    private yourBig5: YourBig5;
    private mobileNavButtons: MobileNavButtons;
    private discussionId: number;
    private mainContent: HTMLElement;

    constructor(discussionId: number) {
        this.discussionId = discussionId;
        this.mainContent = document.createElement('div');
        this.modalForm = new ModalForm();
        this.yourBig5 = new YourBig5(() => {
            // Empty for now since we're in kommin view
        });
        this.mobileNavButtons = new MobileNavButtons(this.modalForm, this.yourBig5);
    }

    public async mount(container: HTMLElement) {
        this.container = container;
        this.container.innerHTML = '';
        this.container.className = 'min-h-screen';

        // Create layout
        const columnLayout = document.createElement('div');
        columnLayout.className = 'flex min-h-screen h-screen overflow-hidden';

        // Content wrapper
        const contentWrapper = document.createElement('div');
        contentWrapper.className = 'flex lg:flex-row flex-col flex-1 overflow-hidden';

        // Mount columns
        const navColumn = new NavColumn(this.modalForm);
        const contentColumn = new ContentColumn();
        const discoveryColumn = new DiscoveryColumn(this.modalForm, this.yourBig5);

        // Only mount nav column on desktop
        const navElement = navColumn.getElement();
        navElement.className = 'hidden sm:block ' + navElement.className;
        navColumn.mount(columnLayout);

        contentColumn.mount(contentWrapper);
        
        // Hide discovery column on mobile
        const discoveryElement = discoveryColumn.getElement();
        discoveryElement.className = 'hidden sm:block discovery-column ' + discoveryElement.className;
        discoveryColumn.mount(contentWrapper);

        // Load discussion content
        await this.loadDiscussionContent(contentColumn.getContainer());

        columnLayout.appendChild(contentWrapper);
        
        // Mount modal form and layout
        this.modalForm.mount(container);
        container.appendChild(columnLayout);

        // Add mobile nav buttons
        this.mobileNavButtons.mount(container);
    }

    private async loadDiscussionContent(container: HTMLElement) {
        container.innerHTML = '';
        
        const mainContent = document.createElement('div');
        mainContent.className = 'max-w-3xl mx-auto px-4 py-6 space-y-6 pb-24';

        // Add content title
        const contentTitle = new ContentTitle('Kommin', 'kommin');
        contentTitle.mount(mainContent);

        // Navigation and controls container
        const navContainer = document.createElement('div');
        navContainer.className = 'flex items-center justify-between mb-6';

        // Back button
        const backButton = document.createElement('button');
        backButton.className = 'flex items-center gap-2 text-white/50 hover:text-white/70 transition-colors text-sm';
        backButton.innerHTML = '<i class="fas fa-arrow-left"></i><span>Back</span>';
        backButton.addEventListener('click', () => navigate('/kommin'));

        // Author controls container
        const authorControls = document.createElement('div');
        authorControls.className = 'flex items-center gap-4';

        try {
            const discussion = await KomminService.getDiscussionById(this.discussionId);
            if (!discussion) throw new Error('Discussion not found');

            // Check if user is author
            const currentUserId = localStorage.getItem('userId');
            const isAuthor = currentUserId && parseInt(currentUserId) === discussion.UserID;

            if (isAuthor) {
                // Edit button
                const editButton = document.createElement('button');
                editButton.className = 'text-white/50 hover:text-white/70 transition-colors';
                editButton.innerHTML = '<i class="fas fa-image"></i>';
                editButton.title = 'Update Picture';
                editButton.addEventListener('click', () => {
                    const hashedId = Hash.encodeId(this.discussionId);
                    navigate(`/kommin/discussion/${hashedId}/upload-media`);
                });
                authorControls.appendChild(editButton);

                // Delete button
                const deleteButton = document.createElement('button');
                deleteButton.className = 'text-white/50 hover:text-red-400 transition-colors';
                deleteButton.innerHTML = '<i class="fas fa-trash"></i>';
                deleteButton.title = 'Delete Discussion';
                deleteButton.addEventListener('click', () => {
                    const confirmContent = document.createElement('div');
                    confirmContent.className = 'flex flex-col gap-6';
                    confirmContent.innerHTML = `
                        <div class="flex items-center gap-3">
                            <i class="fas fa-exclamation-triangle text-red-400"></i>
                            <span class="text-white/90">Delete Discussion</span>
                        </div>
                        <p class="text-white/60">
                            Are you sure you want to delete this discussion? This action cannot be undone.
                        </p>
                        <div class="flex items-center justify-end gap-3">
                            <button class="px-4 py-2 text-white/60 hover:text-white/80 transition-colors" id="cancel-btn">
                                Cancel
                            </button>
                            <button class="px-4 py-2 bg-red-500/80 hover:bg-red-500 text-white rounded transition-colors" id="confirm-btn">
                                Delete
                            </button>
                        </div>
                    `;
                    
                    this.modalForm.append(confirmContent);
                    
                    // Handle cancel button
                    confirmContent.querySelector('#cancel-btn')?.addEventListener('click', () => {
                        this.modalForm.close();
                    });

                    // Handle confirm button 
                    confirmContent.querySelector('#confirm-btn')?.addEventListener('click', async () => {
                        try {
                            const success = await KomminService.deleteDiscussion(this.discussionId);
                            if (success) {
                                UINotifications.shipSuccessToUI('Discussion deleted successfully');
                                this.modalForm.close();
                                navigate('/kommin');
                            } else {
                                throw new Error('Failed to delete discussion');
                            }
                        } catch (error) {
                            console.error('Error deleting discussion:', error);
                            UINotifications.shipErrorToUI('Failed to delete discussion');
                        }
                    });

                    this.modalForm.open();
                });
                authorControls.appendChild(deleteButton);
            }

            // Append elements
            navContainer.appendChild(backButton);
            navContainer.appendChild(authorControls);
            mainContent.appendChild(navContainer);

            // Discussion container
            const discussionElement = document.createElement('div');
            discussionElement.className = 'bg-black/20 backdrop-blur-lg border border-white/5 rounded-xl p-6';

            // Create avatar container
            const avatarContainer = document.createElement('div');
            avatarContainer.className = `
                w-11 h-11 rounded-full 
                bg-gradient-to-br from-orange-500/20 to-orange-600/20 
                border-2 border-purple-500/40
                flex items-center justify-center
                shadow-lg shadow-purple-500/20
                overflow-hidden
                ring-2 ring-purple-500/20 ring-offset-2 ring-offset-black/50
            `;

            const rating = Math.round(Number(discussion.AverageRating) * 100) / 100;
            const ratingDisplay = !isNaN(rating) 
                ? (rating > 50 ? `+${rating}` : rating.toString())
                : 'N/A';
            const ratingColor = !isNaN(rating)
                ? (rating > 50 ? 'text-green-400' : 'text-red-400')
                : 'text-white/50';

            discussionElement.innerHTML = `
                <div class="flex flex-col gap-4">
                    <div class="flex items-center gap-2">
                        <div class="flex-shrink-0" id="avatar-container"></div>
                        <div class="flex items-center gap-2">
                            <span class="font-[Quicksand] font-bold text-white/90 text-[15px]">
                                ${discussion.Author}
                            </span>
                            <span class="text-white/30 text-sm">·</span>
                            <span class="text-white/30 text-sm">${discussion.Timestamp}</span>
                        </div>
                    </div>

                    <div>
                        ${discussion.Title ? `
                            <h2 class="font-[Space_Grotesk] font-bold text-xl text-white/95 mb-3 
                                     border-b border-white/10 pb-2
                                     transition-colors">
                                ${discussion.Title}
                            </h2>
                        ` : ''}
                        <div id="media-container-${discussion.ID}" class="hidden"></div>
                        <p class="text-white/80 mb-4 font-[Crimson_Text] text-lg leading-relaxed">
                            ${discussion.Body}
                        </p>
                    </div>

                    <div class="flex items-center gap-2">
                        <span class="${ratingColor} text-xs font-medium">${ratingDisplay}</span>
                        <span class="text-white/40 text-xs">avg. rating</span>
                    </div>

                    <!-- Rating controls -->
                    <div class="mt-4 space-y-4">
                        <input type="range" min="0" max="100" value="50" 
                            id="rating-slider"
                            class="rating-input w-full h-1.5 
                                   bg-gradient-to-r from-purple-500/20 to-orange-500/20 
                                   rounded-full appearance-none cursor-pointer
                                   accent-orange-500">
                        <button 
                            id="rating-submit"
                            class="rating-submit w-full px-4 py-2 
                                   bg-gradient-to-r from-orange-500/90 to-orange-600/90 
                                   hover:from-orange-500 hover:to-orange-600
                                   active:from-orange-600 active:to-orange-700
                                   rounded-lg text-white text-sm font-medium
                                   transition-all duration-200
                                   shadow-lg shadow-orange-500/20">
                            Rate
                        </button>
                    </div>
                </div>
            `;

            // Load avatar with intersection observer
            const observer = new IntersectionObserver(async (entries) => {
                if (entries[0].isIntersecting) {
                    observer.disconnect();
                    const firstImage = await AuthService.getFirstImage(discussion.UserID);
                    if (firstImage && firstImage.ImageURL) {
                        avatarContainer.innerHTML = `
                            <img src="${firstImage.ImageURL}" 
                                 class="w-full h-full object-cover" 
                                 alt="${discussion.Author}" />`;
                    }
                }
            });
            observer.observe(avatarContainer);

            // Append the avatar container after setting innerHTML
            discussionElement.querySelector('#avatar-container')!.appendChild(avatarContainer);

            mainContent.appendChild(discussionElement);

            // Comments section with improved styling
            const commentsElement = document.createElement('div');
            commentsElement.className = 'w-full bg-gradient-to-b from-black/30 to-black/20 backdrop-blur-xl border border-white/10 rounded-xl mt-6 shadow-xl shadow-black/20';

            commentsElement.innerHTML = `
                <div class="relative flex items-center">
                    <input 
                        type="text"
                        class="w-full bg-black/20 px-8 py-6
                               text-white/90 placeholder-white/30 text-base
                               rounded-t-xl"
                        placeholder="Share something uncommon..."
                        data-mobile-placeholder="Comment..."
                        onload="this.placeholder = window.innerWidth < 640 ? this.dataset.mobilePlaceholder : this.placeholder"
                        onfocus="this.placeholder = window.innerWidth < 640 ? this.dataset.mobilePlaceholder : this.placeholder"
                    />
                    <button class="absolute right-5 w-10 h-10 bg-gradient-to-r from-orange-500 to-orange-600 
                                   border border-orange-500/20 rounded-full
                                   text-white/90 hover:text-white transition-all
                                   flex items-center justify-center">
                        <i class="fas fa-feather-alt"></i>
                    </button>
                </div>
            `;

            // Add event listener after creating the element
            const input = commentsElement.querySelector('input');
            if (input) {
                const updatePlaceholder = () => {
                    input.placeholder = window.innerWidth < 640 ? 'Comment...' : 'Share something uncommon...';
                };
                updatePlaceholder(); // Initial set
                window.addEventListener('resize', updatePlaceholder);
            }

            // Add comment handler
            const commentButton = commentsElement.querySelector('button');
            const commentInput = commentsElement.querySelector('input');
            if (commentButton && commentInput) {
                const submitComment = async () => {
                    const commentText = commentInput.value.trim();
                    if (!commentText) return;

                    try {
                        const userId = localStorage.getItem('userId');
                        if (!userId) {
                            UINotifications.shipErrorToUI('Please log in to comment');
                            return;
                        }

                        const user = await AuthService.getUserById(parseInt(userId));
                        if (!user) throw new Error('Failed to get user details');

                        const comment = await KomminService.postAddComment(
                            parseInt(userId),
                            user.name,
                            this.discussionId,
                            commentText
                        );

                        if (comment) {
                            UINotifications.shipSuccessToUI('Comment posted!');
                            window.location.reload();
                        }
                    } catch (error) {
                        console.error('Error posting comment:', error);
                        UINotifications.shipErrorToUI('Failed to post comment');
                    }
                };

                // Add click handler
                commentButton.addEventListener('click', submitComment);
                
                // Add enter key handler
                commentInput.addEventListener('keypress', (event) => {
                    if (event.key === 'Enter') {
                        event.preventDefault();
                        submitComment();
                    }
                });
            }

            // Add rating handler to existing rating elements
            const ratingButton = discussionElement.querySelector('.rating-submit') as HTMLButtonElement;
            const ratingInput = discussionElement.querySelector('.rating-input') as HTMLInputElement;
            if (ratingButton && ratingInput) {
                ratingButton.addEventListener('click', async () => {
                    try {
                        const userId = localStorage.getItem('userId');
                        if (!userId) {
                            UINotifications.shipErrorToUI('Please log in to rate');
                            return;
                        }

                        const success = await KomminService.postRateDiscussion(
                            parseInt(userId),
                            this.discussionId,
                            parseInt(ratingInput.value)
                        );

                        if (success) {
                            ratingInput.disabled = true;
                            ratingButton.disabled = true;
                            ratingButton.className += ' opacity-50 cursor-not-allowed';
                            UINotifications.shipSuccessToUI('Rating submitted successfully!');
                            window.location.reload();
                        } else {
                            UINotifications.shipErrorToUI('You can only rate once');
                        }
                    } catch (error) {
                        console.error('Error submitting rating:', error);
                        UINotifications.shipErrorToUI('Failed to submit rating');
                    }
                });
            }

            // Comments list with improved styling
            const comments = await KomminService.getDiscussionComments(this.discussionId);
            if (comments && comments.length > 0) {
                const commentsContainer = document.createElement('div');
                commentsContainer.className = 'space-y-6 p-8';
                
                comments.forEach(comment => {
                    const commentElement = this.createCommentElement(comment);
                    commentsContainer.appendChild(commentElement);
                });
                
                commentsElement.appendChild(commentsContainer);
            } else {
                const noCommentsDiv = document.createElement('div');
                noCommentsDiv.className = 'p-8';
                noCommentsDiv.innerHTML = `
                    <div class="text-white/50 text-sm text-center">No comments yet</div>
                `;
                commentsElement.appendChild(noCommentsDiv);
            }

            mainContent.appendChild(commentsElement);
            container.appendChild(mainContent);

            // Add lazy loading for media
            const mediaContainer = discussionElement.querySelector(`#media-container-${discussion.ID}`) as HTMLElement;
            const pictures = await AuthService.getDiscussionPictures(discussion.ID);
            if (pictures && pictures.length > 0) {
                mediaContainer.className = `
                    relative mb-4 w-full aspect-[16/9] rounded-lg overflow-hidden
                    bg-gradient-to-br from-black/40 to-black/20
                    backdrop-blur-xl
                    border border-white/5
                `;

                // Transform the Cloudinary URL for optimal display
                const optimizedUrl = pictures[0].ImageURL.replace('/upload/', '/upload/c_fill,g_auto,w_1280,h_720,q_auto,f_auto/');
                
                mediaContainer.innerHTML = `
                    <img 
                        src="${optimizedUrl}" 
                        alt="Discussion media"
                        class="w-full h-full object-cover object-center transform hover:scale-105 transition-transform duration-500"
                        loading="lazy"
                    />
                `;
                mediaContainer.classList.remove('hidden');
            }

            const memos = await AuthService.getDiscussionMemos(discussion.ID);
            if (memos && memos.length > 0) {
                const memo = memos[0];
                const memoContainer = document.createElement('div');
                memoContainer.className = `
                    relative mt-6 p-5 rounded-xl
                    bg-gradient-to-br from-black/40 to-black/60
                    border border-orange-500/10
                    backdrop-blur-xl
                    hover:border-orange-500/20
                    transition-all duration-300
                    group
                    cursor-pointer
                `;
                memoContainer.innerHTML = `
                    <div class="flex flex-col gap-3">
                        ${memo.Caption ? `
                            <p class="text-sm text-white/60">"${memo.Caption}"</p>
                        ` : ''}
                        <audio src="${memo.AudioURL}" 
                               class="w-full h-[36px] [&::-webkit-media-controls-panel]:bg-orange-950/40 
                               [&::-webkit-media-controls-current-time-display]:text-orange-400
                               [&::-webkit-media-controls-time-remaining-display]:text-orange-400
                               [&::-webkit-media-controls-timeline]:accent-orange-400
                               [&::-webkit-media-controls-play-button]:text-orange-400" 
                               controls
                               preload="none"></audio>
                    </div>
                `;

                // Add click handler for the container
                memoContainer.addEventListener('click', (e) => {
                    e.stopPropagation();
                    if (!(e.target as HTMLElement).closest('audio')) {
                        const audioElement = memoContainer.querySelector('audio');
                        if (audioElement) {
                            if (audioElement.paused) {
                                audioElement.play();
                            } else {
                                audioElement.pause();
                            }
                        }
                    }
                });

                // Insert after post body but before rating controls
                const postBody = discussionElement.querySelector('p');
                if (postBody) {
                    postBody.parentNode?.insertBefore(memoContainer, postBody.nextSibling);
                }
            }

        } catch (error) {
            console.error('Error loading discussion:', error);
            UINotifications.shipErrorToUI('Failed to load discussion');
        }
    }

    private createRatingSlider(): HTMLElement {
        const container = document.createElement('div');
        container.className = 'w-full bg-gradient-to-b from-black/30 to-black/20 backdrop-blur-xl border border-white/10 rounded-xl mt-6 shadow-xl shadow-black/20';

        const sliderId = `rating-slider-${Date.now()}`;
        
        container.innerHTML = `
            <div class="relative flex items-center">
                <input 
                    type="range"
                    min="0"
                    max="100"
                    value="50"
                    id="${sliderId}"
                    class="w-full bg-black/20 px-8 py-6 accent-orange-500"
                />
                <button 
                    id="${sliderId}-submit"
                    class="absolute right-5 w-10 h-10 bg-gradient-to-r from-orange-500 to-orange-600 
                           border border-orange-500/20 rounded-full
                           text-white/90 hover:text-white transition-all
                           flex items-center justify-center">
                    <i class="fas fa-check"></i>
                </button>
            </div>
        `;

        const button = container.querySelector(`#${sliderId}-submit`) as HTMLButtonElement;
        const slider = container.querySelector(`#${sliderId}`) as HTMLInputElement;

        button.addEventListener('click', () => this.handleRatingSubmit(slider.value, sliderId));

        return container;
    }

    private createCommentInput(): HTMLElement {
        const container = document.createElement('div');
        container.className = `
            relative overflow-hidden
            bg-gradient-to-br from-black/40 to-black/20
            backdrop-blur-xl
            border border-white/10
            rounded-2xl p-8 mt-8
            group
        `;

        // Glassmorphic glow effect
        const glow = document.createElement('div');
        glow.className = `
            absolute inset-0
            bg-gradient-to-br from-purple-500/10 to-orange-500/5
            opacity-0 group-hover:opacity-100
            transition-opacity duration-500
        `;

        const content = document.createElement('div');
        content.className = 'relative z-10';

        const header = document.createElement('div');
        header.className = 'text-xl font-medium text-white/90 mb-8';
        header.textContent = 'Join the Discussion';

        const input = document.createElement('textarea');
        input.className = `
            w-full
            bg-black/30
            backdrop-blur-sm
            border border-white/10
            rounded-xl
            p-6
            text-white/90
            placeholder-white/30
            focus:outline-none
            focus:border-white/20
            min-h-[140px]
        `;
        input.placeholder = 'What are your thoughts?';

        const button = document.createElement('button');
        button.className = `
            w-full mt-8
            bg-gradient-to-r from-purple-500/20 to-orange-500/20
            hover:from-purple-500/30 hover:to-orange-500/30
            border border-white/10
            rounded-xl
            py-3
            text-white/90 text-sm font-medium
            transition-all duration-300
            backdrop-blur-lg
        `;
        button.textContent = 'Post Comment';

        button.addEventListener('click', async () => {
            const commentText = input.value.trim();
            if (!commentText) return;

            try {
                const userId = localStorage.getItem('userId');
                if (!userId) {
                    UINotifications.shipErrorToUI('Please log in to comment');
                    return;
                }

                button.disabled = true;
                const user = await AuthService.getUserById(parseInt(userId));
                if (!user) throw new Error('Failed to get user details');

                const comment = await KomminService.postAddComment(
                    parseInt(userId),
                    user.name,
                    this.discussionId,
                    commentText
                );

                if (comment) {
                    UINotifications.shipSuccessToUI('Comment posted!');
                    window.location.reload();
                }
            } catch (error) {
                console.error('Error posting comment:', error);
                UINotifications.shipErrorToUI('Failed to post comment');
            }
        });

        content.appendChild(header);
        content.appendChild(input);
        content.appendChild(button);

        container.appendChild(glow);
        container.appendChild(content);
        return container;
    }

    public unmount(): void {
        if (this.container) {
            this.container.innerHTML = '';
        }
        
        this.container = null;
        this.modalForm.unmount();
        this.yourBig5?.unmount();
        this.mobileNavButtons.unmount();
    }

    async getDiscussionData(): Promise<DiscussionMetadata> {
        const discussion = await KomminService.getDiscussionById(this.discussionId);
        if (!discussion) {
            throw new Error('Discussion not found');
        }
        
        // Extract first line or first X characters as title
        const title = discussion.Body.split('\n')[0].slice(0, 60) + '...';
        
        return {
            title: title,
            description: discussion.Body.slice(0, 160),
            author: discussion.Author,
            created: discussion.CreatedAt
        };
    }

    private async handleRatingSubmit(rating: string, sliderId: string): Promise<void> {
        try {
            const userId = localStorage.getItem('userId');
            if (!userId) {
                UINotifications.shipErrorToUI('Please log in to rate');
                return;
            }

            const success = await KomminService.postRateDiscussion(
                parseInt(userId),
                this.discussionId,
                parseInt(rating)
            );

            if (success) {
                const slider = document.getElementById(sliderId) as HTMLInputElement;
                const button = document.getElementById(`${sliderId}-submit`) as HTMLButtonElement;
                slider.disabled = true;
                button.disabled = true;
                button.className += ' opacity-50 cursor-not-allowed';
                UINotifications.shipSuccessToUI('Rating submitted successfully!');
                window.location.reload();
            } else {
                UINotifications.shipErrorToUI('You can only rate once');
            }
        } catch (error) {
            console.error('Error submitting rating:', error);
            UINotifications.shipErrorToUI('Failed to submit rating');
        }
    }

    private createCommentElement(comment: CommentResponse): HTMLElement {
        const element = document.createElement('div');
        element.className = 'border-b border-white/10 py-6';
        
        const date = new Date(comment.Timestamp);
        const dateStr = date.toLocaleDateString(undefined, { 
            year: 'numeric', 
            month: 'numeric', 
            day: 'numeric'
        });
        const timeStr = date.toLocaleTimeString(undefined, {
            hour: '2-digit',
            minute: '2-digit'
        });
        
        element.innerHTML = `
            <div class="flex flex-col gap-1 mb-2">
                <span class="text-white/60 text-sm">${comment.Author}</span>
                <div class="text-white/30 text-xs">
                    <span>${dateStr}</span>
                    <span class="mx-1">·</span>
                    <span>${timeStr}</span>
                </div>
            </div>
            <p class="text-white/90 text-lg">${comment.Text}</p>
        `;
        
        return element;
    }
}
