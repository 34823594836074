import { ModalForm } from '../../pieces/reusable/modal-form';
import { OtherProfileContent } from '../../pieces/auth/other-profile-content';
import { NavColumn } from '../../pieces/general/nav-column';
import { ContentColumn } from '../../pieces/general/content-column';
import { DiscoveryColumn } from '../../pieces/general/discovery-column';
import { YourBig5 } from '../../pieces/general/your-big-5';
import { ContentTitle } from '../../pieces/reusable/content-title';
import { AuthService } from '../../actions/auth/api-services';
import { MobileNavButtons } from '../../pieces/reusable/mobile-nav-buttons';
import { navigate } from '../../app-router';

export class OtherProfileScreen {
    private container: HTMLElement | null = null;
    private modalForm: ModalForm;
    private yourBig5: YourBig5;
    private mobileNavButtons: MobileNavButtons;
    private userId: number;
    private nameSlug: string;

    constructor(userId: number, nameSlug: string) {
        this.userId = userId;
        this.nameSlug = nameSlug;
        this.modalForm = new ModalForm();
        this.yourBig5 = new YourBig5(() => {
            // Empty for now since we're in profile view
        });
        this.mobileNavButtons = new MobileNavButtons(this.modalForm, this.yourBig5);
    }

    public async mount(container: HTMLElement) {
        localStorage.setItem('chosenApplet', 'profile');
        
        this.container = container;
        this.container.innerHTML = '';
        this.container.className = 'min-h-screen';

        // Create layout
        const columnLayout = document.createElement('div');
        columnLayout.className = 'flex min-h-screen h-screen overflow-hidden';
        
        // Content wrapper
        const contentWrapper = document.createElement('div');
        contentWrapper.className = 'flex lg:flex-row flex-col flex-1 overflow-hidden';

        // Mount columns
        const navColumn = new NavColumn(this.modalForm);
        const contentColumn = new ContentColumn();
        const discoveryColumn = new DiscoveryColumn(this.modalForm, this.yourBig5);

        // Only mount nav column on desktop
        const navElement = navColumn.getElement();
        navElement.className = 'hidden sm:block ' + navElement.className;
        navColumn.mount(columnLayout);

        contentColumn.mount(contentWrapper);
        
        // Hide discovery column on mobile
        const discoveryElement = discoveryColumn.getElement();
        discoveryElement.className = 'hidden sm:block discovery-column ' + discoveryElement.className;
        discoveryColumn.mount(contentWrapper);

        // Load Profile content
        await this.loadProfileContent(contentColumn.getContainer());

        columnLayout.appendChild(contentWrapper);
        
        // Mount modal form and layout
        this.modalForm.mount(container);
        container.appendChild(columnLayout);

        // Add mobile nav buttons
        this.mobileNavButtons.mount(container);
    }

    private async loadProfileContent(container: HTMLElement) {
        container.innerHTML = '';
        
        // Create main container with padding
        const mainContent = document.createElement('div');
        mainContent.className = 'max-w-3xl mx-auto px-4 py-6 space-y-6 pb-24';
        
        // Add content title with user's name
        const contentTitle = new ContentTitle('User Profile', 'profile');
        contentTitle.mount(mainContent);
        
        const profileContent = new OtherProfileContent(this.modalForm);
        profileContent.mount(mainContent);
        profileContent.updateContent((await AuthService.getUserById(this.userId))!);
        
        container.appendChild(mainContent);
    }

    public unmount(): void {
        if (this.container) {
            this.container.innerHTML = '';
        }
        
        this.container = null;
        this.modalForm.unmount();
        this.yourBig5?.unmount();
        this.mobileNavButtons.unmount();
    }
}