import { ModalForm } from '../reusable/modal-form';
import { NavColumn } from '../general/nav-column';
import { YourBig5 } from '../general/your-big-5';

export class MobileNavButtons {
    private element: HTMLElement;
    private modalForm: ModalForm;
    private yourBig5: YourBig5;
    private container: HTMLElement | null = null;
    private resizeObserver: ResizeObserver;

    private createMobileNavContent(): HTMLElement {
        const mobileNav = document.createElement('div');
        mobileNav.className = 'flex flex-col w-full';

        const navList = document.createElement('div');
        navList.className = 'flex flex-col w-full divide-y divide-white/5';
        
        const currentApplet = localStorage.getItem('chosenApplet') || 'home';
        const navItems = [
            {
                name: 'Home',
                icon: 'fa-home',
                color: 'pink',
                href: '/',
                applet: 'home'
            },
            {
                name: 'Nanos',
                icon: 'fa-comments',
                color: 'blue',
                href: '/platochat/nanos',
                applet: 'nanos'
            },
            {
                name: 'Kommin',
                icon: 'fa-k',
                color: 'orange',
                href: '/kommin',
                applet: 'kommin'
            },
            {
                name: 'Nearby',
                icon: 'fa-calendar',
                color: 'purple',
                href: '/nearby',
                applet: 'nearby'
            },
            {
                name: 'Profile',
                icon: 'fa-user',
                color: 'yellow',
                href: '/your-profile',
                applet: 'profile'
            },
            {
                name: 'AI',
                icon: 'fa-robot',
                color: 'emerald',
                href: '/ai',
                applet: 'ai'
            },
            {
                name: 'Icebreakers',
                icon: 'fa-snowflake',
                color: 'sky',
                href: '/platochat/icebreakers',
                applet: 'icebreakers'
            },
            {
                name: 'Upgrade',
                icon: 'fa-bolt',
                color: 'amber',
                href: '/upgrade',
                applet: 'upgrade'
            },
            {
                name: 'Settings',
                icon: 'fa-gear',
                color: 'gray',
                href: '/settings',
                applet: 'settings'
            }
        ];

        navList.innerHTML = navItems.map(item => `
            <a href="${item.href}" 
               class="flex items-center gap-4 p-4 hover:bg-white/5 transition-colors ${
                   currentApplet === item.applet ? `bg-${item.color}-500/10` : ''
               }">
                <i class="fas ${item.icon} text-${item.color}-400 text-lg w-5"></i>
                <span class="text-sm text-${item.color}-400">${item.name}</span>
            </a>
        `).join('');

        mobileNav.appendChild(navList);
        return mobileNav;
    }

    constructor(modalForm: ModalForm, yourBig5: YourBig5) {
        this.element = document.createElement('div');
        this.element.className = 'block sm:hidden fixed bottom-4 right-4 z-50';
        this.modalForm = modalForm;
        this.yourBig5 = yourBig5;
        
        // Initialize resize observer
        this.resizeObserver = new ResizeObserver(this.handleResize.bind(this));
        
        // Container for buttons with glassmorphic effect
        const buttonContainer = document.createElement('div');
        buttonContainer.className = `
            flex gap-2 p-2
            bg-black/40 backdrop-blur-xl
            border border-white/10
            rounded-full
            shadow-lg shadow-black/20
        `;
        
        // Hamburger menu button
        const hamburgerButton = document.createElement('button');
        hamburgerButton.className = `
            w-12 h-12
            flex items-center justify-center
            bg-white/5 hover:bg-white/10
            rounded-full
            text-white
            transition-all duration-300
        `;
        hamburgerButton.innerHTML = '<i class="fas fa-bars"></i>';
        hamburgerButton.addEventListener('click', () => {
            if (!this.container) return;
            const mobileNavContent = this.createMobileNavContent();
            
            this.modalForm.append(mobileNavContent);
            this.modalForm.mount(this.container);
            this.modalForm.open();
        });

        // Discovery button
        const discoveryButton = document.createElement('button');
        discoveryButton.className = `
            w-12 h-12
            flex items-center justify-center
            bg-white/5 hover:bg-white/10
            rounded-full
            text-white
            transition-all duration-300
        `;
        discoveryButton.innerHTML = '<i class="fas fa-compass"></i>';
        discoveryButton.addEventListener('click', () => {
            if (!this.container) return;
            
            const modalWrapper = document.createElement('div');
            modalWrapper.className = `
                w-full max-w-[300px] mx-auto 
                bg-gradient-to-r from-blue-500/10 to-purple-500/10
                backdrop-blur-xl 
                border border-white/10 
                rounded-2xl 
                max-h-[80vh] 
                overflow-y-auto
                p-4
            `;
            
            modalWrapper.appendChild(this.yourBig5.getElement());
            this.modalForm.append(modalWrapper);
            this.modalForm.mount(this.container);
            this.modalForm.open();
        });

        buttonContainer.append(hamburgerButton, discoveryButton);
        this.element.appendChild(buttonContainer);
    }

    private handleResize(): void {
        if (window.innerWidth >= 640) { // sm breakpoint
            this.modalForm.close();
        }
    }

    public mount(container: HTMLElement) {
        this.container = container;
        container.appendChild(this.element);
        this.modalForm.mount(container);
        
        // Start observing viewport changes
        this.resizeObserver.observe(document.body);
    }

    public unmount() {
        this.container = null;
        this.element.remove();
        this.modalForm.unmount();
        this.resizeObserver.disconnect();
    }
}