import { NavColumn } from '../../pieces/general/nav-column';
import { ContentColumn } from '../../pieces/general/content-column';
import { DiscoveryColumn } from '../../pieces/general/discovery-column';
import { ContentTitle } from '../../pieces/reusable/content-title';
import { ModalForm } from '../../pieces/reusable/modal-form';
import { YourBig5 } from '../../pieces/general/your-big-5';
import { MobileNavButtons } from '../../pieces/reusable/mobile-nav-buttons';

interface PricingTier {
    name: string;
    price: string;
    description: string;
    icon: string;
}

export class ColumnUpgradeScreen {
    private element: HTMLElement;
    private currentTier: string;
    private modalForm: ModalForm;
    private yourBig5: YourBig5;
    private mobileNavButtons: MobileNavButtons;

    constructor(currentTier: string = 'Free Tier') {
        this.element = document.createElement('div');
        this.element.className = 'min-h-screen';
        this.currentTier = currentTier;
        this.modalForm = new ModalForm();
        this.yourBig5 = new YourBig5(() => {});
        this.mobileNavButtons = new MobileNavButtons(this.modalForm, this.yourBig5);
        this.initialize();
    }

    private initialize(): void {
        // Set chosen applet
        localStorage.setItem('chosenApplet', 'upgrade');

        // Create layout
        const columnLayout = document.createElement('div');
        columnLayout.className = 'flex min-h-screen h-screen overflow-hidden';

        // Mobile nav buttons container
        this.mobileNavButtons.mount(this.element);

        // Content wrapper
        const contentWrapper = document.createElement('div');
        contentWrapper.className = 'flex sm:flex-row flex-col flex-1 overflow-hidden';

        // Mount columns
        const navColumn = new NavColumn(this.modalForm);
        const contentColumn = new ContentColumn();
        const discoveryColumn = new DiscoveryColumn(this.modalForm, this.yourBig5);

        // Only mount nav column on desktop
        const navElement = navColumn.getElement();
        navElement.className = 'hidden sm:block ' + navElement.className;
        navColumn.mount(columnLayout);

        contentColumn.mount(contentWrapper);
        
        // Hide discovery column on mobile
        const discoveryElement = discoveryColumn.getElement();
        discoveryElement.className = 'hidden sm:block discovery-column ' + discoveryElement.className;
        discoveryColumn.mount(contentWrapper);

        columnLayout.appendChild(contentWrapper);
        this.element.appendChild(columnLayout);

        // Initialize upgrade content in the content column's scrollable container
        const contentContainer = contentColumn.getContainer();
        contentContainer.className = 'flex-1 overflow-y-auto';

        const mainContent = document.createElement('div');
        mainContent.className = 'w-full max-w-[640px] mx-auto px-4 py-4';

        // Add title
        const contentTitle = new ContentTitle('Upgrade PLATO5', 'upgrade');
        contentTitle.mount(mainContent);

        // Add Coming Soon Banner
        const comingSoonBanner = document.createElement('div');
        comingSoonBanner.className = 'mb-6 p-4 rounded-lg bg-purple-500/20 border border-purple-500/30 text-center';
        comingSoonBanner.innerHTML = `
            <div class="text-xl font-bold text-purple-400 mb-1">COMING SOON</div>
            <div class="text-white/70 text-sm">Premium subscriptions are currently in development</div>
        `;
        mainContent.appendChild(comingSoonBanner);

        // Pricing Grid - changed to vertical stack with disabled state
        const pricingGrid = document.createElement('div');
        pricingGrid.className = 'flex flex-col gap-8 mt-6 opacity-75';

        this.getPricingTiers().forEach((tier) => {
            const panel = this.createPricingPanel(tier);
            panel.classList.add('cursor-not-allowed');
            panel.style.pointerEvents = 'none';
            pricingGrid.appendChild(panel);
        });
        mainContent.appendChild(pricingGrid);

        // Feature Comparison with disabled state
        const featureSection = this.createFeatureComparison();
        featureSection.classList.add('opacity-75');
        mainContent.appendChild(featureSection);

        contentContainer.appendChild(mainContent);
    }

    private createPricingPanel(tier: PricingTier, isFullFeatured: boolean = false): HTMLElement {
        const panel = document.createElement('div');
        const isPremiumTier = tier.name !== 'Free Tier';
        
        panel.className = `
            group
            relative
            p-6
            rounded-lg
            backdrop-blur-sm
            transition-all
            duration-500
            cursor-pointer
            hover:transform hover:scale-[1.02]
            ${isPremiumTier 
                ? 'bg-black/40 border border-purple-500/30 hover:border-purple-500/50' 
                : 'bg-black/30 border border-white/5 hover:border-white/20'}
        `;

        // Add badge if current plan
        if (tier.name === this.currentTier) {
            panel.innerHTML = `
                <div class="absolute -top-4 left-1/2 transform -translate-x-1/2">
                    <span class="bg-gradient-to-r from-emerald-500 to-teal-500 text-white text-sm py-1 px-4 rounded-full">
                        Current Plan
                    </span>
                </div>
            `;
        }
        panel.addEventListener('click', () => {
            // Remove selected state from all panels
            const allPanels = this.element.querySelectorAll('.pricing-panel');
            allPanels.forEach(p => {
                p.classList.remove('selected-plan', 'ring-2', 'ring-purple-500', 'bg-black/60');
                const checkmark = p.querySelector('.selected-checkmark');
                if (checkmark) checkmark.remove();
            });
            
            // Add selected state to clicked panel
            panel.classList.add('selected-plan', 'ring-2', 'ring-purple-500', 'bg-black/60');
            
            // Add checkmark to selected panel
            const checkmark = document.createElement('div');
            checkmark.className = 'selected-checkmark absolute -top-3 -right-3 bg-purple-500 rounded-full p-1';
            checkmark.innerHTML = `
                <svg class="w-4 h-4 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                </svg>
            `;
            panel.appendChild(checkmark);
            
            // Update the FAB
            this.updateFAB(true);
        });
        // Add pricing-panel class for selection handling
        panel.classList.add('pricing-panel');

        const features: Record<'Free Tier' | 'PLATO Premier' | 'PLATO Platinum', string[]> = {
            'Free Tier': [
                //'Regular nanos (4 participants)',
                'Post 3 stories',
                'Steward & Zen AI conversation assistants',
                'Basic Kommin discussion board access',
                'Personality, Interest, Location matchmaking',
                'No chat history for AI suggestions',
                '3 image gallery'
            ],
            'PLATO Premier': [
                //'Double nanos (8 participants)',
                'Post 5 stories',
                'Steward, Zen, Plato, Cyba, Gertrude AI conversation assistants',
                'Disable Gamification',
                'Verified-only matching',
                'Basic chat history for AI suggestions',
                '2 free monthly events in PLATO5 Nearby'
            ],
            'PLATO Platinum': [
                //'Unlimited nanos (unlimited participants)',
                'Post 10 stories',
                'Steward, Zen, Plato, Cyba, Gertrude AI conversation assistants',
                'Create Corners & Groups',
                'Extended chat history for AI suggestions',
                'Unlimited events in PLATO5 Nearby',
                'AI discussion writing'
            ]
        };
        panel.innerHTML += `
            <div class="text-2xl mb-6 transition-transform duration-300 group-hover:scale-110 group-hover:transform opacity-75 text-center">${tier.icon}</div>
            <h3 class="text-2xl font-bold mb-2 text-white/90 text-center">${tier.name}</h3>
            <div class="text-3xl font-bold mb-4 ${tier.name === 'PLATO Platinum' ? 'text-purple-300' : 'text-white/80'} text-center">${tier.price}</div>
            
            <div class="h-px w-full bg-gradient-to-r ${isFullFeatured 
                ? 'from-transparent via-purple-500/30 to-transparent' 
                : 'from-transparent via-white/10 to-transparent'} mb-6"></div>

            <p class="text-sm text-white/70 leading-relaxed mb-6 text-center">${tier.description}</p>

            <ul class="text-sm space-y-3">
                ${features[tier.name as keyof typeof features].map(feature => `
                    <li class="flex items-center text-white/70">
                        <svg class="w-4 h-4 mr-2 ${isFullFeatured ? 'text-purple-400' : 'text-blue-400'}" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                        </svg>
                        ${feature}
                    </li>
                `).join('')}
            </ul>
        `;
        panel.addEventListener('click', () => {
            // Remove selected state from all panels
            this.element.querySelectorAll('.pricing-panel').forEach(p => {
                p.classList.remove('selected-plan');
                p.classList.remove('border-purple-500/50', 'bg-black/60');
            });
            
            // Add selected state to clicked panel
            panel.classList.add('selected-plan');
            panel.classList.add('border-purple-500/50', 'bg-black/60');
            
            // Update the FAB
            this.updateFAB(true);
        });

        return panel;
    }

    private createFeatureComparison(): HTMLElement {
        const section = document.createElement('div');
        section.className = 'mt-16';
        
        section.innerHTML = `
            <h2 class="text-2xl font-bold text-center mb-8">
                <span class="bg-gradient-to-r from-blue-400 via-purple-400 to-fuchsia-400 bg-clip-text text-transparent">
                    Compare Features
                </span>
            </h2>
            
            <div class="grid grid-cols-4 gap-4 text-sm backdrop-blur-sm bg-black/20 rounded-xl p-6">
                <!-- Feature comparison table content -->
                ${this.getFeatureComparisonContent()}
            </div>
        `;

        return section;
    }

    private getFeatureComparisonContent(): string {
        const features = [
            //{ name: 'Nano Size', free: '4 participants', premier: '8 participants', platinum: 'Unlimited participants' },
            { name: 'Stories', free: '3 stories', premier: '5 stories', platinum: '10 stories' },
            { name: 'AI Assistants', free: 'Steward & Zen (10 suggestions per month)', premier: 'Steward, Zen, Plato, Cyba, Gertrude (50 suggestions per month)', platinum: 'Steward, Zen, Plato, Cyba, Gertrude (200 suggestions per month)' },
            { name: 'AI Chat History', free: 'No history', premier: 'Basic history', platinum: 'Extended history' },
            //{ name: 'AI Discussion Writing', free: '-', premier: '-', platinum: '✓' },
            { name: 'Kommin Access', free: 'Basic', premier: 'Basic', platinum: 'Create Corners & Groups' },
            { name: 'Feature Suggestions', free: '-', premier: '✓', platinum: '✓' },
            { name: 'Matchmaking', free: 'Standard', premier: 'Verified-only', platinum: 'Verified-only' },
            { name: 'Photo Gallery', free: '3 images', premier: '3 images', platinum: 'Extended (10 images)' },
            //{ name: 'Event Posts', free: '-', premier: '2 free monthly', platinum: 'Unlimited' },
            { name: 'Gamification', free: 'Enabled', premier: 'Optional', platinum: 'Optional' }
        ];

        return `
            <div class="text-white/50 font-medium pb-6">Features</div>
            <div class="text-center font-medium pb-6">
                <span class="text-blue-400 block mb-1">Free</span>
                <span class="text-xs text-white/40">$0/month</span>
            </div>
            <div class="text-center font-medium pb-6">
                <span class="text-pink-400 block mb-1">Premier</span>
                <span class="text-xs text-white/40">$9.99/month</span>
            </div>
            <div class="text-center font-medium pb-6">
                <span class="text-purple-400 block mb-1">Platinum</span>
                <span class="text-xs text-white/40">$14.99/month</span>
            </div>
            ${features.map((feature, index) => `
                <div class="py-4 text-white/70 border-t border-white/5 flex items-center">${feature.name}</div>
                <div class="py-4 text-center text-white/70 border-t border-white/5 flex items-center justify-center">
                    ${feature.free === '✓' ? 
                        '<svg class="w-6 h-6 text-blue-400 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>' : 
                        feature.free === '-' ? 
                        '<span class="text-white/30">—</span>' : 
                        feature.free
                    }
                </div>
                <div class="py-4 text-center text-white/70 border-t border-white/5 flex items-center justify-center">
                    ${feature.premier === '✓' ? 
                        '<svg class="w-6 h-6 text-pink-400 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>' : 
                        feature.premier === '-' ? 
                        '<span class="text-white/30">—</span>' : 
                        feature.premier
                    }
                </div>
                <div class="py-4 text-center text-white/70 border-t border-white/5 flex items-center justify-center">
                    ${feature.platinum === '✓' ? 
                        '<svg class="w-6 h-6 text-purple-400 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>' : 
                        feature.platinum === '-' ? 
                        '<span class="text-white/30">—</span>' : 
                        feature.platinum
                    }
                </div>
            `).join('')}
        `;
    }

    private updateFAB(isSelected: boolean): void {
        const fab = this.element.querySelector('.fixed.bottom-8.right-8');
        if (!fab) return;

        const userId = localStorage.getItem('userId');
        if (!userId) {
            fab.innerHTML = `
                <button class="
                    group
                    relative
                    px-8
                    py-4
                    rounded-full
                    font-bold
                    text-white/70
                    shadow-lg
                    transition-all
                    duration-300
                    bg-white/5
                    backdrop-blur-sm
                    border
                    border-white/10
                    flex
                    items-center
                    gap-2
                ">
                    <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"></path>
                    </svg>
                    <span class="relative z-10">Login to update your PLATO5 Subscription</span>
                </button>
            `;
            return;
        }

        // Get the currently selected plan
        const selectedPanel = this.element.querySelector('.selected-plan');
        const selectedPlanName = selectedPanel?.querySelector('h3')?.textContent;
        const isPremiumPlan = selectedPlanName === 'PLATO Platinum' || selectedPlanName === 'PLATO Premier';

        if (isSelected) {
            fab.innerHTML = `
                <button class="
                    group
                    relative
                    px-8
                    py-4
                    rounded-full
                    font-bold
                    text-white/70
                    shadow-lg
                    transition-all
                    duration-300
                    bg-white/5
                    backdrop-blur-sm
                    border
                    border-white/10
                    cursor-not-allowed
                ">
                    <span class="relative z-10">COMING SOON</span>
                </button>
            `;
        } else {
            fab.innerHTML = `
                <button class="
                    group
                    relative
                    px-8
                    py-4
                    rounded-full
                    font-bold
                    text-white/70
                    shadow-lg
                    transition-all
                    duration-300
                    bg-white/5
                    backdrop-blur-sm
                    border
                    border-white/10
                    flex
                    items-center
                    gap-2
                ">
                    <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-2-2v2M7 11.5V7a5 5 0 0110 0v4.5"></path>
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 11.5h14a2 2 0 012 2V19a2 2 0 01-2 2H5a2 2 0 01-2-2v-5.5a2 2 0 012-2z"></path>
                    </svg>
                    <span class="relative z-10">Select a Plan to Continue</span>
                </button>
            `;
        }
    }

    private getPricingTiers(): PricingTier[] {
        return [
            {
                name: "PLATO Platinum",
                price: "$14.99/month",
                description: "Ultimate access with exclusive features, premium support, and early access to upcoming features. Ideal for power users and community leaders.",
                icon: `
                    <div class="relative w-12 h-12 mx-auto">
                        <div class="absolute inset-0 bg-gradient-to-r from-violet-500 via-purple-500 to-fuchsia-500 clip-hexagon"></div>
                    </div>
                `
            },
            {
                name: "PLATO Premier",
                price: "$9.99/month",
                description: "Enhanced experience with unlimited matches, priority access to new features, and advanced analytics. Perfect for active users seeking deeper connections.",
                icon: `
                    <div class="relative w-12 h-12 mx-auto">
                        <div class="absolute inset-0 bg-gradient-to-br from-purple-500 via-pink-500 to-orange-500" style="clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)"></div>
                    </div>
                `
            },
            {
                name: "Free Tier",
                price: "$0/month",
                description: "Perfect for getting started with PLATO5. Access to basic features including PlatoChat, Kommin, and personality assessments.",
                icon: `
                    <div class="relative w-12 h-12 mx-auto">
                        <div class="absolute inset-0 bg-gradient-to-r from-blue-500 to-cyan-500 rounded-full"></div>
                    </div>
                `
            }
        ];
    }

    public mount(parent: HTMLElement): void {
        parent.appendChild(this.element);
    }

    public unmount(): void {
        this.element.remove();
    }
}
