export class UpdateSubscriptionContent {
    private element: HTMLElement;
    private currentPlan: string;

    constructor(currentPlan: string = 'Free Tier') {
        this.element = document.createElement('div');
        this.element.className = 'p-6';
        this.currentPlan = currentPlan;
        this.initialize();
    }

    private initialize(): void {
        const container = document.createElement('div');
        container.className = 'max-w-4xl mx-auto';

        const comingSoonBanner = document.createElement('div');
        comingSoonBanner.className = 'mb-6 p-4 rounded-lg bg-purple-500/20 border border-purple-500/30 text-center';
        comingSoonBanner.innerHTML = `
            <div class="text-xl font-bold text-purple-400 mb-1">Coming Soon</div>
            <div class="text-white/70 text-sm">Premium subscriptions are currently in development</div>
        `;
        container.appendChild(comingSoonBanner);

        const title = document.createElement('h2');
        title.className = 'text-2xl font-bold mb-3 text-white/90';
        title.textContent = 'Update Subscription';
        container.appendChild(title);

        const supportMessage = document.createElement('div');
        supportMessage.className = 'mb-8 text-white/70 text-sm leading-relaxed';
        supportMessage.innerHTML = `
            Please help support PLATO5. We're growing rapidly and your subscription helps us maintain 
            high-quality discussions, develop new features, and keep our community thriving. 
            Join our premium tiers to unlock enhanced capabilities and support our mission of 
            fostering meaningful conversations.
        `;

        const currentPlanElement = document.createElement('div');
        currentPlanElement.className = 'mb-8 p-4 rounded-lg bg-white/5 border border-white/10';
        currentPlanElement.innerHTML = `
            <div class="text-sm text-white/50 mb-1">Current Plan</div>
            <div class="text-lg font-semibold text-white/90">${this.currentPlan}</div>
        `;
        container.appendChild(currentPlanElement);

        container.appendChild(supportMessage);

        const plans = [
            { 
                name: 'PLATO Platinum', 
                price: '$14.99/month', 
                color: 'purple',
                features: [
                    'Unlimited nanos',
                    'Extended chat history',
                    'Create Corners & Groups',
                    'AI discussion writing',
                    'Unlimited events'
                ]
            },
            { 
                name: 'PLATO Premier', 
                price: '$9.99/month', 
                color: 'pink',
                features: [
                    'Double nanos (8 participants)',
                    'All AI assistants',
                    'Verified-only matching',
                    'Basic chat history',
                    '2 free monthly events'
                ]
            },
            { 
                name: 'Free Tier', 
                price: '$0/month', 
                color: 'blue',
                features: [
                    'Regular nanos (4 participants)',
                    'Steward & Zen AI assistants',
                    'Basic Kommin access',
                    'Standard matchmaking',
                    '3 image gallery'
                ]
            }
        ];

        const plansList = document.createElement('div');
        plansList.className = 'space-y-4';

        plans.forEach(plan => {
            const planOption = document.createElement('div');
            planOption.className = `
                p-4 rounded-lg bg-black/20 border border-white/10 
                opacity-75
                transition-all duration-200
                ${this.currentPlan === plan.name ? `border-${plan.color}-500/30 bg-black/40` : ''}
            `;

            const planContent = document.createElement('div');
            planContent.innerHTML = `
                <div class="flex justify-between items-center mb-3">
                    <div>
                        <div class="font-semibold text-white/90">${plan.name}</div>
                        <div class="text-sm text-white/50">${plan.price}</div>
                    </div>
                    ${this.currentPlan === plan.name ? `
                        <span class="text-${plan.color}-400 text-sm">Current Plan</span>
                    ` : ''}
                </div>
                <ul class="space-y-2 mb-4">
                    ${plan.features.map(feature => `
                        <li class="flex items-center text-sm text-white/70">
                            <svg class="w-4 h-4 mr-2 text-${plan.color}-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                            </svg>
                            ${feature}
                        </li>
                    `).join('')}
                </ul>
            `;

            const button = document.createElement('button');
            button.className = `w-full py-2 px-4 rounded-md bg-${plan.color}-500/20
                text-${plan.color}-400 cursor-not-allowed opacity-50`;
            button.textContent = 'Coming Soon';
            button.disabled = true;

            planOption.appendChild(planContent);
            planOption.appendChild(button);
            plansList.appendChild(planOption);
        });

        container.appendChild(plansList);
        this.element.appendChild(container);
    }

    public getElement(): HTMLElement {
        return this.element;
    }

    public mount(parent: HTMLElement): void {
        parent.appendChild(this.element);
    }
}