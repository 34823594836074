import { UINotifications } from '../../utilities/errors';
import { AuthService } from '../auth/api-services';
import { StoryResponse, StoryCollectionResponse } from './models';

const API_URL = import.meta.env.VITE_AUTH_API_URL
const API_KEY = import.meta.env.VITE_API_KEY

export class StoryService {
    private static getHeaders(): Record<string, string> {
        const baseHeaders = {
            'X-API-Key': API_KEY
        };

        const sessionData = AuthService.getSessionData();
        if (sessionData) {
            return {
                ...baseHeaders,
                'X-Session-ID': sessionData.session_id,
                'X-Public-Key': sessionData.public_key,
                'X-User-ID': sessionData.userId?.toString() || ''
            };
        }

        return baseHeaders;
    }

    static async createStory(imageFile: File): Promise<StoryResponse | null> {
        try {
            const formData = new FormData();
            formData.append('image', imageFile);

            const response = await fetch(`${API_URL}/create-story`, {
                method: 'POST',
                headers: this.getHeaders(),
                body: formData
            });

            const data = await response.json();
            if (!response.ok) throw new Error(data.error || 'Failed to create story');

            return data.story;
        } catch (error) {
            UINotifications.shipErrorToUI('Failed to create story');
            return null;
        }
    }

    static async getActiveStories(userId: number): Promise<StoryResponse[] | null> {
        try {
            const response = await fetch(`${API_URL}/get-active-stories?userId=${userId}`, {
                headers: this.getHeaders()
            });

            const data = await response.json();
            if (!response.ok) throw new Error('Failed to fetch active stories');

            return data.stories;
        } catch (error) {
            return null;
        }
    }

    static async getUserStories(userId: number): Promise<StoryResponse[] | null> {
        try {
            const response = await fetch(`${API_URL}/get-user-stories/${userId}`, {
                headers: this.getHeaders()
            });

            const data = await response.json();
            if (!response.ok) throw new Error('Failed to fetch user stories');

            return data.stories;
        } catch (error) {
            return null;
        }
    }

    static async getRecentStories(page: number = 1, limit: number = 10): Promise<{
        stories: StoryResponse[];
        pagination: {
            current: number;
            limit: number;
            total: number;
        };
    } | null> {
        try {
            const response = await fetch(
                `${API_URL}/get-recent-stories?page=${page}&limit=${limit}`,
                { headers: this.getHeaders() }
            );

            const data = await response.json();
            if (!response.ok) throw new Error('Failed to fetch recent stories');

            return {
                stories: data.stories,
                pagination: data.pagination
            };
        } catch (error) {
            return null;
        }
    }


    static async likeStory(storyId: number): Promise<boolean> {
        try {
            const response = await fetch(`${API_URL}/like-story/${storyId}`, {
                method: 'POST',
                headers: this.getHeaders()
            });

            if (!response.ok) throw new Error('Failed to like/unlike story');
            return true;
        } catch (error) {
            return false;
        }
    }

    static async deleteStory(storyId: number): Promise<boolean> {
        try {
            const response = await fetch(`${API_URL}/delete-story/${storyId}`, {
                method: 'DELETE',
                headers: this.getHeaders()
            });

            if (!response.ok) throw new Error('Failed to delete story');
            return true;
        } catch (error) {
            return false;
        }
    }

    static async incrementViews(storyId: number): Promise<boolean> {
        try {
            const response = await fetch(`${API_URL}/increment-story-views/${storyId}`, {
                method: 'POST',
                headers: this.getHeaders()
            });

            if (!response.ok) throw new Error('Failed to increment views');
            return true;
        } catch (error) {
            return false;
        }
    }

    static async checkIfLiked(storyId: number): Promise<boolean> {
        try {
            const response = await fetch(`${API_URL}/check-if-liked/${storyId}`, {
                method: 'GET',
                headers: this.getHeaders()
            });

            if (!response.ok) throw new Error('Failed to check like status');
            const data = await response.json();
            return data.liked;
        } catch (error) {
            return false;
        }
    }

    static async getViewCount(storyId: number): Promise<number> {
        try {
            const response = await fetch(`${API_URL}/display-views/${storyId}`, {
                method: 'GET',
                headers: this.getHeaders()
            });

            if (!response.ok) throw new Error('Failed to get view count');
            const data = await response.json();
            return data.views;
        } catch (error) {
            return 0;
        }
    }

    static async getStoryLikes(storyId: number): Promise<Array<{ id: number, name: string }>> {
        try {
            const response = await fetch(`${API_URL}/story-likes/${storyId}`, {
                method: 'GET',
                headers: this.getHeaders()
            });

            if (!response.ok) throw new Error('Failed to fetch likes');
            const data = await response.json();
            return data.likes;
        } catch (error) {
            return [];
        }
    }
}