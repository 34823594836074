import { KomminService } from '../../actions/kommin/api-services';
import { UINotifications } from '../../utilities/errors';
import { TextInput } from './text-input';
import { Button } from './button';
import { ITagResponse } from '../../actions/kommin/models';
import { AuthService } from '../../actions/auth/api-services';
import { navigate } from '../../app-router';
import { Hash } from '../../utilities/hash';

export class CreateDiscussionContent {
    private selectedITags: string[] = [];
    private maxITags = 5;
    private formContent: HTMLElement | null = null;

    public async createDiscussionForm(): Promise<HTMLElement> {
        this.formContent = document.createElement('div');
        const userId = localStorage.getItem('userId');

        if (!userId) {
            this.formContent.innerHTML = `
                <div class="flex flex-col items-center justify-center p-8 text-center">
                    <div class="w-16 h-16 mb-6 rounded-full bg-blue-500/20 
                        flex items-center justify-center">
                        <i class="fas fa-lock text-2xl text-blue-400"></i>
                    </div>
                    <h2 class="text-xl font-semibold text-white mb-3">
                        Login or Signup to Start a Conversation
                    </h2>
                    <p class="text-white/60 max-w-md">
                        Join our community to create discussions and connect with others.
                    </p>
                </div>
            `;
            return this.formContent;
        }

        this.formContent.innerHTML = `
            <div>
                <div class="flex items-center gap-3 mb-6">
                    <i class="fas fa-pen-to-square text-orange-400 text-xl"></i>
                    <h2 class="text-white text-xl font-semibold">New Komm</h2>
                </div>

                <div class="grid grid-cols-1 sm:grid-cols-2 gap-3 mb-6">
                    <select class="
                        w-full px-4 py-2 rounded-lg
                        bg-purple-500/20 text-purple-400/50
                        border border-purple-500/30
                        cursor-not-allowed
                    " disabled>
                        <option value="">Add to Corner (Coming Soon)</option>
                    </select>

                    <select class="
                        w-full px-4 py-2 rounded-lg
                        bg-blue-500/20 text-blue-400/50
                        border border-blue-500/30
                        cursor-not-allowed
                    " disabled>
                        <option value="">Add to Group (Coming Soon)</option>
                    </select>
                </div>

                <div class="relative">
                    <input 
                        type="text"
                        id="discussion-title" 
                        class="w-full p-4 border rounded-lg mb-1
                               text-white bg-black/20 
                               border-white/10 focus:border-white/20
                               placeholder-white/40"
                        placeholder="Give your discussion a title..."
                        maxlength="70"
                    >
                    <div class="flex justify-end mb-4">
                        <span id="title-char-count" class="text-xs text-white/40">
                            0/70 characters
                        </span>
                    </div>
                </div>

                <div class="relative">
                    <textarea 
                        id="discussion-body" 
                        class="w-full p-4 border rounded-lg mb-1
                               text-white bg-black/20 
                               border-white/10 focus:border-white/20
                               placeholder-white/40"
                        placeholder="What's on your mind?"
                        maxlength="500"
                    ></textarea>
                    <div class="flex justify-end mb-4">
                        <span id="body-char-count" class="text-xs text-white/40">
                            0/500 characters
                        </span>
                    </div>
                </div>

                <p class="text-sm text-white/60 mb-2">
                    Add 1-5 iTags to help others find your discussion
                </p>

                <div class="flex items-center gap-2 mb-4">
                    <div class="flex-grow" id="custom-itag-input"></div>
                    <button id="add-custom-itag" class="
                        bg-blue-500/20 text-blue-400
                        border border-blue-500/30
                        rounded px-3 py-1.5
                    ">Add</button>
                </div>

                <div id="selected-itags" class="flex flex-wrap gap-2 mb-4">
                    <!-- Selected iTags will be inserted here -->
                </div>

                <div class="border-t border-white/10 pt-4">
                    <h3 class="text-sm font-medium text-white/60 mb-2">Suggested iTags</h3>
                    <div id="default-itags" class="flex flex-wrap gap-2 mb-4">
                        <!-- Default iTags will be inserted here -->
                    </div>
                </div>
            </div>
        `;

        const createDiscussionButton = new Button('Create Komm');
        createDiscussionButton.getElement().className = `
            mt-4 px-6 py-3 rounded-full
            bg-gradient-to-r from-orange-500 to-orange-600
            text-white font-medium
            hover:from-orange-600 hover:to-orange-700
            active:from-orange-700 active:to-orange-800
            transform transition-all duration-200
            hover:scale-[1.02] active:scale-[0.98]
            shadow-lg shadow-orange-500/20
            hover:shadow-xl hover:shadow-orange-500/30
            w-full sm:w-auto
        `;
        createDiscussionButton.mount(this.formContent);

        const defaultITagsContainer = this.formContent.querySelector('#default-itags') as HTMLElement;
        const addCustomITagButton = this.formContent.querySelector('#add-custom-itag') as HTMLElement;

        // Fetch and display default iTags
        const defaultITags = await this.getDefaultTags();
        this.displayITagResults(defaultITagsContainer, defaultITags);

        const customITagInput = new TextInput('Custom iTags...');
        const inputElement = customITagInput.getElement().querySelector('input');
        if (inputElement) {
            inputElement.addEventListener('keydown', (e: KeyboardEvent) => {
                if (e.key === ' ' || e.keyCode === 32) {
                    e.preventDefault();
                }
            });
            
            inputElement.addEventListener('change', (e: Event) => {
                const target = e.target as HTMLInputElement;
                target.value = target.value.replace(/\s/g, "");
            });
        }
        this.formContent.querySelector('#custom-itag-input')?.appendChild(customITagInput.getElement());

        addCustomITagButton.addEventListener('click', () => {
            const customTag = customITagInput.getValue().trim().replace(/\s+/g, '');
            if (customTag && !this.selectedITags.includes(customTag)) {
                if (this.selectedITags.length < this.maxITags) {
                    this.selectedITags.push(customTag);
                    this.updateSelectedITagsDisplay(this.formContent);
                    customITagInput.clear();
                    // Scroll to selected tags
                    const selectedTagsSection = this.formContent?.querySelector('#selected-itags');
                    selectedTagsSection?.scrollIntoView({ behavior: 'smooth' });
                } else {
                    UINotifications.shipHintToUI("You've hit the maximum of 5 iTags");
                }
            }
        });

        createDiscussionButton.onClick(async () => {
            if (!this.formContent) return;
            
            const titleInput = this.formContent.querySelector<HTMLInputElement>('#discussion-title');
            const bodyInput = this.formContent.querySelector<HTMLTextAreaElement>('#discussion-body');
            
            const title = titleInput?.value.trim();
            const body = bodyInput?.value.trim();
            
            if (!title || !body || this.selectedITags.length === 0) {
                UINotifications.shipHintToUI("Please enter a title, discussion body and select at least one iTag.");
                return;
            }

            try {
                const userId = localStorage.getItem('userId');
                if (!userId) {
                    UINotifications.shipErrorToUI("You must be logged in to create a discussion");
                    return;
                }

                const userData = await AuthService.getUserById(parseInt(userId));
                if (!userData) {
                    UINotifications.shipErrorToUI("Could not fetch user data");
                    return;
                }

                const iTags: ITagResponse[] = await Promise.all(this.selectedITags.map(async tag => {
                    const itagResponse = await KomminService.getTagByTagName(tag);
                    if (!itagResponse) {
                        throw new Error(`Tag not found: ${tag}`);
                    }
                    return itagResponse;
                }));

                // First create the discussion
                const discussionId = await KomminService.createDiscussion(
                    title,
                    body, 
                    parseInt(userId), 
                    userData.name, 
                    iTags
                );

                if (discussionId) {
                    const hashedId = Hash.encodeId(discussionId);
                    const modalOverlay = this.formContent.closest('.modal-overlay');
                    if (modalOverlay) {
                        modalOverlay.remove();
                    }
                    UINotifications.shipSuccessToUI("Successfully Created Komm");
                    navigate(`/kommin/discussion/${hashedId}/upload-media`);
                }
            } catch (error) {
                console.error('Error creating discussion:', error);
                UINotifications.shipErrorToUI("Failed to create komm");
            }
        });

        // Add character count handlers
        const titleInput = this.formContent.querySelector('#discussion-title') as HTMLInputElement;
        const titleCharCount = this.formContent.querySelector('#title-char-count') as HTMLElement;
        const bodyTextarea = this.formContent.querySelector('#discussion-body') as HTMLTextAreaElement;
        const bodyCharCount = this.formContent.querySelector('#body-char-count') as HTMLElement;

        titleInput.addEventListener('input', () => {
            const count = titleInput.value.length;
            titleCharCount.textContent = `${count}/70 characters`;
            
            if (count > 55) {
                titleCharCount.className = 'text-xs text-orange-400';
            } else {
                titleCharCount.className = 'text-xs text-white/40';
            }
        });

        bodyTextarea.addEventListener('input', () => {
            const count = bodyTextarea.value.length;
            bodyCharCount.textContent = `${count}/500 characters`;
            
            if (count > 450) {
                bodyCharCount.className = 'text-xs text-orange-400';
            } else {
                bodyCharCount.className = 'text-xs text-white/40';
            }
        });

        return this.formContent;
    }

    private async getDefaultTags(): Promise<string[]> {
        try {
            const tags: ITagResponse[] | null = await KomminService.getDefaultTags();
            return tags ? tags.map(tag => tag.Tag) : [];
        } catch (error) {
            console.error('Error fetching default tags:', error);
            return [];
        }
    }

    private displayITagResults(container: HTMLElement | null, itags: string[]) {
        if (container && this.formContent) {
            container.innerHTML = '';
            const colors = [
                'bg-purple-500/20 text-purple-400 border-purple-500/30',
                'bg-orange-500/20 text-orange-400 border-orange-500/30',
                'bg-green-500/20 text-green-400 border-green-500/30',
                'bg-pink-500/20 text-pink-400 border-pink-500/30',
                'bg-yellow-500/20 text-yellow-400 border-yellow-500/30'
            ];
            
            itags.forEach((itag, index) => {
                const itagElement = document.createElement('button');
                const colorClass = colors[index % colors.length];
                itagElement.className = `${colorClass} px-3 py-1.5 rounded-lg border hover:bg-opacity-30`;
                itagElement.textContent = itag;
                itagElement.addEventListener('click', () => {
                    if (this.selectedITags.length < this.maxITags && !this.selectedITags.includes(itag)) {
                        this.selectedITags.push(itag);
                        this.updateSelectedITagsDisplay(this.formContent);
                        // Scroll to selected tags
                        const selectedTagsSection = this.formContent?.querySelector('#selected-itags');
                        selectedTagsSection?.scrollIntoView({ behavior: 'smooth' });
                    } else {
                        UINotifications.shipHintToUI("You've hit the maximum of 5 iTags or the iTag is already selected");
                    }
                });
                container.appendChild(itagElement);
            });
        }
    }

    private updateSelectedITagsDisplay(parent: HTMLElement | null) {
        const itagsContainer = parent?.querySelector('#selected-itags');
        if (itagsContainer) {
            itagsContainer.innerHTML = '';
            this.selectedITags.forEach(tag => {
                const tagElement = document.createElement('span');
                tagElement.className = 'bg-blue-500/20 text-blue-400 border border-blue-500/30 px-3 py-1.5 rounded-lg';
                tagElement.textContent = tag;
                itagsContainer.appendChild(tagElement);
            });
        }
    }
} 