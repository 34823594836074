import { PIMSService } from '../../actions/pims/api-services';
import { AuthService } from '../../actions/auth/api-services';
import { PIMSMatch, SimpleMatchRequest } from '../../actions/pims/models';
import { UINotifications } from '../../utilities/errors';
import { navigate } from '../../app-router';
import { PlatoChatService } from '../../actions/platochat/api-services';
import { Hash } from '../../utilities/hash';
import { CustomNanoContent } from '../platochat/custom-nano-content';

export class SimpleMatchLoadingContent {
    private element: HTMLElement;
    private progress: number = 0;
    private statusMessage: string = "Starting up...";
    private topMatches: Array<{
        userData: any;
        commonInterests: string[];
    }> = [];

    constructor(
        private userId: number,
        private filterByLocation: boolean,
        private filterByInterest: boolean,
        private onComplete?: () => void
    ) {
        this.element = document.createElement('div');
        this.initialize();
    }

    private async initialize() {
        this.render();
        await this.runSimpleMatchingProcess();
    }

    private async runSimpleMatchingProcess() {
        try {
            this.updateStatus("Finding potential matches...", 0.2);
            const userData = await AuthService.getUserById(this.userId);
            if (!userData) {
                this.handleError();
                return;
            }

            this.updateStatus("Checking your previous matches...", 0.4);
            const excludedUserIds = await this.getExcludedUserIds();

            this.updateStatus("Searching for new connections...", 0.6);
            const analytixIds = await PIMSService.postUsersByGeohash(
                userData.geohash,
                excludedUserIds,
                this.filterByLocation
            );
            if (!analytixIds) {
                this.handleError();
                return;
            }

            this.updateStatus("Finding your best matches...", 0.8);
            let topITags: string[] = [];
            if (this.filterByInterest) {
                topITags = await PIMSService.getTopITagsForUserId(this.userId) || [];
            }

            const simpleMatchRequest: SimpleMatchRequest = {
                requestingUserId: this.userId,
                analytixIds,
                iTags: topITags,
                filterInterests: this.filterByInterest,
            };

            const matches = await PIMSService.simpleMatching(simpleMatchRequest);
            if (!matches) {
                this.handleError();
                return;
            }

            await this.processMatches(matches);
            if (this.topMatches.length === 0) {
                this.handleError();
                return;
            }

            this.updateStatus("Found some great matches for you!", 1.0);
            this.render();

        } catch (error) {
            console.error('Simple matching process failed:', error);
            this.handleError();
        }
    }

    private async getExcludedUserIds(): Promise<number[]> {
        const excludedUserIdsResponse = await PIMSService.getRetrieveExcludeUsers(this.userId);
        return excludedUserIdsResponse?.excludedUserIds || [];
    }

    private async processMatches(matches: PIMSMatch[]) {
        for (let i = 0; i < Math.min(matches.length, 3); i++) {
            const matchUserData = await AuthService.getUserById(matches[i].UserID2);
            if (matchUserData) {
                this.topMatches.push({
                    userData: matchUserData,
                    commonInterests: matches[i].CommonInterests || [],
                });
            }
        }
    }

    private updateStatus(message: string, progress: number) {
        this.statusMessage = message;
        this.progress = progress;
        this.render();
    }

    private handleError() {
        this.element.innerHTML = `
            <div class="text-lg text-blue-400 text-center mb-6">
                As an early adopter, there aren't enough users to run the matching algorithm. In the meantime, you can create your own nano below!
            </div>
        `;
        const customNano = new CustomNanoContent((success: boolean) => {
            if (success && this.onComplete) {
                this.onComplete();
            }
        });
        this.element.appendChild(customNano.getElement());
    }
    
    private async handleCreateNano() {
        if (this.topMatches.length >= 3) {
            const [match1, match2, match3] = this.topMatches;
            const nanoId = await PlatoChatService.createNano(
                match1.userData.id,
                match2.userData.id,
                match3.userData.id,
                this.userId
            );

            if (nanoId) {
                UINotifications.shipSuccessToUI('Nano created successfully');
                if (this.onComplete) this.onComplete();
                const hashedId = Hash.encodeId(nanoId);
                navigate(`/platochat/nanos/${hashedId}`);
            } else {
                UINotifications.shipErrorToUI('Failed to create nano. Do you have enough points?');
            }
        } else {
            UINotifications.shipHintToUI("Unfortunately, we couldn't find enough matches for you. Please share the app so more matches can be generated");
        }
    }

    private render() {
        this.element.innerHTML = `
            <div class="space-y-6">
                <div class="text-xl text-white text-center">${this.statusMessage}</div>
                
                <!-- Progress bar -->
                <div class="h-2 bg-white/10 rounded-full overflow-hidden">
                    <div class="h-full bg-blue-500 transition-all duration-300"
                        style="width: ${this.progress * 100}%">
                    </div>
                </div>

                ${this.progress < 1.0 ? `
                    <div class="text-white/60 text-center">Please wait...</div>
                ` : ''}

                ${this.progress === 1.0 && this.topMatches.length > 0 ? `
                    <div class="space-y-4">
                        ${this.topMatches.map(match => `
                            <div class="bg-white/5 rounded-xl p-4 border border-white/10">
                                <div class="font-bold text-white">${match.userData.name}</div>
                                <div class="text-white/60 text-sm mt-2">
                                    <div>Intro: ${match.userData.intro || 'No intro'}</div>
                                    <div>Location: ${match.userData.location || 'Unknown'}</div>
                                    ${match.commonInterests.length ? `
                                        <div>Common Interests: ${match.commonInterests.join(', ')}</div>
                                    ` : ''}
                                </div>
                            </div>
                        `).join('')}

                        <button id="generate-nano" 
                            class="w-full py-2 px-4 bg-blue-500 hover:bg-blue-600 
                            transition-colors rounded-xl text-white font-medium">
                            Generate Nano
                        </button>
                    </div>
                ` : ''}
            </div>
        `;

        // Add event listener for generate nano button
        const generateNanoBtn = this.element.querySelector('#generate-nano');
        generateNanoBtn?.addEventListener('click', () => this.handleCreateNano());
    }

    public getElement(): HTMLElement {
        return this.element;
    }
}