import { TopBar } from '../pieces/reusable/top-bar';
import { ModalForm } from '../pieces/reusable/modal-form';
import { GlassmorphismBox } from '../pieces/reusable/glassmorphism-box';
import { ContentTitle } from '../pieces/reusable/content-title';
import { AuthService } from '../actions/auth/api-services';
import { DiscussionsContent } from '../pieces/kommin/discussions-content';
import { ITagContent } from '../pieces/kommin/itag-content';
import { ProfileContent } from '../pieces/auth/profile-content';
import { NearbyContent } from '../pieces/nearby/nearby-content';
import { SettingsContent } from '../pieces/auth/settings-content';
import { PlatoChatContent } from '../pieces/platochat/platochat-content';
import { AIContent } from '../pieces/ai/ai-content';
import { AppletSelector } from '../pieces/general/applet-selector';
import { DownloadContent } from '../pieces/reusable/download-content';
import { ArticleContent } from '../pieces/tutorial/article-content';
import { AuthContent } from '../pieces/auth/auth-content';
import { YourBig5 } from '../pieces/general/your-big-5';
import { NavColumn } from '../pieces/general/nav-column';
import { ContentColumn } from '../pieces/general/content-column';
import { DiscoveryColumn } from '../pieces/general/discovery-column';
import { WelcomeText } from '../pieces/auth/welcome-text';
import { MeetSomeoneNewButton } from '../pieces/pims/meet-someone-new-button';
import { ArticleRolodex } from '../pieces/tutorial/article-rolodex';
import { Blurb } from '../pieces/general/blurb';
import { MobileNavButtons } from '../pieces/reusable/mobile-nav-buttons';
import { UserFeed } from '../pieces/auth/user-feed';
import { StoryRow } from '../pieces/general/story-row';

export class ColumnViewScreen {
    private container: HTMLElement | null = null;
    private modalForm: ModalForm;
    private yourBig5: YourBig5;
    private mobileNavButtons: MobileNavButtons;

    constructor() {
        this.modalForm = new ModalForm();
        this.yourBig5 = new YourBig5(() => {
            // Empty for now since we're in dashboard view
        });
        this.mobileNavButtons = new MobileNavButtons(this.modalForm, this.yourBig5);
    }

    public async mount(container: HTMLElement) {
        // Set chosen applet
        localStorage.setItem('chosenApplet', 'home');

        this.container = container;
        this.container.innerHTML = '';
        this.container.className = 'min-h-screen';

        // Create layout
        const columnLayout = document.createElement('div');
        columnLayout.className = 'flex min-h-screen h-screen overflow-hidden';

        // Mobile nav buttons container
        this.mobileNavButtons.mount(container);

        // Content wrapper
        const contentWrapper = document.createElement('div');
        contentWrapper.className = 'flex sm:flex-row flex-col flex-1 overflow-hidden';

        // Mount columns
        const navColumn = new NavColumn(this.modalForm);
        const contentColumn = new ContentColumn();
        const discoveryColumn = new DiscoveryColumn(this.modalForm, this.yourBig5);

        // Only mount nav column on desktop
        const navElement = navColumn.getElement();
        navElement.className = 'hidden sm:block ' + navElement.className;
        navColumn.mount(columnLayout);

        contentColumn.mount(contentWrapper);
        
        // Hide discovery column on mobile
        const discoveryElement = discoveryColumn.getElement();
        discoveryElement.className = 'hidden sm:block discovery-column ' + discoveryElement.className;
        discoveryColumn.mount(contentWrapper);

        // Load home content
        await this.loadHomeContent(contentColumn.getContainer());

        columnLayout.appendChild(contentWrapper);
        
        // Mount mobile nav buttons, modal form and layout
        container.appendChild(columnLayout);
    }

    private async loadHomeContent(container: HTMLElement) {
        container.innerHTML = '';
        const userId = localStorage.getItem('userId');

        const mainContent = document.createElement('div');
        mainContent.className = 'max-w-3xl mx-auto px-4 py-6';
        container.appendChild(mainContent);  // Mount immediately
        
        // Mount static content first
        const contentTitle = new ContentTitle('PLATO5 Matching', 'home');
        contentTitle.mount(mainContent);

        const storyRow = new StoryRow();
        storyRow.mount(mainContent);

        if (userId) {
            const welcomeText = new WelcomeText(parseInt(userId));
            welcomeText.mount(mainContent);
        } else {
            const blurb = new Blurb(this.modalForm);
            blurb.mount(mainContent);
        }
        
        const meetSomeoneNewButton = new MeetSomeoneNewButton();
        meetSomeoneNewButton.mount(mainContent);

        // Start loading feed immediately
        const userFeed = new UserFeed();
        mainContent.appendChild(userFeed.createSkeletonFeed());
        userFeed.mount(mainContent).catch(console.error);  // Don't await

        // Continue with rest of content
        const articleRolodex = new ArticleRolodex();
        articleRolodex.mount(mainContent);

        // Add logout button only if logged in
        if (userId) {
            const logoutButton = document.createElement('button');
            logoutButton.className = `
                w-[95%] sm:w-full mx-auto
                py-3 px-4 mb-8
                flex items-center justify-center gap-2
                text-white/50 hover:text-white/70
                transition-colors
                text-sm
            `;
            logoutButton.innerHTML = `
                <i class="fas fa-sign-out-alt"></i>
                <span>Sign Out</span>
            `;
            logoutButton.addEventListener('click', () => {
                localStorage.clear();
                window.location.href = '/';
            });
            mainContent.appendChild(logoutButton);
        }
    }

    public unmount(): void {
        if (this.container) {
            this.container.innerHTML = '';
        }
        
        this.container = null;
        this.modalForm.unmount();
        this.yourBig5?.unmount();
        this.mobileNavButtons.unmount();
    }
}