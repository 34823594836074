import { ModalForm } from '../../pieces/reusable/modal-form';
import { NavColumn } from '../../pieces/general/nav-column';
import { ContentColumn } from '../../pieces/general/content-column';
import { DiscoveryColumn } from '../../pieces/general/discovery-column';
import { YourBig5 } from '../../pieces/general/your-big-5';
import { ContentTitle } from '../../pieces/reusable/content-title';
import { MobileNavButtons } from '../../pieces/reusable/mobile-nav-buttons';
import { navigate } from '../../app-router';
import { KomminService } from '../../actions/kommin/api-services';
import { UINotifications } from '../../utilities/errors';
import { Hash } from '../../utilities/hash';
import { AuthService } from '../../actions/auth/api-services';

export class KomminMediaUploadScreen {
    private container: HTMLElement | null = null;
    private modalForm: ModalForm;
    private yourBig5: YourBig5;
    private mobileNavButtons: MobileNavButtons;
    private discussionId: number;

    constructor(discussionId: number) {
        this.modalForm = new ModalForm();
        this.yourBig5 = new YourBig5(() => {});
        this.mobileNavButtons = new MobileNavButtons(this.modalForm, this.yourBig5);
        this.discussionId = discussionId;
    }

    public async mount(container: HTMLElement) {
        // Check if user is logged in
        const userId = localStorage.getItem('userId');
        if (!userId) {
            navigate('/');
            return;
        }

        // Set chosen applet
        localStorage.setItem('chosenApplet', 'kommin');

        this.container = container;
        this.container.innerHTML = '';
        this.container.className = 'min-h-screen';

        // Create layout
        const columnLayout = document.createElement('div');
        columnLayout.className = 'flex min-h-screen h-screen overflow-hidden';

        // Content wrapper
        const contentWrapper = document.createElement('div');
        contentWrapper.className = 'flex lg:flex-row flex-col flex-1 overflow-hidden';

        // Mount columns
        const navColumn = new NavColumn(this.modalForm);
        const contentColumn = new ContentColumn();
        const discoveryColumn = new DiscoveryColumn(this.modalForm, this.yourBig5);

        // Only mount nav column on desktop
        const navElement = navColumn.getElement();
        navElement.className = 'hidden sm:block ' + navElement.className;
        navColumn.mount(columnLayout);

        contentColumn.mount(contentWrapper);
        
        // Hide discovery column on mobile
        const discoveryElement = discoveryColumn.getElement();
        discoveryElement.className = 'hidden sm:block discovery-column ' + discoveryElement.className;
        discoveryColumn.mount(contentWrapper);

        // Load Media Upload content
        await this.loadMediaUploadContent(contentColumn.getContainer());

        columnLayout.appendChild(contentWrapper);
        
        // Mount modal form and layout
        this.modalForm.mount(container);
        container.appendChild(columnLayout);

        // Add mobile nav buttons
        this.mobileNavButtons.mount(container);
    }

    private async loadMediaUploadContent(container: HTMLElement) {
        container.innerHTML = '';
        
        // Create main container with padding
        const mainContent = document.createElement('div');
        mainContent.className = 'max-w-3xl mx-auto px-4 py-6 space-y-6 pb-24';
        
        // Add content title
        const contentTitle = new ContentTitle('Upload Media', 'kommin');
        contentTitle.mount(mainContent);

        // Create back button
        const backButton = document.createElement('button');
        backButton.className = `
            flex items-center gap-2
            text-white/50 hover:text-white/70
            transition-colors mb-6
            text-sm
        `;
        backButton.innerHTML = `
            <i class="fas fa-arrow-left"></i>
            <span>Back</span>
        `;
        backButton.addEventListener('click', () => {
            const hashedId = Hash.encodeId(this.discussionId);
            navigate(`/kommin/discussion/${hashedId}`);
        });
        mainContent.appendChild(backButton);
        
        // Create upload form
        const uploadForm = document.createElement('div');
        uploadForm.className = 'bg-black/20 backdrop-blur-xl border border-white/10 rounded-xl p-6 shadow-xl';
        uploadForm.innerHTML = `
            <div class="space-y-6">
                <div>
                    <h2 class="text-xl font-medium text-white/90 mb-2">Upload Image</h2>
                    <p class="text-white/50 text-sm">Images will be moderated before becoming visible.</p>
                </div>

                <div id="upload-zone" class="border-2 border-dashed border-white/10 rounded-xl p-8 text-center 
                            hover:border-orange-500/50 transition-colors">
                    <input type="file" id="media-upload" accept="image/jpeg,image/png,image/gif,image/webp,image/gif" class="hidden" />
                    <label for="media-upload" class="cursor-pointer flex flex-col items-center space-y-2">
                        <i class="fas fa-cloud-upload-alt text-2xl text-white/50"></i>
                        <span class="text-white/70">Click to upload image</span>
                        <span class="text-white/30 text-sm">JPG, PNG, GIF or WebP (max 5MB)</span>
                    </label>
                </div>

                <div id="file-preview" class="hidden">
                    <div class="flex items-center gap-2 p-3 bg-white/5 rounded-lg">
                        <i class="fas fa-file-image text-orange-400"></i>
                        <span class="text-white/70 text-sm flex-grow file-name"></span>
                        <button class="text-white/50 hover:text-white/70" id="remove-file">
                            <i class="fas fa-times"></i>
                        </button>
                    </div>
                </div>

                <div class="flex justify-end space-x-4">
                    <button id="cancel-btn" class="px-4 py-2 text-white/70 hover:text-white/90 transition-colors">
                        Cancel
                    </button>
                    <button id="upload-btn" class="px-4 py-2 bg-gradient-to-r from-orange-500 to-orange-600 
                                   text-white rounded-lg disabled:opacity-50" disabled>
                        Upload Image
                    </button>
                </div>
            </div>
        `;

        const fileInput = uploadForm.querySelector('#media-upload') as HTMLInputElement;
        const uploadBtn = uploadForm.querySelector('#upload-btn') as HTMLButtonElement;
        const cancelBtn = uploadForm.querySelector('#cancel-btn') as HTMLButtonElement;

        // Update the file input handler
        const filePreview = uploadForm.querySelector('#file-preview') as HTMLElement;
        const fileNameElement = filePreview.querySelector('.file-name') as HTMLElement;
        const removeFileBtn = uploadForm.querySelector('#remove-file') as HTMLElement;

        fileInput.addEventListener('change', (e) => {
            const file = (e.target as HTMLInputElement).files?.[0];
            if (file) {
                // Validate file type
                const validTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
                if (!validTypes.includes(file.type)) {
                    UINotifications.shipErrorToUI("Invalid file type. Please upload a JPG, PNG, GIF or WebP image.");
                    return;
                }

                // Validate file size (5MB)
                if (file.size > 5 * 1024 * 1024) {
                    UINotifications.shipErrorToUI("File too large. Maximum size is 5MB.");
                    return;
                }

                // Show file preview
                fileNameElement.textContent = file.name;
                filePreview.classList.remove('hidden');
                uploadBtn.disabled = false;
            }
        });

        // Add remove file handler
        removeFileBtn.addEventListener('click', () => {
            fileInput.value = '';
            filePreview.classList.add('hidden');
            uploadBtn.disabled = true;
        });

        // Update upload button handler with additional logging
        uploadBtn.addEventListener('click', async () => {
            const file = fileInput.files?.[0];
            if (file) {
                try {
                    UINotifications.showGlobalLoadingIndication();
                    console.log('Uploading file:', file);  // Debug log
                    
                    // Use the new APIService method instead of KomminService
                    const result = await AuthService.uploadDiscussionImage(this.discussionId, file);
                    
                    if (result) {
                        UINotifications.shipSuccessToUI(
                            "Image uploaded successfully! It will be visible after moderation."
                        );
                        const hashedId = Hash.encodeId(this.discussionId);
                        navigate(`/kommin/discussion/${hashedId}`);
                    } else {
                        throw new Error('Upload failed');
                    }
                } catch (error) {
                    console.error('Upload error:', error);  // Debug log
                    UINotifications.shipErrorToUI("Failed to upload image. Please try again.");
                } finally {
                    UINotifications.hideGlobalLoadingIndication();
                }
            }
        });

        cancelBtn.addEventListener('click', () => {
            window.location.reload();
        });

        // Check for existing images
        const pictures = await AuthService.getDiscussionPictures(this.discussionId);
        if (pictures && pictures.length > 0) {
            const optimizedUrl = pictures[0].ImageURL.replace('/upload/', '/upload/c_fill,w_1280,h_720,q_auto,f_auto/');
            
            const currentImageDiv = document.createElement('div');
            currentImageDiv.className = 'bg-black/20 backdrop-blur-xl border border-white/10 rounded-xl p-6 shadow-xl';
            currentImageDiv.innerHTML = `
                <h3 class="text-lg font-medium text-white/90 mb-4">Current Image</h3>
                <div class="relative aspect-[16/9] rounded-lg overflow-hidden
                            bg-gradient-to-br from-black/40 to-black/20">
                    <img src="${optimizedUrl}" 
                         alt="Current discussion image"
                         class="w-full h-full object-cover object-center" />
                </div>
                <div class="flex justify-end mt-4">
                    <button class="px-4 py-2 text-red-400 hover:text-red-300 transition-colors
                                 flex items-center gap-2">
                        <i class="fas fa-trash-alt"></i>
                        <span>Delete Image</span>
                    </button>
                </div>
            `;
            mainContent.appendChild(currentImageDiv);

            const deleteImageBtn = currentImageDiv.querySelector('button');
            if (deleteImageBtn) {
                deleteImageBtn.addEventListener('click', () => {
                    const confirmContent = document.createElement('div');
                    confirmContent.className = 'flex flex-col gap-6';
                    confirmContent.innerHTML = `
                        <div class="flex items-center gap-3">
                            <i class="fas fa-exclamation-triangle text-red-400"></i>
                            <span class="text-white/90">Delete Image</span>
                        </div>
                        <p class="text-white/60">
                            Are you sure you want to delete this image? This action cannot be undone.
                        </p>
                        <div class="flex items-center justify-end gap-3">
                            <button class="px-4 py-2 text-white/60 hover:text-white/80 transition-colors" id="cancel-btn">
                                Cancel
                            </button>
                            <button class="px-4 py-2 bg-red-500/80 hover:bg-red-500 text-white rounded transition-colors" id="confirm-btn">
                                Delete
                            </button>
                        </div>
                    `;
                    
                    this.modalForm.append(confirmContent);
                    
                    confirmContent.querySelector('#cancel-btn')?.addEventListener('click', () => {
                        this.modalForm.close();
                    });

                    confirmContent.querySelector('#confirm-btn')?.addEventListener('click', async () => {
                        try {
                            UINotifications.showGlobalLoadingIndication();
                            const success = await AuthService.deleteDiscussionImage(this.discussionId, pictures[0].PublicID);
                            if (success) {
                                UINotifications.shipSuccessToUI("Image deleted successfully!");
                                window.location.reload();
                            }
                        } catch (error) {
                            UINotifications.shipErrorToUI("Failed to delete image");
                        } finally {
                            UINotifications.hideGlobalLoadingIndication();
                            this.modalForm.close();
                        }
                    });

                    this.modalForm.open();
                });
            }
        } else {
            mainContent.appendChild(uploadForm);
        }

        // Add voice memo section
        const voiceMemoSection = document.createElement('div');
        voiceMemoSection.className = 'bg-black/20 backdrop-blur-xl border border-white/10 rounded-xl p-6 shadow-xl mt-6';
        voiceMemoSection.innerHTML = `
            <div class="space-y-6">
                <div>
                    <h2 class="text-xl font-medium text-white/90 mb-2">Add Voice Memo</h2>
                    <p class="text-white/50 text-sm">Record a voice memo up to 30 seconds long.</p>
                </div>
                <div class="flex items-center justify-center gap-4">
                    <button id="record-btn" class="w-16 h-16 rounded-full bg-red-500/20 hover:bg-red-500/30 
                                transition-colors flex items-center justify-center">
                        <i class="fas fa-microphone text-2xl text-red-500"></i>
                    </button>
                    <div id="timer" class="text-white/70 text-xl font-mono">30.0</div>
                </div>
                <div id="audio-preview" class="hidden">
                    <audio id="audio-player" controls class="w-full"></audio>
                </div>
                <input type="text" id="caption-input" 
                       class="w-full bg-black/20 border border-white/10 rounded-lg px-4 py-2 
                              text-white/90 focus:border-orange-500/50 outline-none" 
                       placeholder="Add a caption (optional)">
                <div class="flex justify-end space-x-4">
                    <button id="cancel-voice-btn" class="px-4 py-2 text-white/70 hover:text-white/90 transition-colors">
                        Cancel
                    </button>
                    <button id="upload-voice-btn" class="px-4 py-2 bg-gradient-to-r from-orange-500 to-orange-600 
                                   text-white rounded-lg disabled:opacity-50" disabled>
                        Upload Voice Memo
                    </button>
                </div>
            </div>
        `;

        // Check for existing voice memos
        const memos = await AuthService.getDiscussionMemos(this.discussionId);
        if (memos && memos.length > 0) {
            const existingMemoDiv = document.createElement('div');
            existingMemoDiv.className = 'bg-black/20 backdrop-blur-xl border border-white/10 rounded-xl p-6 shadow-xl mt-6';
            existingMemoDiv.innerHTML = `
                <div class="space-y-4">
                    <h3 class="text-lg font-medium text-white/90">Current Voice Memo</h3>
                    <audio src="${memos[0].AudioURL}" controls class="w-full"></audio>
                    ${memos[0].Caption ? `
                        <p class="text-white/70 text-sm italic">"${memos[0].Caption}"</p>
                    ` : ''}
                    <div class="flex justify-end">
                        <button class="text-red-400 hover:text-red-300 transition-colors
                                     flex items-center gap-2 text-sm">
                            <i class="fas fa-trash-alt"></i>
                            <span>Delete Memo</span>
                        </button>
                    </div>
                </div>
            `;
            mainContent.appendChild(existingMemoDiv);

            const deleteMemoBtn = existingMemoDiv.querySelector('button');
            if (deleteMemoBtn) {
                deleteMemoBtn.addEventListener('click', () => {
                    const confirmContent = document.createElement('div');
                    confirmContent.className = 'flex flex-col gap-6';
                    confirmContent.innerHTML = `
                        <div class="flex items-center gap-3">
                            <i class="fas fa-exclamation-triangle text-red-400"></i>
                            <span class="text-white/90">Delete Voice Memo</span>
                        </div>
                        <p class="text-white/60">
                            Are you sure you want to delete this voice memo? This action cannot be undone.
                        </p>
                        <div class="flex items-center justify-end gap-3">
                            <button class="px-4 py-2 text-white/60 hover:text-white/80 transition-colors" id="cancel-btn">
                                Cancel
                            </button>
                            <button class="px-4 py-2 bg-red-500/80 hover:bg-red-500 text-white rounded transition-colors" id="confirm-btn">
                                Delete
                            </button>
                        </div>
                    `;
                    
                    this.modalForm.append(confirmContent);
                    
                    confirmContent.querySelector('#cancel-btn')?.addEventListener('click', () => {
                        this.modalForm.close();
                    });

                    confirmContent.querySelector('#confirm-btn')?.addEventListener('click', async () => {
                        try {
                            UINotifications.showGlobalLoadingIndication();
                            const success = await AuthService.deleteDiscussionMemo(this.discussionId, memos[0].PublicID);
                            if (success) {
                                UINotifications.shipSuccessToUI("Voice memo deleted successfully!");
                                window.location.reload();
                            }
                        } catch (error) {
                            UINotifications.shipErrorToUI("Failed to delete voice memo");
                        } finally {
                            UINotifications.hideGlobalLoadingIndication();
                            this.modalForm.close();
                        }
                    });

                    this.modalForm.open();
                });
            }
        } else {
            mainContent.appendChild(voiceMemoSection);
        }

        // Add voice recording logic
        let mediaRecorder: MediaRecorder | null = null;
        let audioChunks: BlobPart[] = [];
        let recordingTimer: number | null = null;
        let startTime: number;

        const recordBtn = voiceMemoSection.querySelector('#record-btn') as HTMLButtonElement;
        const timer = voiceMemoSection.querySelector('#timer') as HTMLElement;
        const audioPreview = voiceMemoSection.querySelector('#audio-preview') as HTMLElement;
        const audioPlayer = voiceMemoSection.querySelector('#audio-player') as HTMLAudioElement;
        const uploadVoiceBtn = voiceMemoSection.querySelector('#upload-voice-btn') as HTMLButtonElement;
        const captionInput = voiceMemoSection.querySelector('#caption-input') as HTMLInputElement;

        const updateTimer = () => {
            const elapsed = (Date.now() - startTime) / 1000;
            const remaining = Math.max(0, 30 - elapsed);
            timer.textContent = remaining.toFixed(1).padStart(4, '0');
            if (remaining <= 0 && mediaRecorder) {
                mediaRecorder.stop();
            }
        };

        recordBtn.addEventListener('click', async () => {
            if (mediaRecorder?.state === 'recording') {
                mediaRecorder.stop();
                return;
            }

            try {
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                mediaRecorder = new MediaRecorder(stream);
                audioChunks = [];

                mediaRecorder.ondataavailable = (event) => {
                    audioChunks.push(event.data);
                };

                mediaRecorder.onstop = () => {
                    if (recordingTimer) clearInterval(recordingTimer);
                    recordBtn.innerHTML = '<i class="fas fa-microphone text-2xl text-red-500"></i>';
                    
                    const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
                    const audioUrl = URL.createObjectURL(audioBlob);
                    audioPlayer.src = audioUrl;
                    audioPreview.classList.remove('hidden');
                    uploadVoiceBtn.disabled = false;
                };

                mediaRecorder.start();
                startTime = Date.now();
                recordingTimer = window.setInterval(updateTimer, 100);
                recordBtn.innerHTML = '<i class="fas fa-stop text-2xl text-red-500"></i>';
            } catch (err) {
                UINotifications.shipErrorToUI("Unable to access microphone");
            }
        });

        uploadVoiceBtn.addEventListener('click', async () => {
            const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
            const audioFile = new File([audioBlob], 'voice-memo.wav', { type: 'audio/wav' });
            
            try {
                UINotifications.showGlobalLoadingIndication();
                const result = await AuthService.uploadVoiceMemo(this.discussionId, audioFile, captionInput.value);
                if (result) {
                    UINotifications.shipSuccessToUI("Voice memo uploaded successfully!");
                    window.location.reload();
                }
            } catch (error) {
                UINotifications.shipErrorToUI("Failed to upload voice memo");
            } finally {
                UINotifications.hideGlobalLoadingIndication();
            }
        });

        const cancelVoiceBtn = voiceMemoSection.querySelector('#cancel-voice-btn') as HTMLButtonElement;

        cancelVoiceBtn.addEventListener('click', () => {
            window.location.reload();
        });

        container.appendChild(mainContent);
    }

    public unmount(): void {
        if (this.container) {
            this.container.innerHTML = '';
        }
        
        this.container = null;
        this.modalForm.unmount();
        this.yourBig5?.unmount();
        this.mobileNavButtons.unmount();
    }
}