import { Button } from '../reusable/button';
import { AuthService } from '../../actions/auth/api-services';
import { ModalForm } from '../reusable/modal-form';
import { UpdateSubscriptionContent } from '../reusable/update-subscription-content';


export class SettingsContent {
    private element: HTMLDivElement;

    constructor() {
        this.element = document.createElement('div');
        this.element.className = 'space-y-4 p-2';
        
        // Tutorial Video Section (always show)
        const videoSection = this.createSection('Tutorial Video', 'video');
        const videoContainer = document.createElement('div');
        videoContainer.className = 'relative w-full sm:w-[400px] aspect-video rounded-lg overflow-hidden shadow-md ml-0';
        videoContainer.innerHTML = `
            <iframe 
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/YPXsA-axQrc" 
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen
                class="absolute top-0 left-0 w-full h-full"
            ></iframe>
        `;
        videoSection.appendChild(videoContainer);

        // Features Link Section (always show)
        const featuresSection = this.createSection('Features', 'star');
        const featuresLink = document.createElement('a');
        featuresLink.href = '/features';
        featuresLink.className = 'text-purple-400 hover:text-purple-300 transition-colors duration-300 flex items-center gap-2';
        featuresLink.innerHTML = `
            Check out upcoming features here 
            <i class="fas fa-arrow-right text-sm"></i>
        `;
        featuresSection.appendChild(featuresLink);

        // Check if user is logged in
        const userId = localStorage.getItem('userId');
        if (userId) {
            // Toggles Section (only for logged in users)
            const togglesSection = this.createSection('Preferences', 'sliders-h');
            const gamificationToggle = this.createToggle('Enable Gamification', 'gamification');
            const newsletterToggle = this.createToggle('Subscribe to Newsletter', 'newsletter');
            togglesSection.append(gamificationToggle, newsletterToggle);

            // Add email notifications buttons
            const emailNotificationsContainer = document.createElement('div');
            emailNotificationsContainer.className = 'mb-4 p-3 rounded-lg bg-black/40 border border-white/10';

            const emailComingSoonBanner = document.createElement('div');
            emailComingSoonBanner.className = 'text-sm font-bold text-purple-400 mb-3';
            emailComingSoonBanner.textContent = 'Coming Soon';

            const notificationControls = document.createElement('div');
            notificationControls.className = 'flex items-center justify-between';

            const labelElement = document.createElement('span');
            labelElement.className = 'text-white/70';
            labelElement.textContent = 'Email Notifications';

            const buttonsContainer = document.createElement('div');
            buttonsContainer.className = 'flex gap-2';

            const createButton = (text: string) => {
                const button = document.createElement('button');
                button.className = 'px-3 py-1 text-xs rounded-lg text-white/30 cursor-not-allowed';
                button.textContent = text;
                return button;
            };

            const everyButton = createButton('Every Chat');
            const nanosButton = createButton('Added to Nano');
            const noneButton = createButton('Off');

            // Set initial state to Off
            noneButton.className = 'px-3 py-1 text-xs rounded-lg bg-purple-500/20 text-purple-400 transition-all duration-300';

            buttonsContainer.append(everyButton, nanosButton, noneButton);
            notificationControls.append(labelElement, buttonsContainer);
            emailNotificationsContainer.append(emailComingSoonBanner, notificationControls);
            togglesSection.appendChild(emailNotificationsContainer);

            // Set initial state
            noneButton.className = 'px-3 py-1 text-xs rounded-lg bg-purple-500/20 text-purple-400 transition-all duration-300';

            // Verification Section
            const verificationSection = this.createSection('Verification', 'shield-alt');

            // Add Coming Soon banner
            const comingSoonBanner = document.createElement('div');
            comingSoonBanner.className = 'mb-4 p-3 rounded-lg bg-purple-500/20 border border-purple-500/30 text-center';
            comingSoonBanner.innerHTML = `
                <div class="text-sm font-bold text-purple-400 mb-1">Coming Soon</div>
                <div class="text-white/70 text-xs">Verification system is currently in development</div>
            `;
            verificationSection.appendChild(comingSoonBanner);

            const verifyButton = document.createElement('button');
            verifyButton.className = `
                w-full px-4 py-2.5 text-sm 
                text-blue-400/70
                bg-blue-500/20
                rounded-lg transition-all duration-300 
                border border-blue-500/30
                flex items-center justify-center gap-2
                cursor-not-allowed
                opacity-75
            `;
            verifyButton.innerHTML = `
                <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                        d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z">
                    </path>
                </svg>
                Apply for Verification
            `;

            verifyButton.addEventListener('click', () => {
                const modalForm = new ModalForm();
                modalForm.append((() => {
                    const content = document.createElement('div');
                    content.className = 'space-y-4';
                    content.innerHTML = `
                        <h2 class="text-lg font-semibold text-white mb-4">Choose Verification Method</h2>

                        <div class="mb-6 p-4 rounded-lg bg-purple-500/20 border border-purple-500/30 text-center">
                            <div class="text-lg font-bold text-purple-400 mb-1">Coming Soon</div>
                            <div class="text-white/70 text-sm">Verification system is currently in development</div>
                        </div>

                        <div class="space-y-4">
                            <button class="w-full px-4 py-3 bg-black/40 hover:bg-black/50 rounded-lg border border-white/10 flex items-center gap-3">
                                <i class="fab fa-x-twitter text-white"></i>
                                <span class="text-white/90">Verify with X (Twitter) - @PLATO5Social</span>
                            </button>
                            <button class="w-full px-4 py-3 bg-purple-500/20 hover:bg-purple-500/30 rounded-lg border border-purple-500/30 flex items-center gap-3">
                                <i class="fab fa-instagram text-purple-400"></i>
                                <span class="text-white/90">Verify with Instagram - @plato5socialengines</span>
                            </button>
                            <button class="w-full px-4 py-3 bg-[#E60023]/20 hover:bg-[#E60023]/30 rounded-lg border border-[#E60023]/30 flex items-center gap-3">
                                <i class="fab fa-pinterest text-[#E60023]"></i>
                                <span class="text-white/90">Verify with Pinterest - plato5social</span>
                            </button>

                            <div class="relative">
                                <div class="absolute inset-0 flex items-center">
                                    <div class="w-full border-t border-white/10"></div>
                                </div>
                                <div class="relative flex justify-center text-xs uppercase">
                                    <span class="bg-[#0A0A0A] px-2 text-white/40">or</span>
                                </div>
                            </div>

                            <button class="w-full px-4 py-3 bg-white/5 hover:bg-white/10 rounded-lg border border-white/10 flex items-center gap-3">
                                <i class="fas fa-comment-alt text-white/70"></i>
                                <span class="text-white/90">Get Verified Somewhere Else - Contact Support</span>
                            </button>

                            <div class="mt-6 p-4 bg-white/5 rounded-lg border border-white/10">
                                <h3 class="text-sm font-medium text-white/90 mb-2">Important Notes:</h3>
                                <ol class="space-y-2 text-sm text-white/70">
                                    <li class="flex items-start gap-2">
                                        <span class="font-medium text-white/40">1.</span>
                                        <span>Your social media username needs to be clearly connected to your PLATO5 username</span>
                                    </li>
                                    <li class="flex items-start gap-2">
                                        <span class="font-medium text-white/40">2.</span>
                                        <span>You'll receive a DM from our official account shown above</span>
                                    </li>
                                    <li class="flex items-start gap-2">
                                        <span class="font-medium text-white/40">3.</span>
                                        <span>Simply reply with "yes" to confirm your identity</span>
                                    </li>
                                </ol>
                                <div class="mt-4 text-xs text-white/50">
                                    Note: Our official accounts will never ask for personal information or send you any other messages.
                                </div>
                            </div>
                        </div>
                    `;

                    // Add click handlers for each platform
                    const handlePlatformClick = (platform: string, handle: string) => {
                        content.innerHTML = `
                            <div class="space-y-4">
                                <div class="flex items-center gap-3 mb-2">
                                    <button class="back-button p-2 hover:bg-white/5 rounded-lg transition-colors">
                                        <i class="fas fa-arrow-left text-white/70"></i>
                                    </button>
                                    <h2 class="text-lg font-semibold text-white">Verify with ${platform}</h2>
                                </div>
                                
                                <div class="space-y-4">
                                    <div class="p-4 bg-white/5 rounded-lg border border-white/10">
                                        <p class="text-white/70 text-sm mb-4">
                                            Please provide your ${platform} username. Make sure it matches or clearly identifies your PLATO5 username (${localStorage.getItem('username') || 'your username'}).
                                        </p>
                                        <div class="space-y-4">
                                            <div>
                                                <label class="block text-sm font-medium text-white/70 mb-1.5">
                                                    Your ${platform} Username
                                                </label>
                                                <input 
                                                    type="text" 
                                                    placeholder="@username"
                                                    class="w-full px-4 py-2.5 bg-black/40 border border-white/10 rounded-lg text-white/90 text-sm focus:outline-none focus:border-blue-500/50 placeholder:text-white/30"
                                                >
                                            </div>
                                            
                                            <div>
                                                <label class="block text-sm font-medium text-white/70 mb-1.5">
                                                    Notes & Preferences
                                                </label>
                                                <textarea 
                                                    placeholder="Any specific timing preferences for verification or additional notes..."
                                                    class="w-full px-4 py-2.5 bg-black/40 border border-white/10 rounded-lg text-white/90 text-sm focus:outline-none focus:border-blue-500/50 placeholder:text-white/30 h-24 resize-none"
                                                ></textarea>
                                            </div>
                                        </div>

                                        <div class="mt-4 p-3 bg-blue-500/10 border border-blue-500/20 rounded-lg">
                                            <div class="flex items-start gap-2">
                                                <i class="fas fa-info-circle text-blue-400 mt-0.5"></i>
                                                <p class="text-xs text-blue-300">
                                                    You'll receive a verification message from <span class="font-medium">${handle}</span>. 
                                                    Please ensure your DMs are open and your profile name/bio includes your PLATO5 username.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <button 
                                        class="w-full px-4 py-3 text-sm font-medium bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 text-white rounded-lg transition-all duration-300 flex items-center justify-center gap-2 shadow-lg shadow-blue-500/20"
                                    >
                                        <i class="fas fa-check-circle"></i>
                                        Submit for Verification
                                    </button>
                                </div>
                            </div>
                        `;

                        // Add back button handler
                        content.querySelector('.back-button')?.addEventListener('click', () => {
                            // Re-render the original platform selection view
                            modalForm.close();
                            verifyButton.click(); // This will reopen the modal with the initial view
                        });
                    };

                    content.addEventListener('click', (e) => {
                        const target = e.target as HTMLElement;
                        const button = target.closest('button');
                        if (!button) return;

                        if (button.innerHTML.includes('X (Twitter)')) {
                            handlePlatformClick('X', '@PLATO5Social');
                        } else if (button.innerHTML.includes('Instagram')) {
                            handlePlatformClick('Instagram', '@plato5socialengines');
                        } else if (button.innerHTML.includes('Pinterest')) {
                            handlePlatformClick('Pinterest', 'plato5social');
                        }
                    });

                    content.querySelector('button:last-of-type')?.addEventListener('click', () => {
                        content.innerHTML = `
                            <div class="space-y-4">
                                <div class="flex items-center gap-3 mb-2">
                                    <button class="back-button p-2 hover:bg-white/5 rounded-lg transition-colors">
                                        <i class="fas fa-arrow-left text-white/70"></i>
                                    </button>
                                    <h2 class="text-lg font-semibold text-white">Contact Support for Verification</h2>
                                </div>
                                
                                <div class="space-y-4">
                                    <div class="p-4 bg-white/5 rounded-lg border border-white/10">
                                        <p class="text-white/70 text-sm mb-4">
                                            Let us know how you'd prefer to verify your identity, and our support team will work with you to find a solution.
                                        </p>
                                        <div class="space-y-4">
                                            <div>
                                                <label class="block text-sm font-medium text-white/70 mb-1.5">
                                                    Your Social Media Handle
                                                </label>
                                                <input 
                                                    type="text" 
                                                    placeholder="@username"
                                                    class="w-full px-4 py-2.5 bg-black/40 border border-white/10 rounded-lg text-white/90 text-sm focus:outline-none focus:border-blue-500/50 placeholder:text-white/30"
                                                >
                                            </div>

                                            <div>
                                                <label class="block text-sm font-medium text-white/70 mb-1.5">
                                                    Message to Support Team
                                                </label>
                                                <textarea 
                                                    placeholder="Let us know your preferred way to verify your identity..."
                                                    class="w-full px-4 py-2.5 bg-black/40 border border-white/10 rounded-lg text-white/90 text-sm focus:outline-none focus:border-blue-500/50 placeholder:text-white/30 h-32 resize-none"
                                                ></textarea>
                                            </div>
                                        </div>

                                        <div class="mt-4 p-3 bg-blue-500/10 border border-blue-500/20 rounded-lg">
                                            <div class="flex items-start gap-2">
                                                <i class="fas fa-info-circle text-blue-400 mt-0.5"></i>
                                                <p class="text-xs text-blue-300">
                                                    Our support team will review your request and get back to you.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <button 
                                        class="w-full px-4 py-3 text-sm font-medium bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 text-white rounded-lg transition-all duration-300 flex items-center justify-center gap-2 shadow-lg shadow-blue-500/20"
                                    >
                                        <i class="fas fa-paper-plane"></i>
                                        Send Message to Support
                                    </button>
                                </div>
                            </div>
                        `;

                        // Add back button handler
                        content.querySelector('.back-button')?.addEventListener('click', () => {
                            modalForm.close();
                            verifyButton.click();
                        });
                    });

                    return content;
                })());
                modalForm.mount(document.body);
                modalForm.open();
            });

            verificationSection.appendChild(verifyButton);

            // Subscription Section
            const subscriptionSection = this.createSection('Subscription', 'crown');
            const updateSubscriptionButton = new Button('Update Subscription');
            updateSubscriptionButton.getElement().className = `
                w-full px-4 py-2.5 text-sm 
                text-white/70 hover:text-white/90
                bg-white/5 hover:bg-white/10
                rounded-lg transition-all duration-300 
                border border-white/10 hover:border-white/20
                flex items-center justify-center gap-2
            `;
            updateSubscriptionButton.getElement().innerHTML = `
                Update Subscription
            `;
            updateSubscriptionButton.onClick(() => {
                const modalForm = new ModalForm();
                const updateSubscriptionContent = new UpdateSubscriptionContent();
                modalForm.append(updateSubscriptionContent.getElement());
                modalForm.mount(document.body);
                modalForm.open();
            });
            subscriptionSection.appendChild(updateSubscriptionButton.getElement());

            // Account Section with logout (only for logged in users)
            const accountSection = this.createSection('Profile', 'user');
            const logoutButton = new Button('Logout');
            logoutButton.getElement().className = `
                w-full px-4 py-2.5 text-sm font-medium bg-gradient-to-br from-red-500 to-pink-600 hover:from-red-600 hover:to-pink-700 text-white rounded-lg transition-all duration-300 shadow-md hover:shadow-red-500/25 hover:scale-[1.01] active:scale-[0.99] transform backdrop-blur-sm border border-white/10
            `;
            logoutButton.getElement().innerHTML = `
                <i class="fas fa-sign-out-alt mr-2"></i>
                Logout
            `;
            logoutButton.onClick(async () => {
                await AuthService.logout();
                localStorage.removeItem('userId');
                window.location.reload();
            });
            accountSection.appendChild(logoutButton.getElement());

            const divider = document.createElement('hr');
            divider.className = 'border-t border-white/10 my-4';
            accountSection.appendChild(divider);

            const deleteAccountButton = new Button('Delete Account');
            deleteAccountButton.getElement().className = `
                w-full px-4 py-2.5 text-sm 
                text-red-400/70 hover:text-red-400/90
                bg-transparent hover:bg-red-500/5
                rounded-lg transition-all duration-300 
                border border-red-500/10 hover:border-red-500/20
                mt-4 pt-4 border-t border-white/10
            `;
            deleteAccountButton.getElement().innerHTML = `
                <i class="fas fa-trash-alt mr-2"></i>
                Delete My Account
            `;
            deleteAccountButton.onClick(async () => {
                const modalForm = new ModalForm();
                modalForm.append((() => {
                    const div = document.createElement('div');
                    div.className = 'space-y-4';
                    div.innerHTML = `
                        <h2 class="text-lg font-semibold text-white mb-4">Delete Account</h2>
                        <p class="text-white/70 text-sm mb-6">
                            Are you sure you want to delete your account? This action cannot be undone. 
                            Please enter your password to confirm.
                        </p>
                        
                        <div class="space-y-4">
                            <input 
                                type="password" 
                                id="confirm-password" 
                                placeholder="Enter your password"
                                class="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg text-white/90 text-sm focus:outline-none focus:border-purple-500/50"
                            >
                            <button 
                                id="confirm-delete" 
                                class="w-full px-4 py-2.5 text-sm font-medium bg-gradient-to-br from-red-500 to-pink-600 hover:from-red-600 hover:to-pink-700 text-white rounded-lg transition-all duration-300 shadow-md hover:shadow-red-500/25"
                            >
                                <i class="fas fa-trash-alt mr-2"></i>
                                Delete My Account
                            </button>
                        </div>
                    `;

                    // Add click handler for delete confirmation
                    const confirmButton = div.querySelector('#confirm-delete');
                    const passwordInput = div.querySelector('#confirm-password') as HTMLInputElement;

                    confirmButton?.addEventListener('click', async () => {
                        const password = passwordInput.value;
                        const email = localStorage.getItem('userEmail') || '';

                        const result = await AuthService.initiateDeleteUser(parseInt(userId), password);
                        if (result) {
                            // Show confirmation code input
                            div.innerHTML = `
                                <h2 class="text-lg font-semibold text-white mb-4">Confirm Deletion</h2>
                                <p class="text-white/70 text-sm mb-6">
                                    A confirmation code has been sent to your email. 
                                    Please enter it below to permanently delete your account.
                                </p>
                                
                                <div class="space-y-4">
                                    <input 
                                        type="text" 
                                        id="confirmation-code" 
                                        placeholder="Enter confirmation code"
                                        class="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg text-white/90 text-sm focus:outline-none focus:border-purple-500/50"
                                    >
                                    <button 
                                        id="final-confirm" 
                                        class="w-full px-4 py-2.5 text-sm font-medium bg-gradient-to-br from-red-500 to-pink-600 hover:from-red-600 hover:to-pink-700 text-white rounded-lg transition-all duration-300 shadow-md hover:shadow-red-500/25"
                                    >
                                        <i class="fas fa-check mr-2"></i>
                                        Confirm Deletion
                                    </button>
                                </div>
                            `;

                            const finalConfirmButton = div.querySelector('#final-confirm');
                            const codeInput = div.querySelector('#confirmation-code') as HTMLInputElement;

                            finalConfirmButton?.addEventListener('click', async () => {
                                const success = await AuthService.confirmDeleteUser(
                                    result.userId,
                                    codeInput.value,
                                    result.code
                                );

                                if (success) {
                                    await AuthService.logout();
                                    window.location.href = '/';
                                }
                            });
                        }
                    });

                    return div;
                })());
                modalForm.mount(document.body);
                modalForm.open();
            });
            accountSection.appendChild(deleteAccountButton.getElement());

            this.element.append(
                videoSection,
                togglesSection,
                verificationSection,
                subscriptionSection,
                featuresSection,
                accountSection,
                divider,
                (() => {
                    const spacer = document.createElement('div');
                    spacer.className = 'h-16'; // Tailwind class for height: 8rem
                    return spacer;
                })(),
                deleteAccountButton.getElement()
            );
            
            // Initialize toggle states
            this.initializeToggles();
        } else {
            // Only append the always-visible sections for logged out users
            this.element.append(
                videoSection,
                featuresSection
            );
        }
    }

    private createSection(title: string, iconClass: string): HTMLDivElement {
        const section = document.createElement('div');
        section.className = 'space-y-3';
        
        const heading = document.createElement('h3');
        heading.className = 'text-base font-medium text-white/90 flex items-center gap-2';
        heading.innerHTML = `
            <i class="fas fa-${iconClass}"></i>
            ${title}
        `;
        
        section.appendChild(heading);
        return section;
    }

    private async initializeToggles(): Promise<void> {
        const userId = localStorage.getItem('userId');
        if (!userId) return;

        const userData = await AuthService.getUserById(parseInt(userId));
        if (!userData) return;

        const gamificationToggle = this.element.querySelector('[data-preference="gamification"]');
        const newsletterToggle = this.element.querySelector('[data-preference="newsletter"]');
        
        if (gamificationToggle) {
            this.updateToggleState(gamificationToggle as HTMLElement, userData.gamification || false);
        }
        if (newsletterToggle) {
            this.updateToggleState(newsletterToggle as HTMLElement, userData.newsletter || false);
        }
    }

    private createToggle(label: string, preference: string): HTMLDivElement {
        const container = document.createElement('div');
        container.className = 'flex items-center justify-between py-1.5 px-2 hover:bg-white/5 rounded-lg cursor-pointer transition-colors duration-300';
        container.dataset.preference = preference;

        const labelElement = document.createElement('span');
        labelElement.className = 'text-white/70';
        labelElement.textContent = label;

        const toggle = document.createElement('div');
        toggle.className = `
            relative 
            w-10 
            h-5 
            rounded-full 
            bg-purple-600/20 
            transition-colors 
            duration-300
        `;

        const toggleCircle = document.createElement('span');
        toggleCircle.className = `
            absolute 
            left-1 
            top-1 
            w-3 
            h-3 
            rounded-full 
            bg-white 
            transition-transform 
            duration-300
        `;

        toggle.appendChild(toggleCircle);
        container.append(labelElement, toggle);

        container.addEventListener('click', async () => {
            const userId = localStorage.getItem('userId');
            if (!userId) return;

            const isActive = toggle.classList.contains('bg-purple-600');
            const success = await AuthService.updateUser(parseInt(userId), {
                [preference]: !isActive
            });

            if (success) {
                this.updateToggleState(container, !isActive);
                if (preference === 'gamification') {
                    window.location.reload();
                }
            }
        });

        return container;
    }

    private updateToggleState(container: HTMLElement, isActive: boolean): void {
        const toggle = container.querySelector('div');
        const toggleCircle = container.querySelector('span:last-child') as HTMLElement;
        
        if (toggle && toggleCircle) {
            if (isActive) {
                toggle.className = 'relative w-10 h-5 rounded-full bg-purple-600 transition-colors duration-300';
                toggleCircle.style.transform = 'translateX(20px)';
            } else {
                toggle.className = 'relative w-10 h-5 rounded-full bg-white/10 transition-colors duration-300';
                toggleCircle.style.transform = 'translateX(0)';
            }
        }
    }

    public mount(parent: HTMLElement): void {
        parent.appendChild(this.element);
    }

    public unmount(): void {
        this.element.remove();
    }

    public getElement(): HTMLElement {
        return this.element;
    }
}