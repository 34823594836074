import { UINotifications } from '../../utilities/errors';
import { DiscussionResponse, ITagResponse, CommentResponse, CornerResponse, GroupResponse, parseCornerResponse, parseGroupResponse } from './models';
import { AuthService } from '../auth/api-services';
import { PlatoChatService } from '../platochat/api-services';

const KOMMIN_API_URL = import.meta.env.VITE_KOMMIN_API_URL;
const API_KEY = import.meta.env.VITE_API_KEY;

export class KomminService {
    private static getHeaders(): Record<string, string> {
        const baseHeaders = {
            'Content-Type': 'application/json',
            'X-API-Key': API_KEY
        };

        const sessionData = AuthService.getSessionData();
        if (sessionData) {
            return {
                ...baseHeaders,
                'X-Session-ID': sessionData.session_id,
                'X-Public-Key': sessionData.public_key,
                'X-User-ID': sessionData.userId?.toString() || ''
            };
        }

        return baseHeaders;
    }

    static async getDiscussionsByRecency(offset: number): Promise<DiscussionResponse[] | null> {
        try {
            UINotifications.showGlobalLoadingIndication();

            const response = await fetch(`${KOMMIN_API_URL}/get-discussion-by-recency?offset=${offset}`, {
                method: 'GET',
                headers: this.getHeaders(),
                mode: 'cors',
                credentials: 'omit'
            });

            const discussionsData = await response.json();

            if (!response.ok) {
                throw new Error(discussionsData.error || 'Failed to fetch discussions');
            }

            UINotifications.hideGlobalLoadingIndication();
            return discussionsData.discussions;
        } catch (error) {
            UINotifications.hideGlobalLoadingIndication();
            return null;
        }
    }

    static async getDiscussionsByUserId(userId: number): Promise<DiscussionResponse[] | null> {
        try {
            UINotifications.showGlobalLoadingIndication();

            const response = await fetch(`${KOMMIN_API_URL}/get-discussions-by-user-id?user_id=${userId}`, {
                method: 'GET',
                headers: this.getHeaders(),
                mode: 'cors',
                credentials: 'omit'
            });

            const discussionsData = await response.json();

            if (!response.ok) {
                throw new Error(discussionsData.error || 'Failed to fetch discussions');
            }

            UINotifications.hideGlobalLoadingIndication();
            return discussionsData.discussions;
        } catch (error) {
            UINotifications.hideGlobalLoadingIndication();
            return null;
        }
    }

    static async getDefaultTags(): Promise<ITagResponse[] | null> {
        try {
            UINotifications.showGlobalLoadingIndication();

            const response = await fetch(`${KOMMIN_API_URL}/get-default-tags`, {
                method: 'GET',
                headers: this.getHeaders(),
                mode: 'cors',
                credentials: 'omit'
            });

            const tagsData = await response.json();

            if (!response.ok) {
                throw new Error(tagsData.error || 'Failed to fetch tags');
            }

            UINotifications.hideGlobalLoadingIndication();
            return tagsData.tags;
        } catch (error) {
            UINotifications.hideGlobalLoadingIndication();
            return null;
        }
    }

    static async getDiscussionsByITag(itag: string): Promise<DiscussionResponse[] | null> {
        try {
            UINotifications.showGlobalLoadingIndication();

            const response = await fetch(`${KOMMIN_API_URL}/get-discussion-by-itag?iTag=${itag}`, {
                method: 'GET',
                headers: this.getHeaders(),
                mode: 'cors',
                credentials: 'omit'
            });

            const discussionsData = await response.json();

            if (!response.ok) {
                throw new Error(discussionsData.error || 'Failed to fetch discussions');
            }

            UINotifications.hideGlobalLoadingIndication();
            return discussionsData.discussions;
        } catch (error) {
            UINotifications.hideGlobalLoadingIndication();
            return null;
        }
    }

    static async createDiscussion(
        title: string,
        postBody: string,
        userId: number,
        author: string,
        iTags: ITagResponse[],
        cornerId?: number,
        groupId?: number
    ): Promise<number | null> {
        try {
            UINotifications.showGlobalLoadingIndication();

            const response = await fetch(`${KOMMIN_API_URL}/create-discussion`, {
                method: 'POST',
                headers: {
                    ...this.getHeaders(),
                    'X-Session-ID': AuthService.getSessionData()?.session_id || '',
                    'X-Public-Key': AuthService.getSessionData()?.public_key || '',
                    'X-User-ID': AuthService.getSessionData()?.userId?.toString() || ''
                },
                body: JSON.stringify({
                    title,
                    post_body: postBody,
                    user_id: userId,
                    author,
                    itags: iTags,
                    corner_id: cornerId,
                    group_id: groupId
                }),
                mode: 'cors',
                credentials: 'omit'
            });

            const responseData = await response.json();

            if (response.status === 201) {
                const discussionId = responseData.discussion.ID;
                //PlatoChatService.addToBalance(userId, 1000);
                return discussionId;
            } else {
                throw new Error(responseData.error || 'Failed to create discussion');
            }
        } catch (error) {
            console.error('Error creating discussion:', error);
            throw error;
        } finally {
            UINotifications.hideGlobalLoadingIndication();
        }
    }

    static async getDiscussionComments(discussionId: number): Promise<CommentResponse[] | null> {
        try {
            UINotifications.showGlobalLoadingIndication();

            const response = await fetch(`${KOMMIN_API_URL}/get-discussion-comments?discussion_id=${discussionId}`, {
                method: 'GET',
                headers: this.getHeaders(),
                mode: 'cors',
                credentials: 'omit'
            });

            const commentsData = await response.json();

            if (response.ok) {
                return commentsData.data.map((comment: any) => ({
                    ID: comment.ID,
                    DiscussionID: comment.DiscussionID,
                    Timestamp: comment.CreatedAt,
                    Author: comment.Author,
                    Text: comment.Text
                    
                }
            ));
            } else {
                throw new Error(commentsData.error || 'Failed to fetch comments');
            }
        } catch (error) {
            UINotifications.hideGlobalLoadingIndication();
            return null;
        } finally {
            UINotifications.hideGlobalLoadingIndication();
        }
    }

    static async getDiscussionById(discussionId: number): Promise<DiscussionResponse | null> {
        try {
            UINotifications.showGlobalLoadingIndication();

            const response = await fetch(`${KOMMIN_API_URL}/get-discussion-by-id?discussion_id=${discussionId}`, {
                method: 'GET',
                headers: this.getHeaders(),
                mode: 'cors',
                credentials: 'omit'
            });

            const discussionData = await response.json();

            if (response.ok) {
                return {
                    ID: discussionData.discussion.ID,
                    UserID: discussionData.discussion.UserID,
                    Timestamp: discussionData.discussion.Timestamp,
                    Author: discussionData.discussion.Author,
                    Body: discussionData.discussion.Body,
                    AverageRating: discussionData.discussion.AverageRating,
                    InteractionCount: discussionData.discussion.InteractionCount,
                    CreatedAt: discussionData.discussion.CreatedAt,
                    UpdatedAt: discussionData.discussion.UpdatedAt,
                    DeletedAt: discussionData.discussion.DeletedAt || null,
                    Tags: discussionData.discussion.Tags || [],
                    Title: discussionData.discussion.Title,
                    MediaURL: discussionData.discussion.MediaURL,
                    CornerId: discussionData.discussion.CornerId,
                    GroupId: discussionData.discussion.GroupId
                };
            } else {
                throw new Error(discussionData.error || 'Failed to fetch discussion');
            }
        } catch (error) {
            UINotifications.hideGlobalLoadingIndication();
            return null;
        } finally {
            UINotifications.hideGlobalLoadingIndication();
        }
    }

    static async postRateDiscussion(userId: number, discussionId: number, rating: number): Promise<boolean> {
        try {
            UINotifications.showGlobalLoadingIndication();

            const response = await fetch(`${KOMMIN_API_URL}/update-rating`, {
                method: 'POST',
                headers: this.getHeaders(),
                body: JSON.stringify({
                    user_id: userId,
                    rating: rating,
                    discussion_id: discussionId
                }),
                mode: 'cors',
                credentials: 'omit'
            });

            if (response.ok) {
                UINotifications.hideGlobalLoadingIndication();
                //  PlatoChatService.addToBalance(userId, 25);
                return true;
            } else {
                throw new Error('Failed to rate discussion');
            }
        } catch (error) {
            UINotifications.hideGlobalLoadingIndication();
            return false;
        }
    }

    static async postRecordImpression(discussionId: number): Promise<boolean> {
        try {
            UINotifications.showGlobalLoadingIndication();

            const response = await fetch(`${KOMMIN_API_URL}/record-interaction`, {
                method: 'POST',
                headers: this.getHeaders(),
                body: JSON.stringify({
                    discussion_id: discussionId
                }),
                mode: 'cors',
                credentials: 'omit'
            });

            if (response.ok) {
                UINotifications.hideGlobalLoadingIndication();
                return true;
            } else {
                throw new Error('Failed to record impression');
            }
        } catch (error) {
            UINotifications.hideGlobalLoadingIndication();
            return false;
        }
    }

    static async postAddComment(userId: number, author: string, discussionId: number, text: string): Promise<CommentResponse | null> {
        try {
            UINotifications.showGlobalLoadingIndication();

            const response = await fetch(`${KOMMIN_API_URL}/create-comment`, {
                method: 'POST',
                headers: this.getHeaders(),
                body: JSON.stringify({
                    user_id: userId,
                    author: author,
                    discussion_id: discussionId,
                    text: text
                }),
                mode: 'cors',
                credentials: 'omit'
            });

            const responseData = await response.json();

            if (response.ok) {
                UINotifications.hideGlobalLoadingIndication();
                //PlatoChatService.addToBalance(userId, 150);
                return {
                    ID: responseData.ID,
                    DiscussionID: responseData.DiscussionID,
                    Timestamp: responseData.Timestamp,
                    Author: responseData.Author,
                    Text: responseData.Text
                };
            } else {
                throw new Error(responseData.error || 'Failed to add comment');
            }
        } catch (error) {
            UINotifications.hideGlobalLoadingIndication();
            return null;
        }
    }

    static async getTags(offset: number): Promise<ITagResponse[] | null> {
        try {
            UINotifications.showGlobalLoadingIndication();

            const response = await fetch(`${KOMMIN_API_URL}/get-tags?offset=${offset}`, {
                method: 'GET',
                headers: this.getHeaders(),
                mode: 'cors',
                credentials: 'omit'
            });

            const tagData = await response.json();

            if (response.ok) {
                return tagData.iTags.map((tag: any) => ({
                    Tag: tag.Tag
                }));
            } else {
                throw new Error('Failed to fetch tags');
            }
        } catch (error) {
            UINotifications.hideGlobalLoadingIndication();
            return null;
        }
    }

    static async getTagByTagName(tagName: string): Promise<ITagResponse | null> {
        try {
            UINotifications.showGlobalLoadingIndication();

            const response = await fetch(`${KOMMIN_API_URL}/get-tag-by-tag-name?tag_name=${tagName}`, {
                method: 'GET',
                headers: this.getHeaders(),
                mode: 'cors',
                credentials: 'omit'
            });

            const tagData = await response.json();

            if (response.ok) {
                return {
                    ID: tagData.iTag.ID,
                    CreatedAt: tagData.iTag.CreatedAt,
                    UpdatedAt: tagData.iTag.UpdatedAt,
                    DeletedAt: tagData.iTag.DeletedAt || null,
                    Tag: tagData.iTag.Tag,
                    DiscussionCount: tagData.iTag.DiscussionCount
                };
            } else {
                throw new Error('Failed to fetch tag by name');
            }
        } catch (error) {
            UINotifications.hideGlobalLoadingIndication();
            return null;
        }
    }

    static async getTopiTagsForUserId(userId: number): Promise<string[] | null> {
        try {
            UINotifications.showGlobalLoadingIndication();

            const response = await fetch(`${KOMMIN_API_URL}/get-topi-tags-for-user-id?user_id=${userId}`, {
                method: 'GET',
                headers: this.getHeaders(),
                mode: 'cors',
                credentials: 'omit'
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || 'Failed to fetch top iTags');
            }

            UINotifications.hideGlobalLoadingIndication();
            return data.topTags.map((tag: ITagResponse) => tag.Tag);
        } catch (error) {
            UINotifications.hideGlobalLoadingIndication();
            return null;
        }
    }

    // Corner methods
    static async createCorner(title: string, description: string, createdBy: number): Promise<CornerResponse | null> {
        try {
            UINotifications.showGlobalLoadingIndication();

            const response = await fetch(`${KOMMIN_API_URL}/create-corner`, {
                method: 'POST',
                headers: this.getHeaders(),
                body: JSON.stringify({
                    title,
                    description,
                    created_by: createdBy
                }),
                mode: 'cors',
                credentials: 'omit'
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || 'Failed to create corner');
            }

            return parseCornerResponse(data.corner);
        } catch (error) {
            UINotifications.hideGlobalLoadingIndication();
            return null;
        }
    }

    static async getCornerById(cornerId: number): Promise<CornerResponse | null> {
        try {
            UINotifications.showGlobalLoadingIndication();

            const response = await fetch(`${KOMMIN_API_URL}/get-corner-by-id?corner_id=${cornerId}`, {
                method: 'GET',
                headers: this.getHeaders(),
                mode: 'cors',
                credentials: 'omit'
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || 'Failed to fetch corner');
            }

            return parseCornerResponse(data.corner);
        } catch (error) {
            UINotifications.hideGlobalLoadingIndication();
            return null;
        }
    }

    // Group methods
    static async createGroup(title: string, description: string, createdBy: number, isPublic: boolean): Promise<GroupResponse | null> {
        try {
            UINotifications.showGlobalLoadingIndication();

            const response = await fetch(`${KOMMIN_API_URL}/create-group`, {
                method: 'POST',
                headers: this.getHeaders(),
                body: JSON.stringify({
                    title,
                    description,
                    created_by: createdBy,
                    public: isPublic
                }),
                mode: 'cors',
                credentials: 'omit'
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || 'Failed to create group');
            }

            return parseGroupResponse(data.group);
        } catch (error) {
            UINotifications.hideGlobalLoadingIndication();
            return null;
        }
    }

    static async getGroupById(groupId: number): Promise<GroupResponse | null> {
        try {
            UINotifications.showGlobalLoadingIndication();

            const response = await fetch(`${KOMMIN_API_URL}/get-group-by-id?group_id=${groupId}`, {
                method: 'GET',
                headers: this.getHeaders(),
                mode: 'cors',
                credentials: 'omit'
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || 'Failed to fetch group');
            }

            return parseGroupResponse(data.group);
        } catch (error) {
            UINotifications.hideGlobalLoadingIndication();
            return null;
        }
    }

    static async addMediaToDiscussion(discussionId: number, file: File): Promise<string | null> {
        try {
            const userId = localStorage.getItem('userId');
            if (!userId) {
                throw new Error('No user ID found');
            }

            const sessionData = AuthService.getSessionData();
            if (!sessionData?.session_id || !sessionData?.public_key) {
                throw new Error('Invalid session data');
            }

            UINotifications.showGlobalLoadingIndication();
            const formData = new FormData();
            formData.append('media', file);

            // Debug logs
            console.log('File being uploaded:', file);
            console.log('FormData entries:');
            for (let pair of formData.entries()) {
                console.log(pair[0], pair[1]);
            }

            const response = await fetch(`${KOMMIN_API_URL}/add-media-to-discussion`, {
                method: 'POST',
                headers: {
                    'X-API-Key': API_KEY,
                    'X-Session-ID': sessionData.session_id,
                    'X-Public-Key': sessionData.public_key,
                    'X-User-ID': userId,
                    'X-Discussion-ID': discussionId.toString()
                },
                body: formData,
                mode: 'cors',
                credentials: 'omit'
            });

            // Debug response
            console.log('Response status:', response.status);
            console.log('Response headers:', response.headers);
            
            if (!response.ok) {
                const error = await response.json();
                console.log('Error response:', error);
                throw new Error(JSON.stringify(error));
            }

            const data = await response.json();
            return data.media_url;
        } catch (error) {
            console.error('Error adding image to discussion:', error);
            throw error;
        } finally {
            UINotifications.hideGlobalLoadingIndication();
        }
    }

    static async deleteDiscussion(discussionId: number): Promise<boolean> {
        try {
            UINotifications.showGlobalLoadingIndication();

            const response = await fetch(`${KOMMIN_API_URL}/delete-discussion?discussion_id=${discussionId}`, {
                method: 'DELETE',
                headers: this.getHeaders(),
                mode: 'cors',
                credentials: 'omit'
            });

            if (!response.ok) {
                throw new Error('Failed to delete discussion');
            }

            return true;
        } catch (error) {
            console.error('Error deleting discussion:', error);
            return false;
        } finally {
            UINotifications.hideGlobalLoadingIndication();
        }
    }
} 