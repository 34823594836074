import { ModalForm } from '../../pieces/reusable/modal-form';
import { DiscussionsContent } from '../../pieces/kommin/discussions-content';
import { ITagContent } from '../../pieces/kommin/itag-content';
import { NavColumn } from '../../pieces/general/nav-column';
import { ContentColumn } from '../../pieces/general/content-column';
import { DiscoveryColumn } from '../../pieces/general/discovery-column';
import { YourBig5 } from '../../pieces/general/your-big-5';
import { ContentTitle } from '../../pieces/reusable/content-title';
import { MobileNavButtons } from '../../pieces/reusable/mobile-nav-buttons';

export class KomminAppletScreen {
    private container: HTMLElement | null = null;
    private modalForm: ModalForm;
    private yourBig5: YourBig5;
    private mobileNavButtons: MobileNavButtons;

    constructor() {
        this.modalForm = new ModalForm();
        this.yourBig5 = new YourBig5(() => {
            // Empty for now since we're in kommin view
        });
        this.mobileNavButtons = new MobileNavButtons(this.modalForm, this.yourBig5);
    }

    public async mount(container: HTMLElement) {
        // Set chosen applet
        localStorage.setItem('chosenApplet', 'kommin');

        this.container = container;
        this.container.innerHTML = '';
        this.container.className = 'min-h-screen';

        // Create layout
        const columnLayout = document.createElement('div');
        columnLayout.className = 'flex min-h-screen h-screen overflow-hidden';

        // Content wrapper
        const contentWrapper = document.createElement('div');
        contentWrapper.className = 'flex lg:flex-row flex-col flex-1 overflow-hidden';

        // Mount columns
        const navColumn = new NavColumn(this.modalForm);
        const contentColumn = new ContentColumn();
        const discoveryColumn = new DiscoveryColumn(this.modalForm, this.yourBig5);

        // Only mount nav column on desktop
        const navElement = navColumn.getElement();
        navElement.className = 'hidden sm:block ' + navElement.className;
        navColumn.mount(columnLayout);

        contentColumn.mount(contentWrapper);
        
        // Hide discovery column on mobile
        const discoveryElement = discoveryColumn.getElement();
        discoveryElement.className = 'hidden sm:block discovery-column ' + discoveryElement.className;
        discoveryColumn.mount(contentWrapper);

        // Load Kommin content
        await this.loadKomminContent(contentColumn.getContainer());

        columnLayout.appendChild(contentWrapper);
        
        // Mount modal form and layout
        this.modalForm.mount(container);
        container.appendChild(columnLayout);

        // Add mobile nav buttons
        this.mobileNavButtons.mount(container);
    }

    private async loadKomminContent(container: HTMLElement) {
        container.innerHTML = '';
        
        const mainContent = document.createElement('div');
        mainContent.className = 'max-w-3xl mx-auto px-4 py-6 space-y-6 pb-24';
        
        // Add content title immediately
        const contentTitle = new ContentTitle('Kommin', 'kommin');
        contentTitle.mount(mainContent);
        container.appendChild(mainContent);

        // Create skeleton loader
        const skeletonLoader = document.createElement('div');
        skeletonLoader.className = 'flex flex-col gap-4';

        // Create 5 skeleton items
        for (let i = 0; i < 5; i++) {
            const skeletonItem = document.createElement('div');
            skeletonItem.className = `
                relative overflow-hidden
                bg-gradient-to-br from-black/20 to-black/30
                backdrop-blur-lg
                border-b border-white/5
                p-5
                rounded-xl
                animate-pulse
            `;

            skeletonItem.innerHTML = `
                <div class="flex flex-col gap-4">
                    <div class="flex items-center gap-2">
                        <div class="w-11 h-11 rounded-full bg-white/5"></div>
                        <div class="flex flex-col gap-2">
                            <div class="w-32 h-4 bg-white/5 rounded"></div>
                            <div class="w-24 h-3 bg-white/5 rounded"></div>
                        </div>
                    </div>
                    <div class="space-y-3">
                        <div class="w-3/4 h-6 bg-white/5 rounded"></div>
                        <div class="w-full h-48 bg-white/5 rounded-lg"></div>
                        <div class="w-full h-4 bg-white/5 rounded"></div>
                        <div class="w-2/3 h-4 bg-white/5 rounded"></div>
                    </div>
                    <div class="flex items-center gap-4">
                        <div class="w-24 h-4 bg-white/5 rounded"></div>
                        <div class="w-16 h-4 bg-white/5 rounded"></div>
                    </div>
                </div>
            `;

            skeletonLoader.appendChild(skeletonItem);
        }

        mainContent.appendChild(skeletonLoader);

        // Create placeholder containers for actual content
        const iTagContainer = document.createElement('div');
        const discussionsContainer = document.createElement('div');
        mainContent.appendChild(iTagContainer);
        mainContent.appendChild(discussionsContainer);

        // Load components in parallel
        const discussionsContent = new DiscussionsContent(this.modalForm);
        const iTagContent = new ITagContent(discussionsContent);

        Promise.all([
            iTagContent.mount(iTagContainer),
            discussionsContent.mount(discussionsContainer)
        ]).then(() => {
            // Remove skeleton loader when content is loaded
            skeletonLoader.remove();
        }).catch(console.error);
    }

    public unmount(): void {
        if (this.container) {
            this.container.innerHTML = '';
        }
        
        this.container = null;
        this.modalForm.unmount();
        this.yourBig5?.unmount();
        this.mobileNavButtons.unmount();
    }
}