import { PlatoChatService } from '../actions/platochat/api-services';
import { ChatResponse, NanoResponse } from '../actions/platochat/models';
import { UserData } from '../actions/auth/models';
import { AuthService } from '../actions/auth/api-services';
import { navigate } from '../app-router';
import { ModalForm } from '../pieces/reusable/modal-form';
import { NearbySelectionContent } from '../pieces/platochat/selection/nearby-selection-content';
import { KomminSelectionContent } from '../pieces/platochat/selection/kommin-selection-content';
import { Hash } from '../utilities/hash';
import { AnalytixSelectionContent } from '../pieces/platochat/selection/analytix-selection-content';
import { ReplySelectionContent } from '../pieces/platochat/selection/reply-selection-content';
import { AnalytixService } from '../actions/analytix/api-services';
import { KomminService } from '../actions/kommin/api-services';
import { UINotifications } from '../utilities/errors';
import { MobileNavButtons } from '../pieces/reusable/mobile-nav-buttons';
import { YourBig5 } from '../pieces/general/your-big-5';
import { NavColumn } from '../pieces/general/nav-column';
import { ContentColumn } from '../pieces/general/content-column';
import { DiscoveryColumn } from '../pieces/general/discovery-column';
import { ContentTitle } from '../pieces/reusable/content-title';
import { AIService } from '../actions/ai/api-services';

type AttachmentName = 'Kommin' | 'Nearby' | 'Commib' | 'Analytix' | 'Reply' | 'AI';

type ChatParams = {
    author: string;
    userId: number;
    nanoId: number;
    content: string;
    timestamp: string;
    analytixId?: number;
    commibId?: number;
    postId?: number;
    eventId?: number;
    replyId?: number;
};

async function rateCommib(commibId: number, rating: number) {
    try {
        const userId = Number(localStorage.getItem('userId'));
        const success = await PlatoChatService.updateRating(userId, rating, commibId);
        if (!success) {
            alert('You can only rate once');
        }
    } catch (error) {
        console.error('Rating failed:', error);
        alert('Rating failed');
    }
}

export class NanoScreen {
    private container: HTMLElement | null = null;
    private mobileNavButtons: MobileNavButtons;
    private yourBig5: YourBig5;
    private element: HTMLElement;
    private nanoId: number;
    private nano: NanoResponse | null = null;
    private modal: ModalForm;
    private users: UserData[] = [];
    private currentAttachmentId: number = 0;
    private currentAttachmentType: AttachmentName | 'none' = 'none';

    private adjectives = [
        'exciting', 'powerful', 'fascinating', 'exhilarating', 'thoughtful',
        'transformative', 'wholesome', 'funny', 'clever', 'witty', 'novel',
        'creative', 'inspiring', 'a little stupid', 'to foster community',
        'relatable', 'useful', 'experimental'
    ];

    private userBackgrounds = [
        'bg-yellow-500/30',
        'bg-purple-500/30',
        'bg-pink-500/30',
        'bg-green-500/30'
    ];

    private getRandomAdjective(): string {
        return this.adjectives[Math.floor(Math.random() * this.adjectives.length)];
    }

    constructor(nanoId: number, modal: ModalForm) {
        this.nanoId = nanoId;
        this.element = document.createElement('div');
        this.modal = new ModalForm();
        this.yourBig5 = new YourBig5(() => {
            // Empty for now since we're in kommin view
        });
        this.mobileNavButtons = new MobileNavButtons(this.modal, this.yourBig5);

        
        // Make rateDiscussion available globally
        (window as any).rateDiscussion = async (discussionId: number, rating: number) => {
            const userId = Number(localStorage.getItem('userId'));
            if (!userId) return;

            const success = await KomminService.postRateDiscussion(userId, discussionId, Number(rating));
            if (success) {
                this.loadChats(); // Refresh to show updated rating
            } else {
                UINotifications.shipErrorToUI('You can only rate a discussion once', {persist: true});
            }
        };

        // Add this global function
        (window as any).rateCommib = async (commibId: number, rating: number) => {
            const userId = Number(localStorage.getItem('userId'));
            if (!userId) return;
            
            try {
                const success = await PlatoChatService.updateRating(userId, rating, commibId);
                if (success) {
                    UINotifications.shipSuccessToUI('Rating updated successfully');
                    this.loadChats(); // Refresh to show updated rating
                } else {
                    UINotifications.shipErrorToUI('You can only rate once', {persist: true});
                }
            } catch (error) {
                console.error('Failed to rate commib:', error);
                UINotifications.shipErrorToUI('Failed to rate', {persist: true});
            }
        };

        // Add this to the constructor or initialize method:
        (window as any).handleAttachmentClick = (attachmentType: AttachmentName) => {
            this.handleAttachmentDrop(attachmentType);
        };

        if (this.container) {
            this.initialize(this.container!);
        }
        this.modal.close();
        UINotifications.hideGlobalLoadingIndication();
    }

    private async initialize(container: HTMLElement) {
        localStorage.setItem('chosenApplet', 'nanos');

        this.container = container;
        this.container.innerHTML = '';
        this.container.className = 'min-h-screen';
        

        // Create layout
        const columnLayout = document.createElement('div');
        columnLayout.className = 'flex min-h-screen h-screen overflow-hidden';
        
        // Content wrapper
        const contentWrapper = document.createElement('div');
        contentWrapper.className = 'flex lg:flex-row flex-col flex-1 overflow-hidden';

        try {
            // Load nano data first
            this.nano = await PlatoChatService.getNanoById(this.nanoId);
            if (!this.nano) {
                navigate('/unknown-route');
                return;
            }

            // Check if current user is part of this nano
            const currentUserId = Number(localStorage.getItem('userId'));
            const userIds = [
                this.nano.user1_id,
                this.nano.user2_id,
                this.nano.user3_id,
                this.nano.user4_id
            ].filter(id => id !== 0);
            
            if (!currentUserId || !userIds.includes(currentUserId)) {
                navigate('/unknown-route');
                return;
            }

            // Fetch all user data
            try {
                const userPromises = userIds.map(id => AuthService.getUserById(id));
                const users = await Promise.all(userPromises);
                this.users = users.filter((user): user is UserData => user !== null);
            } catch (error) {
                console.error('Failed to load user data:', error);
            }

            // Mount columns
            const navColumn = new NavColumn(this.modal);
            const contentColumn = new ContentColumn();
            const discoveryColumn = new DiscoveryColumn(this.modal, this.yourBig5);

            // Only mount nav column on desktop
            const navElement = navColumn.getElement();
            navElement.className = 'hidden sm:block ' + navElement.className;
            navColumn.mount(columnLayout);

            contentColumn.mount(contentWrapper);
            
            // Hide discovery column on mobile
            const discoveryElement = discoveryColumn.getElement();
            discoveryElement.className = 'hidden sm:block discovery-column ' + discoveryElement.className;
            discoveryColumn.mount(contentWrapper);

            // Render main content
            this.render();
            contentColumn.getContainer().appendChild(this.element);

            columnLayout.appendChild(contentWrapper);
            this.container.appendChild(columnLayout);

        } catch (error) {
            console.error('Failed to load nano:', error);
            navigate('/unknown-route');
        }
    }

    private render() {
        if (!this.nano) return;
        
        // Create header container
        const headerContainer = document.createElement('div');
        headerContainer.className = 'nano-header';
        
        // Create title container inside header
        const titleContainer = document.createElement('div');
        titleContainer.id = 'content-title-container';
        headerContainer.appendChild(titleContainer);

        const contentTitle = new ContentTitle('PlatoChat Nanos', 'platochat');
        contentTitle.mount(titleContainer);

        // Add home button inside header
        const homeButton = document.createElement('div');
        homeButton.className = 'sm:hidden px-3 py-1';
        homeButton.innerHTML = `
            <button onclick="window.location.href='/'" 
                class="px-2 py-1 text-xs text-white/70 hover:text-white/90 transition-colors">
                <i class="fas fa-home mr-1"></i>Home
            </button>
        `;
        headerContainer.appendChild(homeButton);
        
        // To:
        this.element.appendChild(headerContainer);
        const contentContainer = document.createElement('div');
        contentContainer.className = 'h-screen text-white flex flex-col relative';
        contentContainer.innerHTML = `
            <div class="h-screen text-white flex flex-col relative">
                <!-- Scrollable content area -->
                <div class="flex-1 overflow-y-auto" id="nano-content-zone">
                    <div class="h-full">
                        <!-- Chat area with drag target -->
                        <div class="min-h-full rounded-none p-4 sm:p-8 pb-48
                            bg-background
                            transition-colors duration-200"
                             id="chat-drop-zone">
                            <div class="mb-6 text-center">
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Fixed footer with new design -->
                <div class="sticky bottom-0 left-0 right-0 bg-background">
                    <!-- Staging Area -->
                    <div id="staging-area" class="hidden absolute bottom-full w-full max-w-[90%] mx-auto left-0 right-0 mb-2 sm:mb-4">
                        <div class="bg-[#0F0F0F] px-3 py-3 sm:px-6 sm:py-5 rounded-xl sm:rounded-2xl 
                            border border-white/10 shadow-lg max-w-2xl mx-auto">
                            <div class="relative">
                                <button id="close-staging" 
                                    class="absolute -top-2 -right-2 sm:-top-3 sm:-right-3 
                                    w-6 h-6 sm:w-8 sm:h-8 rounded-full bg-background 
                                    border border-white/10 hover:bg-white/10 transition-colors 
                                    flex items-center justify-center shadow-lg
                                    z-50">
                                    <i class="fas fa-times text-white/80 text-xs sm:text-base"></i>
                                </button>
                                <div id="staging-content" class="pr-4 sm:pr-6"></div>
                            </div>
                        </div>
                    </div>

                    <!-- New Input Section with Improved Accordion -->
                    <div class="px-4 py-4">
                        <div class="bg-[#0F0F0F] border border-white/10 rounded-2xl p-4">
                            <div class="flex gap-3 w-full max-w-3xl mx-auto relative">
                                <!-- Attachment Accordion -->
                                <div class="relative group">
                                    <button class="h-12 w-12 rounded-xl border border-white/10 
                                        text-white/70 hover:text-white/90 hover:border-white/20 
                                        transition-all duration-300 flex items-center justify-center
                                        bg-white/5 hover:bg-white/10">
                                        <i class="fas fa-plus-circle text-lg"></i>
                                    </button>
                                    
                                    <!-- Improved Dropdown Menu - added padding-bottom to create hover bridge -->
                                    <div class="absolute bottom-full left-0 mb-2 w-64
                                        bg-[#0F0F0F]/95 backdrop-blur-md border border-white/10 rounded-xl 
                                        hidden group-hover:block shadow-xl
                                        pb-2 after:content-[''] after:absolute after:bottom-[-8px] 
                                        after:left-0 after:w-full after:h-2">
                                        <div class="p-3 space-y-1">
                                            <!-- Kommin -->
                                            <button class="flex items-center gap-3 w-full p-2.5 rounded-lg
                                                hover:bg-orange-500/10 transition-colors text-white/70 hover:text-white
                                                group/item" onclick="window.handleAttachmentClick('Kommin')">
                                                <div class="w-8 h-8 rounded-lg bg-gradient-to-br from-[#B86E00] to-[#914001]
                                                    flex items-center justify-center text-white group-hover/item:scale-105 transition-transform">
                                                    <span class="font-bold">K</span>
                                                </div>
                                                <div class="text-left">
                                                    <div class="text-sm font-medium">Komms</div>
                                                    <div class="text-xs text-white/40">Share discussions</div>
                                                </div>
                                            </button>

                                            <!-- Events -->
                                            <button class="flex items-center gap-3 w-full p-2.5 rounded-lg
                                                hover:bg-purple-500/10 transition-colors text-white/70 hover:text-white
                                                group/item" onclick="window.handleAttachmentClick('Nearby')">
                                                <div class="w-8 h-8 rounded-lg bg-gradient-to-br from-[#154DAD] to-[#CB0CEC]
                                                    flex items-center justify-center text-white group-hover/item:scale-105 transition-transform">
                                                    <i class="fas fa-calendar-alt"></i>
                                                </div>
                                                <div class="text-left">
                                                    <div class="text-sm font-medium">Events</div>
                                                    <div class="text-xs text-white/40">Share nearby events</div>
                                                </div>
                                            </button>

                                            <!-- Icebreakers -->
                                            <button class="flex items-center gap-3 w-full p-2.5 rounded-lg
                                                hover:bg-blue-500/10 transition-colors text-white/70 hover:text-white
                                                group/item" onclick="window.handleAttachmentClick('Commib')">
                                                <div class="w-8 h-8 rounded-lg bg-gradient-to-br from-[#0088ff] to-[#8EDBFF]
                                                    flex items-center justify-center text-white group-hover/item:scale-105 transition-transform">
                                                    <i class="fas fa-snowflake"></i>
                                                </div>
                                                <div class="text-left">
                                                    <div class="text-sm font-medium">Icebreakers</div>
                                                    <div class="text-xs text-white/40">Fun conversation starters</div>
                                                </div>
                                            </button>

                                            <!-- Personalities -->
                                            <button class="flex items-center gap-3 w-full p-2.5 rounded-lg
                                                hover:bg-pink-500/10 transition-colors text-white/70 hover:text-white
                                                group/item" onclick="window.handleAttachmentClick('Analytix')">
                                                <div class="w-8 h-8 rounded-lg bg-gradient-to-br from-[#E438B9] to-[#EC4073]
                                                    flex items-center justify-center text-white group-hover/item:scale-105 transition-transform">
                                                    <i class="fas fa-chart-bar"></i>
                                                </div>
                                                <div class="text-left">
                                                    <div class="text-sm font-medium">Personalities</div>
                                                    <div class="text-xs text-white/40">Share personality insights</div>
                                                </div>
                                            </button>

                                            <!-- AI Assistance -->
                                            <button class="flex items-center gap-3 w-full p-2.5 rounded-lg
                                                hover:bg-emerald-500/10 transition-colors text-white/70 hover:text-white
                                                group/item" onclick="window.handleAttachmentClick('AI')">
                                                <div class="w-8 h-8 rounded-lg bg-gradient-to-br from-emerald-500 to-green-600
                                                    flex items-center justify-center text-white group-hover/item:scale-105 transition-transform">
                                                    <span class="font-mono text-sm">Ai</span>
                                                </div>
                                                <div class="text-left">
                                                    <div class="text-sm font-medium">AI Assistance</div>
                                                    <div class="text-xs text-white/40">Get AI chat suggestions</div>
                                                </div>
                                            </button>

                                            <!-- Replies -->
                                            <button class="flex items-center gap-3 w-full p-2.5 rounded-lg
                                                hover:bg-red-500/10 transition-colors text-white/70 hover:text-white
                                                group/item" onclick="window.handleAttachmentClick('Reply')">
                                                <div class="w-8 h-8 rounded-lg bg-gradient-to-br from-[#ff0000] to-[#AA0606]
                                                    flex items-center justify-center text-white group-hover/item:scale-105 transition-transform">
                                                    <i class="fas fa-reply"></i>
                                                </div>
                                                <div class="text-left">
                                                    <div class="text-sm font-medium">Replies</div>
                                                    <div class="text-xs text-white/40">Reference other messages</div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <!-- Text Input -->
                                <div class="flex gap-2 w-full">
                                <input type="text" 
                                    class="flex-1 bg-white/5 text-white rounded-xl px-4 py-3 
                                    border border-white/10 
                                    focus:border-white/20 focus:outline-none
                                    placeholder-white/40 min-w-0"
                                    placeholder="Send something ${this.getRandomAdjective()}...">
                                
                                <!-- Send Button -->
                                <button id="send-chat-button" class="h-12 w-12 rounded-xl border border-white/10 
                                    text-white/70 hover:text-white/90
                                    bg-white/5 hover:bg-white/10
                                    transition-all duration-300 flex items-center justify-center 
                                    flex-shrink-0">
                                    <i class="fas fa-arrow-right"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        `;

        this.element.appendChild(contentContainer);

        this.modal.mount(this.element);


        // Add drag and drop listeners
        const dropZone = this.element.querySelector('#chat-drop-zone');
        const draggablesContainer = this.element.querySelector('#draggables-container');

        if (dropZone && draggablesContainer) {
            let draggedElement: HTMLElement | null = null;
            let offsetX: number = 0;
            let offsetY: number = 0;

            // Prevent multiple drags and setup custom drag
            draggablesContainer.addEventListener('dragstart', (event: Event) => {
                const dragEvent = event as DragEvent;
                const target = dragEvent.target as HTMLElement;
                
                // Prevent ghost image
                if (dragEvent.dataTransfer) {
                    const emptyImg = new Image();
                    dragEvent.dataTransfer.setDragImage(emptyImg, 0, 0);
                }

                // Setup custom drag
                draggedElement = target.cloneNode(true) as HTMLElement;
                document.body.appendChild(draggedElement);
                draggedElement.style.position = 'fixed';
                draggedElement.style.pointerEvents = 'none';
                draggedElement.style.zIndex = '1000';
                
                // Calculate offset from mouse to element center
                const rect = target.getBoundingClientRect();
                offsetX = dragEvent.clientX - (rect.left + rect.width / 2);
                offsetY = dragEvent.clientY - (rect.top + rect.height / 2);

                // Hide other draggables
                const draggables = draggablesContainer.querySelectorAll('[draggable="true"]');
                draggables.forEach(draggable => {
                    if (draggable !== target) {
                        draggable.setAttribute('draggable', 'false');
                    }
                });

                // Add this line to store the attachment type
                dragEvent.dataTransfer?.setData('attachment', target.dataset.attachment || '');
            });

            document.addEventListener('dragover', (event: Event) => {
                const dragEvent = event as DragEvent;
                if (draggedElement) {
                    dragEvent.preventDefault();
                    draggedElement.style.left = `${dragEvent.clientX - offsetX}px`;
                    draggedElement.style.top = `${dragEvent.clientY - offsetY}px`;
                }
            });

            draggablesContainer.addEventListener('dragend', () => {
                if (draggedElement) {
                    draggedElement.remove();
                    draggedElement = null;
                }
                
                const draggables = draggablesContainer.querySelectorAll('[draggable="false"]');
                draggables.forEach(draggable => {
                    draggable.setAttribute('draggable', 'true');
                });
            });

            dropZone.addEventListener('dragover', (e) => {
                e.preventDefault();
                dropZone.classList.remove('bg-background');
                dropZone.classList.add('bg-gradient-to-br', 'from-blue-500/20', 'to-indigo-500/20');
            });

            dropZone.addEventListener('dragleave', () => {
                dropZone.classList.remove('bg-gradient-to-br', 'from-blue-500/20', 'to-indigo-500/20');
                dropZone.classList.add('bg-background');
            });

            dropZone.addEventListener('drop', (e: Event) => {
                e.preventDefault();
                const dragEvent = e as DragEvent;
                dropZone.classList.remove('bg-gradient-to-br', 'from-blue-500/20', 'to-indigo-500/20');
                dropZone.classList.add('bg-background');

                // Change this line - it's looking for any draggable element instead of the currently dragged one
                const attachmentType = dragEvent.dataTransfer?.getData('attachment') as AttachmentName;
                this.handleAttachmentDrop(attachmentType);
            });
        }

        // Add click handlers for draggables
        const draggables = this.element.querySelectorAll('[data-attachment]');
        draggables.forEach(draggable => {
            draggable.addEventListener('click', (e) => {
                const attachmentType = draggable.getAttribute('data-attachment') as AttachmentName;
                if (attachmentType) {
                    e.preventDefault();
                    e.stopPropagation();
                    this.handleAttachmentDrop(attachmentType);
                }
            });
        });


        const closeStaging = this.element.querySelector('#close-staging');
        const stagingArea = this.element.querySelector('#staging-area');
        const stagingContent = this.element.querySelector('#staging-content');

        if (closeStaging && stagingArea && stagingContent) {
            closeStaging.addEventListener('click', () => {
                stagingArea.classList.add('hidden');
                stagingContent.innerHTML = '';
            });
        }

        this.loadChats();
        this.setupChatInput();
        UINotifications.hideGlobalLoadingIndication();
    }

    private renderDraggableItem(name: AttachmentName, icon: string, tooltip: string): string {
        const gradients = {
            'Kommin': 'from-[#414141] to-[#272727]',
            'Nearby': 'from-[#154DAD] to-[#CB0CEC]',
            'Commib': 'from-[#0088ff] to-[#8EDBFF]',
            'Analytix': 'from-[#E438B9] to-[#EC4073]',
            'AI': 'from-emerald-500 to-green-600',
            'Reply': 'from-[#ff0000] to-[#AA0606]'
        };

        const iconColors = {
            'Kommin': 'text-orange-400',
            'Nearby': 'text-white',
            'Commib': 'text-white',
            'Analytix': 'text-white',
            'AI': 'text-white',
            'Reply': 'text-white'
        };

        const fontWeight = name === 'Kommin' || name === 'Reply' ? 'font-extrabold' : 'font-bold';

        return `
            <div class="group relative shrink-0 cursor-pointer" draggable="true" data-attachment="${name}">
                <div class="w-12 h-12 sm:w-14 sm:h-14 bg-gradient-to-br ${gradients[name]} rounded-full 
                    flex items-center justify-center text-xl sm:text-2xl ${fontWeight} ${iconColors[name]}
                    shadow-lg hover:scale-105 transition-all duration-300
                    active:scale-95">
                    ${icon}
                </div>
                <!-- Tooltip -->
                <div class="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 px-2 py-1 
                    bg-black/90 text-white text-sm rounded whitespace-nowrap
                    opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                    ${tooltip}
                </div>
            </div>
        `;
    }

    private renderAttachmentOption(name: AttachmentName, icon: string, tooltip: string): string {
        const iconColors = {
            'Kommin': 'text-orange-400',
            'Nearby': 'text-white',
            'Commib': 'text-white',
            'Analytix': 'text-white',
            'AI': 'text-emerald-400',
            'Reply': 'text-red-400'
        };

        return `
            <button class="flex items-center gap-3 w-full px-3 py-2 rounded-lg
                hover:bg-white/5 transition-colors text-white/70 hover:text-white
                text-sm" onclick="handleAttachmentClick('${name}')">
                <span class="${iconColors[name]}">${icon}</span>
                <span>${tooltip}</span>
            </button>
        `;
    }

    private async handleAttachmentDrop(attachmentType: AttachmentName) {
        const stagingArea = this.element.querySelector('#staging-area');
        const stagingContent = this.element.querySelector('#staging-content');
        if (!stagingArea || !stagingContent || !this.nano) return;

        stagingArea.classList.remove('hidden');
        stagingContent.innerHTML = ''; // Clear previous content

        switch (attachmentType) {
            case 'Kommin':
                const komminContent = new KomminSelectionContent((discussion) => {
                    stagingContent.innerHTML = `
                        <div class="flex flex-col gap-2 text-white relative">
                            <div class="flex items-center gap-2 mb-1">
                                <div class="text-sm text-white/60">Discussion by</div>
                                <div class="px-2 py-0.5 text-xs text-white bg-blue-500/30 
                                    rounded-full border border-white/10">
                                    ${this.truncateText(discussion.Author, 20)}
                                </div>
                            </div>
                            <div class="text-base">${this.truncateText(discussion.Body)}</div>
                            <div class="text-xs text-white/40 mt-2">
                                ${discussion.Timestamp}
                            </div>
                            <a href="/kommin/discussion/${Hash.encodeId(discussion.ID)}" 
                                class="absolute bottom-0 right-0 w-8 h-8 flex items-center justify-center
                                bg-white/5 hover:bg-white/10 rounded-lg border border-white/10 
                                transition-colors"
                                target="_blank">
                                <i class="fas fa-external-link-alt text-white/60"></i>
                            </a>
                        </div>
                    `;
                    this.currentAttachmentId = discussion.ID;
                    this.currentAttachmentType = 'Kommin';
                    this.modal.close();
                });
                this.modal.clear();
                this.modal.append(komminContent.getElement());
                this.modal.open();
                
                // Only triggers when user clicks overlay or close button
                this.modal.onVoluntaryClose(() => this.clearStagingArea());
                break;
            case 'Nearby':
                const nearbyContent = new NearbySelectionContent();
                this.modal.clear();
                this.modal.append(nearbyContent.getElement());
                this.modal.open();
                this.clearStagingArea(); // Clear staging area after opening modal
                break;
            case 'Commib':
                stagingContent.innerHTML = '<div class="animate-pulse text-white/60">Loading icebreaker...</div>';
                const commib = await PlatoChatService.getHighestRatedCommibForNano(
                    this.nano.user1_id,
                    this.nano.user2_id,
                    this.nano.user3_id,
                    this.nano.user4_id
                );
                if (commib) {
                    stagingContent.innerHTML = `
                        <div class="flex flex-col gap-2 text-white">
                            <div class="flex items-center gap-2 mb-1">
                                <div class="text-sm text-white/60">Icebreaker by</div>
                                <div class="px-2 py-0.5 text-xs text-white bg-blue-500/30 
                                    rounded-full border border-white/10">
                                    ${this.truncateText(commib.author, 20)}
                                </div>
                            </div>
                            <div class="text-lg">${this.truncateText(commib.content)}</div>
                            <div class="flex gap-2 text-sm text-white/40">
                                <span>Avg Rating: ${commib.avgRating.toFixed(1)}</span>
                                <span></span>
                                <span>Views: ${commib.views}</span>
                            </div>
                        </div>
                    `;
                    this.currentAttachmentId = commib.ID;
                    this.currentAttachmentType = 'Commib';
                    this.modal.close();
                } else {
                    stagingContent.innerHTML = `
                        <div class="text-white/60">
                            No icebreaker available at this time
                        </div>
                    `;

                }
                break;
            case 'Analytix':
                const analytixContent = new AnalytixSelectionContent(this.users, (result) => {
                    stagingContent.innerHTML = `
                        <div class="flex flex-col gap-2 text-white relative">
                            <div class="flex items-center gap-2 mb-1">
                                <div class="text-xs text-white/40">Personality Analytix for</div>
                                <div class="px-2 py-0.5 text-xs text-white/60 bg-white/5 rounded-full border border-white/10">
                                    ${this.truncateText(result.userName, 20)}
                                </div>
                            </div>
                            <div class="flex justify-center gap-0.5 max-w-[300px] mx-auto w-full">
                                ${result.scores.map(({ category, score }) => {
                                    const gradientClass = 
                                        category === 'O' ? 'from-purple-500 to-pink-500' :
                                        category === 'C' ? 'from-orange-500 to-yellow-300' :
                                        category === 'E' ? 'from-blue-500 to-cyan-300' :
                                        category === 'A' ? 'from-green-500 to-emerald-300' :
                                                         'from-red-500 to-rose-300';
                                    return `
                                        <div class="flex-1">
                                            <div class="h-20 bg-white/5 rounded-lg relative overflow-hidden">
                                                <div class="absolute bottom-0 left-0 right-0 bg-gradient-to-t ${gradientClass}"
                                                    style="height: ${score}%">
                                                </div>
                                            </div>
                                            <div class="text-[10px] mt-1 text-center">
                                                <div class="text-white/40">${category}</div>
                                                <div class="text-white/60">${score.toFixed(2)}%</div>
                                            </div>
                                        </div>
                                    `;
                                }).join('')}
                            </div>
                        </div>
                    `;
                    this.currentAttachmentId = result.analytixId;
                    this.currentAttachmentType = 'Analytix';
                    this.modal.close();
                });
                this.modal.clear();
                this.modal.append(analytixContent.getElement());
                this.modal.open();
                this.modal.onVoluntaryClose(() => this.clearStagingArea());
                break;
            case 'Reply':
                const replyContent = new ReplySelectionContent(this.nano.ID, (chat) => {
                    stagingContent.innerHTML = `
                        <div class="flex flex-col gap-2 text-white relative">
                            <div class="flex items-center gap-2 mb-1">
                                <div class="text-sm text-white/60">Replying to</div>
                                <div class="px-2 py-0.5 text-xs text-white bg-blue-500/30 
                                    rounded-full border border-white/10">
                                    ${this.truncateText(chat.author, 20)}
                                </div>
                            </div>
                            <div class="text-base">${this.truncateText(chat.content)}</div>
                            <div class="text-white/40 text-xs text-right mt-2">
                                ${(() => {
                                    const date = new Date(chat.timestamp);
                                    const now = new Date();
                                    const diffTime = now.getTime() - date.getTime();
                                    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
                                    
                                    // Format time consistently
                                    const timeStr = date.toLocaleString(undefined, {
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    });

                                    // Today
                                    if (diffDays === 0) {
                                        return `Today, ${timeStr}`;
                                    }
                                    
                                    // Yesterday
                                    if (diffDays === 1) {
                                        return `Yesterday, ${timeStr}`;
                                    }
                                    
                                    // If within the current week (Sunday -> Sunday)
                                    if (diffDays < 7 && date.getDay() <= now.getDay()) {
                                        return date.toLocaleString(undefined, {
                                            weekday: 'long',
                                            hour: '2-digit',
                                            minute: '2-digit'
                                        });
                                    }
                                    
                                    // Otherwise show the full date
                                    return date.toLocaleString(undefined, {
                                        year: 'numeric',
                                        month: 'short',
                                        day: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    });
                                })()}
                            </div>
                        </div>
                    `;
                    this.currentAttachmentId = chat.ID;
                    this.currentAttachmentType = 'Reply';
                    this.modal.close();
                });
                this.modal.clear();
                this.modal.append(replyContent.getElement());
                this.modal.open();
                this.modal.onVoluntaryClose(() => this.clearStagingArea());
                break;
            case 'AI':
                const currentUserId = Number(localStorage.getItem('userId'));
                const currentUser = await AuthService.getUserById(currentUserId);
                const suggestorName = currentUser?.name || 'Unknown User';
                const suggestorData = `${currentUser?.intro || 'No intro'} | ${currentUser?.location || 'No location'}`;

                // Filter out current user from nano users
                const nanoUserIds = [this.nano!.user1_id, this.nano!.user2_id, this.nano!.user3_id, this.nano!.user4_id]
                    .filter(id => id !== 0 && id !== currentUserId);
                const nanoUsers = await Promise.all(nanoUserIds.map(id => AuthService.getUserById(id)));
                const userData = nanoUsers.filter(Boolean).map((user) => 
                    `${(user as UserData).name}: ${(user as UserData).intro || 'No bio available'}`
                ).join('\n');

                // Get last few chats for context
                const lastChats = (await PlatoChatService.getChats(this.nano.ID, 0)) || [];
                const chatContext = lastChats.length > 0 
                    ? lastChats.slice(-5).map(chat => 
                        `${chat.author}: ${chat.content}`
                    ).join('\n')
                    : "No previous chats yet"; // Add default value when no chats exist

                const aiSelectionContent = document.createElement('div');
                aiSelectionContent.className = 'flex flex-col gap-4 p-4';

                const title = document.createElement('div');
                title.className = 'text-lg font-medium text-white';
                title.textContent = 'Injected AI Chat Suggestion';

                const description = document.createElement('div'); 
                description.className = 'text-sm text-white/60';
                description.textContent = 'Using this service gives the AI the last 10 chats that were written and the public info of the users in the chat to generate better suggestions';

                const assistantInfo = document.createElement('div');
                assistantInfo.className = 'flex items-center gap-2 px-3 py-2 bg-white/5 rounded-lg border border-white/10';
                assistantInfo.innerHTML = `
                    <span class="text-sm text-white/60">Assistant:</span>
                    <span class="text-sm text-white">Zen</span>
                `;

                const customInput = document.createElement('textarea');
                customInput.className = 'mt-4 w-full bg-white/5 text-white rounded-xl px-4 py-3 border border-white/10 focus:border-white/20 focus:outline-none placeholder-white/40 resize-none';
                customInput.placeholder = 'What kind of message would you like help with?';
                customInput.maxLength = 500;
                customInput.rows = 3;

                const charCounter = document.createElement('div');
                charCounter.className = 'text-xs text-white/40 mt-1 text-right';
                charCounter.textContent = '0/500';

                customInput.oninput = () => {
                    charCounter.textContent = `${customInput.value.length}/500`;
                };

                const sendButton = document.createElement('button');
                sendButton.className = 'px-4 py-2 bg-emerald-500/20 hover:bg-emerald-500/30 text-white/90 rounded-lg transition-colors';
                sendButton.textContent = 'Get Suggestion';
                sendButton.onclick = async () => {
                    const prompt = customInput.value.trim();
                    if (!prompt) return;

                    sendButton.disabled = true;
                    sendButton.innerHTML = `
                        <div class="flex items-center gap-2">
                            <div class="animate-spin w-4 h-4 border-2 border-white/10 border-t-white/90 rounded-full"></div>
                            <span>Getting suggestion...</span>
                        </div>
                    `;

                    try {
                        const suggestion = await AIService.getInjectedAIResponse(
                            currentUserId,
                            6,
                            prompt,
                            suggestorData,
                            suggestorName,
                            userData,
                            chatContext
                        );

                        if (suggestion) {
                            console.log('Full AI Response:', suggestion);

                            // Parse sections
                            const explanationMatch = suggestion.match(/EXPLANATION:(.*?)SUGGESTION:/s);
                            const suggestionMatch = suggestion.match(/SUGGESTION:(.*?)NOTES:/s);
                            const notesMatch = suggestion.match(/NOTES:(.*?)$/s);

                            const explanation = explanationMatch ? explanationMatch[1].trim() : '';
                            const suggestedText = suggestionMatch ? suggestionMatch[1].trim() : '';
                            const notes = notesMatch ? notesMatch[1].trim() : '';

                            const responseContent = document.createElement('div');
                            responseContent.className = 'flex flex-col gap-4 bg-[#1a1a1a] p-6 rounded-xl';
                            responseContent.innerHTML = `
                                <div class="text-white/80">
                                    <div class="text-sm uppercase text-white/40 mb-2">Explanation</div>
                                    <div class="text-base">${explanation}</div>
                                </div>
                                <div class="bg-black/20 p-4 rounded-lg">
                                    <div class="flex items-center justify-between mb-2">
                                        <div class="text-sm uppercase text-white/40">Suggestion</div>
                                        <button class="populate-btn px-3 py-1 text-sm bg-emerald-500/20 hover:bg-emerald-500/30 text-white/90 rounded-lg transition-colors">
                                            Populate
                                        </button>
                                    </div>
                                    <div contenteditable="true" class="suggestion-text text-white/90 focus:outline-none p-2 rounded bg-black/20">${suggestedText}</div>
                                </div>
                                <div class="text-white/80">
                                    <div class="text-sm uppercase text-white/40 mb-2">Notes</div>
                                    <div class="text-base text-white/60">${notes}</div>
                                </div>
                            `;

                            const populateBtn = responseContent.querySelector('.populate-btn');
                            const suggestionText = responseContent.querySelector('.suggestion-text');
                            
// In the populate button click handler
populateBtn?.addEventListener('click', () => {
    const input = this.element.querySelector('input[type="text"]') as HTMLInputElement;
    if (input && suggestionText) {
        let text = suggestionText.textContent || '';
        // Remove quotes from start and end if they exist
        text = text.replace(/^["'](.*)["']$/, '$1');
        input.value = text;
        input.focus();
        this.modal.close();
    }
});

                            this.modal.clear();
                            this.modal.append(responseContent);
                        }
                    } catch (error) {
                        sendButton.innerHTML = 'Error getting suggestion. Try again.';
                        setTimeout(() => {
                            sendButton.textContent = 'Get Suggestion';
                            sendButton.disabled = false;
                        }, 2000);
                    }
                };

                aiSelectionContent.appendChild(title);
                aiSelectionContent.appendChild(description);
                aiSelectionContent.appendChild(assistantInfo);
                aiSelectionContent.appendChild(customInput);
                aiSelectionContent.appendChild(charCounter);
                aiSelectionContent.appendChild(sendButton);

                this.modal.clear();
                this.modal.append(aiSelectionContent);
                this.modal.open();
                break;
        }
    }

    public mount(container: HTMLElement): void {
        this.modal.mount(document.body);  // Mount to body first
        container.appendChild(this.element);
        this.initialize(container);
    }

    public unmount(): void {
        this.element.remove();
        this.modal.unmount();
    }

    private truncateText(text: string, maxLength: number = 200): string {
        if (text.length <= maxLength) return text;
        return text.substring(0, maxLength - 3) + '...';
    }

    private clearStagingArea(): void {
        const stagingArea = this.element.querySelector('#staging-area');
        const stagingContent = this.element.querySelector('#staging-content');
        if (stagingArea && stagingContent) {
            stagingArea.classList.add('hidden');
            stagingContent.innerHTML = '';
            this.currentAttachmentId = 0;
            this.currentAttachmentType = 'none';
        }
    }

    private async loadChats() {
        const contentZone = this.element.querySelector('#chat-drop-zone');
        if (!contentZone || !this.nano) return;

        // Add skeleton first
    contentZone.innerHTML = `
        <div class="flex flex-col gap-4 px-2 sm:px-4 w-full sm:max-w-4xl mx-auto">
            ${Array(3).fill(0).map(() => `
                <div class="flex flex-col sm:flex-row gap-0 sm:gap-3 items-stretch w-full relative animate-pulse">
                    <!-- Message Skeleton -->
                    <div class="w-full sm:flex-[0.6] min-w-0">
                        <div class="h-full p-4 rounded-xl sm:rounded-xl rounded-b-none 
                            bg-white/5 backdrop-blur-sm border border-white/10 sm:border-b-white/10 border-b-transparent">
                            <!-- Author -->
                            <div class="h-5 w-32 bg-white/10 rounded mb-3"></div>
                            <!-- Content lines -->
                            <div class="space-y-2">
                                <div class="h-4 w-full bg-white/10 rounded"></div>
                                <div class="h-4 w-3/4 bg-white/10 rounded"></div>
                                <div class="h-4 w-1/2 bg-white/10 rounded"></div>
                            </div>
                            <!-- Timestamp -->
                            <div class="h-3 w-24 bg-white/10 rounded mx-auto mt-4"></div>
                        </div>
                    </div>

                    <!-- Mobile Connector -->
                    <div class="sm:hidden h-12 px-6 py-3 bg-gradient-to-b from-white/5 to-white/10
                        border-l border-r border-white/10
                        flex items-center gap-3">
                        <div class="w-6 h-6 rounded-full bg-white/10"></div>
                        <div class="h-3 w-24 bg-white/10 rounded"></div>
                    </div>

                    <!-- Attachment Skeleton -->
                    <div class="w-full sm:flex-[0.4] min-w-0">
                        <div class="h-full p-4 rounded-xl sm:rounded-xl rounded-t-none 
                            backdrop-blur-md bg-opacity-20 border border-white/10
                            bg-white/5">
                            <!-- Header -->
                            <div class="flex items-center gap-2 mb-3">
                                <div class="h-4 w-20 bg-white/10 rounded"></div>
                                <div class="h-4 w-24 bg-white/10 rounded"></div>
                            </div>
                            <!-- Content -->
                            <div class="space-y-2">
                                <div class="h-4 w-5/6 bg-white/10 rounded"></div>
                                <div class="h-4 w-full bg-white/10 rounded"></div>
                                <div class="h-4 w-4/5 bg-white/10 rounded"></div>
                            </div>
                            <!-- Controls -->
                            <div class="flex items-center justify-between mt-4">
                                <div class="h-3 w-16 bg-white/10 rounded"></div>
                                <div class="h-6 w-6 bg-white/10 rounded"></div>
                            </div>
                        </div>
                    </div>
                </div>
            `).join('')}
        </div>
`;

try {
        const chats = await PlatoChatService.getChats(this.nano.ID, 0);
        if (!chats || chats.length === 0) {
            contentZone.innerHTML = `
                <div class="h-full flex flex-col items-center justify-center text-center">
                    <i class="fas fa-comment-dots text-white/10 text-6xl mb-6"></i>
                    <div class="text-xl text-indigo-400 font-light max-w-md">
                        Empty nano here! Get started by dragging an attachment below into the chat and then share your reaction.
                    </div>
                </div>
            `;
            return;
        }
        contentZone.innerHTML = '';

        const chatContainer = document.createElement('div');
        chatContainer.className = 'flex flex-col gap-4 px-2 sm:px-4 w-full sm:max-w-4xl mx-auto';

        for (const chat of chats) {
            const chatElement = document.createElement('div');
            chatElement.className = 'flex flex-col sm:flex-row gap-0 sm:gap-3 items-stretch w-full relative animate-in fade-in slide-in-from-bottom-4 duration-300';
            
            // Main chat content
            const contentHtml = `
                <div class="w-full sm:flex-[0.6] min-w-0">
                    <div class="h-full p-4 rounded-xl sm:rounded-xl rounded-b-none 
                        bg-white/5 hover:bg-white/[0.075] transition-colors
                        border border-white/10 sm:border-b-white/10 border-b-transparent">
                        <div class="font-bold text-white/90 mb-2">${chat.author}</div>
                        <div class="text-white/80 text-base break-words">${chat.content}</div>
                        <div class="text-white/40 text-xs text-right mt-2">
                            ${(() => {
                                const date = new Date(chat.timestamp);
                                const now = new Date();
                                const diffTime = now.getTime() - date.getTime();
                                const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
                                
                                // Format time consistently
                                const timeStr = date.toLocaleString(undefined, {
                                    hour: '2-digit',
                                    minute: '2-digit'
                                });

                                // Today
                                if (diffDays === 0) {
                                    return `Today, ${timeStr}`;
                                }
                                
                                // Yesterday
                                if (diffDays === 1) {
                                    return `Yesterday, ${timeStr}`;
                                }
                                
                                // If within the current week (Sunday -> Sunday)
                                if (diffDays < 7 && date.getDay() <= now.getDay()) {
                                    return date.toLocaleString(undefined, {
                                        weekday: 'long',
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    });
                                }
                                
                                // Otherwise show the full date
                                return date.toLocaleString(undefined, {
                                    year: 'numeric',
                                    month: 'short',
                                    day: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit'
                                });
                            })()}
                        </div>
                    </div>
                </div>
                <!-- Connector area (only visible on mobile) -->
                <div class="sm:hidden h-12 px-6 py-3 bg-gradient-to-b from-white/5 to-[${this.getAttachmentColor(chat)}] 
                    border-l border-r border-white/10
                    flex items-center gap-3">
                    <div class="w-6 h-6 rounded-full bg-gradient-to-br from-blue-500 to-blue-600 
                        flex items-center justify-center shadow-lg
                        ring-2 ring-blue-500/50 ring-offset-1 ring-offset-blue-500/10">
                        <i class="fas fa-paperclip rotate-45 text-white text-sm"></i>
                    </div>
                    <span class="text-xs text-white/60">Reacting to:</span>
                </div>
            `;

            // Attachment section - full width on mobile, 40% on desktop
            let attachment = null;
            if (chat.analytix_id) {
                const analytixData = await AnalytixService.getAnalytixById(chat.analytix_id);
                if (analytixData) {
                    const user = await AuthService.getUserById(analytixData.userId);
                    attachment = {
                        ...analytixData,
                        analytixId: chat.analytix_id,
                        userName: user?.name || 'Unknown User'
                    };
                }
                console.log('Analytix attachment:', attachment);
            } else if (chat.commib_id) {
                attachment = await PlatoChatService.getCommibById(chat.commib_id);
                console.log('Commib attachment:', attachment);
            } else if (chat.post_id) {
                attachment = await KomminService.getDiscussionById(chat.post_id);
                console.log('Kommin attachment:', attachment);
            } else if (chat.reply_id) {
                attachment = await PlatoChatService.getChatById(chat.reply_id);
                console.log('Reply attachment:', attachment);
            }

            const attachmentHtml = `
                <div class="w-full sm:flex-[0.4] min-w-0">
                    <div class="h-full p-4 rounded-xl sm:rounded-xl rounded-t-none 
                        backdrop-blur-md bg-opacity-20 border border-white/10 sm:border-l-0
                        ${this.getAttachmentColor(chat)} 
                        bg-gradient-to-br from-white/5 to-transparent">
                        ${this.buildAttachmentContent(chat, attachment)}
                    </div>
                </div>
            `;

            chatElement.innerHTML = `${contentHtml}${attachmentHtml}`;
            chatContainer.appendChild(chatElement);
        }

        const existingContainer = contentZone.querySelector('.chat-container');
        if (existingContainer) existingContainer.remove();
        
        chatContainer.classList.add('chat-container');
        contentZone.appendChild(chatContainer);

        // Mark chats as read after they're displayed
        const userId = Number(localStorage.getItem('userId'));
        if (userId) {
            await PlatoChatService.markChatsAsRead(this.nano.ID, userId);
        }

    } catch (error) {
        console.error('Failed to load chats:', error);
        contentZone.innerHTML = `
            <div class="h-full flex flex-col items-center justify-center text-center">
                <i class="fas fa-exclamation-circle text-red-400 text-4xl mb-4"></i>
                <div class="text-lg text-white/70 mb-2">Failed to load chats</div>
                <button onclick="window.location.reload()" 
                    class="px-4 py-2 bg-white/10 hover:bg-white/20 rounded-lg
                    text-white/90 text-sm transition-colors">
                    Try Again
                </button>
            </div>
        `;
    }
}
    private getAttachmentColor(chat: ChatResponse): string {
        if (chat.commib_id) return 'bg-[#0D69B4]/40';
        if (chat.post_id) return 'bg-[#B86E00]/40';
        if (chat.reply_id) return 'bg-[#B8160A]/40';
        if (chat.analytix_id) return 'bg-[#8F0988]/40';
        return 'bg-[#313131]/40';
    }

    private buildAttachmentContent(chat: ChatResponse, attachment: any): string {
        if (!attachment) {
            const noAttachmentStrings = [
                'Nothing here', 'Nada', 'Anybody seen my attachment?',
                'Zilch', 'No attachment, no fun', 'Wherefore art thou attachment?',
                '[Enter attachment here]', '♫ If I was an attachment ♫',
                'Attached at the hip', 'Lack of attachment means lack of satisfaction'
            ];
            const randomMessage = noAttachmentStrings[Math.floor(Math.random() * noAttachmentStrings.length)];
            return `<div class="text-white/90 text-sm text-center">${randomMessage}</div>`;
        }

        if (chat.post_id) {
            return `
                <div class="flex flex-col gap-2 text-white break-words">
                    <!-- Author -->
                    <div class="px-2 py-0.5 text-xs text-white bg-blue-500/30 
                        rounded-full border border-white/10 w-fit">
                        ${this.truncateText(attachment.Author, 20)}
                    </div>

                    <!-- Content -->
                    <div class="text-base whitespace-normal break-words">
                        ${this.truncateText(attachment.Body)}
                    </div>

                    <!-- Rating -->
                    <div class="text-xs text-white/40">
                        Avg Rating: ${attachment.AverageRating.toFixed(1)}
                    </div>

                    <!-- Rating Controls -->
                    <div class="flex items-center gap-2">
                        <input type="range" min="1" max="100" step="1" value="50"
                            class="w-24 sm:w-32 md:w-full accent-blue-500"
                            data-discussion-id="${attachment.ID}"
                            oninput="this.nextElementSibling.textContent = this.value">
                        <span class="text-xs text-white/60 w-8">50</span>
                    </div>

                    <button class="px-3 py-1 text-xs bg-blue-500/20 hover:bg-blue-500/30 
                        rounded border border-white/10 transition-colors"
                        onclick="rateDiscussion(${attachment.ID}, this.previousElementSibling.querySelector('input').value)">
                        Rate Discussion
                    </button>

                    <a href="/kommin/discussion/${Hash.encodeId(attachment.ID)}" 
                        class="w-8 h-8 flex items-center justify-center
                        bg-white/5 hover:bg-white/10 rounded-lg border border-white/10 
                        transition-colors"
                        target="_blank">
                        <i class="fas fa-external-link-alt text-white/60"></i>
                    </a>

                    <div class="text-xs text-white/40">
                        ${new Date(attachment.CreatedAt).toLocaleString()}
                    </div>
                </div>
            `;
        }

        if (chat.analytix_id) {
            return `
                <div class="flex flex-col gap-2 text-white">
                    <div class="flex items-center gap-2 mb-1">
                        <div class="text-xs text-white/40">Personality Analytix for</div>
                        <div class="px-2 py-0.5 text-xs text-white/60 bg-white/5 rounded-full border border-white/10">
                            ${this.truncateText(attachment.userName, 20)}
                        </div>
                    </div>
                    <div class="flex justify-center gap-0.5 max-w-[200px] sm:max-w-[300px] mx-auto w-full">
                        ${[
                            { category: 'O', score: attachment.tOpenness, gradient: 'from-purple-500 to-pink-500' },
                            { category: 'C', score: attachment.tConscientiousness, gradient: 'from-orange-500 to-yellow-300' },
                            { category: 'E', score: attachment.tExtraversion, gradient: 'from-blue-500 to-cyan-300' },
                            { category: 'A', score: attachment.tAgreeableness, gradient: 'from-green-500 to-emerald-300' },
                            { category: 'N', score: attachment.tNeuroticism, gradient: 'from-red-500 to-rose-300' }
                        ].map(({ category, score, gradient }) => `
                            <div class="flex-1">
                                <div class="h-20 bg-white/5 rounded-lg relative overflow-hidden">
                                    <div class="absolute bottom-0 left-0 right-0 bg-gradient-to-t ${gradient}"
                                        style="height: ${score}%">
                                    </div>
                                </div>
                                <div class="text-[10px] mt-1 text-center">
                                    <div class="text-white/40">${category}</div>
                                    <div class="text-white/60">${score.toFixed(1)}%</div>
                                </div>
                            </div>
                        `).join('')}
                    </div>
                </div>
            `;
        }

        if (chat.commib_id) {
            return `
                <div class="flex flex-col gap-2 text-white break-words">
                    <!-- Header -->
                    <div class="flex items-center justify-between">
                        <div class="px-2 py-0.5 text-xs text-white bg-blue-500/30 
                            rounded-full border border-white/10">
                            ${this.truncateText(attachment.author, 20)}
                        </div>
                    </div>

                    <!-- Content -->
                    <div class="text-base whitespace-normal break-words p-3 
                        bg-white/5 rounded-lg border border-white/10">
                        ${this.truncateText(attachment.content)}
                    </div>

                    <!-- Rating Controls -->
                    <div class="flex items-center gap-2">
                            <input type="range" min="1" max="100" step="1" value="50"
                            class="w-24 sm:w-32 md:w-full accent-blue-500"                              data-commib-id="${attachment.ID}"
                                oninput="this.nextElementSibling.textContent = this.value">
                        <span class="text-xs text-white/60 w-8">50</span>
                        </div>

                    <button class="px-3 py-1 text-xs bg-blue-500/20 hover:bg-blue-500/30 
                        rounded border border-white/10 transition-colors"
                            onclick="window.rateCommib(${attachment.ID}, parseInt(this.previousElementSibling.querySelector('input').value))">
                            Rate Icebreaker
                        </button>
                    </div>

                    <!-- Stats -->
                    <div class="flex justify-between text-xs text-white/60 mt-1 px-1">
                        <div class="flex items-center gap-1">
                            <i class="fas fa-star text-yellow-500/80"></i>
                            ${attachment.avgRating?.toFixed(1) || 'No ratings'}
                        </div>
                        <div class="flex items-center gap-1">
                            <i class="fas fa-eye"></i>
                            ${attachment.views}
                        </div>
                    </div>
                </div>
            `;
        }

        if (chat.reply_id) {
            return `
                <div class="flex flex-col gap-3 text-white break-words">
                    <!-- Header -->
                    <div class="flex items-center justify-between">
                        <div class="px-2 py-0.5 text-xs text-white bg-red-500/30 
                            rounded-full border border-white/10">
                            ${this.truncateText(attachment.author, 20)}
                        </div>
                        <div class="text-xs text-white/60">
                            <i class="fas fa-reply mr-1"></i>Reply
                        </div>
                    </div>

                    <!-- Content -->
                    <div class="text-base whitespace-normal break-words p-3 
                        bg-white/5 rounded-lg border border-white/10">
                        ${this.truncateText(attachment.content)}
                    </div>

                    <!-- Timestamp -->
                    <div class="text-xs text-white/40 text-right">
                        ${new Date(attachment.timestamp).toLocaleTimeString([], { 
                            hour: '2-digit', 
                            minute: '2-digit'
                        })}
                    </div>
                </div>
            `;
        }

        return '';
    }

    private setupChatInput() {
        const input = this.element.querySelector('input[type="text"]') as HTMLInputElement;
        const sendButton = this.element.querySelector('#send-chat-button') as HTMLButtonElement;
        
        if (!input || !sendButton) {
            console.error('Chat input elements not found');
            return;
        }

        const handleSend = async () => {
            const message = input.value.trim();
            if (!message || !this.nano) return;

            try {
                const userId = Number(localStorage.getItem('userId'));
                const user = this.users.find(u => u.id === userId);
                if (!user) {
                    console.error('User not found:', userId);
                    return;
                }

                const chatParams: ChatParams = {
                    author: user.name,
                    userId: user.id,
                    nanoId: this.nano.ID,
                    content: message,
                    timestamp: new Date().toISOString(),
                    analytixId: undefined,
                    commibId: undefined,
                    postId: undefined,
                    eventId: undefined,
                    replyId: undefined
                };

                // Add attachment if exists
                if (this.currentAttachmentType !== 'none' && this.currentAttachmentId) {
                    switch (this.currentAttachmentType) {
                        case 'Analytix':
                            chatParams.analytixId = Number(this.currentAttachmentId);
                            break;
                        case 'Commib':
                            chatParams.commibId = Number(this.currentAttachmentId);
                            break;
                        case 'Kommin':
                            chatParams.postId = Number(this.currentAttachmentId);
                            break;
                        case 'Reply':
                            chatParams.replyId = Number(this.currentAttachmentId);
                            break;
                    }
                }

                const success = await PlatoChatService.createChat(chatParams);
                if (success) {
                    input.value = '';
                    this.clearStagingArea();
                    await this.loadChats();
                }
            } catch (error) {
                console.error('Error sending chat:', error);
            }
        };

        input.addEventListener('keypress', (e) => {
            if (e.key === 'Enter') {
                e.preventDefault();
                handleSend();
            }
        });

        sendButton.addEventListener('click', (e) => {
            e.preventDefault();
            handleSend();
        });
    }

    private updateStagingContent(type: AttachmentName, data: any) {
        const stagingContent = this.element.querySelector('#staging-content');
        if (!stagingContent) return;

        this.currentAttachmentType = type;
        this.currentAttachmentId = data.id?.toString() || null;

        switch (type) {
            case 'Analytix':
                stagingContent.setAttribute('data-insights', 
                    `${data.userName}: O:${data.scores[0].score}% C:${data.scores[1].score}% E:${data.scores[2].score}% A:${data.scores[3].score}% N:${data.scores[4].score}%`
                );
                break;
            case 'Kommin':
                stagingContent.setAttribute('data-insights', data.Body?.substring(0, 100) + '...');
                break;
            case 'Commib':
                stagingContent.setAttribute('data-insights', data.content);
                break;
            case 'Nearby':
                stagingContent.setAttribute('data-insights', data.title);
                break;
        }
    }

    private async loadAttachment(chat: ChatResponse): Promise<any> {
        if (chat.commib_id) {
            const commib = await PlatoChatService.getCommibById(chat.commib_id);
            if (commib) {
                // Increment view count when loading commib
                await PlatoChatService.incrementViewCount(chat.commib_id);
                return commib;
            }
        }
        // ... rest of the method
    }

    private async handleAIAssist() {
        const userId = Number(localStorage.getItem('userId'));
        if (!userId || !this.nano) return;

        // Get last few chats for context
        const lastChats = (await PlatoChatService.getChats(this.nano.ID, 0)) || [];
        const chatContext = lastChats.length > 0 
            ? lastChats.slice(-5).map(chat => 
                `${chat.author}: ${chat.content}`
            ).join('\n')
            : "No previous chats yet"; // Add default value when no chats exist

        // Get user data
        const userIds = [
            this.nano.user1_id,
            this.nano.user2_id,
            this.nano.user3_id,
            this.nano.user4_id
        ].filter(id => id !== 0);
        
        const users = await Promise.all(userIds.map(id => AuthService.getUserById(id)));
        const userData = users.filter(Boolean).map((user) => 
            `${(user as UserData).name}: ${(user as UserData).intro || 'No bio available'}`
        ).join('\n');

        try {
            const response = await AIService.getInjectedAIResponse(
                userId,
                1, // assistantId for nano chat
                "Suggest a chat message for this conversation",
                "", // suggestorData
                "Nano Chat Assistant",
                userData,
                chatContext
            );

            if (response) {
                const input = this.element.querySelector('input[type="text"]') as HTMLInputElement;
                if (input) {
                    input.value = response;
                    input.focus();
                }
            }
        } catch (error) {
            console.error('AI assist failed:', error);
        }
    }
}
