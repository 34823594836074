import { UINotifications } from '../../utilities/errors';
import { PlatoChatService } from '../platochat/api-services';
import { AuthResponse, LoginData, SignupData, UserData } from './models';
import { LoginResponseRaw, SignupResponseRaw } from './models';

const API_URL = import.meta.env.VITE_AUTH_API_URL
const API_KEY = import.meta.env.VITE_API_KEY

export class AuthService {
    private static SESSION_KEY = 'p5_session_key';
    private static SESSION_ID_KEY = 'p5_session_id';
    private static PUBLIC_KEY_KEY = 'p5_public_key';

    static async signup(data: SignupData): Promise<SignupResponseRaw | null> {
        try {
            UINotifications.showGlobalLoadingIndication();
            
            const response = await fetch(`${API_URL}/signup`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Key': API_KEY
                },
                mode: 'cors',
                credentials: 'omit',
                body: JSON.stringify(data)
            });
            
            const responseData: SignupResponseRaw = await response.json();

            if (!response.ok) {
                throw new Error(responseData.error || 'Signup failed');
            }

            this.saveSessionData({
                session_id: responseData.session.session_id,
                public_key: responseData.session.public_key
            }, responseData.id);

            localStorage.setItem('userId', responseData.id.toString());
            UINotifications.hideGlobalLoadingIndication();
            UINotifications.shipSuccessToUI('Account created successfully!', { persist: true });
            
            window.location.reload();
            return responseData;
        } catch (error) {
            UINotifications.hideGlobalLoadingIndication();
            UINotifications.shipErrorToUI('Signup failed');
            return null;
        }
    }

    static async login(data: LoginData): Promise<LoginResponseRaw | null> {
        try {
            UINotifications.showGlobalLoadingIndication();
            
            const response = await fetch(`${API_URL}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Key': API_KEY
                },
                mode: 'cors',
                credentials: 'omit',
                body: JSON.stringify(data)
            });
            
            const rawResponse: LoginResponseRaw = await response.json();

            if (!response.ok) {
                throw new Error((rawResponse as any).error || 'Login failed');
            }

            this.saveSessionData({
                session_id: rawResponse.session_id,
                public_key: rawResponse.public_key
            }, rawResponse.user.ID);

            UINotifications.hideGlobalLoadingIndication();
            UINotifications.shipSuccessToUI('Logged in successfully!', { persist: true });
            
            //PlatoChatService.addToBalance(rawResponse.user.ID, 15);
            window.location.reload();
            return rawResponse;
        } catch (error) {
            UINotifications.hideGlobalLoadingIndication();
            UINotifications.shipErrorToUI('Login failed');
            return null;
        }
    }

    private static saveSessionData(sessionData: { session_id: number, public_key: string }, userId: number): void {
        try {
            localStorage.setItem(this.SESSION_ID_KEY, sessionData.session_id.toString());
            localStorage.setItem(this.PUBLIC_KEY_KEY, sessionData.public_key);
            localStorage.setItem('userId', userId.toString());
        } catch (error) {
            // Silent fail - errors will be apparent through missing session data
        }
    }

    public static getSessionData(): { session_id: string, public_key: string, userId?: number } | null {
        try {
            const sessionId = localStorage.getItem(this.SESSION_ID_KEY);
            const publicKey = localStorage.getItem(this.PUBLIC_KEY_KEY);
            const userId = localStorage.getItem('userId');

            if (sessionId && publicKey) {
                return { 
                    session_id: sessionId, 
                    public_key: publicKey,
                    userId: userId ? parseInt(userId) : undefined
                };
            }
            return null;
        } catch (error) {
            return null;
        }
    }

    private static clearSessionData(): void {
        try {
            localStorage.removeItem(this.SESSION_ID_KEY);
            localStorage.removeItem(this.PUBLIC_KEY_KEY);
            localStorage.removeItem('userId');
        } catch (error) {
            // Silent fail - next auth check will handle invalid session
        }
    }

    static async logout(): Promise<void> {
        try {
            UINotifications.showGlobalLoadingIndication();
            
            const sessionData = this.getSessionData();
            if (sessionData) {
                await fetch(`${API_URL}/logout`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-API-Key': API_KEY,
                        'X-Session-ID': sessionData.session_id,
                        'X-Public-Key': sessionData.public_key,
                        'X-User-ID': sessionData.userId?.toString() || ''
                    },
                    credentials: 'omit'
                });
            }
            
            this.clearSessionData();
            localStorage.removeItem('userId');
            
            UINotifications.hideGlobalLoadingIndication();
            UINotifications.shipSuccessToUI('Logged out successfully!', { persist: true });
            
            window.location.reload();
        } catch (error) {
            UINotifications.hideGlobalLoadingIndication();
            UINotifications.shipErrorToUI('Logout failed');
        }
    }

    static async getUserById(userId: number): Promise<UserData | null> {
        try {
            const response = await fetch(`${API_URL}/get-user-by-id?userId=${userId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Key': API_KEY
                },
                mode: 'cors',
                credentials: 'omit'
            });

            const userData = await response.json();

            if (!response.ok) {
                throw new Error(userData.error || 'Failed to fetch user data');
            }

            return userData;
        } catch (error) {
            return null;
        }
    }

    static async updateUser(userId: number, updateData: Record<string, any>): Promise<boolean> {
        try {
            const sessionData = this.getSessionData();
            if (!sessionData) {
                throw new Error('No session data found');
            }

            const data = { ...updateData, userId };
            
            const response = await fetch(`${API_URL}/update-user`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Key': API_KEY,
                    'X-Session-ID': sessionData.session_id,
                    'X-Public-Key': sessionData.public_key,
                    'X-User-ID': sessionData.userId?.toString() || ''
                },
                mode: 'cors',
                credentials: 'omit',
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                throw new Error('Failed to update user');
            }

            UINotifications.hideGlobalLoadingIndication();
            UINotifications.shipSuccessToUI('Profile updated successfully!');
            return true;
        } catch (error) {
            UINotifications.hideGlobalLoadingIndication();
            UINotifications.shipErrorToUI('Failed to update profile');
            return false;
        }
    }

    static async sendRegCode(email: string, name: string): Promise<string | null> {
        try {
            const response = await fetch(`${API_URL}/send-reg-code`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Key': API_KEY
                },
                mode: 'cors',
                credentials: 'omit',
                body: JSON.stringify({ email, name })
            });

            const responseData = await response.json();

            if (!response.ok) {
                throw new Error(responseData.error || 'Failed to send registration code');
            }

            return responseData.random_string;
        } catch (error) {
            UINotifications.shipErrorToUI('Failed to send registration code');
            return null;
        }
    }

    static async forgotPassword(email: string): Promise<{ randomString: string; userId: number } | null> {
        try {
            const response = await fetch(`${API_URL}/forgot-password-code`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Key': API_KEY
                },
                mode: 'cors',
                credentials: 'omit',
                body: JSON.stringify({ email })
            });

            const responseData = await response.json();

            if (!response.ok) {
                throw new Error(responseData.error || 'Failed to send forgot password code');
            }

            return {
                randomString: responseData.random_string,
                userId: responseData.userId
            };
        } catch (error) {
            UINotifications.shipErrorToUI('Failed to send forgot password code');
            return null;
        }
    }

    static async resetPassword(id: number, newPassword: string, email: string): Promise<number | null> {
        try {
            const response = await fetch(`${API_URL}/reset-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Key': API_KEY
                },
                mode: 'cors',
                credentials: 'omit',
                body: JSON.stringify({
                    email,
                    id,
                    password: newPassword
                })
            });

            const responseData = await response.json();

            if (!response.ok) {
                throw new Error(responseData.error || 'Failed to reset password');
            }

            return responseData.userId;
        } catch (error) {
            UINotifications.shipErrorToUI('Failed to reset password');
            return null;
        }
    }

    static async submitEasyReport(
        userId: number,
        appSection: string,
        issueType: string,
        description: string
    ): Promise<boolean> {
        try {
            const sessionData = this.getSessionData();
            if (!sessionData) {
                throw new Error('No session data found');
            }

            const response = await fetch(`${API_URL}/easy-report`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Key': API_KEY,
                    'X-Session-ID': sessionData.session_id,
                    'X-Public-Key': sessionData.public_key,
                    'X-User-ID': sessionData.userId?.toString() || ''
                },
                mode: 'cors',
                credentials: 'omit',
                body: JSON.stringify({
                    userId,
                    appSection,
                    issueType,
                    description
                })
            });

            if (!response.ok) {
                throw new Error('Failed to submit report');
            }

            UINotifications.shipSuccessToUI('Thank you for your feedback! Your report has been submitted successfully.', { persist: true });
            return true;
        } catch (error) {
            UINotifications.shipErrorToUI('An error occurred while submitting your report', { persist: true });
            return false;
        }
    }

    static async checkEmailExists(email: string): Promise<boolean> {
        try {
            const response = await fetch(`${API_URL}/check-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Key': API_KEY
                },
                mode: 'cors',
                credentials: 'omit',
                body: JSON.stringify({ email })
            });

            const data = await response.json();
            return data.exists;
        } catch (error) {
            return false;
        }
    }

    static async createGeohash(userId: number, latitude: number, longitude: number): Promise<string | null> {
        try {
            const sessionData = this.getSessionData();
            if (!sessionData) {
                throw new Error('No session data found');
            }

            const response = await fetch(`${API_URL}/create-geohash`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Key': API_KEY,
                    'X-Session-ID': sessionData.session_id,
                    'X-Public-Key': sessionData.public_key,
                    'X-User-ID': sessionData.userId?.toString() || ''
                },
                mode: 'cors',
                credentials: 'omit',
                body: JSON.stringify({
                    user_id: userId,
                    latitude,
                    longitude
                })
            });

            if (!response.ok) {
                throw new Error('Failed to create geohash');
            }

            //PlatoChatService.addToBalance(userId, 555);

            const data = await response.json();
            return data.data;
        } catch (error) {
            UINotifications.shipErrorToUI('Failed to update location');
            return null;
        }
    }

    static async validate(): Promise<boolean> {
        try {
            const sessionData = this.getSessionData();
            if (!sessionData) {
                return false;
            }

            const response = await fetch(`${API_URL}/validate`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Key': API_KEY,
                    'X-Session-ID': sessionData.session_id,
                    'X-Public-Key': sessionData.public_key,
                    'X-User-ID': sessionData.userId?.toString() || ''
                },
                mode: 'cors',
                credentials: 'omit'
            });

            if (!response.ok) {
                await this.logout();
                return false;
            }

            return true;
        } catch (error) {
            await this.logout();
            return false;
        }
    }

    static async getUsers(offset: number): Promise<UserData[]> {
        try {
            const response = await fetch(`${API_URL}/get-users?offset=${offset}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Key': API_KEY
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch users');
            }

            const data = await response.json();
            const users: UserData[] = data.users.map((user: any) => ({
                id: user.ID,
                name: user.Name || '',
                location: user.Location || '',
                intro: user.Intro || '',
                pic: user.Pic || '',
                geohash: user.Geohash || '',
                country: user.Country || '',
                instagramLink: user.InstagramLink || '',
                instagramVotes: user.InstagramVotes || 0,
                extendedBio: user.ExtendedBio || '',
                lookingFor: user.LookingFor || '',
                references: user.References || 0
            }));

            return users;
        } catch (error) {
            UINotifications.shipErrorToUI('Failed to load users');
            return [];
        }
    }

    static async createFavorite(userId: number, favoriteId: number): Promise<boolean> {
        try {
            const sessionData = this.getSessionData();
            if (!sessionData) return false;

            const response = await fetch(`${API_URL}/create-favorite`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Key': API_KEY,
                    'X-Session-ID': sessionData.session_id,
                    'X-Public-Key': sessionData.public_key,
                    'X-User-ID': sessionData.userId?.toString() || ''
                },
                body: JSON.stringify({ userId, favoriteId })
            });

            if (response.status === 409) {
                return true;
            }
            return response.ok;
        } catch (error) {
            return false;
        }
    }

    static async getFavorites(userId: number): Promise<any[]> {
        try {
            const sessionData = this.getSessionData();
            if (!sessionData) return [];

            const response = await fetch(`${API_URL}/get-favorites?userId=${userId}`, {
                headers: {
                    'X-API-Key': API_KEY,
                    'X-Session-ID': sessionData.session_id,
                    'X-Public-Key': sessionData.public_key,
                    'X-User-ID': sessionData.userId?.toString() || ''
                }
            });
            const data = await response.json();
            return data.favorites || [];
        } catch (error) {
            return [];
        }
    }

    static async deleteFavorite(userId: number, favoriteId: number): Promise<boolean> {
        try {
            const sessionData = this.getSessionData();
            if (!sessionData) return false;

            const response = await fetch(`${API_URL}/delete-favorite`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Key': API_KEY,
                    'X-Session-ID': sessionData.session_id,
                    'X-Public-Key': sessionData.public_key,
                    'X-User-ID': sessionData.userId?.toString() || ''
                },
                body: JSON.stringify({ userId, favoriteId })
            });
            return response.ok;
        } catch (error) {
            return false;
        }
    }

    static async uploadGalleryImage(file: File): Promise<any> {
        try {
            const sessionData = this.getSessionData();
            if (!sessionData) return null;

            const formData = new FormData();
            formData.append('image', file);
            
            const response = await fetch(`${API_URL}/upload-gallery-image`, {
                method: 'POST',
                headers: {
                    'X-API-Key': API_KEY,
                    'X-Session-ID': sessionData.session_id,
                    'X-Public-Key': sessionData.public_key,
                    'X-User-ID': sessionData.userId?.toString() || ''
                },
                body: formData
            });
            return response.ok ? await response.json() : null;
        } catch (error) {
            return null;
        }
    }

    static async getFirstImage(userId: number): Promise<any> {
        try {
            const response = await fetch(`${API_URL}/get-first-image?userId=${userId}`, {
                headers: {
                    'X-API-Key': API_KEY
                }
            });
            return response.ok ? await response.json() : null;
        } catch (error) {
            return null;
        }
    }

    static async getGalleryImages(userId?: number): Promise<any[]> {
        try {
            // If no userId provided, use the one from session (for profile page)
            const sessionData = this.getSessionData();
            const targetUserId = userId || sessionData?.userId;
            
            if (!targetUserId) return [];

            const response = await fetch(`${API_URL}/get-gallery-images?userId=${targetUserId}`, {
                headers: {
                    'X-API-Key': API_KEY
                }
            });
            const data = await response.json();
            return data.images || [];
        } catch (error) {
            return [];
        }
    }

    static async deleteGalleryImage(publicId: string): Promise<boolean> {
        try {
            const sessionData = this.getSessionData();
            if (!sessionData) return false;

            const response = await fetch(`${API_URL}/delete-gallery-image?publicId=${publicId}&userId=${sessionData.userId}`, {
                method: 'DELETE',
                headers: {
                    'X-API-Key': API_KEY,
                    'X-Session-ID': sessionData.session_id,
                    'X-Public-Key': sessionData.public_key,
                    'X-User-ID': sessionData.userId?.toString() || ''
                }
            });
            return response.ok;
        } catch (error) {
            return false;
        }
    }

    static async initiateDeleteUser(userId: number, password: string): Promise<{ code: string; userId: number } | null> {
        try {
            const sessionData = this.getSessionData();
            if (!sessionData) return null;

            const response = await fetch(`${API_URL}/delete-user`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Key': API_KEY,
                    'X-Session-ID': sessionData.session_id,
                    'X-Public-Key': sessionData.public_key,
                    'X-User-ID': sessionData.userId?.toString() || ''
                },
                body: JSON.stringify({ userId, password })
            });

            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.error || 'Failed to initiate account deletion');
            }

            return {
                code: data.code,
                userId: data.userId
            };
        } catch (error) {
            UINotifications.shipErrorToUI('Failed to initiate account deletion');
            return null;
        }
    }

    static async confirmDeleteUser(userId: number, confirmationCode: string, expectedCode: string): Promise<boolean> {
        try {
            const sessionData = this.getSessionData();
            if (!sessionData) return false;

            const response = await fetch(`${API_URL}/confirm-delete-user`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Key': API_KEY,
                    'X-Session-ID': sessionData.session_id,
                    'X-Public-Key': sessionData.public_key,
                    'X-User-ID': sessionData.userId?.toString() || ''
                },
                body: JSON.stringify({
                    userId,
                    confirmationCode,
                    expectedCode
                })
            });

            if (!response.ok) {
                throw new Error('Failed to confirm account deletion');
            }

            return true;
        } catch (error) {
            UINotifications.shipErrorToUI('Failed to delete account');
            return false;
        }
    }

    static async getUsersForFeed(offset: number): Promise<UserData[]> {
        try {
            const response = await fetch(`${API_URL}/get-users-for-feed?offset=${offset}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Key': API_KEY
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch users for feed');
            }

            const data = await response.json();
            return data.users.map((user: any) => ({
                id: user.id,
                name: user.name,
                location: user.location,
                intro: user.intro
            }));
        } catch (error) {
            UINotifications.shipErrorToUI('Failed to load users feed');
            return [];
        }
    }

    static async uploadVoiceMemo(discussionId: number, audioFile: File, caption: string): Promise<any> {
        try {
            const sessionData = this.getSessionData();
            if (!sessionData) return null;

            const userId = localStorage.getItem('userId');
            if (!userId) return null;

            const formData = new FormData();
            formData.append('audio', audioFile);
            formData.append('caption', caption);

            const response = await fetch(`${API_URL}/upload-voice-memo`, {
                method: 'POST',
                headers: {
                    'X-API-Key': API_KEY,
                    'X-Session-ID': sessionData.session_id,
                    'X-Public-Key': sessionData.public_key,
                    'X-User-ID': userId,
                    'X-Discussion-ID': discussionId.toString()
                },
                body: formData
            });

            if (!response.ok) {
                throw new Error('Failed to upload voice memo');
            }

            const data = await response.json();
            return data.memo;
        } catch (error) {
            UINotifications.shipErrorToUI('Failed to upload voice memo');
            return null;
        }
    }

    static async uploadDiscussionImage(discussionId: number, imageFile: File): Promise<any> {
        try {
            const sessionData = this.getSessionData();
            if (!sessionData) return null;

            const formData = new FormData();
            formData.append('image', imageFile);

            const response = await fetch(`${API_URL}/upload-discussion-image`, {
                method: 'POST',
                headers: {
                    'X-API-Key': API_KEY,
                    'X-Session-ID': sessionData.session_id,
                    'X-Public-Key': sessionData.public_key,
                    'X-User-ID': sessionData.userId?.toString() || '',
                    'X-Discussion-ID': discussionId.toString()
                },
                body: formData
            });

            if (!response.ok) {
                throw new Error('Failed to upload discussion image');
            }

            const data = await response.json();
            return data.image;
        } catch (error) {
            UINotifications.shipErrorToUI('Failed to upload discussion image');
            return null;
        }
    }

    static async getDiscussionPictures(discussionId: number): Promise<any[]> {
        try {
            const response = await fetch(`${API_URL}/get-discussion-pictures?discussion_id=${discussionId}`, {
                method: 'GET',
                headers: {
                    'X-API-Key': API_KEY
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch discussion pictures');
            }

            const data = await response.json();
            return data.pictures;
        } catch (error) {
            UINotifications.shipErrorToUI('Failed to load discussion pictures');
            return [];
        }
    }

    static async getDiscussionMemos(discussionId: number): Promise<any[]> {
        try {
            const response = await fetch(`${API_URL}/get-discussion-memos?discussion_id=${discussionId}`, {
                method: 'GET',
                headers: {
                    'X-API-Key': API_KEY
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch discussion memos');
            }

            const data = await response.json();
            return data.memos;
        } catch (error) {
            UINotifications.shipErrorToUI('Failed to load discussion voice memos');
            return [];
        }
    }

    static async deleteDiscussionMemo(discussionId: number, publicId: string): Promise<boolean> {
        try {
            const sessionData = this.getSessionData();
            if (!sessionData) return false;

            const response = await fetch(`${API_URL}/delete-discussion-memo`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Key': API_KEY,
                    'X-Session-ID': sessionData.session_id,
                    'X-Public-Key': sessionData.public_key,
                    'X-User-ID': sessionData.userId?.toString() || '',
                    'X-Discussion-ID': discussionId.toString()
                },
                body: JSON.stringify({ public_id: publicId })
            });

            if (!response.ok) {
                throw new Error('Failed to delete voice memo');
            }

            return true;
        } catch (error) {
            UINotifications.shipErrorToUI('Failed to delete voice memo');
            return false;
        }
    }

    static async deleteDiscussionImage(discussionId: number, publicId: string): Promise<boolean> {
        try {
            const sessionData = this.getSessionData();
            if (!sessionData) return false;

            const response = await fetch(`${API_URL}/delete-discussion-image`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Key': API_KEY,
                    'X-Session-ID': sessionData.session_id,
                    'X-Public-Key': sessionData.public_key,
                    'X-User-ID': sessionData.userId?.toString() || '',
                    'X-Discussion-ID': discussionId.toString()
                },
                body: JSON.stringify({ public_id: publicId })
            });

            if (!response.ok) {
                throw new Error('Failed to delete image');
            }

            return true;
        } catch (error) {
            UINotifications.shipErrorToUI('Failed to delete image');
            return false;
        }
    }
}